<mat-card class="mat-elevation-z8">
    <mat-card-content>
        <form id="matching-vcc-detail-viewer-form" class="template-form">
            <div id="matching-vcc-detail-main-container" class="container">
                @if(details?.merchantName) {
                    <read-only-form-field title="Merchant Name" testId="matching-vcc-detail-merchant-name" value="{{details?.merchantName}}" />
                }
                @if(details?.merchantGuid) {
                    <read-only-form-field title="Merchant Guid" testId="matching-vcc-detail-merchant-guid" value="{{details?.merchantGuid}}" />
                }
                @if(details?.releasedDate) {
                    <read-only-form-field title="Released Date" testId="matching-vcc-detail-released-date" value="{{details?.releasedDate | date:'M/d/yyyy'}}" />
                }
                @if(details?.connexPayDescription) {
                    <read-only-form-field title="ConnexPay Description" testId="matching-vcc-detail-connexpay-description" value="{{details?.connexPayDescription}}" />
                }
                @if(details?.cardGuid) {
                    <read-only-form-field title="Card Guid" testId="matching-vcc-detail-card-guid" value="{{details?.cardGuid}}" />
                }
                @if(details?.connexPayTripReferenceNumber) {
                    <read-only-form-field title="ConnexPay Trip Reference Number" testId="matching-vcc-detail-connexpay-trip-reference-number" value="{{details?.connexPayTripReferenceNumber}}" />
                }
                @if(details?.adxTripReferenceNumber) {
                    <read-only-form-field title="Adx Trip Reference Number" testId="matching-vcc-detail-adx-trip-reference-number" value="{{details?.adxTripReferenceNumber}}" />
                }
                @if(details?.tramsInvoiceNumber) {
                    <read-only-form-field title="Trams Invoice Number" testId="matching-vcc-detail-trams-invoice-number" value="{{details?.tramsInvoiceNumber}}" />
                }
                @if(details?.vccNumber) {
                    <read-only-form-field title="Vcc Number" testId="matching-vcc-detail-vcc-number" value="{{details?.vccNumber}}" />
                }
                @if(details?.serviceStartDate) {
                    <read-only-form-field title="Service Start Date" testId="matching-vcc-detail-service-start-date" value="{{details?.serviceStartDate | date:'M/d/yyyy'}}" />
                }
                @if(details?.serviceEndDate) {
                    <read-only-form-field title="Service End Date" testId="matching-vcc-detail-service-end-date" value="{{details?.serviceEndDate | date:'M/d/yyyy'}}" />
                }
                @if(details?.adxIssuedDate) {
                    <read-only-form-field title="Adx Issued Date" testId="matching-vcc-detail-adx-issued-date" value="{{details?.adxIssuedDate | date:'M/d/yyyy'}}" />
                }
                @if(details?.chargeDate) {
                    <read-only-form-field title="Charge Date" testId="matching-vcc-detail-charge-date" value="{{details?.chargeDate | date:'M/d/yyyy'}}" />
                }
                @if(details?.expectedVendorName) {
                    <read-only-form-field title="Expected Vendor Name" testId="matching-vcc-detail-expected-vendor-name" value="{{details?.expectedVendorName}}" />
                }
                @if(details?.vendorName) {
                    <read-only-form-field title="Vendor Name" testId="matching-vcc-detail-vendor-name" value="{{details?.vendorName}}" />
                }
                @if(details?.connexPayCurrency) {
                    <read-only-form-field title="ConnexPay Currency" testId="matching-vcc-detail-connexpay-currency" value="{{details?.connexPayCurrency}}" />
                }
                @if(details?.adxCurrency) {
                    <read-only-form-field title="Adx Currency" testId="matching-vcc-detail-adx-currency" value="{{details?.adxCurrency}}" />
                }
                @if(details?.tramsCurrency) {
                    <read-only-form-field title="Trams Currency" testId="matching-vcc-detail-trams-currency" value="{{details?.tramsCurrency}}" />
                }
                @if(details?.expectedChargeAmount) {
                    <read-only-form-field title="Expected Charge Amount" testId="matching-vcc-detail-expected-charge-amount" value="{{details?.expectedChargeAmount}}" />
                }
                @if(details?.connexPayCurrencyAmount) {
                    <read-only-form-field title="ConnexPay Currency Amount" testId="matching-vcc-detail-connexpay-currency-amount" value="{{details?.connexPayCurrencyAmount}}" />
                }
                @if(details?.vccCurrency) {
                    <read-only-form-field title="Vcc Currency" testId="matching-vcc-detail-vcc-currency" value="{{details?.vccCurrency}}" />
                }
                @if(details?.tramsBookingTotal) {
                    <read-only-form-field title="Trams Booking Total" testId="matching-vcc-detail-trams-booking-total" value="{{details?.tramsBookingTotal}}" />
                }
                @if(details?.tramsCommissionAmount) {
                    <read-only-form-field title="Trams Commission Amount" testId="matching-vcc-detail-trams-commission-amount" value="{{details?.tramsCommissionAmount}}" />
                }
                @if(details?.varianceAmount) {
                    <read-only-form-field title="Suggested Adjustment to Commission Amount" testId="matching-vcc-suggested-adjustment-commission-amount" value="{{details?.varianceAmount}}" />
                }
                @if(details?.variance) {
                    <read-only-form-field title="Variance" testId="matching-vcc-variance" value="{{details?.variance}}" />
                }
                @if(details?.agentName) {
                    <read-only-form-field title="Agent Name" testId="matching-vcc-agent-name" value="{{details?.agentName}}" />
                }
                @if(details?.customerId) {
                    <read-only-form-field title="Customer Id" testId="matching-vcc-customer-id" value="{{details?.customerId}}" />
                }
                @if(details?.advisorName) {
                    <read-only-form-field title="Advisor Name" testId="matching-vcc-advisor-name" value="{{details?.advisorName}}" />
                }
                @if(details?.tramsDataSourceName) {
                    <read-only-form-field title="Trams Data Source Name" testId="matching-vcc-trams-data-source-name" value="{{details?.tramsDataSourceName}}" />
                }
            </div>
        </form>
    </mat-card-content>
</mat-card>
