<form [formGroup]="glForm" (submit)="createGeneralLedger()">
    <modal-panel
        data-testid="create-general-ledger-form-modal"
        class="filter-panel"
        [title]="'Create new General Ledger'"
        (close)="closeGeneralLedgerModal();"
        >
        <div data-testid="create-general-ledger-form-modal-content" class="filter__fields">
            <mat-form-field>
                <mat-label>GL Number</mat-label>
                <input matInput placeholder="GL Number" value="" formControlName="glNo" type="number" step="1" min="0" (keypress)="checkInputInteger($event)">
                @if(glForm.controls['glNo'].value){
                    <button 
                        data-testid="create-general-ledger-form-modal-glNo-clear-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFields('glNo')">
                            <mat-icon>close</mat-icon>
                    </button>
                }
            </mat-form-field>
            <mat-form-field>
                <mat-label>GL Name</mat-label>
                <input matInput placeholder="GL Name" value="" formControlName="glName" (blur)="updateErrorMessage()">
                @if(glForm.controls['glName'].value){
                    <button 
                        data-testid="create-general-ledger-form-modal-glName-clear-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFields('glName')">
                            <mat-icon>close</mat-icon>
                    </button>
                }
                @if (glForm.controls['glName'].invalid) {
                    <mat-error>{{errorMessage()}}</mat-error>
                }
            </mat-form-field>

            <mat-form-field>
                <mat-label>GL Type</mat-label>
                <mat-select formControlName="glType">
                    <mat-option value="G/L Account">G/L Account</mat-option>
                    <mat-option value="Vendor">Vendor</mat-option>
                </mat-select>
                @if(glForm.controls['glType'].value){
                    <button 
                        data-testid="create-general-ledger-form-modal-glType-clear-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFields('glName')">
                            <mat-icon>close</mat-icon>
                    </button>
                }
            </mat-form-field>
        </div>

        <ng-container data-testid="create-general-ledger-form-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button data-testid="create-general-ledger-form-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                    <mat-icon data-testid="create-general-ledger-form-modal-icon-clear-all-filter-button-icon">delete_outline</mat-icon>
                    Clear All
                </button>
                <button data-testid="create-general-ledger-form-modal-submit-button" mat-raised-button color="primary" type="submit" [disabled]="glForm.invalid || isLoading">
                    @if (isLoading) {
                        <mat-spinner
                        data-testid="create-general-ledger-form-modal-submit-button-spinner"
                        color="accent"
                        diameter="30">
                      </mat-spinner>
                    } @else {
                        Create
                    }
                </button>
            </div>
        </ng-container>
    </modal-panel>
</form>