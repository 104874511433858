import {Component} from '@angular/core';
import {ChatBotService} from './chat-bot.service';
import {IConversationMessage} from '../model/conversation-message';

@Component({
  selector: 'app-chatbot',
  providers: [ChatBotService],
  templateUrl: './chatbot.component.html'
})
export class ChatbotComponent {
  public messages: IConversationMessage[] = [];
  public chatboxOpen: boolean = false;

  constructor(private chatBotService: ChatBotService) {
  }

  public ngOnInit() {
    this.messages = this.chatBotService.messages;
  }

  public toggleChatbox() {
    this.chatboxOpen = !this.chatboxOpen;
  }

  public handlePromptChange($event: string) {
    this.messages.push({
      from: 'user',
      text: $event
    });
    this.chatBotService.submitPrompt($event).then(
      (res) => {
        this.messages.push(<IConversationMessage>{
          from: 'bot',
          text: res?.content.trim()
        });
      },
      (err) => {
        this.messages.push({
          from: 'bot',
          text: err
        });
    });
  }
}
