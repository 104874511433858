import { Component } from '@angular/core';

@Component({
  selector: 'app-client-balance',
  standalone: true,
  imports: [],
  templateUrl: './client-balance.component.html'
})
export class ClientBalanceComponent {

}
