import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, Observable } from "rxjs";
import { removeEmptyParams } from "src/infrastructure/helper/remove-empty-params";
import { AdxPaymentFilter, AdxPaymentResponse, SearchAdxPaymentParams } from "../models/adx-payment";
import * as moment from "moment";

@Injectable({
    providedIn: 'root',
})
export class AdxPaymentService {
    
    private _dataSourceReceived$ = new BehaviorSubject<AdxPaymentFilter | {}>({});
    private _dataSourceMade$ = new BehaviorSubject<AdxPaymentFilter | {}>({});


    constructor(private http:HttpClient) {}

    getPaymentReceivedFilter(): Observable<AdxPaymentFilter | {}> {
        return this._dataSourceReceived$.asObservable();
    }
    
    setPaymentReceivedFilter(value:AdxPaymentFilter): void {
        this._dataSourceReceived$.next(value || null);
    }

    getPaymentMadeFilter(): Observable<AdxPaymentFilter | {}> {
        return this._dataSourceMade$.asObservable();
    }
    
    setPaymentMadeFilter(value:AdxPaymentFilter): void {
        this._dataSourceMade$.next(value || null);
    }

    getAdxPaymentFilters(filters?: AdxPaymentFilter): Record<string, string | boolean| number> {
        return {
            paymentDateFrom: filters?.paymentDateFrom ? moment(filters?.paymentDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
            paymentDateTo: filters?.paymentDateTo ? moment(filters?.paymentDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
            profileTypeCode: filters?.profileTypeCode ?? '',
            payTypeId: filters?.payTypeId ?? '',
            payMethodId: filters?.payMethod ?? '',
            branchId: filters?.branch ?? '',
            bankId: filters?.bank ?? '',
            adxInvoiceId: filters?.invoiceId ?? '',
            clientName: filters?.payeeName ?? '',
            adxInvoiceRecordLocator: filters?.adxInvoiceRecordLocator ?? '',
        }
    }

    searchAdxPayment({filters,page,sort}:SearchAdxPaymentParams): Observable<AdxPaymentResponse> {
        const params:Record<string, string | number | boolean> = {
            pageSize:  page?.pageSize || 25,
            pageNumber : (page?.pageIndex || 0) + 1,
            propertyName: sort?.active || '',
            orderKey: sort?.direction || '',

            ...this.getAdxPaymentFilters(filters),

        };
      
        removeEmptyParams(params);

        return  this.http.get<AdxPaymentResponse>('dbov2/adx-payment/search', 
            { params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }
}