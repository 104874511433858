import {Component, Optional} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'nav-export-send-modal',
    templateUrl: './nav-export-send-modal.component.html',
})
export class NavExportSendModalComponent {

    formSendModal!:FormGroup;

    showPassword = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<NavExportSendModalComponent>,
        private formBuilder:FormBuilder,
    ){
        this.formSendModal = this.formBuilder.group({
            userName: [null, Validators.required],
            password: [null, Validators.required],
            batch: [null, Validators.required],
        });
    }

    clearFormFields(...args:string[]){
        args.forEach((field)=>{
            this.formSendModal.controls[field].reset()
        })
    }

    closeModal(){
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    submitForm(){
        this.dialogRef.close();
    }
}