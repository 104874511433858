import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {InvoiceService} from '../../service/invoice.service';
import {BookingUdidInfoComponent} from '../popup/booking-udid-info/booking-udid-info.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AgentInfoPopupComponent} from '../popup/agent-info-popup/agent-info-popup.component';
import {DatePipe} from '@angular/common';
import {InvoiceLineItemService} from '../../service/invoice-line-item.service';
import {InvoiceDetailResponse} from '../../model/invoice-detail-model';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {SegmentInfoComponent} from '../popup/segment-info/segment-info.component';
import {SegmentsInfoModel} from '../../model/segments-info-model';

@Component({
  selector: 'app-invoice-info',
  templateUrl: './invoice-info.component.html'
})
export class InvoiceInfoComponent {
  @Input() invoiceInfo!: { invoiceId: string; invoiceLineItemId: string; };
  public invoiceData: any = [];
  public selectedTramsBookingNo: string = "Invoice";
  loading$: any;
  constructor(
    private invoiceService: InvoiceService,
    private invoiceLineItemService: InvoiceLineItemService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public loader: LoadingService) {
  }

  ngOnInit() {
    this.loading$ = this.loader.loading$;
    if (this.invoiceInfo.invoiceId != null) {
      this.getTramsInvoiceDetail();
    } else {
      this.getAdxInvoiceLineItemDetail();
    }
  }

  getTramsInvoiceDetail() {
    this.invoiceService.getInvoiceById(this.invoiceInfo.invoiceId)
      .subscribe((data: InvoiceDetailResponse) => {
        this.invoiceData = data;
      });
  }

  getAdxInvoiceLineItemDetail() {
    this.invoiceLineItemService.getInvoiceLineItemById(this.invoiceInfo.invoiceLineItemId)
      .subscribe((data: InvoiceDetailResponse) => {
        data.bookings?.forEach((item, index) => {
          item.tramsBookingNo = index + 1;
        });
        this.invoiceData = data;
      });
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }

  areTaxesDefined(booking: InvoiceDetailResponse): boolean {
    return (
      booking?.tax1Amt !== undefined ||
      booking?.tax2Amt !== undefined ||
      booking?.tax3Amt !== undefined ||
      booking?.gstAmt !== undefined ||
      booking?.qstAmt !== undefined ||
      booking?.clientGstAmt !== undefined
    );
  }

  getTotalTaxes(booking: InvoiceDetailResponse): number | undefined {
    if (!this.areTaxesDefined(booking)) {
      return undefined;
    }
    const tax1Amt = booking?.tax1Amt ?? 0;
    const tax2Amt = booking?.tax2Amt ?? 0;
    const tax3Amt = booking?.tax3Amt ?? 0;
    const gstAmt = booking?.gstAmt ?? 0;
    const qstAmt = booking?.qstAmt ?? 0;
    const clientGstAmt = booking?.clientGstAmt ?? 0;

    const totalTaxes = tax1Amt + tax2Amt + tax3Amt + gstAmt + qstAmt + clientGstAmt;

    return totalTaxes;
  }

  getUdidValues(bookingUdids: any[]): string {
    return bookingUdids.map(udid => udid.udidValue).join(', ');
  }

  getAgentValues(agentBookings: any[]): string {
    return agentBookings.map(agent => agent.agentName).join(', ');
  }

  getSegmentValues(segmentBookings: SegmentsInfoModel[]): string {
    return segmentBookings.map(segment => `${segment.departureCity} - ${segment.arrivalCity}`).join(', ');
  }

  getMainPassengers(bookings: any[]): string {
    return bookings
      .map(booking => booking.passengerName)
      .filter(name => name)
      .join(', ');
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTramsBookingNo = event.tab.textLabel;
  }

  showMoreAboutBookingUdidsInfo() {
    const currentTabBooking = this.invoiceData.bookings.find((booking: any) => booking.tramsBookingNo === this.selectedTramsBookingNo);
    let dialogConfirmationRef =
      this.dialog.open(BookingUdidInfoComponent, {
        width: '30%',
        data: {
          bookingNo: this.selectedTramsBookingNo,
          bookingUdids: currentTabBooking.bookingUdids
        }
      })
    dialogConfirmationRef.afterClosed().subscribe(
      (result: boolean) => {
        if (result ?? false) {
          console.log(result);
        }
      }
    );
  }

  showMoreAboutAgentsInfo() {
    const currentTabBooking = this.invoiceData.bookings.find((booking: any) => booking.tramsBookingNo === this.selectedTramsBookingNo);
    let dialogConfirmationRef =
      this.dialog.open(AgentInfoPopupComponent, {
        width: '30%',
        data: {
          bookingNo: this.selectedTramsBookingNo,
          agents: currentTabBooking.agentBookings
        }
      })
    dialogConfirmationRef.afterClosed().subscribe(
      (result: boolean) => {
        if (result != null && result) {
          console.log(result);
        }
      }
    );
  }

  showMoreAboutSegmentsInfo() {
    const currentTabBooking = this.invoiceData.bookings.find((booking: SegmentsInfoModel) => booking.tramsBookingNo === this.selectedTramsBookingNo);
    let dialogConfirmationRef =
      this.dialog.open(SegmentInfoComponent, {
        width: '30%',
        data: {
          bookingNo: this.selectedTramsBookingNo,
          segments: currentTabBooking.bookingSegments
        }
      })
    dialogConfirmationRef.afterClosed().subscribe(
      (result: boolean) => {
        if (result != null && result) {
          console.log(result);
        }
      }
    );
  }
}
