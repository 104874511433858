@if(!isLoading)
{
  @if(title)
  {
    <mat-dialog-content  class="flex-space-between">
      <mat-label id="trip-reference-label-title" class="modal-title-bold">{{this.title}}</mat-label>
      <mat-dialog-actions align="end">
        <button id="trip-reference-cancel-button" mat-icon-button color="primary" aria-label="Close Modal"
          (click)="handleTripReferenceCloseBtnClick()">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-dialog-actions>
    </mat-dialog-content>
  }
  <form id="trip-reference-viewer-form" class="template-form">
    <div id="trip-reference-main-container" class="container">
      @if(_booking.recordLocator)
      {
        <mat-form-field id="trip-reference-record-locator-field">
          <mat-label id="trip-reference-record-locator-title">Trip Id: </mat-label>
          <input id="trip-reference-record-locator-input-field" matInput value="{{_booking.recordLocator}}">
        </mat-form-field>
      }
      @if(_booking.departDate)
      {
        <mat-form-field id="trip-reference-service-depart-date">
          <mat-label id="trip-reference-service-depart-date-title">Service Start Date: </mat-label>
          <input id="trip-reference-service-depart-date-input-field" matInput value="{{_booking.departDate | date: 'M/d/yyyy'}}">
        </mat-form-field>
      }
      @if(_booking.returnDate)
      {
        <mat-form-field id="trip-reference-service-return-date">
          <mat-label id="trip-reference-service-return-date-title">Service End Date: </mat-label>
          <input id="trip-reference-service-return-date-input-field" matInput value="{{_booking.returnDate | date: 'M/d/yyyy'}}">
        </mat-form-field>
      }
      @if(_booking.confirmationNumber)
      {
        <mat-form-field id="trip-reference-confirmation-number-field">
          <mat-label id="trip-reference-confirmation-number-field-title">Confirmation Number: </mat-label>
          <input id="trip-reference-confirmation-number-input-field" matInput value="{{_booking.confirmationNumber}}">
        </mat-form-field>
      }
      @if(_booking.reservationNo)
      {
        <mat-form-field id="trip-reference-reservation-no-field">
          <mat-label id="trip-reference-reservation-no-field-title">Reservation No: </mat-label>
          <input id="trip-reference-reservation-no-input-field" matInput value="{{_booking.reservationNo}}">
        </mat-form-field>
      }
      @if( _booking.vendor && _booking.vendor.vendorName)
      {
        <mat-form-field id="trip-reference-vendor-name-field">
          <mat-label id="trip-reference-vendor-name-field-title">Vendor Name: </mat-label>
          <input id="trip-reference-vendor-name-input-field" matInput value="{{_booking.vendor.vendorName}}">
        </mat-form-field>
      }
      @if(_booking.totalFare)
      {
        <mat-form-field id="trip-reference-total-fare">
          <mat-label id="trip-reference-total-fare-title">Total Fare: </mat-label>
          <input id="trip-reference-total-fare-input-field" matInput value="{{_booking.totalFare }}">
        </mat-form-field>
      }
      @if(_booking.currency.currencyName)
      {
        <mat-form-field id="trip-reference-service-cost-currency-code-field">
          <mat-label id="trip-reference-service-cost-currency-code-title">Service Cost Currency: </mat-label>
          <input id="trip-reference-service-cost-currency-input-field" matInput value="{{_booking.currency.currencyName}}">
        </mat-form-field>
      }
      @if(_booking.tramsDataSource)
      {
        <mat-form-field id="trip-reference-trams-data-source-field">
          <mat-label id="trip-reference-trams-data-source-field-title">Trams Data Source: </mat-label>
          <input id="trip-referencetrams-data-source-input-field" matInput value="{{_booking.tramsDataSource.sourceName}}">
        </mat-form-field>
      }
      @if(_booking.submitToCode)
      {
        <mat-form-field id="trip-reference-submit-to-code-field">
          <mat-label id="trip-reference-submit-to-code-field-title">Submit To Code: </mat-label>
          <input id="trip-reference-submit-to-code-input-field" matInput value="{{_booking.submitToCode}}">
        </mat-form-field>
      }
    </div>
  </form>
 }
 @else{
  <div class="loading-spinner">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
 }
