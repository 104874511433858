import {Component,Inject, Input, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AdxBookingResponse} from 'src/app/adx-booking/model/adx-booking-response';
import {AdxBookingService} from 'src/app/adx-booking/service/adx-booking.service';

@Component({
  selector: 'trip-reference-modal',
  templateUrl: './trip-reference-modal.component.html'
})

@AutoUnsubscribe()
export class TripReferenceComponent {
  @Input() public title!: string;
  _booking!: AdxBookingResponse;
  isLoading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    @Optional() public dialogRef: MatDialogRef<TripReferenceComponent>,
    private adxBookingService: AdxBookingService) {
  }

  ngOnInit() {
    this.adxBookingService.getAdxBookingByTravelServiceId(this.data.travelServiceId)
    .subscribe((booking: AdxBookingResponse)=>{
      if(booking)
        {
          this._booking = booking;
          this.isLoading = false;
        }else {
          const message = "No related records found.";
          this._snackBar.open(message, 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['message-position', 'app-notification-success']
          });
          this.dialogRef.close();
        }
    });
  }

  handleTripReferenceCloseBtnClick() {
    this.dialogRef.close();
  }
}
