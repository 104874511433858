import {Component, Input, OnInit} from '@angular/core';
import {AgentService} from '../service/agent.service';
import {AgentSplitRateModel} from '../service/agent.model';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'split-rate-info',
  templateUrl: './split-rate-info.component.html'
})
export class SplitRateInfoComponent implements OnInit {
    displayedColumns = [
        'rate',
        'validFrom',
        'validTo',
    ]
    @Input() public data: any;
    splitRateList: AgentSplitRateModel[] = [];
    pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
    sorting: Partial<Sort> = {};
    loadingData = true;

    constructor(
        private agentService: AgentService,
    ) {}

    ngOnInit(): void {
        this.pagination = {
            pageSize: this.pagination.pageSize || 25,
            pageIndex: 0,
            totalItemCount: 0,
        };
        this.getItems();
    }

    getItems(): void {
        this.loadingData = true;
        this.agentService.getAgentSplitRate({
          filters: {
            agentId: this.data,
          },
          page: this.pagination,
          sort: this.sorting,
        }).subscribe((data)=>{
            this.splitRateList = data.items;
            this.loadingData = false
            this.pagination = {
                ... this.pagination,
                pageIndex: data.pagination.pageNumber - 1,
                totalItemCount: data.pagination.totalItemCount,
            };
        });
    }

    handleSortChange(sort: Sort): void { 
        this.sorting = sort;
        
        if(!this.sorting?.active || !this.sorting?.direction) {
            this.sorting = {};
        }
          
        this.getItems();
    }

    handlePageChanged(page: PageEvent): void {
        this.pagination = {
          ...this.pagination,
          ...page,
        };
    
        this.getItems();
    }
}
