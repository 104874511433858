<h2 mat-dialog-title>{{this.data.mode}} History : {{this.data.id}}</h2>

<mat-dialog-content>
    <mat-table [dataSource]="this.data.historyData">
        <ng-container data-testid="history-list-user-updated-column" matColumnDef="userUpdated">
          <mat-header-cell data-testid="history-list-user-updated-header" class="mat-header-cell" *matHeaderCellDef> User ID </mat-header-cell>
          <mat-cell data-testid="history-list-user-updated-cell" class="mat-cell" *matCellDef="let element"> {{element.userUpdated}} </mat-cell>
        </ng-container>

        <ng-container data-testid="history-list-date-updated-column" matColumnDef="dateUpdated">
          <mat-header-cell data-testid="history-list-date-updated-header" class="mat-header-cell" *matHeaderCellDef> Date/Time </mat-header-cell>
          <mat-cell data-testid="history-list-date-updated-cell" class="mat-cell" *matCellDef="let element"> {{element.dateUpdated | date: 'M/d/yyyy hh:mm:ss'}} </mat-cell>
        </ng-container>

        <ng-container data-testid="history-list-class-name-column" matColumnDef="className">
          <mat-header-cell data-testid="history-list-class-name-header" class="mat-header-cell" *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell data-testid="history-list-class-name-cell" class="mat-cell" *matCellDef="let element"> {{element.className}} </mat-cell>
        </ng-container>
      
        <ng-container data-testid="history-list-property-column" matColumnDef="property">
          <mat-header-cell data-testid="history-list-property-header" class="mat-header-cell" *matHeaderCellDef> Field </mat-header-cell>
          <mat-cell data-testid="history-list-property-cell" class="mat-cell" *matCellDef="let element"> {{element.property}} </mat-cell>
        </ng-container>
      
        <ng-container data-testid="history-list-old-value-column" matColumnDef="oldValue">
          <mat-header-cell data-testid="history-list-old-value-header" class="mat-header-cell" *matHeaderCellDef> Old Value </mat-header-cell>
          <mat-cell data-testid="history-list-old-value-cell" class="mat-cell" *matCellDef="let element"> {{element.oldValue}} </mat-cell>
        </ng-container>
      
        <ng-container data-testid="history-list-new-value-column" matColumnDef="newValue">
          <mat-header-cell data-testid="history-list-new-value-header" class="mat-header-cell" *matHeaderCellDef> New Value </mat-header-cell>
          <mat-cell data-testid="history-list-new-value-cell" class="mat-cell" *matCellDef="let element"> {{element.newValue}} </mat-cell>
        </ng-container>
      
        <mat-header-row data-testid="history-list-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row data-testid="history-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
      
      </mat-table>
</mat-dialog-content>
<hr>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>