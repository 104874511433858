<modal-panel [title]="title" data-testid="adx-payment-details-modal-panel" (close)="closeDialog()" >
  <ng-container data-testid="adx-payment-details-modal-container">
    <app-adx-payment-details [data]="data.adxPaymentId"></app-adx-payment-details>
  </ng-container>

  <ng-container data-testid="adx-payment-details-modal-footer" modalPanelFooter >
    <button data-testid="adx-payment-details-modal-close-button" mat-raised-button color="primary" 
      (click)="closeDialog()">Close</button>
  </ng-container>
</modal-panel>
