<div class="grid-title-event">
    <h2>FMS Invoice</h2>
</div>
@if(fmsInvoice){
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" dynamicHeight>
        <mat-tab label="Invoice">
            <mat-card class="container" appearance="outlined">
                <mat-card-content>
                    @let invoiceData = fmsInvoice?.infoBlock;
                    @if(invoiceData) {
                        <read-only-form-field  [value]="invoiceData?.fmsInvoiceNumber" title="FMS Invoice Number" />
                        <read-only-form-field  [value]="invoiceData?.issueDate" title="Issue Date" [isDate]="true" />
                        <read-only-form-field  [value]="invoiceData?.branch?.name" title="Branch" />
                        <read-only-form-field  [value]="invoiceData?.agent?.name" title="Agent" />
                        <read-only-form-field  [value]="invoiceData?.client?.name" title="client" />
                        <read-only-form-field  [value]="invoiceData?.message" title="Message" />
                        <read-only-form-field  [value]="invoiceData?.remarks" title="Remarks" />
                        <read-only-form-field  [value]="invoiceData?.invoiceType" title="Invoice Type" />
                        <read-only-form-field  [value]="invoiceData?.recordLocator" title="Record Locator" />
                        <read-only-form-field  [value]="invoiceData?.invoiceStatus" title="Invoice Status" />
                        <read-only-form-field  [value]="invoiceData?.invoiceGroup" title="Invoice Group" />
                        <read-only-form-field  [value]="invoiceData?.primaryInvoice ? 'Yes' : 'No'" title="Primary Invoice" />
                        <read-only-form-field  [value]="invoiceData?.displayRestricted ? 'Yes' : 'No'" title="Display Restricted" />
                        <read-only-form-field  [value]="invoiceData?.voucherBalance" title="Voucher Balance" />
                        <read-only-form-field  [value]="invoiceData?.voucherCurrency" title="Voucher Currency" />
                    }
                </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab label="Bookings">
            <mat-card class="container" appearance="outlined">
                @if(fmsInvoice?.bookings?.length){
                    <mat-card-content class="w-100">
                        <mat-tab-group dynamicHeight>
                            @for (booking of fmsInvoice?.bookings; track booking?.id) {
                                <mat-tab [label]=" 'Booking ' + booking?.id">
                                    <div class="container">
                                        <read-only-form-field  [value]="booking?.confirmationNo" title="Confirmation Number" />
                                        <read-only-form-field  [value]="booking?.vendorName" title="Vendor Name" />
                                        <read-only-form-field  [value]="booking?.adxOperatorId" title="Adx Operator Id" />
                                        <read-only-form-field  [value]="booking?.startDate" title="Start Date" [isDate]="true" />
                                        <read-only-form-field  [value]="booking?.endDate" title="End Date" [isDate]="true" />
                                        <read-only-form-field  [value]="booking?.numberOfPassengers" title="Number Of Passengers" />
                                        <read-only-form-field  [value]="booking?.travelCategory" title="Travel Category" />
                                        <read-only-form-field  [value]="booking?.recordLocator" title="Record Locator" />
                                        <read-only-form-field  [value]="booking?.resCardTripLocator" title="Card Trip Locator" />
                                        <read-only-form-field  [value]="booking?.clientPayStatus" title="Client Pay Status" />
                                        <read-only-form-field  [value]="booking?.duration" title="Duration" />
                                        <read-only-form-field  [value]="booking?.amount" title="Amount" />
                                        <read-only-form-field  [value]="booking?.tax" title="Tax" />
                                        <read-only-form-field  [value]="booking?.commission" title="Commission" />
                                        <read-only-form-field  [value]="booking?.clientRemarks" title="Client Remarks" />
                                        <read-only-form-field  [value]="booking?.commTrackCCNo" title="Comm Track CC Number" />
                                        <read-only-form-field  [value]="booking?.finalPayDate" title="Final Pay Date" [isDate]="true" />
                                        <read-only-form-field  [value]="booking?.finalPayAmount" title="Final Pay Amount" />
                                        <read-only-form-field  [value]="booking?.finalPayCommAmount" title="Final Pay Commission Amount" />
                                        <read-only-form-field  [value]="booking?.currencyCode" title="Currency Code" />
                                        <read-only-form-field  [value]="booking?.priorInvoicedAmount" title="Prior Invoiced Amount" />
                                        <read-only-form-field  [value]="booking?.reservationTotalBase" title="Reservation Total Base" />
                                        <read-only-form-field  [value]="booking?.reservationTotalTax" title="Reservation Total Tax" />
                                        <read-only-form-field  [value]="booking?.reservationNo" title="Reservation Total Number" />
                                        <read-only-form-field  [value]="booking?.travelTypeId" title="Travel Service Type Id" />
                                        <read-only-form-field  [value]="booking?.travelType" title="Travel Service Type Name" />
                                        <read-only-form-field  [value]="booking?.externalTravelServiceTypeId" title="External Travel Service Type Id" />
                                        <read-only-form-field  [value]="booking?.externalTravelServiceTypeName" title="External Travel Service Type Name" />
                                    
                                        <mat-card-content class="w-100">
                                            @if(booking?.carInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Car Information</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.carInfo?.rentalCompanyName" title="Rental Company Name" />
                                                        <read-only-form-field  [value]="booking?.carInfo?.pickUpDateTime" title="Pick Up Date Time" [isDate]="true" />
                                                        <read-only-form-field  [value]="booking?.carInfo?.dropOffDateTime" title="Drop Off Date Time" [isDate]="true" />
                                                        <read-only-form-field  [value]="booking?.carInfo?.numberOfCars" title="Number Of Cars" />
                                                        <read-only-form-field  [value]="booking?.carInfo?.amount" title="Amount" />

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.cruiseInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Cruise Info</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.cruiseInfo?.shipName" title="Ship Name" />
                                                        <read-only-form-field  [value]="booking?.cruiseInfo?.cabinRoom" title="Cabin Room" />
                                                        <read-only-form-field  [value]="booking?.cruiseInfo?.bedding" title="Bedding" />
                                                        <read-only-form-field  [value]="booking?.cruiseInfo?.dining" title="Dining" />
                                                        <read-only-form-field  [value]="booking?.cruiseInfo?.shipDeck" title="Ship Deck" />
                                                        <read-only-form-field  [value]="booking?.cruiseInfo?.amount" title="Amount" />

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.airInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Air Information</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.airInfo?.originalTicketNumber" title="Original Ticket Number" />
                                                        <read-only-form-field  [value]="booking?.airInfo?.startingTicketNo" title="Starting Ticket Number" />
                                                        <read-only-form-field  [value]="booking?.airInfo?.endingTicketNo" title="Ending Ticket Number" />
                                                        <read-only-form-field  [value]="booking?.airInfo?.ticketPrice" title="Ticket Price" />

                                                        <mat-card-content class="w-100">
                                                            <mat-tab-group dynamicHeight>
                                                                @for (segment of booking?.airInfo?.segments; track $index) {
                                                                    <mat-tab [label]="'Flight ' + segment?.flightNo">
                                                                        <mat-card class="container">
                                                                            <read-only-form-field  [value]="segment?.airlineCode" title="Airline Code" />
                                                                            <read-only-form-field  [value]="segment?.class" title="Class" />
                                                                            <read-only-form-field  [value]="segment?.mileage" title="Mileage" />
                                                                            <read-only-form-field  [value]="segment?.departureDateTime" title="Departure Date Time" [isDate]="true"/>
                                                                            <read-only-form-field  [value]="segment?.arrivalDateTime" title="Arrival Date Time" [isDate]="true"/>
                                                                            <read-only-form-field  [value]="segment?.departureCity" title="Departure City" />
                                                                            <read-only-form-field  [value]="segment?.arrivalCity" title="Arrival City" />
                                                                        </mat-card>
                                                                    </mat-tab>
                                                                }
                                                            </mat-tab-group>
                                                        </mat-card-content>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.hotelInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Hotel Information</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.hotelInfo?.propertyName" title="Property Name" />
                                                        <read-only-form-field  [value]="booking?.hotelInfo?.numberOfRooms" title="Property Number Of Rooms" />
                                                        <read-only-form-field  [value]="booking?.hotelInfo?.category" title="Category" />
                                                        <read-only-form-field  [value]="booking?.hotelInfo?.isSmoking ? 'Yes' : 'No'" title="Is Smoking" />
                                                        <read-only-form-field  [value]="booking?.hotelInfo?.checkInDateTime" title="Check In Date Time" />
                                                        <read-only-form-field  [value]="booking?.hotelInfo?.checkOutDateTime" title="Check Out Date Time" />
                                                        <read-only-form-field  [value]="booking?.hotelInfo?.amount" title="Amount" />

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.insuranceInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Insurance Info</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.insuranceInfo?.numberOfPolicies" title="Number Of Policies" />
                                                        <read-only-form-field  [value]="booking?.insuranceInfo?.underwriterName" title="Underwriter Name" />
                                                        <read-only-form-field  [value]="booking?.insuranceInfo?.amount" title="Amount" />
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.serviceFeeInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Service Fee Info</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.serviceFeeInfo?.providerName" title="Provider Name" />
                                                        <read-only-form-field  [value]="booking?.serviceFeeInfo?.amount" title="Amount" />
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.tourInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Tour Info</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.tourInfo?.tourProviderName" title="Tour Provider Name" />
                                                        <read-only-form-field  [value]="booking?.tourInfo?.amount" title="Amount" />
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.railInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Rail Info</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.railInfo?.railProviderName" title="Rail Provider Name" />
                                                        <read-only-form-field  [value]="booking?.railInfo?.amount" title="Amount" />

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.transportationInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Transportation Info</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.transportationInfo?.transportationProviderName" title="Transportation Provider Name" />
                                                        <read-only-form-field  [value]="booking?.transportationInfo?.amount" title="Amount" />

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.otherInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Other Info</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.otherInfo?.providerName" title="Provider Name" />
                                                        <read-only-form-field  [value]="booking?.otherInfo?.amount" title="Amount" />

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.taxInfo){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Tax Info</mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                        @for(tax of booking?.taxInfo; track $index){
                                                            <read-only-form-field  [value]="tax?.code" title="Code" />
                                                            <read-only-form-field  [value]="tax?.amount" title="Amount" />
                                                            <br/>
                                                            <br/>
                                                        }
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.agentCommission){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Agent Commission</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <mat-card-content class="w-100">
                                                            <mat-tab-group dynamicHeight>
                                                                @for (commission of booking?.agentCommission; track $index) {
                                                                    <mat-tab [label]="'Commission ' + ($index + 1)">
                                                                        <mat-card class="container">
                                                                            <read-only-form-field  [value]="commission?.insideOut" title="Inside Out" />
                                                                            <read-only-form-field  [value]="commission?.action" title="Action" />
                                                                            <read-only-form-field  [value]="commission?.amount" title="Amount" />
                                                                            <read-only-form-field  [value]="commission?.rate" title="Rate" />
                                                                            <read-only-form-field  [value]="commission?.agentName" title="Agent Name" />
                                                                        </mat-card>
                                                                    </mat-tab>
                                                                }
                                                            </mat-tab-group>
                                                        </mat-card-content>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                            @if(booking?.foreignCurrency){
                                                <mat-accordion class="w-100">
                                                    <mat-expansion-panel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Foreign Currency</mat-panel-title>
                                                        </mat-expansion-panel-header>

                                                        <read-only-form-field  [value]="booking?.foreignCurrency?.currencyCode" title="Currency Code" />
                                                        <read-only-form-field  [value]="booking?.foreignCurrency?.exchangeRate" title="Exchange Rate" />
                                                        <read-only-form-field  [value]="booking?.foreignCurrency?.amountInForeignCurrency" title="Amount In Foreign Currency" />

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <mat-divider></mat-divider>
                                            }
                                        </mat-card-content>
                                    </div>
                                </mat-tab>
                            }
                        </mat-tab-group>
                    </mat-card-content>
                }
            </mat-card>
        </mat-tab>
    </mat-tab-group>
}
