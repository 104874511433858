import {Component, Optional, signal} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {merge} from "rxjs";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AutoUnsubscribe} from "src/infrastructure/decorators/auto-unsubscribe.decorator";
import {GeneralLedgerService} from "../../service/general-ledger.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@AutoUnsubscribe()
@Component({
    selector: 'create-general-ledger',
    templateUrl: './create-general-ledger.component.html',
})
export class CreateGeneralLedgerComponent {
    glForm!: FormGroup;

    errorMessage = signal('');

    isLoading = false;

    constructor(
        @Optional() public createGeneralLedgerDialogRef: MatDialogRef<CreateGeneralLedgerComponent>,
        private generalLedgerService: GeneralLedgerService,
        private fb: FormBuilder,
        private _snackBar: MatSnackBar,
    ){
        this.glForm = this.fb.group({ 
            glNo: [null, Validators.required],
            glName: [null, [Validators.required, Validators.maxLength(250)]],
            glType: [null, Validators.required],
        });

        merge(this.glForm.statusChanges, this.glForm.valueChanges)
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.updateErrorMessage());
    }

    checkInputInteger(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }

    createGeneralLedger(): void {
        this.isLoading = true;
        this.generalLedgerService.createGeneralLedger(this.glForm.value)
            .subscribe({
                next: ()=>{
                    this.closeGeneralLedgerModal(true);
                    this._snackBar.open('GL created successfully', 'Dismiss', {
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        duration: 3000,
                    }); 
                }, 
                error: ()=>{
                    this.closeGeneralLedgerModal();
                }
            });
    }

    closeGeneralLedgerModal(completed?:boolean): void {
        if (this.createGeneralLedgerDialogRef) {
            this.createGeneralLedgerDialogRef.close(completed);
        }
    }

    clearFields(...args:string[]): void {
        if(!args.length) {
            this.glForm.reset();
        } else {
            args.forEach((field)=>{
                this.glForm.controls[field].reset();
            });
        }
    }

    updateErrorMessage(): void {
        if (this.glForm.controls['glName'].hasError('maxlength')) {
            this.errorMessage.set('Max length exceeded (250)');
            return;
        }
        this.errorMessage.set('');
    }

}
