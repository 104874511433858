import {Component, AfterContentInit, ContentChild, input, output} from '@angular/core';
import {NgControl} from '@angular/forms';
import {Subscription} from 'rxjs';  
@Component({
    selector: 'read-only-form-field',
    templateUrl: './read-only-form-field.component.html',
})
export class ReadOnlyFormFieldComponent implements AfterContentInit {
  @ContentChild(NgControl) control?: NgControl;
  value= input<string | number | undefined | null>('')
  readonly = input<boolean>(true);
  title = input<string>('');
  testId = input<string>('');
  isDate = input<boolean>(false);
  displayPropFunc = input<(value:any)=> string | number>((value)=> value);
  buttonIcon = input<{icon: string, tooltip:string}>();
  buttonClick = output<string | number | undefined | null>();
  innerValue: any = '';
  showContent = true;
  valueChangeSubscription$?: Subscription;

  ngAfterContentInit(): void {
    if (this.control) {
      this.innerValue = this.control.value ?? '';
      if (this.readonly()) {
        this.valueChangeSubscription$ = this.control.valueChanges?.subscribe(
          (value) => {
            this.innerValue = value ?? '';
          }
        );

        this.showContent = false;
      }
    } else {
      this.innerValue = this.value() ?? '';
    }
  }

  handleIconButton(): void {
    this.buttonClick.emit(this.value());
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription$) {
      this.valueChangeSubscription$.unsubscribe();
    }
  }
}