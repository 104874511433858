<form data-testid="adx-payment-info-form" class="template-form adx-payment-info-form">  
    <div data-testid="adx-payment-info-main-div" class="filter__fields">
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-payment-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile data-testid="adx-payment-info-amount-tile">
                        <mat-form-field data-testid="adx-payment-info-amount-field">
                            <mat-label data-testid="adx-payment-info-amount-field-title">Amount: </mat-label>
                            <input data-testid="adx-payment-info-amount-input-field" matInput value="{{_adxPayment.amount}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-currency-tile">
                        <mat-form-field data-testid="adx-payment-info-currency-field">
                            <mat-label data-testid="adx-payment-info-currency-field-title">Currency: </mat-label>
                            <input data-testid="adx-payment-info-currency-input-field" matInput value="{{_adxPayment.currencyCode}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-pay-method-tile">
                        <mat-form-field data-testid="adx-payment-info-pay-method-field">
                            <mat-label data-testid="adx-payment-info-pay-method-field-title">Pay Method: </mat-label>
                            <input data-testid="adx-payment-info-pay-method-input-field" matInput value="{{_adxPayment.payMethod?.paymethodName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-pay-type-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-pay-type-field">
                            <mat-label data-testid="adx-payment-info-pay-type-field-title">Pay Type: </mat-label>
                            <input data-testid="adx-payment-info-pay-type-input-field" matInput value="{{_adxPayment.payType?.payTypeName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-payment-date-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-payment-date-field">
                            <mat-label data-testid="adx-payment-info-payment-date-field-title">Payment Date: </mat-label>
                            <input data-testid="adx-payment-info-payment-date-input-field" matInput [value]="formatDate(_adxPayment.paymentDate)">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-code-tile">
                        <mat-form-field data-testid="adx-payment-info-code-field">
                            <mat-label data-testid="adx-payment-info-code-field-title">Code: </mat-label>
                            <input data-testid="adx-payment-info-code-input-field" matInput value="{{_adxPayment.code}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-date-created-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-date-created-field">
                            <mat-label data-testid="adx-payment-info-date-created-field-title">Date Created: </mat-label>
                            <input data-testid="adx-payment-info-date-created-input-field" matInput [value]="formatDate(_adxPayment.dateCreated)">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-date-cleared-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-date-cleared-field">
                            <mat-label data-testid="adx-payment-info-date-cleared-field-title">Date Cleared: </mat-label>
                            <input data-testid="adx-payment-info-date-cleared-input-field" matInput [value]="formatDate(_adxPayment.dateCleared)">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-profile-name-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-profile-name-field">
                            <mat-label data-testid="adx-payment-info-profile-name-field-title">Client Name: </mat-label>
                            <input data-testid="adx-payment-info-profile-name-input-field" matInput value="{{_adxPayment.client?.profileName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-submit-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-submit-field">
                            <mat-label data-testid="adx-payment-info-submit-field-title">Submit to code: </mat-label>
                            <input data-testid="adx-payment-info-submit-input-field" matInput value="{{_adxPayment.submitToCode}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-bank-name-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-bank-name-field">
                            <mat-label data-testid="adx-payment-info-bank-name-field-title">Bank: </mat-label>
                            <input data-testid="adx-payment-info-bank-name-input-field" matInput value="{{_adxPayment.bank?.name}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-branch-name-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-branch-name-field">
                            <mat-label data-testid="adx-payment-info-branch-name-field-title">Branch: </mat-label>
                            <input data-testid="adx-payment-info-branch-name-input-field" matInput value="{{_adxPayment.branch?.name}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-invoice-id-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-invoice-id-field">
                            <mat-label data-testid="adx-payment-info-invoice-id-field-title">Invoice Id: </mat-label>
                            <input data-testid="adx-payment-info-invoice-id-input-field" matInput value="{{_adxPayment.adxPaydtls[0]?.adxInvoiceId}}">
                            @if(_adxPayment.adxPaydtls[0]?.adxInvoiceId && !_adxPayment.isModal){
                                <button matSuffix mat-icon-button aria-label="Navigate to invoice"  matTooltip="Navigate to invoice" (click)="redirectToInvoice();" type="button">
                                    <mat-icon>open_in_new</mat-icon>  
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-booking-id-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-booking-id-field">
                            <mat-label data-testid="adx-payment-info-booking-id-field-title">Service Id: </mat-label>
                            <input data-testid="adx-payment-info-booking-id-input-field" matInput value="{{_adxPayment.adxPaydtls[0]?.adxBookingId}}">
                            @if(_adxPayment.adxPaydtls[0]?.adxBookingId && !_adxPayment.isModal){
                                <button matSuffix mat-icon-button aria-label="Navigate to service"  matTooltip="Navigate to service" (click)="redirectToBooking();" type="button">
                                    <mat-icon>open_in_new</mat-icon>  
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-payee-name-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-payee-name-field">
                            <mat-label data-testid="adx-payment-info-payee-name-field-title">Payee Name: </mat-label>
                            <input data-testid="adx-payment-info-payee-name-input-field" matInput value="{{_adxPayment.payeeName}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-remarks-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-remarks-field">
                            <mat-label data-testid="adx-payment-info-remarks-field-title">Remarks: </mat-label>
                            <input data-testid="adx-payment-info-remarks-input-field" matInput value="{{_adxPayment.remarks}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-payment-group-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-payment-group-field">
                            <mat-label data-testid="adx-payment-info-payment-group-field-title">Payment Group: </mat-label>
                            <input data-testid="adx-payment-info-payment-group-input-field" matInput value="{{_adxPayment.paymentGroup}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-ckCcNo-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-ckCcNo-field">
                            <mat-label data-testid="adx-payment-info-ckCcNo-field-title">Check Credit Card Number: </mat-label>
                            <input data-testid="adx-payment-info-ckCcNo-input-field" matInput value="{{_adxPayment.ckCcNo}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-voided-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-voided-field">
                            <mat-label data-testid="adx-payment-info-voided-field-title">Voided: </mat-label>
                            <input data-testid="adx-payment-info-voided-input-field" matInput value="{{_adxPayment.voided}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-payment-info-invoice-ref-grid-tile">
                        <mat-form-field data-testid="adx-payment-info-invoice-ref-field">
                            <mat-label data-testid="adx-payment-info-invoice-ref-field-title">Invoice Ref: </mat-label>
                            <input data-testid="adx-payment-info-invoice-ref-input-field" matInput value="{{_adxPayment.invoiceRef}}">
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>