import {Component, Inject, Optional} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FinancialAlertsService} from "../../service/financial-alerts.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-confirmation-dialog-delete-alert',
    templateUrl: './confirmation-dialog-delete-alert.component.html'
})
export class ConfirmationDialogDeleteAlertComnponent {

    constructor(
        @Optional() public confirmationDialogRef: MatDialogRef<ConfirmationDialogDeleteAlertComnponent>,
        @Inject(MAT_DIALOG_DATA) public alertData: { workflowId:number, alertName:string, },
        private financialAlertsService: FinancialAlertsService,
        private _snackBar: MatSnackBar,
    ){}

    okClick(){
        this.financialAlertsService.deleteAlert(this.alertData.workflowId).subscribe(()=>{
            this._snackBar.open('Alert deleted successfully', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              }); 
            this.confirmationDialogRef.close(true);
        });

    }

    onNoClick(){
        this.confirmationDialogRef.close(false);
    }
}