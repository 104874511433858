<div class="grid-title-event">
  <h2 data-testid="vendor-balance-list-title">Vendor Balances Summary</h2>
  <div class="events-container">
    <button data-testid="vendor-balance-refresh-button" aria-label="Refresh grid" mat-icon-button (click)="getItems()">
      <mat-icon fontIcon="replay"></mat-icon>
    </button>
    @if (items.length){
    <button color="primary" data-testid="download-vendor-balance-made-button" class="btn-download" aria-label="Download Vendor Balance Excel Report" (click)="downloadGridToFile()" mat-stroked-button>
      <mat-icon>download</mat-icon>
    </button>
    <button color="primary" data-testid="download-vendor-balance-made-button" class="btn-download" aria-label="Download Vendor Balance PDF Report" (click)="downloadGridToPdf()" mat-stroked-button>
      <mat-icon>picture_as_pdf</mat-icon>
    </button>
    }
  </div>
</div>

<mat-table data-testid="vendor-balance-table" class="compact-mat-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>
  <ng-container matColumnDef="vendorName">
    <mat-header-cell data-testid="vendor-balance-vendor-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Vendor Name
    </mat-header-cell>
    <mat-cell data-testid="vendor-balance-vendor-balance-cell" class="mat-cell" *matCellDef="let element">
      <span class="align-text-left">{{element?.vendorName}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="id">
    <mat-header-cell data-testid="vendor-balance-id-header" class="mat-header-cell" *matHeaderCellDef>
      Vendor ID
    </mat-header-cell>
    <mat-cell data-testid="vendor-balance-id-cell" class="mat-cell" *matCellDef="let element">
      <span>{{element?.id}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell data-testid="vendor-balance-email-header" class="mat-header-cell" *matHeaderCellDef>
      Vendor Email
    </mat-header-cell>
    <mat-cell data-testid="vendor-balance-email-cell" class="mat-cell" *matCellDef="let element">
      <span>{{element?.email}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <mat-header-cell data-testid="vendor-balance-phone-number-header" class="mat-header-cell" *matHeaderCellDef>
      Phone Number
    </mat-header-cell>
    <mat-cell data-testid="vendor-balance-phone-number-cell" class="mat-cell" *matCellDef="let element">
      <span>{{element?.phoneNumber}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="totalBilled">
    <mat-header-cell data-testid="vendor-balance-total-billed-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Invoice Total
    </mat-header-cell>
    <mat-cell data-testid="vendor-balance-total-billed-cell" class="mat-cell" *matCellDef="let element">
      <span>{{element?.totalBilled}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="outstandingBalance">
    <mat-header-cell data-testid="vendor-balance-outstanding-balance-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Balance Due
    </mat-header-cell>
    <mat-cell data-testid="vendor-balance-outstanding-balance-cell" class="mat-cell" *matCellDef="let element">
      <span>{{element?.outstandingBalance}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dataSourceName">
    <mat-header-cell data-testid="vendor-balance-data-source-name-header" class="mat-header-cell" *matHeaderCellDef>
      Data Source Name
    </mat-header-cell>
    <mat-cell data-testid="vendor-balance-data-source-name-cell" class="mat-cell" *matCellDef="let element">
      <span>{{element?.dataSourceName}}</span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

  <mat-row *matRowDef="let row; columns: displayedColumns; let i = index">
  </mat-row>
</mat-table>
<mat-paginator data-testid="vendor-balance-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
               [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>
