<mat-card class="mat-elevation-z8">
    <mat-card-content>
        <form id="matching-vcc-detail-viewer-form" class="template-form">
            <div id="matching-vcc-detail-main-container" class="container">
                @if(details?.merchantName) {
                    <mat-form-field id="matching-vcc-detail-merchant-name-mat-form-field">
                        <mat-label id="matching-vcc-detail-merchant-name-field-title">Merchant Name: </mat-label>
                        <input id="matching-vcc-detail-merchant-name-input-field" matInput value="{{details?.merchantName}}">
                    </mat-form-field>
                }
                @if(details?.merchantGuid) {
                    <mat-form-field id="matching-vcc-detail-merchant-guid-mat-form-field">
                        <mat-label id="matching-vcc-detail-merchant-guid-field-title">Merchant Guid: </mat-label>
                        <input id="matching-vcc-detail-merchant-guid-input-field" matInput value="{{details?.merchantGuid}}">
                    </mat-form-field>
                }
                @if(details?.releasedDate) {
                    <mat-form-field id="matching-vcc-detail-released-date-mat-form-field">
                        <mat-label id="matching-vcc-detail-released-date-field-title">Released Date: </mat-label>
                        <input id="matching-vcc-detail-released-date-input-field" matInput value="{{details?.releasedDate | date:'M/d/yyyy'}}">
                    </mat-form-field>
                }
                @if(details?.connexPayDescription) {
                    <mat-form-field id="matching-vcc-detail-connexpay-description-mat-form-field">
                        <mat-label id="matching-vcc-detail-connexpay-description-field-title">ConnexPay Description: </mat-label>
                        <input id="matching-vcc-detail-connexpay-description-input-field" matInput value="{{details?.connexPayDescription}}">
                    </mat-form-field>
                }
                @if(details?.cardGuid) {
                    <mat-form-field id="matching-vcc-detail-card-guid-mat-form-field">
                        <mat-label id="matching-vcc-detail-card-guid-field-title">Card Guid: </mat-label>
                        <input id="matching-vcc-detail-card-guid-input-field" matInput value="{{details?.cardGuid}}">
                    </mat-form-field>
                }
                @if(details?.connexPayTripReferenceNumber) {
                    <mat-form-field id="matching-vcc-detail-connexpay-trip-reference-number-mat-form-field">
                        <mat-label id="matching-vcc-detail-connexpay-trip-reference-number-field-title">ConnexPay Trip Reference Number: </mat-label>
                        <input id="matching-vcc-detail-connexpay-trip-reference-number-input-field" matInput value="{{details?.connexPayTripReferenceNumber}}">
                    </mat-form-field>
                }
                @if(details?.adxTripReferenceNumber) {
                    <mat-form-field id="matching-vcc-detail-adx-trip-reference-number-mat-form-field">
                        <mat-label id="matching-vcc-detail-adx-trip-reference-number-field-title">Adx Trip Reference Number: </mat-label>
                        <input id="matching-vcc-detail-adx-trip-reference-number-input-field" matInput value="{{details?.adxTripReferenceNumber}}">
                    </mat-form-field>
                }
                @if(details?.tramsInvoiceNumber) {
                    <mat-form-field id="matching-vcc-detail-trams-invoice-number-mat-form-field">
                        <mat-label id="matching-vcc-detail-trams-invoice-number-field-title">Trams Invoice Number: </mat-label>
                        <input id="matching-vcc-detail-trams-invoice-number-input-field" matInput value="{{details?.tramsInvoiceNumber}}">
                    </mat-form-field>
                }
                @if(details?.vccNumber) {
                    <mat-form-field id="matching-vcc-detail-vcc-number-mat-form-field">
                        <mat-label id="matching-vcc-detail-vcc-number-field-title">Vcc Number: </mat-label>
                        <input id="matching-vcc-detail-vcc-number-input-field" matInput value="{{details?.vccNumber}}">
                    </mat-form-field>
                }
                @if(details?.serviceStartDate) {
                    <mat-form-field id="matching-vcc-detail-service-start-date-mat-form-field">
                        <mat-label id="matching-vcc-detail-service-start-date-field-title">Service Start Date: </mat-label>
                        <input id="matching-vcc-detail-service-start-date-input-field" matInput value="{{details?.serviceStartDate | date:'M/d/yyyy'}}">
                    </mat-form-field>
                }
                @if(details?.serviceEndDate) {
                    <mat-form-field id="matching-vcc-detail-service-end-date-mat-form-field">
                        <mat-label id="matching-vcc-detail-service-end-date-field-title">Service End Date: </mat-label>
                        <input id="matching-vcc-detail-service-end-date-input-field" matInput value="{{details?.serviceEndDate | date:'M/d/yyyy'}}">
                    </mat-form-field>
                }
                @if(details?.adxIssuedDate) {
                    <mat-form-field id="matching-vcc-detail-adx-issued-date-mat-form-field">
                        <mat-label id="matching-vcc-detail-adx-issued-date-field-title">Adx Issued Date: </mat-label>
                        <input id="matching-vcc-detail-adx-issued-date-input-field" matInput value="{{details?.adxIssuedDate | date:'M/d/yyyy'}}">
                    </mat-form-field>
                }
                @if(details?.chargeDate) {
                    <mat-form-field id="matching-vcc-detail-charge-date-mat-form-field">
                        <mat-label id="matching-vcc-detail-charge-date-field-title">Charge Date: </mat-label>
                        <input id="matching-vcc-detail-charge-date-input-field" matInput value="{{details?.chargeDate | date:'M/d/yyyy'}}">
                    </mat-form-field>
                }
                @if(details?.expectedVendorName) {
                    <mat-form-field id="matching-vcc-detail-expected-vendor-name-mat-form-field">
                        <mat-label id="matching-vcc-detail-expected-vendor-name-field-title">Expected Vendor Name: </mat-label>
                        <input id="matching-vcc-detail-expected-vendor-name-input-field" matInput value="{{details?.expectedVendorName}}">
                    </mat-form-field>
                }
                @if(details?.vendorName) {
                    <mat-form-field id="matching-vcc-detail-vendor-name-mat-form-field">
                        <mat-label id="matching-vcc-detail-vendor-name-field-title">Vendor Name: </mat-label>
                        <input id="matching-vcc-detail-vendor-name-input-field" matInput value="{{details?.vendorName}}">
                    </mat-form-field>
                }
                @if(details?.connexPayCurrency) {
                    <mat-form-field id="matching-vcc-detail-connexpay-currency-mat-form-field">
                        <mat-label id="matching-vcc-detail-connexpay-currency-field-title">ConnexPay Currency: </mat-label>
                        <input id="matching-vcc-detail-connexpay-currency-input-field" matInput value="{{details?.connexPayCurrency}}">
                    </mat-form-field>
                }
                @if(details?.adxCurrency) {
                    <mat-form-field id="matching-vcc-detail-adx-currency-mat-form-field">
                        <mat-label id="matching-vcc-detail-adx-currency-field-title">Adx Currency: </mat-label>
                        <input id="matching-vcc-detail-adx-currency-input-field" matInput value="{{details?.adxCurrency}}">
                    </mat-form-field>
                }
                @if(details?.tramsCurrency) {
                    <mat-form-field id="matching-vcc-detail-trams-currency-mat-form-field">
                        <mat-label id="matching-vcc-detail-trams-currency-field-title">Trams Currency: </mat-label>
                        <input id="matching-vcc-detail-trams-currency-input-field" matInput value="{{details?.tramsCurrency}}">
                    </mat-form-field>
                }
                @if(details?.expectedChargeAmount) {
                    <mat-form-field id="matching-vcc-detail-expected-charge-amount-mat-form-field">
                        <mat-label id="matching-vcc-detail-expected-charge-amount-field-title">Expected Charge Amount: </mat-label>
                        <input id="matching-vcc-detail-expected-charge-amount-input-field" matInput value="{{details?.expectedChargeAmount}}">
                    </mat-form-field>
                }
                @if(details?.connexPayCurrencyAmount) {
                    <mat-form-field id="matching-vcc-detail-connexpay-currency-amount-mat-form-field">
                        <mat-label id="matching-vcc-detail-connexpay-currency-amount-field-title">ConnexPay Currency Amount: </mat-label>
                        <input id="matching-vcc-detail-connexpay-currency-amount-input-field" matInput value="{{details?.connexPayCurrencyAmount}}">
                    </mat-form-field>
                }
                @if(details?.vccCurrency) {
                    <mat-form-field id="matching-vcc-detail-vcc-currency-mat-form-field">
                        <mat-label id="matching-vcc-detail-vcc-currency-field-title">Vcc Currency: </mat-label>
                        <input id="matching-vcc-detail-vcc-currency-input-field" matInput value="{{details?.vccCurrency}}">
                    </mat-form-field>
                }
                @if(details?.tramsBookingTotal) {
                    <mat-form-field id="matching-vcc-detail-trams-booking-total-mat-form-field">
                        <mat-label id="matching-vcc-detail-trams-booking-total-field-title">Trams Booking Total: </mat-label>
                        <input id="matching-vcc-detail-trams-booking-total-input-field" matInput value="{{details?.tramsBookingTotal}}">
                    </mat-form-field>
                }
                @if(details?.tramsCommissionAmount) {
                    <mat-form-field id="matching-vcc-detail-trams-commission-amount-mat-form-field">
                        <mat-label id="matching-vcc-detail-trams-commission-amount-field-title">trams Commission Amount: </mat-label>
                        <input id="matching-vcc-detail-trams-commission-amount-input-field" matInput value="{{details?.tramsCommissionAmount}}">
                    </mat-form-field>
                }
                @if(details?.varianceAmount) {
                    <mat-form-field id="matching-vcc-suggested-adjustment-commission-amount-mat-form-field">
                        <mat-label id="matching-vcc-suggested-adjustment-commission-amount-field-title">Suggested Adjustment to Commission Amount: </mat-label>
                        <input id="matching-vcc-detail-suggested-adjustment-commission-amount-input-field" matInput value="{{details?.varianceAmount}}">
                    </mat-form-field>
                }
                @if(details?.variance) {
                    <mat-form-field id="matching-vcc-variance-mat-form-field">
                        <mat-label id="matching-vcc-variance-field-title">Variance: </mat-label>
                        <input id="matching-vcc-detail-variance-input-field" matInput value="{{details?.variance}}">
                    </mat-form-field>
                }
                @if(details?.agentName) {
                    <mat-form-field id="matching-vcc-agent-name-mat-form-field">
                        <mat-label id="matching-vcc-agent-name-field-title">Agent Name: </mat-label>
                        <input id="matching-vcc-detail-agent-name-input-field" matInput value="{{details?.agentName}}">
                    </mat-form-field>
                }
                @if(details?.customerId) {
                    <mat-form-field id="matching-vcc-customer-id-mat-form-field">
                        <mat-label id="matching-vcc-customer-id-field-title">Customer Id: </mat-label>
                        <input id="matching-vcc-detail-customer-id-input-field" matInput value="{{details?.customerId}}">
                    </mat-form-field>
                }
                @if(details?.advisorName) {
                    <mat-form-field id="matching-vcc-advisor-name-mat-form-field">
                        <mat-label id="matching-vcc-advisor-name-field-title">Advisor Name: </mat-label>
                        <input id="matching-vcc-detail-advisor-name-input-field" matInput value="{{details?.advisorName}}">
                    </mat-form-field>
                }
                @if(details?.tramsDataSourceName) {
                    <mat-form-field id="matching-vcc-trams-data-source-name-mat-form-field">
                        <mat-label id="matching-vcc-trams-data-source-name-field-title">Trams Data Source Name: </mat-label>
                        <input id="matching-vcc-detail-trams-data-source-name-input-field" matInput value="{{details?.tramsDataSourceName}}">
                    </mat-form-field>
                }
            </div>
        </form>
    </mat-card-content>
</mat-card>
