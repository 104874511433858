<div class="grid-title-event">
    <h2 data-testid="hs-processing-list-title">Services HS Processing</h2>
    @if (items.length){
        <div class="events-container">
            <button  data-testid="hs-processing-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="download-hs-processing-button" aria-label="Download Matching Vcc File" mat-stroked-button (click)="downloadGridToFile()" style="justify-content:flex-end">
                <mat-icon>download</mat-icon>
            </button>
        </div>
    }
</div>
<mat-table data-testid="hs-processing-table" class="compact-mat-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>
    <ng-container matColumnDef="ServiceId">
        <mat-header-cell data-testid="service-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Service Id
        </mat-header-cell>
        <mat-cell data-testid="service-id-cell" *matCellDef="let element">
            <span>{{element?.serviceId}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="notificationId">
        <mat-header-cell data-testid="hs-processing-notification-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Notification Id
        </mat-header-cell>
        <mat-cell data-testid="notification-id-cell" *matCellDef="let element">
            <span>{{element?.notificationId}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="adxReference">
        <mat-header-cell data-testid="hs-processing-adx-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Adx Reference
        </mat-header-cell>
        <mat-cell data-testid="hs-processing-adx-reference-cell" *matCellDef="let element">
            <span>{{element?.adxReference}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="agentName">
        <mat-header-cell data-testid="hs-processing-agent-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Agent Name
        </mat-header-cell>
        <mat-cell data-testid="hs-processing-agent-name-cell" *matCellDef="let element">
            <span>{{element?.agentName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
        <mat-header-cell data-testid="hs-processing-date-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Date Created
        </mat-header-cell>
        <mat-cell data-testid="hs-processing-date-created-cell" *matCellDef="let element">
            <span>{{element?.dateCreated | date:'M/d/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="hsTicketNumber">
        <mat-header-cell data-testid="hs-processing-hs-ticket-header" class="mat-header-cell" *matHeaderCellDef>
            HS Ticket
        </mat-header-cell>
        <mat-cell data-testid="hs-processing-hs-ticket-cell" *matCellDef="let element">
            <span>{{element?.hsTicketNumber}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="processed">
        <mat-header-cell data-testid="hs-processing-is-processed-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Processed
        </mat-header-cell>
        <mat-cell data-testid="hs-processing-is-processed-cell" *matCellDef="let element">
            <span>{{element?.processed ? 'True': 'False'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="invalid">
        <mat-header-cell data-testid="hs-processing-is-invalid-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Invalid
        </mat-header-cell>
        <mat-cell data-testid="hs-processing-is-invalid-cell" *matCellDef="let element">
            <span>{{element?.invalid ? 'True': 'False'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="urgent">
        <mat-header-cell data-testid="hs-processing-is-urgent-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Urgent
        </mat-header-cell>
        <mat-cell data-testid="hs-processing-is-urgent-cell" *matCellDef="let element">
            <span>{{element?.urgent ? 'True': 'False'}}</span>
        </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index">
    </mat-row>
</mat-table>
<mat-paginator data-testid="hs-processing-paginator" [length]="pagination.totalItemCount" [pageSizeOptions]="[25, 50, 75, 100]"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>
