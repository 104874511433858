<div>
    <h2 data-testid="trip-details-title" class="page-details-title-style">Trip {{this.data.tripId}}</h2>
</div>

<app-trip-info [data]="data"></app-trip-info>

<adx-invoice-list [data]="data"></adx-invoice-list>

<br>
<app-adx-payment-grid [data]="data"></app-adx-payment-grid>
