<section class="grid-title-event">
  <h2 style="text-align: center">Client Balances Summary</h2>

  <div class="events-container">
    <button mat-icon-button (click)="refresh()">
      <mat-icon fontIcon="replay"></mat-icon>
    </button>
    <button class="btn-download"
            color="primary"
            mat-stroked-button
            (click)="downloadGridToFile()">
      <mat-icon>download</mat-icon>
    </button>
    <button class="btn-download"
            color="primary"
            mat-stroked-button
            (click)="downloadGridToPdf()">
      <mat-icon>picture_as_pdf</mat-icon>
    </button>
  </div>
</section>

<mat-table
  class="compact-mat-table"
  [dataSource]="datasource"
  matSort
  (matSortChange)="onSortChanged($event)"
>
  <ng-container matColumnDef="id">
    <mat-header-cell class="mat-header-cell" *matHeaderCellDef>
      Client ID
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="clientName">
    <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Client Name
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.clientName }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell class="mat-header-cell" *matHeaderCellDef>
      Client Email
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <mat-header-cell class="mat-header-cell" *matHeaderCellDef>
      Phone Number
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.phoneNumber }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="totalBilled">
    <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Invoice Total
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.totalBilled }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="outstandingBalance">
    <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Balance Due
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.outstandingBalance }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dataSourceName">
    <mat-header-cell class="mat-header-cell" *matHeaderCellDef>
      Data Source Name
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.dataSourceName }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />
  <mat-row *matRowDef="let row; columns: displayedColumns" />
</mat-table>
<mat-paginator
  [hidePageSize]="true"
  [length]="pagination?.totalItemCount"
  [pageSize]="pagination?.pageSize"
  [pageIndex]="(pagination?.pageNumber ?? 1) - 1"
  (page)="onPageChanged($event)"
/>
