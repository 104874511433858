import {Component, Input, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ActivatedRoute} from '@angular/router';
import {AdxPaymentService} from '../../service/adx-payment.service';
import {AdxPaymentByIdResponse} from '../../model/adx-payment-by-id-response';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-adx-payment-details',
  templateUrl: './adx-payment-details.component.html'
})

@AutoUnsubscribe()
export class AdxPaymentDetailsComponent implements OnInit {
  @Input() public data: any;
  paymentData: any;
  adxPaymentId: number = 0;

  constructor(private adxPaymentService: AdxPaymentService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {
  }

  public ngOnInit(): void {
    if (this.data == null){
      this.route.paramMap.subscribe(params => {
        const id = params.get('id');
        this.adxPaymentId = id ? parseInt(id) : 0;
      });
    }
    else{
      this.adxPaymentId = parseInt(this.data);
    }
    
    this.getAdxPayment();
  }

  getAdxPayment() {
    this.adxPaymentService
      .getAdxPaymentById(this.adxPaymentId)
      .subscribe((data: AdxPaymentByIdResponse) => {
          this.paymentData = data;
          this.paymentData.isModal = this.data != null;
      });
    }
}
