@if(_booking && _booking.transactionBookingId > 0){
<form [formGroup]="bookingViewerForm" class="template-form">
  <div class="container">
    <div id="booking-error" *ngIf="bookingViewerForm.invalid">
      <mat-error>Form is invalid</mat-error>
    </div>
      @if(_booking.transactionBookingId!== undefined && _booking.transactionBookingId!== null){
        <read-only-form-field testId="booking-number-field" title="Service number" value="{{_booking.transactionBookingId}}" />
      }
      @if(_booking.serviceTypeName!== undefined && _booking.serviceTypeName!== null){
        <read-only-form-field testId="booking-service-type-name" title="Service type" value="{{_booking.serviceTypeName}}" />
      }
      @if(_booking.bookingTotal!== undefined && _booking.bookingTotal!== null){
        <read-only-form-field testId="booking-total" title="Service total" value="{{_booking.bookingTotal  | number : '1.2-2'}}" />
      }
      @if(_booking.bookingStartDate!== undefined && _booking.bookingStartDate!== null){
        <read-only-form-field testId="booking-start-date" title="Service start date" value="{{_booking.bookingStartDate  | date: 'MM/dd/yyyy'}}" />
      }
      @if(_booking.bookingEndDate!== undefined && _booking.bookingEndDate!== null){
        <read-only-form-field testId="booking-end-date" title="Service end date" value="{{_booking.bookingEndDate  | date: 'MM/dd/yyyy'}}" />
      }
      @if(_booking.virtualCreditCardUrl!== undefined && _booking.virtualCreditCardUrl!== null){
        <read-only-form-field testId="booking-credit-card" title="VCC URL" value="{{_booking.virtualCreditCardUrl}}" />
      }
      @if(_booking.bookingStatusName!== undefined && _booking.bookingStatusName!== null){
        <read-only-form-field testId="booking-status-name" title="Fms status" value="{{_booking.bookingStatusName}}" />
      }
      @if(_booking.vendorName!== undefined && _booking.vendorName!== null){
        <read-only-form-field testId="booking-vendor-name" title="Vendor" value="{{_booking.vendor.vendorName}}" />
      }
      @if(_booking.mainPassengerName!== undefined && _booking.mainPassengerName!== null){
        <read-only-form-field testId="booking-main-passenger" title="Passenger" value="{{_booking.mainPassengerName}}" />
      }
      @if(_booking.taskNumbers!== undefined && _booking.taskNumbers!== null){
        <read-only-form-field testId="booking-task-number" title="Tasks" value="{{_booking.taskNumbers}}" />
      }
  </div>
  <br>
  <div class="row">
    <ng-container #container></ng-container>
  </div>
</form>
}