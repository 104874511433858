<div class="grid-title-event" *ngIf="!this.isLoading">
  <h2 data-testid="adx-payment-list-title">Payments</h2>
  <div class="events-container">
      @if(dataSource.filteredData.length){
          <button data-testid="adx-payment-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
              <mat-icon fontIcon="replay"></mat-icon>
          </button>
          <button color="primary" data-testid="adx-payment-download-button" aria-label="Download Adx Payment File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
              <mat-icon>download</mat-icon>
          </button>
      }
  </div>
</div>
<div class="adx-payment-container mat-elevation-z8" *ngIf="!this.isLoading">
  <mat-table
    data-testid="adx-payment-table"
    [dataSource]="dataSource"
    (matSortChange)="handleSortChange($event)"
    matSort>

    <ng-container matColumnDef="id">
      <mat-header-cell data-testid="adx-payment-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
      <mat-cell data-testid="adx-payment-id-cell" class="mat-cell" *matCellDef="let element">
        <span class="adx-payment-id-cell-text" matTooltip={{element.id}}  [matTooltipPosition]="'above'">
          <a data-testid="adx-payment-id-cell-link" href="AdxPayments/{{element?.id}}" (click)="handleIdClick($event, element)">{{element.id}}</a>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="paymentDate">
      <mat-header-cell data-testid="adx-payment-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Payment Date</mat-header-cell>
      <mat-cell data-testid="adx-payment-date-cell" *matCellDef="let element">{{element.paymentDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="client.profileName">
      <mat-header-cell data-testid="adx-payment-client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Client Name</mat-header-cell>
      <mat-cell data-testid="adx-payment-client-name-cell" *matCellDef="let element">{{element.client?.profileName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="payType.payTypeName">
      <mat-header-cell data-testid="adx-payment-pay-type-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Payment Type</mat-header-cell>
      <mat-cell data-testid="adx-payment-pay-type-name-cell" *matCellDef="let element">{{element.payType?.payTypeName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="payMethod.paymethodName">
      <mat-header-cell data-testid="adx-payment-pay-method-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Payment Method</mat-header-cell>
      <mat-cell data-testid="adx-payment-pay-method-name-cell" *matCellDef="let element">{{element.payMethod?.paymethodName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell data-testid="adx-payment-amount-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
      <mat-cell data-testid="adx-payment-amount-cell" *matCellDef="let element">{{element.amount}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ckCcNo">
      <mat-header-cell data-testid="adx-payment-ckCcNo-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>ckCcNo</mat-header-cell>
      <mat-cell data-testid="adx-payment-ckCcNo-cell" *matCellDef="let element">
        <span class="adx-payment-ckCcNo-cell-text">{{element?.ckCcNo | maskedCard}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="branch.name">
      <mat-header-cell data-testid="adx-payment-branch-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Branch</mat-header-cell>
      <mat-cell data-testid="adx-payment-branch-name-cell" *matCellDef="let element">{{element.branch?.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bank.name">
      <mat-header-cell data-testid="adx-payment-bank-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Bank</mat-header-cell>
      <mat-cell data-testid="adx-payment-bank-name-cell" *matCellDef="let element">{{element.bank?.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="paymentGroup">
      <mat-header-cell data-testid="adx-payment-group-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Payment Group</mat-header-cell>
      <mat-cell data-testid="adx-payment-group-cell" *matCellDef="let element">{{element.paymentGroup}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="remarks">
      <mat-header-cell data-testid="adx-payment-remarks-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Remarks</mat-header-cell>
      <mat-cell data-testid="adx-payment-remarks-cell" *matCellDef="let element">{{element.remarks}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="invoiceRef">
      <mat-header-cell data-testid="adx-payment-invoice-ref-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Invoice Ref</mat-header-cell>
      <mat-cell data-testid="adx-payment-invoice-ref-cell" *matCellDef="let element">{{element.invoiceRef}}</mat-cell>
    </ng-container>

    <mat-header-row data-testid="adx-payment-list-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row data-testid="adx-payment-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator
    data-testid="adx-payment-paginator"
    [length]="pagination.totalItemCount"
    [hidePageSize]="true"
    [pageSize]="pagination.pageSize"
    (page)="pageChanged($event)">
  </mat-paginator>
</div>
