import {Component, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {ReconciliationFileService} from '../../service/reconciliation-file.service';

@Component({
    selector: 'reconciliation-upload-modal',
    templateUrl: './reconciliation-upload-modal.component.html',
})
export class ReconciliationUploadModalComponent {

    isLoading = false;

    reconciliationForm!:FormGroup;

    ccProcessors = [
        {
            id: 1,
            displayName: 'Connexpay',
        },
        {
            id: 2,
            displayName: 'Braintree',
        },
        {
            id: 3,
            displayName: 'Elavon'
        }
    ];

    constructor(
        @Optional() public reonciliationUploadDialogRef: MatDialogRef<ReconciliationUploadModalComponent>,
        private formBuilder: FormBuilder,
        private reconFileService: ReconciliationFileService,
    ){

        this.reconciliationForm = this.formBuilder.group({
            ccProcessorId: [null, Validators.required],
            file: [null, Validators.required],
        });
    }

    formCloseEvent(sentFile?: boolean): void {
        if (this.reonciliationUploadDialogRef) {
          this.reonciliationUploadDialogRef.close(sentFile);
        }
    }

    onFileChange(event: any) {
        const file = event?.target?.files[0];
        if(file) {
            this.reconciliationForm.controls['file'].patchValue({file});
        } else {
            this.reconciliationForm.controls['file'].reset();
        }

   }

    sendFile() : void {
        this.isLoading = true;
        this.reconFileService.sendFileContent(this.reconciliationForm.value).subscribe({
            next: ()=>{
                this.formCloseEvent(true);
            },
            error: ()=>{
                this.isLoading = false
                this.formCloseEvent(false);
            }
        });

    }

    clearFields(...args:string[]): void {
        if(!args.length) {
            this.reconciliationForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.reconciliationForm.controls[field].reset()
        })
    }
}