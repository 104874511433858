import {Component} from "@angular/core";
import {ActivatedRoute} from '@angular/router';
import {MatchingVccService} from "../../service/matching-vcc.service";
import {MatchingVccDetails} from "../../model/match-vcc";
import {MerchantsService} from "src/infrastructure/merchants/service/merchants.service";

@Component({
    selector: 'matching-vcc-detail',
    templateUrl: './matching-vcc-detail.component.html',
})
export class MatchingVccDetailComponent {

    id = '';

    details?:MatchingVccDetails;
    
    constructor(
        private route: ActivatedRoute,
        private matchingVccService: MatchingVccService,
        private merchantsService: MerchantsService,
    ){
        this.id = this.route.snapshot.paramMap.get('id')!;
        this.matchingVccService.getMatchingDetails(this.id).subscribe((data)=>{
            this.details = data;
            this.getMerchantGuidData();
        })
    }

    getMerchantGuidData(){
        this.merchantsService.merchants$.subscribe((data)=>{
            const matchedMerchant = data.filter((merchant)=>{
                return merchant.merchantGuid === this.details?.merchantGuid;
            });
            
            this.details!.connexPayCurrency = `${matchedMerchant[0].currencyCode}`;
            this.details!.vccCurrency = `${matchedMerchant[0].currencyCode}`;
        });
    }
}
