import {Component, Inject, Optional, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-adx-booking-details-modal',
  templateUrl: './adx-booking-details-modal.component.html'
})
export class AdxBookingDetailsModalComponent implements OnInit {
  title: string;
  adxBookingId: any;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AdxBookingDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, adxBookingId: any },
    public dialog: MatDialog,
  ) {
    this.title = data.title;
    this.adxBookingId = data.adxBookingId;
  }

  ngOnInit(): void {
    if (!this.data) {
      console.error('Data is missing');
    }
  }

  public closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
  }
}
