import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AdxBookingUdidService} from '../../service/adx-booking-udid.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdxUdidInformationService} from 'src/app/adx-udid-information/service/adx-udid-information.service';
import {SearchPaginatedAdxUdidInformationResponse} from 'src/app/adx-udid-information/models/search-paginated-adx-udid-information-response';
import {AddAdxBookingUdidRequest} from '../../models/add-adx-booking-udid-request';

@Component({
  selector: 'app-adx-booking-udid-info',
  templateUrl: './adx-booking-udid-info.component.html'
})

@AutoUnsubscribe()
export class AdxBookingUdidInfoComponent {
  @Input() public data: any;
  _adxBookingUdids : any;
  _adxUdidInformation : any;
  isEdit = false;
  adxBookingUdidForm: FormGroup = this.fb.group({});
  showAddUdidForm = false;
  formEdited = false;
  _adxBookingUdidsPriorChanges: any;
  
  constructor(
      private fb: FormBuilder,
      private adxBookingUdidService: AdxBookingUdidService,
      private adxUdidInformationService: AdxUdidInformationService,
      private _snackBar: MatSnackBar) {
  }

  ngOnInit(){
    this.getAdxBookingUdidInfo();
    this.getAdxUdidInformation();
    this.createForm();
  }

  getAdxBookingUdidInfo() {
    if (this.data != null && this._adxBookingUdids == null){
      this._adxBookingUdids = this.data.adxBookingUdids;
    }
    else {
      this.adxBookingUdidService.getAdxBookingUdidsByBookingId(this.data.id)
        .subscribe((data: any) => {
          this._adxBookingUdids = data;
        });
    }
  }

  getAdxUdidInformation() {
    this.adxUdidInformationService.searchPaginatedAdxUdidInformation()
      .subscribe((data: SearchPaginatedAdxUdidInformationResponse) => {
        if (data) {
            this._adxUdidInformation = data.items
              .filter(item => !this._adxBookingUdids.some((udid: any) => udid.udidNo === item.udid))
              .sort((a, b) => a.udid - b.udid);
        }});
  }

  createForm(): void {
    this.adxBookingUdidForm = this.fb.group({
      newUdidNumber: ['', Validators.required],
      newUdidValue: ['', Validators.required],
    });
  }

  toggleEdit(): void {
    if (!this.showAddUdidForm && !this.isEdit) {
      this._adxBookingUdidsPriorChanges = this._adxBookingUdids.map((udid: any) => ({ ...udid }));
    }

    if (!this.showAddUdidForm && this.isEdit) {
      this._adxBookingUdids = this._adxBookingUdidsPriorChanges;
      this.formEdited = false;
    }

    if (this.showAddUdidForm) {
      this.showAddUdidForm = false;
    }
    else{
      this.isEdit = !this.isEdit;
    }
  }

  addUdid(){
    const newUdid: AddAdxBookingUdidRequest = {
      udidNo: this.adxBookingUdidForm.get('newUdidNumber')?.value,
      udidValue: this.adxBookingUdidForm.get('newUdidValue')?.value,
      dataSourceId: this.data.dataSourceId,
      adxBookingId: this.data.id
    };

    this.adxBookingUdidService.createAdxBookingUdid(newUdid).subscribe({
      next: () => {
        this._snackBar.open('Successfully added new UDID!', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['message-position', 'app-notification-success']
        });
        this.showAddUdidForm = false;
        this.getAdxUdidInformation();
        this.getAdxBookingUdidInfo();
        this.adxBookingUdidForm.reset();
      },
      error: () => {
        this._snackBar.open('Failed to add new UDID.', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['message-position', 'app-notification-error']
        });
      }
    });
  }

  updateUdidValue(obj: any, event: any){
    this.formEdited = true;
    obj.udidValue = event.target.value;
  }

  updateUdid(){
    if (!this.formEdited){
      this._snackBar.open('There is no change to save!!', 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['message-position', 'app-notification-success']
      });
    }
    else {
    let success = true;

      this._adxBookingUdids.forEach((udid: any) => {
        this.adxBookingUdidService.updateAdxBookingUdid(udid)
          .subscribe({
            next: () => {
              
            },
            error: () => {
              success = false;
            }
        });
      });
  
      if (success){
        this._snackBar.open('Successfully updated service UDIDs information!!', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['message-position', 'app-notification-success']
        });
  
        this.isEdit = !this.isEdit;
      } 
      else{
        this._snackBar.open('Failed to update service UDIDs information.', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['message-position', 'app-notification-error']
        });
      }
    }
  }

  getUneditedUdids(): number[] {
    return [904, 227];
  }
}