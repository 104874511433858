import {Component, effect, input, output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdxBookingService} from '../../service/adx-booking.service';

@Component({
  selector: 'app-adx-booking-info',
  templateUrl: './adx-booking-info.component.html'
})

@AutoUnsubscribe()
export class AdxBookingInfoComponent {
  data = input<any>()
  submitedData = output<boolean>();
  _adxBooking : any;
  adxBookingInfoForm?: FormGroup;
  isEdit = false;
  regex = /^\d{1,10}(\.\d{1,4})?$/;

  constructor(
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private adxBookingService: AdxBookingService,
  ){
    effect(()=>{
      this.getAdxBookingInfo();
    })
  }

  ngOnInit(){
    this.getAdxBookingInfo();
    this.createForm();
  }

  getAdxBookingInfo() {
    if (this.data() != null){
      this._adxBooking = this.data();
      this.resetForm();
    }
  } 

  createForm(): void {
    this.adxBookingInfoForm = this.fb.group({
      commAmt: [this._adxBooking?.commAmt, [Validators.required, Validators.pattern(this.regex)]],
      tax1Amt: [this._adxBooking?.tax1Amt, [Validators.required, Validators.pattern(this.regex)]],
      tax2Amt: [this._adxBooking?.tax2Amt, [Validators.required, Validators.pattern(this.regex)]],
      tax3Amt: [this._adxBooking?.tax3Amt, [Validators.required, Validators.pattern(this.regex)]],
      tax4Amt: [this._adxBooking?.tax4Amt, [Validators.required, Validators.pattern(this.regex)]],
      totalFare: [this._adxBooking?.totalFare, [Validators.required, Validators.pattern(this.regex)]],
    });
  }

  resetForm(): void {
    this.adxBookingInfoForm?.patchValue({
      commAmt: this._adxBooking?.commAmt ?? 0,
      tax1Amt: this._adxBooking?.tax1Amt ?? 0,
      tax2Amt: this._adxBooking?.tax2Amt ?? 0,
      tax3Amt: this._adxBooking?.tax3Amt ?? 0,
      tax4Amt: this._adxBooking?.tax4Amt ?? 0,
      totalFare: this._adxBooking?.totalFare ?? 0,
    })
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
    this.resetForm();
  }

  updateBooking(): void {
    this.adxBookingService.updateAdxBooking({
      ...this._adxBooking,
      ...this.adxBookingInfoForm?.value,
      submitToCode: this._adxBooking?.submitToCode[0] || '',
      adxInvoiceId: this._adxBooking?.adxInvoiceId,
      id: this._adxBooking?.id,
    }).subscribe(()=>{
      this.isEdit = false;
      this.submitedData.emit(true);

      this._snackBar.open('Booking updated successfully', 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      }); 
    })
  }

  redirectToInvoice(): void {
    if(this._adxBooking?.adxInvoiceId && !this._adxBooking.isModal) {
      location.href = `AdxInvoices/${this._adxBooking?.adxInvoiceId}`;
    }
  }
}