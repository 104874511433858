import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, catchError, throwError} from 'rxjs';
import {UpdateAdxAgentBooking} from '../models/update-adx-agent-booking-request';
import {AdxAgentBookingModel} from '../models/adx-agent-booking-model';

@Injectable({
  providedIn: 'root'
})

export class AdxAgentBookingService {
  private resourcePath: string = 'dbov2/adx-agent-booking';
  
  constructor(private httpClient: HttpClient) {
  }  
  
  getAdxAgentBookingById(id: number): Observable<AdxAgentBookingModel> {
      return this.httpClient
        .get<AdxAgentBookingModel>(`${this.resourcePath}/${id}`)
        .pipe(catchError(this.errorHandler));
  }
  
  getAdxAgentBookingsByBookingId(bookingId: number): Observable<AdxAgentBookingModel[]> {
      return this.httpClient
        .get<AdxAgentBookingModel[]>(`${this.resourcePath}/booking/${bookingId}`)
        .pipe(catchError(this.errorHandler));
  }

  addAdxAgentBooking(data: AdxAgentBookingModel): Observable<AdxAgentBookingModel> {
    return this.httpClient.post<AdxAgentBookingModel>(`${this.resourcePath}`, data)
      .pipe(catchError(this.errorHandler));
  }

  updateAdxAgentBooking(data: UpdateAdxAgentBooking): Observable<AdxAgentBookingModel> {
    return this.httpClient.put<AdxAgentBookingModel>(`${this.resourcePath}`, data)
      .pipe(catchError(this.errorHandler));
  }

  deleteAdxAgentBooking(adxAgentBookingId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.resourcePath}`, { params: { id: adxAgentBookingId } })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message || 'Server Error'));
  }
}
