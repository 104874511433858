<div class="grid-title-event">
    <h2 style="text-align:center" data-testid="payments-made-list-title">Payments Made</h2>
    @if (items.length){
        <div class="events-container">
            <button  data-testid="payments-made-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="payments-made-new-alert-button" aria-label="New alert button" mat-stroked-button (click)="handleCreatePaymentDialog()">
                <mat-icon fontIcon="attach_money"></mat-icon>
                Create Payment
            </button>
            <button color="primary" data-testid="download-payments-made-button" aria-label="Download Payments Made File" mat-stroked-button (click)="downloadGridToFile()" style="justify-content:flex-end">
                <mat-icon>download</mat-icon>
            </button>
        </div>
    }
</div>
<mat-table data-testid="payments-made-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>
  <ng-container matColumnDef="fmsAdxPaymentDetailId">
    <mat-header-cell data-testid="payments-made-fms-payment-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Payment Fms Id
    </mat-header-cell>
    <mat-cell data-testid="payments-made-fms-payment-id-cell" class="mat-cell" *matCellDef="let element">
      <span matTooltip={{element?.fmsAdxPaymentDetailId}}><a href="AdxPayments/{{element?.fmsAdxPaymentDetailId}}" (click)="handleIdClick($event, element)">{{element?.fmsAdxPaymentDetailId}}</a></span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="payeeName">
    <mat-header-cell data-testid="payments-made-client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Payee Name
    </mat-header-cell>
    <mat-cell data-testid="payments-made-client-name-cell" *matCellDef="let element">
      <span>{{element?.payeeName}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="vendorName">
    <mat-header-cell data-testid="payments-made-vendor-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Vendor Name
    </mat-header-cell>
    <mat-cell data-testid="payments-made-vendor-name-cell" *matCellDef="let element">
      <span>{{element?.vendorName}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="payTypeName">
    <mat-header-cell data-testid="payments-made-pay-type-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Pay Type
    </mat-header-cell>
    <mat-cell data-testid="payments-made-pay-type-cell" *matCellDef="let element">
      <span>{{element?.payTypeName}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="payMethodName">
    <mat-header-cell data-testid="payments-made-pay-method-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Pay Method
    </mat-header-cell>
    <mat-cell data-testid="payments-made-pay-method-cell" *matCellDef="let element">
      <span>{{element?.payMethodName}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="profileTypeName">
    <mat-header-cell data-testid="payments-made-profile-type-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Profile Type
    </mat-header-cell>
    <mat-cell data-testid="payments-made-profile-type-cell" *matCellDef="let element">
      <span>{{element?.profileTypeName}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="amount">
    <mat-header-cell data-testid="payments-made-amount-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Amount
    </mat-header-cell>
    <mat-cell data-testid="payments-made-amount-cell" *matCellDef="let element">
      <span>{{element?.amount}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="ckCcNo">
    <mat-header-cell data-testid="payments-made-ck-cc-no-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      ckCcNo
    </mat-header-cell>
    <mat-cell data-testid="payments-made-ck-cc-no-cell" *matCellDef="let element">
      <span>{{element?.ckCcNo | maskedCard}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="branchName">
    <mat-header-cell data-testid="payments-made-branch-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Branch
    </mat-header-cell>
    <mat-cell data-testid="payments-made-branch-cell" *matCellDef="let element">
      <span>{{element?.branchName}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="bankName">
    <mat-header-cell data-testid="payments-made-bank-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Bank
    </mat-header-cell>
    <mat-cell data-testid="payments-made-bank-cell" *matCellDef="let element">
      <span>{{element?.bankName}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="paymentDate">
    <mat-header-cell data-testid="payments-made-payment-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Payment Date
    </mat-header-cell>
    <mat-cell data-testid="payments-made-payment-date-cell" *matCellDef="let element">
      <span>{{element?.paymentDate | date:'M/d/yyyy'}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="tripExternalReference">
    <mat-header-cell data-testid="payments-made-trip-external-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      ADX Trip Ref
    </mat-header-cell>
    <mat-cell data-testid="payments-made-trip-external-reference-cell" class="mat-cell" *matCellDef="let element">
      <span  class="trip-external-reference-cell-text" matTooltip={{element?.tripExternalReference}}>
        <a data-testid="payments-made-trip-external-reference-cell-link" href="AdxInvoices/{{element?.adxInvoiceId}}">{{element?.tripExternalReference}}</a>
      </span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

  <mat-row *matRowDef="let row; columns: displayedColumns; let i = index">
  </mat-row>
</mat-table>
<mat-paginator data-testid="payments-made-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>
