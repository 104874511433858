import {Component, Input} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {TripService} from '../../service/trip.service';
import {Trip} from '../../model/trip';

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html'
})

@AutoUnsubscribe()
export class TripInfoComponent {
  @Input() public data: any;
  _trip!: Trip;
  isEditable: boolean = false;
  currentDate: string; 

  constructor(
    private buildr: FormBuilder,
    private tripService: TripService) {
      this.currentDate = new Date().toISOString().split('T')[0];
  }

  ngOnInit() {
    this.getTripInformation();
    this.tripInfoForm.get('tripStartDate')?.valueChanges.subscribe(() => {
      this.tripInfoForm.get('tripEndDate')?.updateValueAndValidity();
    });
  }

  getTripInformation() {
    if (this.data != null) {
      this.tripService
        .getTripById(this.data.tripId)
        .subscribe((data: Trip) => {
          if (data) {
            this._trip = data;
            this.patchForm(data);
          }
        });
    }
  }

  tripInfoForm = this.buildr.group({
    tripName: [{ value: '', disabled: !this.isEditable },  [Validators.required, this.tripNameLengthValidator]],
    tripTotal: [{ value: 0, disabled: true }],
    tripStartDate: [{ value: '', disabled: !this.isEditable }, Validators.required],
    tripEndDate: [{ value: '', disabled: !this.isEditable }, [Validators.required, this.endDateValidator.bind(this)]],
    templateNotes: this.buildr.control('')
  })

  optionalLengthValidator(maxLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value && value.length > maxLength ? { lengthTooLong: { maxLength } } : null;
    };
  }

  tripNameLengthValidator(control: AbstractControl): ValidationErrors | null {
    const tripName = control.value;
    return tripName && tripName.length > 500 ? { tripNameTooLong: true } : null;
  }

  endDateValidator(control: AbstractControl): ValidationErrors | null {
    const startDate = this.tripInfoForm?.get('tripStartDate')?.value;
    if (startDate && control.value) {
      return new Date(control.value) < new Date(startDate) ? { endDateInvalid: true } : null;
    }
    return null;
  }

  resetForm(): void {
    this.tripInfoForm.reset(this._trip);
  }

  patchForm(data: Trip){
    this.tripInfoForm?.patchValue({
      tripName: data.tripName,
      tripTotal: data.tripTotal,
      tripStartDate: data.tripStartDate,
      tripEndDate: data.tripEndDate,
    });
  }
}

