import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {PayTypeResponse, PayMethodResponse, ProfileTypeResponse, SubmitToResponse, CurrencyResponse} from '../../model/pay';
import {BanksResponse, BranchResponse} from '../../model/bank';

@Injectable({
  providedIn: 'root'
})
export class PayService {

    constructor(private http:HttpClient){}

    getPayType(params?: Record<string, number | string | boolean>): Observable<PayTypeResponse>{
        return this.http.get<PayTypeResponse>('dbov2/pay-type', {
            params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }

    getPayMethod(params?: Record<string, number | string | boolean>): Observable<PayMethodResponse>{
        return this.http.get<PayMethodResponse>('dbov2/payment-method', {
            params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }

    getBranches(params?: Record<string, number | string | boolean>): Observable<BranchResponse>{
        return this.http.get<BranchResponse>('dbov2/branch', {
            params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }

    getBanks(params?: Record<string, number | string | boolean>): Observable<BanksResponse>{
        return this.http.get<BanksResponse>('dbov2/bank', {
            params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }

    getProfileType(params?: Record<string, number | string | boolean>): Observable<ProfileTypeResponse>{
        return this.http.get<ProfileTypeResponse>('dbov2/profile-type', {
            params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }

    getSubmitTo(params?: Record<string, number | string | boolean>): Observable<SubmitToResponse>{
        return this.http.get<SubmitToResponse>('dbov2/submit-to', {
            params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }

    getCurrency(params?: Record<string, number | string | boolean>): Observable<CurrencyResponse>{
        return this.http.get<CurrencyResponse>('dbov2/currency', {
            params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }
}

