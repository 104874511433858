import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ClientData, ClientFilterResult} from './client.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private baseUrl = 'dbov2/client/search';
  private clientFilter: ClientFilterResult = {};

  constructor(private http: HttpClient) { }

  getClients(pageNumber: number = 1, pageSize: number = 25, filters: ClientFilterResult = {}): Observable<ClientData> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (filters.clientName) {
      params = params.set('ClientName', filters.clientName);
    }
    if (filters.agentName) {
      params = params.set('AgentName', filters.agentName);
    }
    if (filters.dataSource) {
      params = params.set('DataSource', filters.dataSource);
    }

    const options = { params };
    return this.http.get<ClientData>(`${this.baseUrl}`, options);
  }

  setClientFilter(filter: ClientFilterResult): void {
    this.clientFilter = filter;
  }

  getClientFilter(): ClientFilterResult {
    return this.clientFilter;
  }
}
