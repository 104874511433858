<form data-testid="adx-agent-booking-info-form" class="template-form adx-booking-info-form" [formGroup]="adxAgentBookingForm">  
    <div data-testid="adx-agent-booking-info-main-div" class="filter__fields">
        <h2 data-testid="adx-agent-booking-info-title" class="page-details-title-style">Agent {{_adxAgentBooking.agent?.adxAgentId}}</h2>
        <div class="items-space-between m-t-8 m-b-5">
            <span>
              <button data-testid="adx-agent-booking-info-edit-button"
                      mat-stroked-button
                      color="primary"
                      (click)="toggleEdit()"
                      style="margin-right: 10px;"
                      type="button">
                {{ isEdit ? 'Cancel' : 'Edit' }}
              </button>
              
              @if(isEdit) {
                <button data-testid="adx-agent-booking-info-update-button" mat-raised-button color="primary"
                (click)="updateAgent();" [disabled]="adxAgentBookingForm.invalid">Update</button>
              }
            </span>
        </div>
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-agent-booking-info-name-grid-list" [cols]="2" gutterSize="10px" rowHeight="90">
                    <mat-grid-tile data-testid="adx-agent-booking-info-name-grid-tile">
                      <ng-container *ngIf="isEdit; else viewAgentName">
                        <profile-autocomplete [autocompleteMode]="autocompleteMode" [dataSourceId] ="dataSourceId" [searchWithProfile]="false" formControlName="agent" style="width:100%"></profile-autocomplete>
                      </ng-container>
                      <ng-template #viewAgentName>
                        <read-only-form-field title="Agent" data-testid="adx-agent-booking-info-name">
                            <mat-form-field data-testid="adx-agent-booking-info-name-field">
                                <input data-testid="adx-agent-booking-info-name-input" matInput formControlName="agentName">
                            </mat-form-field>
                        </read-only-form-field>
                      </ng-template>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-first-name-grid-tile">
                        <read-only-form-field title="First Name" data-testid="adx-agent-booking-info-first-name">
                            <mat-form-field data-testid="adx-agent-booking-info-first-name-field">
                              <input data-testid="adx-agent-booking-info-first-name-input" matInput formControlName="firstName">
                            </mat-form-field>
                        </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-middle-name-grid-tile">
                        <read-only-form-field title="Middle Name" data-testid="adx-agent-booking-info-middle-name">
                            <mat-form-field data-testid="adx-agent-booking-info-middle-name-field">
                                <input data-testid="adx-agent-booking-info-middle-name-input" matInput formControlName="middleName">
                              </mat-form-field>
                        </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-last-name-grid-tile">
                        <read-only-form-field title="Last Name" data-testid="adx-agent-booking-info-last-name">
                            <mat-form-field data-testid="adx-agent-booking-info-last-name-field">
                                <input data-testid="adx-agent-booking-info-last-name-input" matInput formControlName="lastName">
                              </mat-form-field>
                        </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-interface-grid-tile">
                        <read-only-form-field title="Interface" data-testid="adx-agent-booking-info-interface">
                            <mat-form-field data-testid="adx-agent-booking-info-interface-field">
                                <input data-testid="adx-agent-booking-info-interface-input" matInput formControlName="interfaceId">
                              </mat-form-field>
                        </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-email-grid-tile">
                        <read-only-form-field title="Email" data-testid="adx-agent-booking-info-email">
                            <mat-form-field data-testid="adx-agent-booking-info-email-field">
                                <input data-testid="adx-agent-booking-info-email-input" matInput formControlName="email">
                              </mat-form-field>
                        </read-only-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>