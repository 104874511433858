import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { MaskedCardPipe } from 'src/infrastructure/pipes/masked-card.pipe';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BraintreeMatchingVccService } from '../../service/braintree-matching-vcc.service';
import {  BraintreeMatchingVccModel } from '../../model/braintree-matching-vcc';

@AutoUnsubscribe()
@Component({
  selector: 'braintree-matching-vcc-edit-modal',
  templateUrl: './braintree-matching-vcc-edit-modal.component.html',
  styleUrls: ['./braintree-matching-vcc-edit-modal.component.scss']
})
export class BraintreeMatchingVccModalComponent implements OnInit {

  displayedColumns: string[] = [
    'tramsInvoiceNumber',
    'tramsBookingNo',
    'tramsBookingTravelTypeName',
    'tramsBookingVendorName',
    'totalFare'
  ];

  loadedData = false;
  submitedData = true;
  dataSource: any[] = [];
  formControlNames: { [key: string]: { totalFare: string } } = {};

  formChanges$?: Subscription;
  dynamicFormGroup?: FormGroup;
  initialValues: Record<string, string> = {};
  hasChanges = false;

  amountPattern = /^-?\d{1,10}(\.\d{1,4})?$/;

  pagination: Partial<PageEvent & { totalItemCount?: number }> = {};
  sorting: Partial<Sort> = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<BraintreeMatchingVccModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BraintreeMatchingVccModel,
    private braintreeMatchingVccService: BraintreeMatchingVccService,
    private maskedCardPipe: MaskedCardPipe,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if (!this.data) {
      this.dialogRef.close();
    }

    this.pagination = {
      pageSize: this.pagination.pageSize || 25,
      pageIndex: 0,
      totalItemCount: 0,
    };

    this.getItems();
  }

  buildForm(): void {
    const formControllers = this.getFormControlers();
    this.dynamicFormGroup = new FormGroup(formControllers);

    this.dataSource.forEach((payment) => {
      const tramsBookingId = payment.tramsBookingId;

      if (!this.formControlNames[tramsBookingId]) {
        this.formControlNames[tramsBookingId] = {
          totalFare: `totalFare_${tramsBookingId}`
        };
      }

      const formControlNames = this.formControlNames[tramsBookingId];

      if (this.dynamicFormGroup) {
        this.initialValues[`totalFare_${tramsBookingId}`] = payment.totalFare;

        this.dynamicFormGroup.addControl(formControlNames.totalFare, new FormControl(payment.totalFare));
      }
    });
  }

  getFormControlers(): Record<string, FormControl> {
    const formList: Record<string, FormControl> = {};
    this.dataSource.forEach((payment) => {
      const tramsBookingId = payment.tramsBookingId;
      formList[`totalFare_${tramsBookingId}`] = new FormControl('', Validators.pattern(this.amountPattern));
    });

    return formList;
  }

  handleSortChange(sort: Sort): void {
    this.sorting = sort;

    if (!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = {};
    }

    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  getItems(): void {
    this.loadedData = false;
    this.braintreeMatchingVccService.searchBraintreeMatchingReconciliation({
      filters: {
        ccNoLastFourDigits: this.maskedCardPipe.transform(this.data.lastFour, ''),
        tripExternalReference: this.data.externalReference
        /*payeeName: this.data.payeeName*/
      },
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((data: { items: any[]; pagination: { pageNumber: number; pageSize: any; totalItemCount: any; }; }) => {
      this.dataSource = [];
      data?.items.forEach((item: any) => {
        this.dataSource.push(item);
        console.log(item);
      });
      this.pagination = {
        ...this.pagination,
        pageIndex: data.pagination?.pageNumber ? data.pagination.pageNumber - 1 : 0,
        pageSize: data.pagination?.pageSize || this.pagination.pageSize || 25,
        totalItemCount: data.pagination?.totalItemCount || this.dataSource.length,
      };
      this.loadedData = true;
      this.buildForm();
    });
  }

  isDisabled(): boolean {
    return !this.dataSource.some((payment) => {
      const tramsBookingId = payment.tramsBookingId;
      const formControlNames = this.formControlNames[tramsBookingId];

      const totalFareValue = this.dynamicFormGroup?.get(formControlNames.totalFare)?.value;

      const initialTotalFare = Number(this.initialValues[`totalFare_${tramsBookingId}`]);


      const totalFare = totalFareValue !== '' && totalFareValue !== null ? Number(totalFareValue) : initialTotalFare;

      return totalFare !== initialTotalFare;

    });
  }

  updateAmounts(): void {
    this.submitedData = false;
    const updatedPairs: { tramsBookingId: any; amount: number; }[] = [];

    const changedPayments = this.dataSource.filter((payment) => {
      const tramsBookingId = payment.tramsBookingId;
      const formControlNames = this.formControlNames[tramsBookingId];

      const totalFareValue = this.dynamicFormGroup?.get(formControlNames.totalFare)?.value;

      const initialTotalFare = Number(this.initialValues[`totalFare_${tramsBookingId}`]);

      const totalFare = totalFareValue !== '' && totalFareValue !== null ? Number(totalFareValue) : initialTotalFare;

      return totalFare !== initialTotalFare;
    });

    changedPayments.forEach((payment) => {
      const tramsBookingId = payment.tramsBookingId;
      const formControlNames = this.formControlNames[tramsBookingId];

      const totalFareValue = this.dynamicFormGroup?.get(formControlNames.totalFare)?.value;

      const initialTotalFare = Number(this.initialValues[`totalFare_${tramsBookingId}`]);

      const totalFare = totalFareValue !== '' && totalFareValue !== null ? Number(totalFareValue) : initialTotalFare;

      updatedPairs.push({
        tramsBookingId: payment.tramsBookingId,
        amount: totalFare
      });
    });

    if (updatedPairs.length > 0) {
      this.braintreeMatchingVccService.adjustmentTramsBraintreeReconciliation(updatedPairs).subscribe({
        next: () => {
          this.snackBar.open('Service adjusted successfully', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
          });
          this.getItems();
          this.submitedData = true;
        },
        error: () => {
          this.submitedData = true;
        }
      });
    } else {
      this.snackBar.open('No changes detected', 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      this.submitedData = true;
    }
  }



  closeModal(): void {
    this.dialogRef.close();
  }
}
