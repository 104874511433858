<div class="grid-title-event">
  <h2 data-testid="client-payments-reconciliation-list-title">Payments Reconciliation</h2>

  <div class="events-container">
    @if (items.length){
    <button data-testid="client-payments-reconciliation-button" aria-label="Refresh grid" mat-icon-button (click)="getItems()">
      <mat-icon fontIcon="replay"></mat-icon>
    </button>
    }
    <button color="primary" data-testid="client-payments-reconciliation-upload-file-button" aria-label="Upload file button" mat-stroked-button (click)="uploadFileDialog()">
      <mat-icon fontIcon="upload_file"></mat-icon>
      Upload File
    </button>
    @if (items.length){
    <button class="btn-download" color="primary" data-testid="download-client-payments-reconciliation-button" aria-label="Download Client Payments Reconciliation File" mat-stroked-button (click)="downloadGridToFile()">
      <mat-icon>download</mat-icon>
    </button>
    }
  </div>
</div>
<div class="mat-elevation-z8">

  <mat-table data-testid="client-payments-reconciliation-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort >

    <!-- clientNameAlias Column -->
    <ng-container matColumnDef="clientNameAlias" >
      <mat-header-cell data-testid="client-payments-reconciliation-cc-processor-id-header" class="mat-header-cell" style="min-width: 10%;" *matHeaderCellDef mat-sort-header  >
        Name
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-cc-processor-id-cell" class="mat-cell" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}" style="min-width: 10%;">
        <span>{{element?.clientNameAlias}}</span>
      </mat-cell>
    </ng-container>

    <!-- reconType Column -->
    <ng-container matColumnDef="reconType" class="hideOnMin">
      <mat-header-cell data-testid="client-payments-reconciliation-recon-type-header" class="mat-header-cell hideOnMin" *matHeaderCellDef mat-sort-header>
        Type
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-recon-type-cell" class="mat-cell hideOnMin" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.reconType}}</span>
      </mat-cell>
    </ng-container>

    <!-- payeeName Column -->
    <ng-container matColumnDef="payeeName">
      <mat-header-cell data-testid="client-payments-reconciliation-payee-name-header" class="mat-header-cell" style="min-width: 10%;" *matHeaderCellDef mat-sort-header>
        Payee Name
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-payee-name-cell" class="mat-cell" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}" style="min-width: 10%;">
        <span>{{element?.payeeName}}</span>
      </mat-cell>
    </ng-container>

    <!-- lastFour Column -->
    <ng-container matColumnDef="lastFour">
      <mat-header-cell data-testid="client-payments-reconciliation-last-four-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
        Last Four
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-last-four-cell" class="mat-cell" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.lastFour}}</span>
      </mat-cell>
    </ng-container>

    <!-- onFileAmountTotal Column -->
    <ng-container matColumnDef="onFileAmountTotal">
      <mat-header-cell data-testid="client-payments-reconciliation-on-file-amount-total-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
        On File Total
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-on-file-amount-total-cell" class="mat-cell" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.onFileAmountTotal}}</span>
      </mat-cell>
    </ng-container>

    <!-- fmsPaymentAmountTotal Column -->
    <ng-container matColumnDef="fmsPaymentAmountTotal">
      <mat-header-cell data-testid="client-payments-reconciliation-fms-payment-amount-total-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
        Fms Payments Total
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-fms-payment-amount-total-cell" class="mat-cell" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.fmsPaymentAmountTotal}}</span>
      </mat-cell>
    </ng-container>

    <!-- adxReference Column -->
    <ng-container matColumnDef="adxReference">
      <mat-header-cell data-testid="client-payments-reconciliation-adx-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
        ADX Reference
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-adx-reference-cell" class="mat-cell" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.adxReference}}</span>
      </mat-cell>
    </ng-container>

    <!-- agentName Column -->
    <ng-container matColumnDef="agentName" class="hideOnMin">
      <mat-header-cell data-testid="client-payments-reconciliation-agent-name-header" class="mat-header-cell hideOnMin" style="min-width: 10%;" *matHeaderCellDef mat-sort-header>
        Agent Name
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-agent-name-cell" class="mat-cell hideOnMin" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}" style="min-width: 10%;">
        <span>{{element?.agentName}}</span>
      </mat-cell>
    </ng-container>

    <!-- vendorName Column -->
    <ng-container matColumnDef="vendorName">
      <mat-header-cell data-testid="client-payments-reconciliation-fms-vendor-name-header" class="mat-header-cell" style="min-width: 10%;" *matHeaderCellDef mat-sort-header>
        Vendor Name
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-fms-vendor-name-cell" class="mat-cell" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}" style="min-width: 10%;">
        <span>{{element?.vendorName}}</span>
      </mat-cell>
    </ng-container>

    <!-- bankNo Column -->
    <ng-container matColumnDef="bankNo" class="hideOnMin" >
      <mat-header-cell data-testid="client-payments-reconciliation-bank-no-header" class="mat-header-cell hideOnMin" *matHeaderCellDef mat-sort-header>
        Bank No
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-bank-no-cell" class="mat-cell hideOnMin" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.bankNo}}</span>
      </mat-cell>
    </ng-container>

    <!-- bankName Column -->
    <ng-container matColumnDef="bankName" class="hideOnMin">
      <mat-header-cell data-testid="client-payments-reconciliation-bank-name-header" class="mat-header-cell hideOnMin" *matHeaderCellDef mat-sort-header>
        Bank Name
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-bank-name-cell" class="mat-cell hideOnMin" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.bankName}}</span>
      </mat-cell>
    </ng-container>

    <!-- dataSourceName Column -->
    <ng-container matColumnDef="dataSourceName" class="hideOnMin">
      <mat-header-cell data-testid="client-payments-reconciliation-data-source-name-header" class="mat-header-cell hideOnMin" *matHeaderCellDef mat-sort-header>
        Source Name
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-data-source-name-cell" class="mat-cell hideOnMin" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.dataSourceName}}</span>
      </mat-cell>
    </ng-container>

    <!-- dataSourceId Column -->
    <ng-container matColumnDef="dataSourceId" class="hideOnMin">
      <mat-header-cell data-testid="client-payments-reconciliation-data-source-id-header" class="mat-header-cell dataSourceId" *matHeaderCellDef mat-sort-header>
        Source Id
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-data-source-id-cell" class="mat-cell dataSourceId" *matCellDef="let element" [ngStyle]="{'background-color': getBackgroundColor(element)}">
        <span>{{element?.dataSourceId}}</span>
      </mat-cell>
    </ng-container>

    <!-- actions Column -->
    <ng-container matColumnDef="actions" class="hideOnMin">
      <mat-header-cell data-testid="client-payments-reconciliation-actions-header" class="mat-header-cell hideOnMin" *matHeaderCellDef>
        Actions
      </mat-header-cell>
      <mat-cell data-testid="client-payments-reconciliation-actions-cell" class="mat-cell hideOnMin" *matCellDef="let element">
        <button mat-icon-button aria-label="edit payment reconciliation" (click)="openEditModal(element)" [ngStyle]="{'display': getDisplayIcon(element)}">
          <mat-icon fontIcon="edit" />
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator data-testid="client-payments-reconciliation-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
                 [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
  </mat-paginator>
</div>
