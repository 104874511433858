import {Injectable} from '@angular/core';
import {ResponseCard} from '../model/response-card';
import {Card} from '../model/card';
import {AzureKeyCredential, ChatCompletions, EventStream, OpenAIClient} from '@azure/openai';

@Injectable({
  providedIn: 'root'
})
export class OpenAiService {

  private readonly openAiClient: OpenAIClient;
  private readonly azureOpenAiEndpoint: string = 'https://triparc-fms-openai.openai.azure.com';
  private readonly azureOpenAiApiKey: string = '55c54b5dc41b4a58b6fa9170a22d4bd9';
  private readonly azureOpenAiDeploymentName: string = 'gpt-4o-mini-fms-2';
  private readonly azureLogsEndpoint: string = 'https://adxlegacylogs-search.search.windows.net';
  private readonly azureLogsIndexName: string = 'fms-documents-index';
  private readonly azureLogsApiKey: string = 'toZ2AyYHpMGDMwNwt9ifyIfYOdpN97hv7k8kOPKhQsAzSeAwuiJg';

  constructor() {
    this.openAiClient = new OpenAIClient(this.azureOpenAiEndpoint, new AzureKeyCredential(this.azureOpenAiApiKey));
  }

  public async createChatCompletion(_: Card[]): Promise<ResponseCard | undefined> {

    const messages = [
      {role: 'user', content: _[0].content},
    ];

    const govnOptions = this.createDefaultOptions();

    const eventStreamChatCompletions: EventStream<ChatCompletions> = await this.openAiClient.streamChatCompletions(
      this.azureOpenAiDeploymentName,
      messages,
      govnOptions);

    let response: string = '';
    for await (const event of eventStreamChatCompletions) {
      for (const choice of event.choices) {
        const newText = choice.delta?.content;
        if (!!newText) {
          response += newText;
        }
      }
    }

    return new Promise((resolve, reject) => {
      try {
        // @ts-ignore
        resolve({content: response, kind: 'response'});
      } catch (error) {
        reject(error);
      }
    });
  }

  createDefaultOptions(): any {
    return {
      maxTokens: 800,
        temperature: 0.7,
      topP: 0.95,
      frequencyPenalty: 0,
      presencePenalty: 0,
      azureExtensionOptions: {
      extensions: [
        {
          type: 'AzureCognitiveSearch',
          parameters: {
            'endpoint': this.azureLogsEndpoint,
            'index_name': this.azureLogsIndexName,
            'semantic_configuration': 'default',
            'query_type': 'simple',
            'fields_mapping': {},
            'in_scope': true,
            'role_information': 'You are an AI assistant that helps people find information.',
            'filter': null,
            'strictness': 3,
            'top_n_documents': 5,
            'authentication':
              {
                'type': 'api_key',
                'key': this.azureLogsApiKey
              }
          }
        }
      ]
    },
    }
  }
}
