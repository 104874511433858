import {Component, forwardRef, Injector, input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, Validators, NgControl, FormControlName, FormGroupDirective} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {Observable, of} from 'rxjs';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {AgentService} from 'src/app/agent/component/service/agent.service';
import {ClientService} from 'src/app/client/service/client.service';
import {Agent, AgentData} from 'src/app/agent/component/service/agent.model';
import {Client, ClientData} from 'src/app/client/service/client.model';
import {AutocompleteMode} from '../../model/autocomplete';

@Component({
  selector: 'autocomplete',
  templateUrl: 'autocomplete.component.html',
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AutocompleteComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => AutocompleteComponent),
        multi: true,
    },
  ],
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor, Validator {
    autocompleteMode = input<'A'| 'I'>('A');

    autocompleteInnerControl = new FormControl<string | null>(null);

    options: Agent[] | Client[] = [];
    filteredOptions$?: Observable<Agent[] | Client[]>;

    value?: Agent | Client | null;
    onChange!:(value: Agent | Client | null) => void;
    onTouched!:(value: Agent | Client | null) => void;

    modeConfig: Record<string, AutocompleteMode> = {
        'A': {
            label: 'Agent',
            nameFn: (value: Agent | Client) => (value as Agent)?.agentName,
        },
        'I': {
            label: 'Client',
            nameFn: (value: Agent | Client) => {
                return  value?.firstName 
                        ? `${value?.firstName || ''} ${value?.lastName || ''}`
                        : '';
            },
        },
    };

    
    constructor(
        private agentService: AgentService,
        private clientService: ClientService,
        private injector: Injector,   
    ){}

    ngOnInit(): void {
        this.filteredOptions$ = this.autocompleteInnerControl.valueChanges
        .pipe(
            startWith(''),
            debounceTime(1000),
            switchMap(value => {
                if(value && value?.length >= 3){
                    return this.serviceGet(value)
                    .pipe(map((data)=>{
                        this.options = data.items;
                        return this.filterOptions(value || '');
                    }));
                }
                this.options = [];
                return of([]);
            }),
        );

        const parentControl = this.injector.get(NgControl) as FormControlName;
        const parentForm = this.injector.get(FormGroupDirective).getControl(parentControl);

        if(parentForm.hasValidator(Validators.required)){
            this.autocompleteInnerControl.setValidators(Validators.required);
        }
    }

    filterOptions(value: string): Agent[] | Client[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(
            option => 
                this.modeConfig[this.autocompleteMode()]
                    .nameFn(option)
                    ?.toLowerCase()
                    ?.includes(filterValue)
        ) as Agent[] | Client[];
        
            
    }

    clearAutocomplete(componentRef: MatAutocomplete): void {
        componentRef.options.forEach((optionRef) => optionRef.deselect());
        this.options = [];
        this.autocompleteInnerControl.reset();
        this.onChange(null);
        this.onTouched(null);
    }

    writeValue(value: Agent | Client | null): void {
        this.value = value;
        if (!value) {
            this.autocompleteInnerControl.reset();
        }
    }

    registerOnChange(fn: (value: Agent | Client |  null)=> void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: (value: Agent | Client | null)=> void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.autocompleteInnerControl[isDisabled? 'disable' : 'enable']();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if(control.errors && !control.value){
            return {
                required: true,
            };
        }
        return null;
    }

    setValue(value:Agent | Client): void {
        this.onChange(value);
        this.onTouched(value);
    }

    serviceGet(name:string): Observable<AgentData | ClientData>{
        if(this.autocompleteMode() === 'I'){
            return this.clientService.getClients(1, 1000, {
                clientName: name
            });
        }

        return this.agentService.getAgents(1, 1000, {
            agentName: name
        });
    }
}