<modal-panel id="bank-account-modal-panel" title="{{title}}"  (close)="handleBankAccountModalCloseBtnClick()">
    <form [formGroup]="bankAccountModalForm" id="bank-account-modal-form" class="template-form">
        <div id="bank-account-main-container" class="container">
            <div id="bank-account-error-div" *ngIf="bankAccountModalForm.invalid">
                <mat-error>Form is invalid</mat-error>
            </div>
            <h2> {{data.fundingData[0].clientName}} </h2>
            <h4> {{data.dateRange | async}} </h4>

            @if (data.fundingData[0].totalAta === 0) {
                <mat-form-field id="bank-account-total-ati-mat-form-field">
                    <mat-label id="bank-account-total-ati-field-title">Total Balance</mat-label>
                    <input id="bank-account-total-ati-input-field" matInput value="{{data.fundingData[0].totalAti}}">
                </mat-form-field>
                <mat-form-field id="bank-account-outstanding-liability-mat-form-field">
                    <mat-label id="bank-account-outstanding-liability-field-title">Outstanding Liability</mat-label>
                    <input id="bank-account-outstanding-liability-input-field" matInput value="{{data.fundingData[0].outstandingLiability}}">
                </mat-form-field>
            }

            @if (data.fundingData[0].totalAti === 0) {
                <mat-form-field id="bank-account-total-ata-mat-form-field">
                    <mat-label id="bank-account-total-ata-field-title">Total Balance</mat-label>
                    <input id="bank-account-total-ata-input-field" matInput value="{{data.fundingData[0].totalAta}}">
                </mat-form-field>
                <mat-form-field id="bank-account-outstanding-authorization-mat-form-field">
                    <mat-label id="bank-account-outstanding-authorization-field-title">Outstanding Authorization</mat-label>
                    <input id="bank-account-outstanding-authorization-input-field" matInput value="{{data.fundingData[0].outstandingAuthorization}}">
                </mat-form-field>
            }

            <mat-form-field id="bank-account-sales-allowance-mat-form-field">
                <mat-label id="bank-account-sales-allowance-field-title">Sales Allowance</mat-label>
                <input id="bank-account-sales-allowance-input-field" matInput value="{{data.fundingData[0].salesAllowance}}">
            </mat-form-field>

            <mat-form-field id="bank-account-overage-mat-form-field">
                <mat-label id="bank-account-overage-field-title">Overage</mat-label>
                <input id="bank-account-overage-input-field" matInput value="{{data.fundingData[0].overage}}">
            </mat-form-field>

            <mat-form-field id="bank-account-total-activity-mat-form-field">
                <mat-label id="bank-account-total-activity-field-title">Total Activity</mat-label>
                <input id="bank-account-total-activity-input-field" matInput value="{{data.fundingData[0].totalActivity}}">
            </mat-form-field>

            <mat-form-field id="bank-account-beginning-cash-balance-mat-form-field">
                <mat-label id="bank-account-beginning-cash-balance-field-title">Beginning Cash Balance</mat-label>
                <input id="bank-account-beginning-cash-balance-input-field" matInput value="{{data.fundingData[0].beginningCashBalance}}">
            </mat-form-field>
            <mat-form-field id="bank-account-ending-cash-balance-mat-form-field">
                <mat-label id="bank-account-ending-cash-balance-field-title">Ending Cash Balance</mat-label>
                <input id="bank-account-ending-cash-balance-input-field" matInput value="{{data.fundingData[0].endingCashBalance}}">
            </mat-form-field>
            <mat-form-field id="bank-account-minimum-cash-balance-field-mat-form-field">
                <mat-label id="bank-account-minimum-cash-balance-field-title">Minimun Cash Balance</mat-label>
                <input id="bank-account-minimum-cash-balance-input-field" matInput value="{{data.fundingData[0].minimumCashBalance}}">
            </mat-form-field>
            <mat-form-field id="bank-account-new-cash-balance-field-mat-form-field">
                <mat-label id="bank-account-new-cash-balance-field-title">New Cash Balance</mat-label>
                <input id="bank-account-new-cash-balance-input-field" matInput value="{{data.fundingData[0].newCashBalance}}">
            </mat-form-field>
            <mat-form-field id="bank-account-prior-day-cash-balance-field-mat-form-field">
                <mat-label id="bank-account-prior-day-cash-balance-field-title">Prior Day Cash Balance</mat-label>
                <input id="bank-account-prior-day-cash-balance-input-field" matInput value="{{data.fundingData[0].priorDayCashBalance}}">
            </mat-form-field>

            <mat-form-field id="bank-account-credit-chargeback-mat-form-field">
                <mat-label id="bank-account-credit-chargeback-field-title">Credit Chargeback Amount</mat-label>
                <input id="bank-account-credit-chargeback-input-field" matInput value="{{data.fundingData[0].creditChargebackAmount}}">
            </mat-form-field>

            <mat-form-field id="bank-account-credit-return-mat-form-field">
                <mat-label id="bank-account-credit-return-field-title">Credit Return Amount</mat-label>
                <input id="bank-account-credit-return-input-field" matInput value="{{data.fundingData[0].creditReturnAmount}}">
            </mat-form-field>

            <mat-form-field id="bank-account-credit-sale-mat-form-field">
                <mat-label id="bank-account-credit-sale-field-title">Credit Sale Amount</mat-label>
                <input id="bank-account-credit-sale-input-field" matInput value="{{data.fundingData[0].creditSaleAmount}}">
            </mat-form-field>

            <mat-form-field id="bank-account-credit-void-mat-form-field">
                <mat-label id="bank-account-credit-void-field-title">Credit Void Amount</mat-label>
                <input id="bank-account-credit-void-input-field" matInput value="{{data.fundingData[0].creditVoidAmount}}">
            </mat-form-field>

            <mat-form-field id="bank-account-net-debit-credit-mat-form-field">
                <mat-label id="bank-account-net-debit-credit-field-title">Net Debit Credit To Client</mat-label>
                <input id="bank-account-net-debit-credit-input-field" matInput value="{{data.fundingData[0].netDebitCreditToClient}}">
            </mat-form-field>

            <mat-form-field id="bank-account-purchase-card-amount-mat-form-field">
                <mat-label id="bank-account-purchase-card-amount-field-title">Purchase Card Amount</mat-label>
                <input id="bank-account-purchase-card-amount-input-field" matInput value="{{data.fundingData[0].purchaseCardAmount}}">
            </mat-form-field>

            <mat-form-field id="bank-account-purchase-card-return-amount-mat-form-field">
                <mat-label id="bank-account-purchase-card-return-amount-field-title">Purchase Card Return Amount</mat-label>
                <input id="bank-account-purchase-card-return-amount-input-field" matInput value="{{data.fundingData[0].purchaseCardReturnAmount}}">
            </mat-form-field>

            <mat-form-field id="bank-account-wire-ach-fund-mat-form-field">
                <mat-label id="bank-account-wire-ach-fund-field-title">Wire Ach To Fund Cash Amount</mat-label>
                <input id="bank-account-wire-ach-fund-input-field" matInput value="{{data.fundingData[0].wireAchToFundCashAmount}}">
            </mat-form-field>

        </div>
        <br>
        <div class="row">
            <ng-container #container></ng-container>
        </div>
    </form>
    <ng-container modalPanelFooter>
        <button id="bank-account-modal-close-button" mat-raised-button color="primary" (click)="handleBankAccountModalCloseBtnClick()">Close</button>
    </ng-container>
</modal-panel>