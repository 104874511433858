import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {Observable, Subscription} from 'rxjs';
import {AdxVccService} from '../../service/adx-vcc.service';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {AdxVccResponse, AdxVccFilter, AdxVccModel} from '../../model/adx-data';
import {ModalService} from 'src/app/shared/service/modal/modal.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';
import {MaskedCardPipe} from 'src/infrastructure/pipes/masked-card.pipe';
import {TransactionHistoryDialog} from '../transaction-history-dialog/transaction-history-dialog.component';
import {TripReferenceComponent} from '../trip-reference-modal/trip-reference-modal.component';
import {VendorViewerModalComponent} from '../../../vendor/component/viewer/vendor-viewer-modal/vendor-viewer-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {VendorViewerType} from 'src/app/vendor/component/viewer/vendor-viewer-modal/vendor-viewer-modal.component';
import {HttpParams} from '@angular/common/http';
import {VendorService} from '../../../vendor/service/vendor.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from 'src/environments/environment';
import {FmsVccPayComponent} from '../fms-vcc-pay/fms-vcc-pay.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-adx-vcc',
  templateUrl: './adx-vcc.component.html',
})
export class AdxVccComponent implements OnInit{
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';

  verticalPosition: MatSnackBarVerticalPosition = 'top';

  displayedColumns: Array<string> = [
    'id',
    'tripReference',
    'merchantName',
    'vendorName',
    'cardStatus',
    'maskedCreditCardNumber',
    'dateCreated',
    'expirationDate',
    'amount',
    'availableBalance',
    'amountCurrencyId',
  ];
  adxFilter: AdxVccFilter = {}
  adxFilter$?: Subscription;
  adxPage: PageEvent = {pageIndex: 0, pageSize: 25, length: 0};
  dataSource$?: Observable<AdxVccResponse>;
  cardGuid = '';

  public readonly showPay: boolean = false;
  private readonly testEnvironments = ['Local', 'Development', 'QA', 'UAT'];
  payClosed$?: Subscription;

  constructor(
    private adxService: AdxVccService,
    private vendorService: VendorService,
    private modalService: ModalService,
    private _snackBar: MatSnackBar,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
    private maskedCardPipe: MaskedCardPipe,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ){
    this.showPay = this.testEnvironments.includes(environment.environmentName);
    if(this.showPay){
      this.displayedColumns.push('Pay')
    }
  }

  ngOnInit(): void {
    this.searchAdxGridData();
  }

  searchAdxGridData(){
    this.route.paramMap.subscribe(params => {
        this.cardGuid = params.get('cardGuid') || '';
        if (this.cardGuid) {
          this.adxFilter.cardGuid = this.cardGuid;
        } else {
          this.adxFilter.cardGuid = '';
        }

        this.dataSource$ = this.adxService.searchAdx({
            filters: this.adxFilter,
            sort: { active: 'DateCreated', direction: 'desc' }
        });
    });

    this.adxFilter$ = this.adxService.getAdxFilter().subscribe((filter: AdxVccFilter)=>{
        if(this.cardGuid){
          filter.cardGuid = this.cardGuid;
        }
        this.adxFilter = filter;
        this.adxPage = {pageIndex: 0, pageSize: 25, length: 0};
        this.dataSource$ = this.adxService.searchAdx({filters: this.adxFilter,sort: { active: 'DateCreated', direction: 'desc' }});
    });
  }

  handleSortChange(sort: Sort): void {
    if(!sort?.active || !sort?.direction) {
      sort = {active: 'DateCreated', direction: 'desc'} as Sort;
    }
    this.dataSource$ = this.adxService.searchAdx({sort});
  }

  handlePageChanged(page: PageEvent): void {
    this.adxPage = page;
    this.dataSource$ = this.adxService.searchAdx({page});
  }

  onVendorNameClick(record: AdxVccModel): void {
    const params = new HttpParams().set('tramsCentralDocumentId', record.tramsCentralDocumentId);
    this.vendorService.getVendorByKey(params).subscribe((vendorDetailResponse) => {
      const vendorViewerRef = this.dialog.open(VendorViewerModalComponent, { width: '55%' });
      const vendorViewerInstance = vendorViewerRef.componentInstance as VendorViewerModalComponent;
      vendorViewerInstance.title = 'Vendor Information';
      vendorViewerInstance.viewerType = VendorViewerType.Vendor;
      vendorViewerInstance.vendorData = vendorDetailResponse.items[0];
    });
  }

  public onTripReferenceClick(record: AdxVccModel): void {
    this.handleVccClick(record, TripReferenceComponent, 'Trip Reference', '90%');
  }

  public onMaskedCreditCardNumberClick(record: AdxVccModel): void {
    this.handleVccClick(record, TransactionHistoryDialog, 'Virtual Credit Card', '90%');
  }

  private handleVccClick(record: AdxVccModel, component: any, title: string, height: string): void {
    if (record.paymentProviderId === 19) {
      const modalRef = this.modalService.open(component, { width: '55%', height: height, panelClass: 'overflowAuto' });
      modalRef.componentInstance.data = record;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.guid = record?.providerUniqueId;
    } else {
      const message = "Selected VCC does not belong to Connex Pay Payment Provider. Please use filter to view only Connex Pay VCC's to see details of VCC.";
      this._snackBar.open(message, 'Dismiss', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['message-position', 'app-notification-success']
      });
    }
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.dataSource$?.subscribe((data)=>{
      this.exportGridService.getByDataSource(
        'dbov2/adx-virtual-credit-card',
        'adx-vcc',
        {
          tripReference: this.adxFilter?.tripReference ?? '',
          recordLocator: this.adxFilter?.recordLocator ?? '',
          vccLastFourDigits: this.adxFilter?.vccLastFourDigits ?? '',
          dateCreatedFrom: this.adxFilter?.dateCreatedFrom?.toJSON() ?? '',
          dateCreatedTo: this.adxFilter?.dateCreatedTo?.toJSON() ?? '',
          paymentProviderId: this.adxFilter?.paymentProvider ?? '',
          cardStatus: this.adxFilter?.cardStatus ?? '',
          merchantGuid: this.adxFilter?.merchantGuid ?? '',
          pageSize: data?.pagination?.totalItemCount || 100,
          propertyName: 'DateCreated',
          orderKey: 'desc',
        },
        (data)=>{
          const record = data as AdxVccModel;
          return {
            'FMS Id': record?.id,
            'Trip Reference': record?.tripReference,
            'Merchant Name': record?.merchantName,
            'Vendor Name': record?.vendorName,
            'Status': record?.cardStatus,
            'Masked Credit Card Number': this.maskedCardPipe.transform(record?.maskedCreditCardNumber),
            'Posting Date': this.datePipe.transform(record?.dateCreated, 'M/d/yyyy'),
            'Expiration Date': this.datePipe.transform(record?.expirationDate, 'M/d/yyyy'),
            'Issued Amount': record?.amount,
            'Balance Amount': record?.availableBalance,
            'Currency': record?.amountCurrency,
          }
        }
      );
      downloadSubs$?.unsubscribe();
    });

  }

  handleKeepFilters(keepFilter: {filters: AdxVccFilter; page: PageEvent}): void {
    let page = keepFilter.page;
    this.dataSource$ = this.adxService.searchAdx({filters: keepFilter.filters, page, sort: {active: 'DateCreated', direction: 'desc'}});
  }

  openAmountModal(adxVcc:AdxVccModel):void {
    adxVcc.mode = 'AdxVcc';
    this.payClosed$ = this.modalService.open(
      FmsVccPayComponent,
      {
        disableClose: true,
        data: adxVcc,
      }).afterClosed().subscribe((data)=>{
        if(data?.amount){
          this.searchAdxGridData();
        }
      });
  }
}
