<form data-testid="adx-vcc-filter-modal-form" class="filter" [formGroup]="adxFilterForm" (submit)="applyAdxFilters()">
<modal-panel
    data-testid="adx-vcc-filter-modal-panel"
    class="adx-vcc-filter-modal-panel-cls filter-panel"
    [title]="'Filter'"
    (close)="handleBookingFiltersFormCloseEvent()">
        <div data-testid="adx-vcc-filter-modal-div-2" class="filter__fields">
            <mat-grid-list data-testid="adx-vcc-filter-modal-grid-list-1" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
                <mat-grid-tile data-testid="adx-vcc-filter-modal-date-created-from-grid-tile">
                    <mat-form-field data-testid="adx-vcc-filter-modal-date-created-from-field">
                        <mat-label data-testid="adx-vcc-filter-modal-date-created-from-field-title">Date created from</mat-label>
                        <input data-testid="adx-vcc-filter-modal-date-created-from-input-field" matInput [matDatepicker]="picker1" formControlName="dateCreatedFrom">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker data-testid="adx-vcc-filter-modal-date-created-from-date-picker" #picker1></mat-datepicker>
                        @if(adxFilterForm.controls['dateCreatedFrom'].value){
                            <button 
                                data-testid="adx-vcc-filter-modal-date-created-from-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('dateCreatedFrom')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle data-testid="adx-vcc-filter-modal-date-created-from-date-picker-toggle" matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile data-testid="adx-vcc-filter-modal-date-created-to-grid-tile">
                    <mat-form-field data-testid="adx-vcc-filter-modal-date-created-to-field">
                        <mat-label data-testid="adx-vcc-filter-modal-date-created-to-field-title">Date created to</mat-label>
                        <input data-testid="adx-vcc-filter-modal-date-created-to-input-field" matInput [matDatepicker]="picker2" formControlName="dateCreatedTo">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker data-testid="adx-vcc-filter-modal-date-created-to-date-picker" #picker2></mat-datepicker>
                        @if(adxFilterForm.controls['dateCreatedTo'].value){
                            <button 
                                data-testid="adx-vcc-filter-modal-date-created-to-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('dateCreatedTo')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        } @else {
                            <mat-datepicker-toggle data-testid="adx-vcc-filter-modal-date-created-to-date-picker-toggle" matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile data-testid="adx-vcc-filter-modal-payment-provider-grid-tile">
                    <mat-form-field data-testid="adx-vcc-filter-modal-payment-provider-field">
                        <mat-label data-testid="adx-vcc-filter-modal-payment-provider-field-title">Payment provider</mat-label>
                        <mat-select data-testid="adx-vcc-filter-modal-payment-provider-input-field" formControlName="paymentProvider">
                            @if(paymentProviders$ | async; as paymentProviders){
                                @for (provider of paymentProviders; track provider) {
                                    <mat-option [value]="provider?.id">{{provider?.paymentProviderName}}</mat-option>
                                }
                            }
                        </mat-select>
                        @if(adxFilterForm.controls['paymentProvider'].value){
                            <button 
                                data-testid="adx-vcc-filter-modal-payment-provider-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('paymentProvider'); $event.stopPropagation()">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile data-testid="adx-vcc-filter-modal-last-four-digits-grid-tile">
                    <mat-form-field data-testid="adx-vcc-filter-modal-last-four-digits-field" class="example-full-width">
                        <mat-label data-testid="adx-vcc-filter-modal-last-four-digits-field-title">Masked Credit Card Number</mat-label>
                        <input data-testid="adx-vcc-filter-modal-last-four-digits-input-field" matInput placeholder="Card Number" value="" formControlName="vccLastFourDigits" maxlength="4">
                        @if(adxFilterForm.controls['vccLastFourDigits'].value){
                            <button 
                                data-testid="adx-vcc-filter-modal-last-four-digits-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('vccLastFourDigits')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>

            </mat-grid-list>
            <mat-grid-list data-testid="adx-vcc-filter-modal-grid-list-2" cols="1" gutterSize="10px" rowHeight="85">
                <mat-grid-tile data-testid="adx-vcc-filter-modal-card-guid-grid-tile">
                    <mat-form-field data-testid="adx-vcc-filter-modal-card-guid-field" class="example-full-width">
                        <mat-label data-testid="adx-vcc-filter-modal-card-guid-field-title">Card Guid</mat-label>
                        <input data-testid="adx-vcc-filter-modal-card-guid-input-field" matInput placeholder="Card Guid" value="" formControlName="cardGuid">
                        @if(adxFilterForm.controls['cardGuid'].value){
                            <button 
                                data-testid="adx-vcc-filter-modal-card-guid-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('cardGuid')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile data-testid="adx-vcc-filter-modal-trip-reference-grid-tile">
                    <mat-form-field data-testid="adx-vcc-filter-modal-trip-reference-field" class="example-full-width">
                        <mat-label data-testid="adx-vcc-filter-modal-trip-reference-field-title">Trip Reference</mat-label>
                        <input data-testid="adx-vcc-filter-modal-trip-reference-input-field" matInput placeholder="Trip Reference" value="" formControlName="tripReference">
                        @if(adxFilterForm.controls['tripReference'].value){
                            <button 
                                data-testid="adx-vcc-filter-modal-trip-reference-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('tripReference')">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile data-testid="adx-vcc-filter-modal-merchant-name-grid-tile">
                    <mat-form-field data-testid="adx-vcc-filter-modal-merchant-name-field">
                        <mat-label data-testid="adx-vcc-filter-modal-merchant-name-field-title">Merchant name</mat-label>
                        <mat-select data-testid="adx-vcc-filter-modal-merchant-name-input-field" [disableOptionCentering]="true"  formControlName="merchantGuid">
                            @if(merchantGuids){
                                @for (merchant of merchantGuids; track merchant) {
                                    <mat-option [value]="merchant?.merchantGuid">{{merchant?.fullName}}</mat-option>
                                }
                            }
                        </mat-select>
                        @if(adxFilterForm.controls['merchantGuid'].value){
                            <button 
                                data-testid="adx-vcc-filter-modal-merchant-name-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('merchantGuid'); $event.stopPropagation()">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile data-testid="adx-vcc-filter-modal-vcc-status-grid-tile">
                    <mat-form-field data-testid="adx-vcc-filter-modal-vcc-status-field">
                        <mat-label data-testid="adx-vcc-filter-modal-vcc-status-field-title">Vcc status</mat-label>
                        <mat-select data-testid="adx-vcc-filter-modal-vcc-status-input-field" formControlName="cardStatus">
                            <mat-option value="Card - Active">Card - Active</mat-option>
                            <mat-option value="Card - Deactivated">Card - Deactivated</mat-option>
                        </mat-select>
                        @if(adxFilterForm.controls['cardStatus'].value){
                            <button 
                                data-testid="adx-vcc-filter-modal-vcc-status-clear-filter-button"
                                matSuffix mat-icon-button
                                type="button"
                                (click)="clearAdxFields('cardStatus'); $event.stopPropagation()">
                                    <mat-icon>close</mat-icon>
                            </button>
                        }
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <ng-container data-testid="adx-vcc-filter-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button data-testid="adx-vcc-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearAdxFields()">Clear All
                    <mat-icon data-testid="adx-vcc-filter-modal-clear-all-filter-button-icon">delete_outline</mat-icon>
                </button>
                <button data-testid="adx-vcc-filter-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
            </div>
        </ng-container>
</modal-panel>
</form>
