import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder, FormArray} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {AlertComponent, AlertCondition, AlertCriteria, AlertDetail, MerchantServiceOption} from '../../../shared/model/financial-alerts-result';
import {FinancialAlertsService} from '../../service/financial-alerts.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'financial-alerts-modal',
  templateUrl: './financial-alerts-modal.component.html'
})
export class FinancialAlertsModalComponent implements OnInit {
  merchantGuids: MerchantServiceOption[] = [];
  merchantGuidsIds: string[] = [];
  merchantsPatch: string[] = [];
  alertCriteria: AlertCriteria[] = [];
  alertCondition: AlertCondition[] = [];
  alertDetails?: AlertDetail;
  alertCriteriaSelect$?:Subscription;

  merchantComponent?: AlertComponent;
  amountComponent?: AlertComponent;
  transactionsComponent?: AlertComponent;

  componentCase: Record<string, (value:AlertComponent)=>void> = {
    'drop down list': (value)=>{
      this.merchantGuids = value.options
      this.merchantGuidsIds = this.merchantGuids.map(merchant => merchant.id);
      this.merchantComponent = value;
    },
    'text box': (value)=>{
      if (value.entityColumn === 'PerDayCount') {
        this.transactionsComponent = value;
        this.form.addControl(
          'transactions',
          new FormControl(
            this.alertDetails?.workflow?.workflowRules[0]?.workflowRuleConditions[3]?.workflowRuleConditionValue || null,
            [Validators.required],
          )
        );
        this.form.addControl(
          'alertConditionTransactions',
          new FormControl(
            this.alertDetails?.workflow?.workflowRules[0]?.workflowRuleConditions[3]?.workflowLogicOperatorId || null,
            [Validators.pattern(/^[1-9]\d*$/), Validators.min(1), Validators.required],
          )
        );
      } else {
        this.amountComponent = value;
        this.form.removeControl('transactions');
        this.form.removeControl('alertConditionTransactions');
      }
    },
    'default': ()=>{},
  };
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: string,
    @Optional() public dialogRef: MatDialogRef<FinancialAlertsModalComponent>,
    private fb: FormBuilder,
    private financialAlertsService: FinancialAlertsService,
    private _snackBar: MatSnackBar,
  ) {  }

  newEmailControl: FormControl = new FormControl('', [Validators.email]);

  form: FormGroup = new FormGroup({
    alertName: new FormControl(null,[Validators.required]),
    description: new FormControl(null),
    merchantGuid: new FormControl(null,[Validators.required]),
    alertCriteria: new FormControl(null,[Validators.required]),
    alertCondition: new FormControl(null,[Validators.required]),
    amount: new FormControl('',[Validators.pattern(/^[1-9]\d*$/), Validators.min(1), Validators.required]),
    emailsForm: new FormArray([], [Validators.required]),
    enabled: new FormControl(true, [Validators.required]),
  });

  ngOnInit(): void {    
    this.financialAlertsService.getAlertCriteria().subscribe((alertCriteria)=>{
      this.alertCriteria = alertCriteria;
    });

    this.financialAlertsService.getAlertConditions().subscribe((alertCondition)=>{
      this.alertCondition = alertCondition;
    });

    if (this.dialogData) {
      this.financialAlertsService.getAlertDetails(this.dialogData).subscribe((data)=>{
        this.alertDetails = data;
        this.merchantsPatch = this.alertDetails?.workflow?.workflowRules
        .filter((rule)=> rule?.isEnabled)
        .map((rule)=> (rule?.workflowRuleConditions[0]?.workflowRuleConditionValue))

        this.form.patchValue({
          alertName: `${data?.workflow?.name}`,
          description: data?.workflow?.description,
          alertCriteria: `${data?.workflow?.criteriaId}`,
          alertCondition: data?.workflow?.workflowRules[0]?.workflowRuleConditions[1].workflowLogicOperatorId,
          amount: data?.workflow?.workflowRules[0]?.workflowRuleConditions[1].workflowRuleConditionValue,
          enabled: data?.workflow?.isEnabled,
        });

        this.patchMerchantValue();

        data?.workflow.workflowNotification.notificationValue.split(';').forEach((email)=>{
          this.emails.push(this.fb.group({email}));
        });
        
        this.form.controls['alertCriteria'].disable();
      });
    }

    this.alertCriteriaSelect$ = this.form.controls['alertCriteria'].valueChanges.subscribe((data)=>{
      this.getComponentsList(data);
      this.patchMerchantValue();
    });
  }

  applyAlerts() {
    if(this.alertDetails) {
      this.financialAlertsService.updateAlert(this.form.getRawValue(), this.alertDetails).subscribe(()=>{
        this.dialogRef.close(true);
        this._snackBar.open('Alert updated successfully', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        }); 
      });
    } else {
      this.financialAlertsService.setAlert(this.form.getRawValue()).subscribe(()=>{
          this.dialogRef.close(true);
          this._snackBar.open('Alert created successfully', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });  
      });
    }
  }

  handleClose(): void {
    this.dialogRef?.close();
  }

  get emails(): FormArray {
    return this.form.get('emailsForm') as FormArray;
  }

  addEmail(): void {
    if (this.newEmailControl.valid && this.newEmailControl.value) {
      this.emails.push(this.fb.group({
        email: [this.newEmailControl.value]
      }));

      this.newEmailControl.reset();
    }
  }

  removeEmail(index: number): void {
    this.emails.removeAt(index);
  }

  getComponentsList(alertCriteria:string) {
    this.merchantComponent = undefined;
	  this.amountComponent = undefined;
	  this.transactionsComponent = undefined;
	  if(alertCriteria == '6') {
	    this.form.removeControl('amount');
	    this.form.removeControl('alertCondition');
	    this.form.removeControl('transactions');
	    this.form.removeControl('alertConditionTransactions');
	  }
    this.financialAlertsService.getComponentsByAlertCriteria(alertCriteria).subscribe((data)=>{
      data?.forEach((component)=>{
        this.componentCase[component.type || 'default'](component);
      })
    });
  }

  getMerchantNameById(id?: string): string {
    return this.merchantGuids.find((merchant)=> merchant.id === id)?.value || '';
  }

  patchMerchantValue() {
    this.form.controls['merchantGuid'].reset(this.merchantsPatch || '');
  }
}
