import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AdxVccResponse, AdxVccFilter, SearchAdxParams} from '../model/adx-data';
import {BehaviorSubject, Observable, catchError, throwError} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AdxVccService {
  private resourcePath: string = 'dbov2/adx-virtual-credit-card';
  filters?: AdxVccFilter;
  page?: Partial<PageEvent>;
  sort?: Sort;

  private _dataSource$ = new BehaviorSubject<AdxVccFilter | {}>({});

  constructor(private http: HttpClient) {}

  getAdxFilter (): Observable<AdxVccFilter | {}>{
    return this._dataSource$.asObservable();
  }

  setAdxFilter(value:AdxVccFilter){
    this._dataSource$.next(value || null);
  }

  searchAdx({filters, page, sort} : SearchAdxParams): Observable<AdxVccResponse>  {

    this.filters = filters || this.filters;
    this.page = page || this.page;
    this.sort = sort || this.sort;

    if(filters && !page) {
      this.page = {};
    }

    const params:Record<string, string | number> = {
      pageSize:  this.page?.pageSize || 25,
      pageNumber : (this.page?.pageIndex || 0) + 1,
      propertyName: this.sort?.active || '',
      orderKey: this.sort?.direction || '',

      tripReference: this.filters?.tripReference ?? '',
      recordLocator: this.filters?.recordLocator ?? '',
      vccLastFourDigits: this.filters?.vccLastFourDigits ?? '',
      dateCreatedFrom: this.filters?.dateCreatedFrom? moment(this.filters?.dateCreatedFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      dateCreatedTo: this.filters?.dateCreatedTo? moment(this.filters?.dateCreatedTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      paymentProviderId: this.filters?.paymentProvider ?? '',
      cardStatus: this.filters?.cardStatus ?? '',
      merchantGuid: this.filters?.merchantGuid ?? '',
      cardGuid: this.filters?.cardGuid ?? '',
    };

    removeEmptyParams(params);

    return this.http.get<AdxVccResponse>(this.resourcePath, {
      params: {...params}
    }).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  public getAdxVccByCardGuid(cardGuid: string): Observable<AdxVccResponse> {
    return this.http
      .get<AdxVccResponse>(`${this.resourcePath}/card-guid`, { params: { cardGuid : cardGuid } })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message || 'Server Error'));
  }
}
