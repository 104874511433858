import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {VendorModel, VendorService} from '../../service/vendor.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {
  VendorViewerModalComponent,
  VendorViewerType
} from '../viewer/vendor-viewer-modal/vendor-viewer-modal.component';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {VendorTemplate} from '../../model/vendor-template';
import {VendorFilter} from '../../model/vendor-filter';
import {Pagination} from '../../../../infrastructure/pagination/model/pagination';
import {SearchVendorResponse} from '../../model/search-vendor-response';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';

@AutoUnsubscribe()
@Component({
  selector: 'vendor-list',
  templateUrl: './vendor-list.component.html',
})
export class VendorListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  public dataSource: MatTableDataSource<VendorModel> = new MatTableDataSource<VendorModel>([]);

  public displayedColumns: Array<string> = [
    'id',
    'vendorName',
    'Address.Country.Name',
    'Address.City',
    'vendorPaymentTC',
    'cancellationRefundTC'
  ];
  public isLoading: boolean = false;
  public vendorViewerType = VendorViewerType;
  public pagination: Pagination = {} as Pagination;
  public vendorFilter: VendorFilter = {} as VendorFilter;

  constructor(
    private vendorService: VendorService,
    private exportGridService: ExportGridService,
    private dialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.vendorService.getVendorFilter().subscribe((filter) => {
      if (this.paginator !== undefined) {
        this.paginator.pageIndex = 0;
        this.vendorFilter.pageNumber = 1;
      }
      this.vendorFilter.vendorName = filter.vendorName;
      this.vendorFilter.vendorCity = filter.vendorCity;
      this.vendorFilter.vendorCountry = filter.vendorCountry;
      this.searchVendors(new PageEvent());
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public clearFilters(propertyName: string): void {
    let temp: any = this.vendorFilter;
    temp[propertyName] = undefined;
    this.vendorFilter = temp;

    if (propertyName === 'all') {
      this.vendorFilter = {} as VendorFilter;
    }

    this.searchVendors(new PageEvent());
  }

  public searchVendors(pageEvent: Partial<PageEvent>): void {
    this.setPaginationAfterPageChanged(pageEvent);
    this.vendorService
      .searchVendors(this.vendorFilter)
      .subscribe((data: SearchVendorResponse) => {
        if (data) {
          this.pagination = data.pagination;
          this.vendorFilter.pageNumber = data.pagination.pageNumber;
          this.dataSource = new MatTableDataSource<VendorModel>(data.items as VendorModel[]);
          this.isLoading = false;
        }
      });
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.vendorService
      .searchVendors(this.vendorFilter)
      .subscribe((data: SearchVendorResponse) => {
        this.exportGridService.getByDataSource(
          'dbov2/vendor/search',
          'vendors',
          {
            ... this.vendorFilter,
            pageNumber: 0,
            pageSize: data?.pagination?.totalItemCount || 100,
          },
          (data)=>{
            const record = data as VendorModel;
            return {
              'ID': record?.id,
              'Name': record?.vendorName,
              'Country': record?.vendorCountryName,
              'City': record?.vendorCity,
              'Vendor Payment TC': record.vendorPaymentTC,
              'Cancellation Refund TC': record.vendorCancellationTC,
            }
          }
        );
        downloadSubs$?.unsubscribe();
      });
  }

  replayGrid (){
    this.searchVendors({
      pageIndex: this.pagination.pageNumber + 1,
      previousPageIndex: this.pagination.pageNumber + 1,
    });
  }

  public handleSortChange(sort: any): void {
    this.vendorFilter.propertyName = sort.active;
    this.vendorFilter.orderKey = sort.direction.toUpperCase();

    this.searchVendors(new PageEvent());
  }

  public setPaginationAfterPageChanged(pageEvent: Partial<PageEvent>): void {
    this.vendorFilter.pageSize = 25;
    const isStepForward = pageEvent.pageIndex! > pageEvent.previousPageIndex!;

    if (isStepForward) {
      this.vendorFilter.pageNumber += 1;
    } else {
      if (pageEvent.pageIndex !== pageEvent.previousPageIndex) {
        this.vendorFilter.pageNumber = this.vendorFilter.pageNumber !== undefined && this.vendorFilter.pageNumber > 1 ? this.vendorFilter.pageNumber-1 : 1;
      }
    }
  }

  public getRecord(row: VendorModel): void {
    this.openDialog(row, this.vendorViewerType.Vendor);
  }

  public openDialog(record: VendorModel, viewerType: VendorViewerType): void {
    let titleHeader;

    if (viewerType == VendorViewerType.VendorPayment) {
      titleHeader = 'Vendor Payment Terms and Conditions';
    } else if (viewerType == VendorViewerType.CancellationRefund) {
      titleHeader = 'Cancellation Refund Terms and Conditions';
    } else {
      titleHeader = 'Vendor Information';
    }

    const vendorViewerRef = this.dialog.open(VendorViewerModalComponent, { width: '55%' });
    const vendorViewerInstance = vendorViewerRef.componentInstance as VendorViewerModalComponent;
    vendorViewerInstance.title = titleHeader;
    vendorViewerInstance.viewerType = viewerType;
    vendorViewerInstance.vendorData = record;
  }

  public checkVendorPaymentBookingTemplate(vendorTemplates: VendorTemplate[]): boolean {
    return vendorTemplates?.some(x => !x.isDeleted && x.termsConditionsTypeId == Number(VendorViewerType.VendorPayment));
  }

  public checkCancellationRefundBookingTemplate(vendorTemplates: VendorTemplate[]): boolean {
    return vendorTemplates?.some(x => !x.isDeleted && x.termsConditionsTypeId == Number(VendorViewerType.CancellationRefund));
  }
}
