<form>
    <mat-form-field>
      <mat-label>{{modeConfig[autocompleteMode()].label}}</mat-label>
      @let displayNameWith = modeConfig[autocompleteMode()].nameFn;
      <input type="text"
             placeholder="Type 3 characters or more..."
             [ariaLabel]="modeConfig[autocompleteMode()].label"
             matInput
             [value]=""
             [formControl]="autocompleteInnerControl"
             [matAutocomplete]="autocompleteRef"> 
        <mat-autocomplete 
            #autocompleteRef="matAutocomplete"
            [displayWith]="displayNameWith"
            (optionSelected)='setValue($event.option.value)'>
            @let options = filteredOptions$ | async;
            @for (option of options; track option?.id) {
                <mat-option [value]="option">{{displayNameWith(option)}}</mat-option>
            }   
        </mat-autocomplete>
        @if(autocompleteInnerControl.value){
            <button matSuffix mat-icon-button
                type="button"
                (click)="clearAutocomplete(autocompleteRef); $event.stopPropagation()">
                    <mat-icon>close</mat-icon>
            </button>
        }
    </mat-form-field>
  </form>