<modal-panel [title]="title" data-testid="transaction-history-diaglog-panel" (close)="closeDialog()" >
  <ng-container data-testid="transaction-history-diaglog-container">
    <transaction-history-details  
      [title]="title"
      [data]="data">
    </transaction-history-details>
  </ng-container>

  <ng-container data-testid="transaction-history-diaglog-footer" modalPanelFooter >
    <button data-testid="transaction-history-diaglog-close-button" mat-raised-button color="primary" 
      (click)="closeDialog()">Close</button>
  </ng-container>
</modal-panel>