<h2 mat-dialog-title>Manage Virtual Credit Card for Service ID: '{{inputData.id}}'</h2>
<hr>
<mat-dialog-content>
  <ng-container *ngIf="!isLoading; else loadingView">
    <mat-dialog-actions>
      <ng-container id="vcc-header-issue-container" *ngIf="isTypeUpdate">
        <button id="vcc-header-issue-button" mat-raised-button color="primary" (click)="handleIssue()">Issue</button>
      </ng-container>
    </mat-dialog-actions>
    <form [formGroup]="vccViewerForm" id="vcc-viewer-form" class="template-form vcc-viewer-form">
      <div id="vcc-main-container" class="container">
        <mat-tab-group dynamicHeight>
          <mat-tab *ngFor="let vcc of _vccs; let index = index" [label]= "vcc.id + ''">
            <mat-form-field id="vcc-url-field" *ngIf="vcc.virtualCreditCardUrl != undefined && vcc.virtualCreditCardUrl != null" (keypress)="navigateToConnexPayUrl(vcc.virtualCreditCardUrl)" (click)="navigateToConnexPayUrl(vcc.virtualCreditCardUrl)">
              <mat-label id="vcc-url-field-title">URL: </mat-label>
              <input id="vcc-input-field" matInput value="{{vcc.virtualCreditCardUrl}}">
            </mat-form-field>
            <mat-form-field id="vcc-number-field" *ngIf="vcc.vccNumber != undefined && vcc.vccNumber != null">
              <mat-label id="vcc-number-field-title">Number: </mat-label>
              <input id="vcc-input-field" matInput value="{{vcc.vccNumber}}">
            </mat-form-field>
            <mat-form-field id="vcc-purchased-card-guid-field" *ngIf="vcc.purchasedCardGuid != undefined && vcc.purchasedCardGuid != null">
              <mat-label id="vcc-purchased-card-guid-field-title">GUID: </mat-label>
              <input id="vcc-input-field" matInput value="{{vcc.purchasedCardGuid}}">
            </mat-form-field>
            <mat-form-field id="vcc-issue-date-field" *ngIf="vcc.virtualCreditCardIssueDate != undefined && vcc.virtualCreditCardIssueDate != null">
              <mat-label id="vcc-issue-date-field-title">Issue Date: </mat-label>
              <input id="vcc-input-field" matInput value="{{vcc.virtualCreditCardIssueDate | date: 'M/d/yyyy'}}">
            </mat-form-field>
            <mat-form-field id="vcc-terminate-date-field" *ngIf="vcc.virtualCreditCardTerminateDate != undefined && vcc.virtualCreditCardTerminateDate != null">
              <mat-label id="vcc-terminate-date-field-title">Terminate Date: </mat-label>
              <input id="vcc-input-field" matInput value="{{vcc.virtualCreditCardTerminateDate | date: 'M/d/yyyy'}}">
            </mat-form-field>
            <mat-form-field id="vcc-start-active-date-field" *ngIf="vcc.virtualCreditCardStartActiveDate != undefined && vcc.virtualCreditCardStartActiveDate != null">
              <mat-label id="vcc-start-active-date-field-title">Start Active Date: </mat-label>
              <input id="vcc-input-field" matInput value="{{vcc.virtualCreditCardStartActiveDate | date: 'M/d/yyyy'}}">
            </mat-form-field>
            <mat-form-field id="vcc-end-active-date-field" *ngIf="vcc.virtualCreditCardEndActiveDate != undefined && vcc.virtualCreditCardEndActiveDate != null">
              <mat-label id="vcc-end-active-date-field-title">End Active Date: </mat-label>
              <input id="vcc-input-field" matInput value="{{vcc.virtualCreditCardEndActiveDate | date: 'M/d/yyyy'}}">
            </mat-form-field>
            <mat-form-field id="vcc-current-amt-field" *ngIf="vcc.virtualCreditCardCurrentAmt != undefined && vcc.virtualCreditCardCurrentAmt != null">
              <mat-label id="vcc-current-amt-field-title">Current Amount: </mat-label>
              <input id="vcc-input-field" matInput value="{{vcc.virtualCreditCardCurrentAmt}}">
            </mat-form-field>
            <button id="vcc-show-more-button" mat-raised-button color="primary" (click)="showMore(index)" [hidden]="vcc.isVccPaymentGatewayInfoLoaded">Show more</button>
            <app-vcc-payment-gateway-viewer [data]="this._vccs[index]" [isLoaderVisible]="this.isLoaderVisible" *ngIf="vcc.isVccPaymentGatewayInfoLoaded"></app-vcc-payment-gateway-viewer>
          
            <div id="vcc-footer-update-container" class="vcc-footer-update-container" *ngIf="isTypeUpdate">
              <button id="vcc-footer-update-button" mat-raised-button color="primary" (click)="handleUpdate(index)">Update</button>
              <button id="vcc-footer-delete-button" mat-raised-button color="primary" (click)="handleDelete(index)">Delete</button>
            </div>
          </mat-tab>
        </mat-tab-group>
        <div *ngIf="this._vccs.length === 0">
          <div id="no-vcc-issued-table-feed-text" class="table-feed-text col-md-12" style="margin-top: 2%;">
            <mat-label>
              There is no virtual credit card (VCC) issued so far for the selected service.
              Please ask manager role user to issue VCC for service ID: <b>'{{ inputData.id }}'</b>.
            </mat-label>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</mat-dialog-content>
<hr>
<mat-dialog-actions class="p-l-2">
  <button mat-raised-button color="warn" mat-dialog-close>Close</button>
</mat-dialog-actions>

  <ng-template #loadingView>
    <div class="loading-spinner">
      <mat-spinner *ngIf="isLoaderVisible"></mat-spinner>
    </div>
  </ng-template>
