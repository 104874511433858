import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import * as XLSX from 'xlsx';
import { UploadFileRequest } from '../models/client-reconciliation';

@Injectable({
    providedIn: 'root',
})
export class ReconciliationFileService {
    constructor(private http: HttpClient) { }

    private processJsonRecordsElavon(jsonRecords: any[]): any[] {
        // Filter objects with more than 5 properties
        return jsonRecords.filter(record => Object.keys(record).length > 5);
    }

    private processJsonRecordsConnexPay(jsonRecords: any[]): any[] {
        // verifiy that the record has some props to determinate if is a record
        return jsonRecords.filter((record)=>{
            return record.hasOwnProperty('ClientNameAlias') &&
            record.hasOwnProperty('Amount') &&
            record.hasOwnProperty('MerchantName') &&
            record.hasOwnProperty('ReleasedDate');
        });
    }

    private processJsonRecordsBrainTree(jsonRecords: any[]): any[] {
        // verifiy that the record has some props to determinate if is a record
        return jsonRecords.filter((record)=>{
            return record.hasOwnProperty('Transaction ID') &&
            record.hasOwnProperty('Amount Authorized') &&
            record.hasOwnProperty('Cardholder Name') &&
            record.hasOwnProperty('Merchant Account');
        });

    }

    // Remove blank spaces from headers Order ID -> OrderID
    private removeSpacesFromKeys(obj: Record<string, unknown>) {
        const newObj: Record<string, unknown> = {}; // Nuevo objeto con las claves sin espacios
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            const newKey = key.replace(/\s+/g, ''); // Eliminar los espacios en la clave
            newObj[newKey] = obj[key]; // Asignar el valor de la propiedad al nuevo objeto
          }
        }
        return newObj;
    }

    // Function to read and map Elavon file
    private readMappingFileElavonContent(file: File): Observable<any[]> {
        return new Observable<any[]>((observer) => {
            const fileReader = new FileReader();

            // File read callback
            fileReader.onload = () => {

                const data = fileReader.result;
                const workBook = XLSX.read(data, { type: 'binary' });

                // Acumulate the sheets 
                const jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                    const sheet = workBook.Sheets[name];
                    let rawData = XLSX.utils.sheet_to_json(sheet, { raw: true });
                    // Map cells by getting the row
                    rawData = rawData.map((row: any) => {
                        // Convert time fields from decimal to hh:mm:ss format
                            row['Settle Time'] = XLSX.SSF.format('hh:mm:ss', row['Settle Time']);
                            row['Settle Date'] = XLSX.SSF.format('yyyy-mm-dd hh:mm:ss', row['Settle Date']);
                            row['Transaction Date'] = XLSX.SSF.format('yyyy-mm-dd hh:mm:ss', row['Transaction Date']);
                            row['Transaction Time'] = XLSX.SSF.format('hh:mm:ss', row['Transaction Time']);
                        return row;
                    });
                    initial[name] = rawData;
                    return initial;
                }, {});

                // Remove the summary sheet and generate a single array with all data
                const filteredJsonData: any[] = [];
                    Object.entries(jsonData).forEach((sheet)=>{
                        const filter = this.processJsonRecordsElavon(sheet[1] as any[])
                        filteredJsonData.push(...filter);
                })
                

                // Return data and complete observable with result
                observer.next(filteredJsonData);
                observer.complete();
            };

            // If error return the error
            fileReader.onerror = () => {
                observer.error('Error reading file...');
            };

            // File event trigger
            fileReader.readAsBinaryString(file);
        });
    }
    
    // Function to read and map ConnexPay file
    private readMappingFileConnexPayContent(file: File): Observable<any[]> {
        return new Observable<any[]>((observer) => {
            const fileReader = new FileReader();

            // File read callback
            fileReader.onload = () => {
                const data = fileReader.result;
                const workBook = XLSX.read(data, { type: 'binary' });

                // Acumulate by sheets 
                const jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                    const sheet = workBook.Sheets[name];
                    let rawData = XLSX.utils.sheet_to_json(sheet, { raw: true });
                    rawData = rawData.map((row: any) => {
                        row['ReleasedDate'] = XLSX.SSF.format('yyyy-mm-dd hh:mm:ss', row['ReleasedDate']);
                        row['Date'] = XLSX.SSF.format('yyyy-mm-dd hh:mm:ss', row['Date']);
                        row['DateTime'] = XLSX.SSF.format('yyyy-mm-dd hh:mm:ss', row['DateTime']);
                        row['ITC'] = `${row['ITC']}`;
                        row['TicketNumber'] = `${row['TicketNumber']}`;
                        delete row['IssuedAmount'];
                        delete row['TicketNumber'];
                        return row;
                    });
                    initial[name] = rawData;
                    return initial;
                }, {});

                // Remove the summary sheet and generate a single array with all data
                const filteredJsonData: any[] = [];
                Object.entries(jsonData).forEach((sheet)=>{
                    if(!sheet[0].includes('Summary')){
                        const filter = this.processJsonRecordsConnexPay(sheet[1] as any[])

                        filteredJsonData.push(...filter);
                    }
                })

                // Return data and complete observable with result
                observer.next(filteredJsonData);
                observer.complete();
            };

            // If error return the error
            fileReader.onerror = () => {
                observer.error('Error reading file...');
            };

            // File event trigger
            fileReader.readAsBinaryString(file);
        });
    }

    // Function to read Braintree file
    private readMappingFileBrainTreeContent(file: File): Observable<any[]> {
        return new Observable<any[]>((observer) => {
            const fileReader = new FileReader();

            // File read callback
            fileReader.onload = () => {
                const data = fileReader.result;
                const workBook = XLSX.read(data, { type: 'binary' });
                
                // Acumulate by sheets 
                const jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                    const sheet = workBook.Sheets[name];
                    let rawData = XLSX.utils.sheet_to_json(sheet, { raw: true });
                    rawData = rawData.map((row: any) => {
                        row['Created Datetime'] = XLSX.SSF.format('mm/dd/yyyy hh:mm:ss', row['Expiration Date']);
                        row['Settlement Date'] = XLSX.SSF.format('mm/dd/yyyy', row['Expiration Date']);
                        row['Expiration Date'] = XLSX.SSF.format('dd-mmm', row['Expiration Date']);
                        row['Tax Exempt'] = `${row['Tax Exempt']}`;
                        row['First Six of Credit Card'] = `${row['First Six of Credit Card']}`;
                        row['Last Four of Credit Card'] = `${row['Last Four of Credit Card']}`;
                        row['Processor Response Code'] = `${row['Processor Response Code']}`;
                        row['Transaction Type'] = `${row['Transaction Type']}`;
                        row['Billing Postal Code'] = `${row['Billing Postal Code']}`;
                        row['Authorization Code'] = `${row['Authorization Code']}`;
                        row['Acquirer Reference Number'] = `${row['Acquirer Reference Number']}`;
                        return row;
                    });
                    initial[name] = rawData;
                    return initial;
                }, {});

                // Remove the summary sheet and generate a single array with all data
                const filteredJsonData: any[] = [];
                Object.entries(jsonData).forEach((sheet)=>{
                    const filter = this.processJsonRecordsBrainTree(sheet[1] as any[])
                    filteredJsonData.push(...filter);
                })



                // Replace prop names, should remove space chars
                const cleanedData = filteredJsonData.map(this.removeSpacesFromKeys);

                // Return data and complete observable with result
                observer.next(cleanedData);
                observer.complete();
            };

            // If error return the error
            fileReader.onerror = () => {
                observer.error('Error reading file...');
            };

            // Leemos el archivo como texto
            fileReader.readAsBinaryString(file);
        });
    }

    // Function to post file
    sendFileContent(request: UploadFileRequest): Observable<any> {

        let seviceCall$: Observable<any>;
        if(request.ccProcessorId === 1) {
            seviceCall$ = this.readMappingFileConnexPayContent(request.file.file);
        }
        if(request.ccProcessorId === 2) {
            seviceCall$ = this.readMappingFileBrainTreeContent(request.file.file);
        }
        if(request.ccProcessorId === 3) {
            seviceCall$ = this.readMappingFileElavonContent(request.file.file);
        }

        return seviceCall$!.pipe(switchMap((records)=>{
            const uploadPayload = {
                CcProcessorId: request.ccProcessorId,
                Records: records
            };

            return this.http.post('dbov2/reconciliation-data/upload-file-merge-records', {
                ...uploadPayload
            });
        }))

    }

}
