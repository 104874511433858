import {Component, forwardRef, Injector, input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, Validators, NgControl, FormControlName, FormGroupDirective} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {Observable, of} from 'rxjs';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {AgentService} from 'src/app/agent/component/service/agent.service';
import {ClientService} from 'src/app/client/service/client.service';
import {Agent, AgentData} from 'src/app/agent/component/service/agent.model';
import {Client, ClientData} from 'src/app/client/service/client.model';
import {ProfileAutocompleteMode} from '../../model/profile-autocomplete';
import {VendorModel} from 'src/app/vendor/model/vendor-model';
import {VendorService} from 'src/app/vendor/service/vendor.service';
import {SearchVendorResponse} from 'src/app/vendor/model/search-vendor-response';
import {autocompleteModelValidator} from '../../../../infrastructure/helper/autocomplete-model-validator';
import {ProfileService} from '../../service/profile/profile.service';
import {ProfileModel} from '../../model/profiles';

@Component({
  selector: 'profile-autocomplete',
  templateUrl: 'profile-autocomplete.component.html',
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ProfileAutocompleteComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => ProfileAutocompleteComponent),
        multi: true,
    },
  ],
})
export class ProfileAutocompleteComponent implements OnInit, ControlValueAccessor, Validator {
    autocompleteMode = input<'A'| 'I' | 'V'>('A');
    dataSourceId = input<number>();
    searchWithProfile = input<boolean>(true);

    autocompleteInnerControl = new FormControl<string | null>(null);

    options: Agent[] | Client[] | VendorModel[] | ProfileModel[] = [];
    filteredOptions$?: Observable<Agent[] | Client[] | VendorModel[] | ProfileModel[]>;

    value?: Agent | Client | VendorModel | ProfileModel | null;
    onChange!:(value: Agent | Client | VendorModel | ProfileModel | null) => void;
    onTouched!:(value: Agent | Client | VendorModel | ProfileModel | null) => void;

    modeConfig: Record<string, ProfileAutocompleteMode> = {
        'A': {
            label: 'Agent',
            nameFn: (value: Agent | Client | VendorModel | ProfileModel ) => {
                if(this.searchWithProfile()){
                    return (value as ProfileModel)?.profileName;
                } else {
                    return (value as Agent)?.agentName;
                }
            },
        },
        'I': {
            label: 'Client',
            nameFn: (value: Agent | Client | VendorModel | ProfileModel) => {
                if(this.searchWithProfile()){
                    return (value as ProfileModel)?.profileName;
                } else {
                    return  (value as Client)?.firstName 
                    ? `${(value as Client)?.firstName || ''} ${(value as Client)?.lastName || ''}`
                    : '';
                }
            },
        },
        'V': {
            label: 'Vendor',
            nameFn: (value: Agent | Client | VendorModel | ProfileModel) => {
                if(this.searchWithProfile()){
                    return (value as ProfileModel)?.profileName;
                } else {
                    return (value as VendorModel)?.vendorName;
                }
            }
        },
    };

    
    constructor(
        private agentService: AgentService,
        private clientService: ClientService,
        private vendorService: VendorService,
        private profileService: ProfileService,
        private injector: Injector,   
    ){}

    ngOnInit(): void {
        this.filteredOptions$ = this.autocompleteInnerControl.valueChanges
        .pipe(
            startWith(''),
            debounceTime(1000),
            switchMap(value => {
                if(value && value?.length >= 3){
                    return this.serviceGet(value)
                    .pipe(map((data)=>{
                        if(this.searchWithProfile()){
                            this.options = data as ProfileModel[];
                        } else {
                            this.options = (data as AgentData | ClientData | SearchVendorResponse).items;
                        }
                        return this.filterOptions(value || '');
                    }));
                }
                this.options = [];
                return of([]);
            }),
        );

        const parentControl = this.injector.get(NgControl) as FormControlName;
        const parentForm = this.injector.get(FormGroupDirective).getControl(parentControl);

        if(parentForm.hasValidator(Validators.required)){
            this.autocompleteInnerControl.setValidators([autocompleteModelValidator() , Validators.required]);
        }
    }

    filterOptions(value: string): Agent[] | Client[] | VendorModel[] | ProfileModel[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(
            option => 
                this.modeConfig[this.autocompleteMode()]
                    .nameFn(option)
                    ?.toLowerCase()
                    ?.includes(filterValue)
        ) as Agent[] | Client[] | VendorModel[] | ProfileModel[];
        
            
    }

    clearAutocomplete(componentRef: MatAutocomplete): void {
        componentRef.options.forEach((optionRef) => optionRef.deselect());
        this.options = [];
        this.autocompleteInnerControl.reset();
        this.onChange(null);
        this.onTouched(null);
    }

    writeValue(value: Agent | Client | VendorModel | ProfileModel | null): void {
        this.value = value;
        if (!value) {
            this.autocompleteInnerControl.reset();
        }
    }

    registerOnChange(fn: (value: Agent | Client | VendorModel | ProfileModel | null)=> void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: (value: Agent | Client | VendorModel | ProfileModel | null)=> void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.autocompleteInnerControl[isDisabled? 'disable' : 'enable']();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if(control.errors && !control.value){
            return {
                required: true,
            };
        }
        return null;
    }

    inputValueCheck(){
        if(this.autocompleteInnerControl.hasError('invalidModel')) {
            this.setValue(null);
        }
    }

    setValue(value:Agent | Client | VendorModel | null): void {
        this.onChange(value);
        this.onTouched(value);
    }

    serviceGet(name:string): Observable<AgentData | ClientData | SearchVendorResponse | ProfileModel[]>{
        if(this.searchWithProfile()){
            return this.profileService.searchProfiles({
                profileType: this.autocompleteMode(),
                profileName: name,
                dataSourceId: this.dataSourceId(),
            })
        }

        if(this.autocompleteMode() === 'I'){
            return this.clientService.getClients(1, 1000, {
                clientName: name,
                dataSource: this.dataSourceId(), 
            });
        }

        if(this.autocompleteMode() === 'V'){
            return this.vendorService.getVendorsDboV2(1, 1000, {
                vendorName: name, 
            });
        }

        return this.agentService.getAgents(1, 1000, {
            agentName: name,
            dataSourceId: this.dataSourceId(),
        });
        
    }
}