<modal-panel
  [title]="'Vouchers'"
  (close)="closeModal()"
>
  <div class="filter__fields " [ngClass]="{'loading-spinner': !loadedData || !dataSource.length}">
    @if (!loadedData) {
      <mat-spinner ></mat-spinner>
    }
    @if (!dataSource.length && loadedData) {
      <span>There are no records to display.</span>
    }
    @if (dataSource.length) {
      <div class="clients-modal-container mat-elevation-z8" id="client-list-clients-modal-container">
        <mat-table [dataSource]="dataSource" matSort id="client-modal-list-mat-table">
          <ng-container matColumnDef="recordLocator">
            <mat-header-cell class="mat-header-cell" *matHeaderCellDef>Adx Trip Reference</mat-header-cell>
            <mat-cell *matCellDef="let invoice">{{ invoice.recordLocator }}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="issueDate">
            <mat-header-cell class="mat-header-cell" *matHeaderCellDef>Issue Date</mat-header-cell>
            <mat-cell *matCellDef="let invoice">{{ invoice.issueDate | date: 'M/d/yyyy' }}</mat-cell>
          </ng-container>
  
  
          <ng-container matColumnDef="usdTotal">
            <mat-header-cell class="mat-header-cell" *matHeaderCellDef>USD Total</mat-header-cell>
            <mat-cell *matCellDef="let invoice">{{ getUsdSum(invoice) }}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="cadTotal">
            <mat-header-cell class="mat-header-cell" *matHeaderCellDef>CAD Total</mat-header-cell>
            <mat-cell *matCellDef="let invoice">{{ getCadSum(invoice) }}</mat-cell>
          </ng-container>
      
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    }
  </div>

  <ng-container modalPanelFooter>
    <button mat-button mat-raised-button color="primary" (click)="closeModal()">Close</button>
  </ng-container>
  
</modal-panel>

