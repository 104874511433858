import {Component, Inject, Optional, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-adx-payment-details-modal',
  templateUrl: './adx-payment-details-modal.component.html'
})
export class AdxPaymentDetailsModalComponent implements OnInit {
  title: string;
  adxPaymentId: any;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AdxPaymentDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, adxPaymentId: number },
    public dialog: MatDialog
  ) {
    this.title = data.title;
    this.adxPaymentId = data.adxPaymentId;
  }

  ngOnInit(): void {
    if (!this.data) {
      console.error('Data is missing');
    }
  }

  public closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
  }
}
