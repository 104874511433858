<form [formGroup]="ttmForm" (submit)="submitTtmForm()">
    <modal-panel
        data-testid="create-travel-type-mapping-form-modal"
        class="filter-panel"
        [title]="glTravelTypeModel? 'Edit Travel Type Mapping ' : 'Create New Travel Type Mapping'"
        (close)="closeTtmModal();"
        >
        <div data-testid="create-travel-type-mapping-form-modal-content" class="filter__fields">

            <read-only-form-field [readonly]="!!glTravelTypeModel" [displayPropFunc]="glReadOnlyDisplay"  title="General Ledger">
                <mat-form-field>
                    <mat-label>General Ledger</mat-label>
                    <mat-select formControlName="generalLedgerId">
                        @for (gl of generalLedgerList; track gl?.id) {
                        <mat-option [value]="gl?.id">{{gl?.glNo}} - {{gl?.glName}}</mat-option>
                        } 
                    </mat-select>
                    @if(ttmForm.controls['generalLedgerId'].value){
                        <button 
                            data-testid="create-travel-type-mapping-form-modal-general-ledger-id-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('generalLedgerId')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>
            </read-only-form-field>
            

            <mat-form-field>
                <mat-label>Travel Type</mat-label>
                <mat-select formControlName="travelTypeId">
                    @for (travelType of travelTypeMappingList$ | async; track travelType?.id) {
                      <mat-option [value]="travelType?.id">{{travelType?.travelTypeName}}</mat-option>
                    } 
                </mat-select>
                @if(ttmForm.controls['travelTypeId'].value){
                    <button 
                        data-testid="create-travel-type-mapping-form-modal-travel-type-id-clear-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFields('travelTypeId')">
                            <mat-icon>close</mat-icon>
                    </button>
                }
            </mat-form-field>
        </div>

        <ng-container data-testid="create-travel-type-mapping-form-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button data-testid="create-travel-type-mapping-form-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                    <mat-icon data-testid="create-travel-type-mapping-form-modal-icon-clear-all-filter-button-icon">delete_outline</mat-icon>
                    Clear All
                </button>
                <button data-testid="create-travel-type-mapping-form-modal-submit-button" mat-raised-button color="primary" type="submit" [disabled]="ttmForm.invalid || isLoading">
                    @if (isLoading) {
                        <mat-spinner
                            data-testid="create-travel-type-mapping-form-modal-submit-button-spinner"
                            color="accent"
                            diameter="30">
                        </mat-spinner>
                    } @else {
                        @if(glTravelTypeModel){
                            Update
                        } @else {
                            Create
                        }
                    }
                </button>
            </div>
        </ng-container>
    </modal-panel>
</form>
