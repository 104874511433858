import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, catchError, throwError} from 'rxjs';
import {SearchPaginatedAdxUdidInformationResponse} from '../models/search-paginated-adx-udid-information-response';

@Injectable({
  providedIn: 'root'
})

export class AdxUdidInformationService {
  private resourcePath: string = 'dbov2/adx-udid-information';
  
  constructor(private httpClient: HttpClient) {
  }

  searchPaginatedAdxUdidInformation(): Observable<SearchPaginatedAdxUdidInformationResponse> {
      return this.httpClient
        .get<SearchPaginatedAdxUdidInformationResponse>(`${this.resourcePath}`)
        .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message || 'Server Error'));
  }
}
