import {Component, OnInit} from '@angular/core';
import {ChartDataSet, ChartOptions} from '../../../../../shared/components/chart/chart.component';
import {Router} from '@angular/router';
import {ChartsVccService} from '../../../../service/charts-vcc.service';
import * as moment from 'moment';
import {VccChartFilter} from '../../../../model/funding-vcc-chart-filter';
import {MatchingResult} from '../../../../model/matching-result';
import {TooltipItem} from 'chart.js';
import {MatchingVccService} from 'src/app/finance-resource/service/matching-vcc.service';
import {ChartResponse} from '../../../../model/vcc-chart-model';
import {MatchedVccFilter} from 'src/app/finance-resource/model/match-vcc';
import {MerchantsService} from 'src/infrastructure/merchants/service/merchants.service';
import {Merchant} from 'src/app/dashboard/model/merchant-guid';

@Component({
    selector: 'matching-vcc-chart',
    templateUrl: './matching-vcc-chart.component.html'
})

export class MatchingVccChartComponent implements OnInit {
  
  public shortLabels: string[] = [];
  merchantGuids: Merchant[] = [];
  public vccChartFilter: VccChartFilter = {} as VccChartFilter;
  public dataMatching!: ChartDataSet;
  originalMatch: number[] = [];
  originalUnmatch: number[] = [];
  limitPercentageMatch: number = 0;
  limitPercentageUnmatch: number = 0;
  baseLimit: number = 0.11;
  adjustedValueMatch: number = 0;
  adjustedValueUnmatch: number = 0;
  adjustedLimit: number = 0.5;
  public matchingData: MatchingResult[] = [];
  public matchingVccFilterConfigResult: MatchedVccFilter = {};
  public matchingVccChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
          callbacks: {
              label: (tooltipItem: TooltipItem<'bar'>) => {
                const originalMatch = this.originalMatch[tooltipItem.dataIndex];
                const originalUnmatch = this.originalUnmatch[tooltipItem.dataIndex];
                if (tooltipItem.dataset.label === 'Match') {
                  return `${tooltipItem.label}: ${originalMatch}`;
                } else {
                  return`${tooltipItem.label}: ${originalUnmatch}`;
                }
              }
          }
      },
        legend: {
          display: true,
          align: 'center'
        },
        datalabels: {
          color: '#ffffff',
          align: 'start',
          anchor: 'end',
          borderWidth: 1,
          borderRadius: 5,
          offset: 1,
          font: {
            weight: 'bold'
          },
          padding: {
            top: 2,
            right: 5,
            bottom: 1,
            left: 5
          },
          formatter: (value, context) => {
            const { dataIndex, dataset } = context;
            const isMatch = dataset.label === 'Match';
            const isUnmatch = dataset.label === 'Unmatch';

            // Retrieve the original value based on the dataset label
            const originalValue = isMatch
              ? this.originalMatch[dataIndex]
              : isUnmatch
              ? this.originalUnmatch[dataIndex]
              : null;

            // Hide datalabel if the original value is 0
            if (originalValue === 0) {
              return null;
            }

            // Return the formatted value, ensuring consistency
            return value === originalValue ? value : `${originalValue}`;
          }
        }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    },
  }

  constructor(private router: Router,
              private chartsVccService: ChartsVccService,
              private matchingVccService: MatchingVccService,
              private merchantsService: MerchantsService,
  ) {
  }
    
  ngOnInit() {
    const today = moment();
    this.chartsVccService.getLatestDate().subscribe((latestReleasedDate) => {
      this.vccChartFilter.propertyName = 'releasedDateFrom';
      this.vccChartFilter.orderKey = 'DESC';
      this.vccChartFilter.releasedDateFrom = latestReleasedDate;
      this.vccChartFilter.releasedDateTo = today.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  
      this.chartsVccService.getVccChartFilter().subscribe((filter) => {
        this.vccChartFilter.merchantGuid = filter.merchantGuid;
        this.vccChartFilter.releasedDateFrom = filter.releasedDateFrom ? moment(filter.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : this.vccChartFilter.releasedDateFrom;
        this.vccChartFilter.releasedDateTo = filter.releasedDateTo ? moment(filter.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : this.vccChartFilter.releasedDateTo;
        
        this.merchantsService.merchants$.subscribe((data)=>{
          this.merchantGuids = data;
          this.getMatchingData();
        });
      });
    });
  }
  
  get dateFilterText() {
    return this.chartsVccService.getDateSubTitle();
  }

  getMatchingData(): void {
    this.chartsVccService.searchMatchingVcc(this.vccChartFilter).subscribe((data: MatchingResult[]) => {
      this.matchingData = data;
      this.buildMatchingDataSet(data);
    });
  }

  buildMatchingDataSet(matchingData: MatchingResult[] ){
    this.shortLabels = matchingData.map(item => {
      const merchant = this.merchantGuids.find(m => m.merchantGuid === item.merchantGuid);
      return merchant ? merchant.shortName : '';
    });
    this.originalMatch = matchingData.map((item) => item.match);
    this.originalUnmatch = matchingData.map((item) => item.unmatch);
    
    const maxMatch = Math.max(...matchingData.map(item => item.match));
    this.limitPercentageMatch = maxMatch * this.baseLimit;
    const maxUnmatch = Math.max(...matchingData.map(item => item.unmatch));
    this.limitPercentageUnmatch = maxUnmatch * this.baseLimit;

    const adjustedMatch = matchingData.map((item: MatchingResult) => {
      this.adjustedValueMatch = item.match;
      if (this.adjustedValueMatch < this.limitPercentageMatch) {
        this.adjustedValueMatch += (this.limitPercentageMatch - this.adjustedValueMatch) * this.adjustedLimit;
      }
      return this.adjustedValueMatch;
    });

    const adjustedUnmatch = matchingData.map((item: MatchingResult) => {
      this.adjustedValueUnmatch = item.unmatch;
      if (this.adjustedValueUnmatch < this.limitPercentageUnmatch) {
        this.adjustedValueUnmatch += (this.limitPercentageUnmatch - this.adjustedValueUnmatch) * this.adjustedLimit;
      }
      return this.adjustedValueUnmatch;
    });

    this.dataMatching = {
      labels: this.shortLabels,
      datasets: [{
          data: adjustedMatch,
          label: 'Match',
          backgroundColor: '#a2798f'
        },
        {
          data: adjustedUnmatch,
          label: 'Unmatch',
          backgroundColor: '#4c516d'
        }
      ]
    };
  }

  navigateToReport(event: ChartResponse) {
    const selectedMerchant = this.merchantGuids.find(merchant => merchant.shortName === event.label);
    const filteredMatchingData = this.matchingData.filter(item => item.merchantGuid === selectedMerchant?.merchantGuid);
    const matched = filteredMatchingData[0].match != 0 ? true : false;
    const unmatched = filteredMatchingData[0].unmatch != 0 ? true : false;

    this.matchingVccFilterConfigResult.merchantGuid = selectedMerchant?.merchantGuid;
    this.matchingVccFilterConfigResult.isAmountMatches = matched && unmatched ? null : matched ? "1" : unmatched ? "0" : null;
    this.matchingVccFilterConfigResult.releasedDateFrom = this.vccChartFilter.releasedDateFrom ? moment(this.vccChartFilter.releasedDateFrom).toDate() : null;
    this.matchingVccFilterConfigResult.releasedDateTo = this.vccChartFilter.releasedDateTo ? moment(this.vccChartFilter.releasedDateTo).toDate() : null;

    this.matchingVccService.setMatchingFilter(this.matchingVccFilterConfigResult);
    this.router.navigate(['/FinanceResources/MatchingVcc']);
  }
}