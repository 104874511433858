<modal-panel [title]="title" data-testid="adx-booking-details-modal-panel" (close)="closeDialog()" >
  <ng-container data-testid="adx-booking-details-modal-container">
    <app-adx-booking-details [data]="data.adxBookingId"></app-adx-booking-details>
  </ng-container>

  <ng-container data-testid="adx-booking-details-modal-footer" modalPanelFooter >
    <button data-testid="adx-booking-details-modal-close-button" mat-raised-button color="primary" 
      (click)="closeDialog()">Close</button>
    <button *ngIf="isLoggedInUserHasManagerRole" data-testid="adx-booking-detail-copy-button" mat-raised-button color="primary" 
      (click)="openCopyConfirmationDialog(data.adxBookingId)" style="float: right;">Copy service</button>
  </ng-container>
</modal-panel>
