import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, Observable, switchMap} from 'rxjs';
import {AlertCriteria, AlertCondition, AlertFormValue, AlertsResponse, SearchAlertsParams, AlertDetail, WorkflowRules, AlertPostResponse, AlertComponent} from '../../shared/model/financial-alerts-result';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import {MerchantsService} from 'src/infrastructure/merchants/service/merchants.service';
import {Merchant} from 'src/app/dashboard/model/merchant-guid';

@Injectable({
  providedIn: 'root'
})
export class FinancialAlertsService {
    private readonly conditions: Record<number,string> = {
        1: '>',
        2: '>=',
        3: '<',
        4: '<=',
        5: '==',
    };

    constructor(
      private httpClient: HttpClient,
      private merchantsService: MerchantsService,
    ){}

    getAlerts({page,sort}:SearchAlertsParams): Observable<AlertsResponse>{
      const params:Record<string, string | number> = {
        pageSize:  page?.pageSize || 25,
        pageNumber : (page?.pageIndex || 0) + 1,
        propertyName: sort?.active || '',
        orderKey: sort?.direction || '',
      };

      removeEmptyParams(params);

      return this.httpClient.get<AlertsResponse>('dbov2/alert-rule', {
        params: {...params}
      })
      .pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error.message || 'Server error')
      }));
    }

    getAlertDetails(alertId:string): Observable<AlertDetail>{
      return this.httpClient.get<AlertDetail>(`dbov2/alert-rule/${alertId}`)
      .pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error.message || 'Server error')
      }));
    }

    setAlert(form:AlertFormValue): Observable<AlertPostResponse>{
      return this.merchantsService.merchants$.pipe(switchMap((merchants)=>{
        const request = {
          name: form.alertName,
          criteriaId: form.alertCriteria,
          description: form.description,
          isEnabled: form.enabled,
          workflowNotification: {
            notificationValue: form.emailsForm.map((element) => element.email).join(";"),
          },
          workflowRules: form.merchantGuid.map((merchant)=>{
            const merchantDetails = merchants.find((merchantData)=> merchantData.merchantGuid === merchant);
            return {
              ruleName: `${form.alertName} for ${merchantDetails?.name}`,
              description: `${form.description} for ${merchantDetails?.name}`,
              expression: this.upsertExpression(merchantDetails, form, this.conditions),
              isEnabled: true,
              workflowRuleConditions: this.upsertWorkflowRuleConditions(merchantDetails, form),
              workflowRuleActions: [
                {
                  typeId: 1,
                },
                {
                  typeId: 2,
                },
              ],
            }
          }),
        }

        return this.httpClient.post<AlertPostResponse>('dbov2/alert-rule', request)
        .pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error.message || 'Server error')
        }));
      }))
    }

    updateAlert(form:AlertFormValue, alert:AlertDetail): Observable<AlertPostResponse> {
      return this.merchantsService.merchants$.pipe(switchMap((merchants)=>{
        const request = {
          id: alert.workflow.id,
          name: form.alertName,
          criteriaId: form.alertCriteria,
          description: form.description,
          isEnabled: form.enabled,
          workflowNotification: {
            id: alert.workflow.workflowNotification.id,
            workflowId: alert.workflow.workflowNotification.workflowId,
            notificationTypeId: alert.workflow.workflowNotification.notificationTypeId,
            notificationValue: form.emailsForm.map((element) => element.email).join(";"),
          },
          workflowRules: {},
        }

        const updatedRules: any = alert?.workflow?.workflowRules.map((rule)=>{
          const merchantDetails = merchants.find(
            (merchantData)=> merchantData.merchantGuid === rule.workflowRuleConditions[0].workflowRuleConditionValue
          );
          const expression = this.upsertExpression(merchantDetails, form, this.conditions);
          const workflowRuleConditions = form.alertCriteria === '5'
            ? [
                { ...rule.workflowRuleConditions[0] },
                { ...rule.workflowRuleConditions[1], workflowLogicOperatorId: form.alertCondition, workflowRuleConditionValue: `${form.amount}` },
                { ...rule.workflowRuleConditions[2] },
                { ...rule.workflowRuleConditions[3], workflowLogicOperatorId: form.alertConditionTransactions, workflowRuleConditionValue: form.transactions },
                { ...rule.workflowRuleConditions[4] }
              ]
            : form.alertCriteria === '6'
            ? [
                { ...rule.workflowRuleConditions[0] },
                { ...rule.workflowRuleConditions[1] },
                { ...rule.workflowRuleConditions[2] },
                { ...rule.workflowRuleConditions[3] }
              ]
            : [
                { ...rule.workflowRuleConditions[0] },
                { ...rule.workflowRuleConditions[1], workflowLogicOperatorId: form.alertCondition, workflowRuleConditionValue: `${form.amount}` }
              ];

          return {
            id: rule.id,
            ruleName: `${form.alertName} for ${merchantDetails?.name}`,
            description: `${form.description} for ${merchantDetails?.name}`,
            workflowId: rule.workflowId,
            expression,
            successEvent: rule.successEvent,
            operatorId: rule.operatorId,
            errorMessage: rule.errorMessage,
            isEnabled: form.merchantGuid.includes(rule.workflowRuleConditions[0].workflowRuleConditionValue),
            workflowRuleConditions,
            workflowRuleActions: rule.workflowRuleActions,
          }
        });

        form.merchantGuid.forEach((merchantId)=>{
          if(!updatedRules?.find((rule: WorkflowRules)=> rule.workflowRuleConditions[0].workflowRuleConditionValue === merchantId)){
            const merchantDetails = merchants.find((merchantData)=> merchantData.merchantGuid === merchantId);
            const expression = this.upsertExpression(merchantDetails, form, this.conditions);
            updatedRules?.push({
              ruleName: `${form.alertName} for ${merchantDetails?.name}`,
              description: `${form.description} for ${merchantDetails?.name}`,
              expression,
              isEnabled: true,
              workflowRuleConditions: this.upsertWorkflowRuleConditions(merchantDetails, form),
              workflowRuleActions: [
                {
                  typeId: 1,
                },
                {
                  typeId: 2,
                },
              ]
            });
          }
        });

        request.workflowRules = updatedRules;

        return this.httpClient.post<AlertPostResponse>('dbov2/alert-rule', request)
        .pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error.message || 'Server error')
        }));
      }));
    }

    upsertExpression = (merchantDetails: Merchant | undefined, form: AlertFormValue, conditions: Record<number,string>) => {
      if (form.alertCriteria === '5') {
        return `input1.MerchantIdentifier == "${merchantDetails?.merchantGuid}" and input1.Amount ${conditions[form.alertCondition as number]} ${form.amount} and input1.PerDayCount ${conditions[form.alertConditionTransactions as number]} ${form.transactions} and (input1.ConnexPayEventTypeId == 5 or input1.ConnexPayEventTypeId == 6 or input1.ConnexPayEventTypeId == 10)`;
      }
      if (form.alertCriteria === '6') {
        return `input1.MerchantIdentifier == "${merchantDetails?.merchantGuid}" and input1.ServiceEndDate < DateTime.Today and input1.EventDate == DateTime.Today and (input1.ConnexPayEventTypeId == 5 or input1.ConnexPayEventTypeId == 6 or input1.ConnexPayEventTypeId == 10)`;
      }
      return `input1.merchantGuid == "${merchantDetails?.merchantGuid}" and input2.Amount ${conditions[form.alertCondition as number]} ${form.amount}`;
    };

    upsertWorkflowRuleConditions = (merchantDetails: Merchant | undefined, form: AlertFormValue) => {
      if (form.alertCriteria === '5') {
        return [
          {
            workflowLogicOperatorId: 5,
            workflowRuleConditionValue: merchantDetails?.merchantGuid,
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "MerchantIdentifier",
            orderNumber: 1,
          },
          {
            workflowLogicOperatorId: form.alertCondition,
            workflowRuleConditionValue: form.amount,
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "Amount",
            orderNumber: 2,
          },
          {
            workflowLogicOperatorId: 5,
            workflowRuleConditionValue: '5,6,10',
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "ConnexPayEventTypeId",
            orderNumber: 3,
          },
          {
            workflowLogicOperatorId: form.alertConditionTransactions,
            workflowRuleConditionValue: form.transactions,
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "PerDayCount",
            orderNumber: 4,
          },
          {
            workflowLogicOperatorId: 5,
            workflowRuleConditionValue: 'CAST(GETDATE() AS DATE)',
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "EventDate",
            orderNumber: 5,
          },
        ];
      } 
      if (form.alertCriteria === '6') {
        return [
          {
            workflowLogicOperatorId: 5,
            workflowRuleConditionValue: merchantDetails?.merchantGuid,
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "MerchantIdentifier",
            orderNumber: 1,
          },
          {
            workflowLogicOperatorId: 3,
            workflowRuleConditionValue: 'CAST(GETDATE() AS DATE)',
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "ServiceEndDate",
            orderNumber: 2,
          },
          {
            workflowLogicOperatorId: 5,
            workflowRuleConditionValue: 'CAST(GETDATE() AS DATE)',
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "EventDate",
            orderNumber: 3,
          },
          {
            workflowLogicOperatorId: 5,
            workflowRuleConditionValue: '5,6,10',
            entityName: "ConexPayTransactionInformationViewModel",
            entityColumn: "ConnexPayEventTypeId",
            orderNumber: 4,
          }
        ];
      }
      return [
        {
          workflowLogicOperatorId: 5,
          workflowRuleConditionValue: merchantDetails?.merchantGuid,
          entityName: "ConnexPayEventDetail",
          entityColumn: "MerchantIdentifier",
          orderNumber: 1,
        },
        {
          workflowLogicOperatorId: form.alertCondition,
          workflowRuleConditionValue: form.amount,
          entityName: "ConnexPayEventCard",
          entityColumn: "AmountLimit",
          orderNumber: 2,
        },
      ];
    };

    updateAlertState(alert:AlertDetail, state:boolean): Observable<AlertPostResponse> {
      alert.workflow.isEnabled = state;

      return this.httpClient.post<AlertPostResponse>('dbov2/alert-rule', alert.workflow)
      .pipe(catchError((error:HttpErrorResponse)=>{
          throw new Error(error.message || 'Server error')
      }));
    }

    getAlertCriteria(): Observable<AlertCriteria[]> {
        return this.httpClient.get<AlertCriteria[]>('dbov2/workflow-target-criteria')
        .pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error')
        }));
    }

    getAlertConditions(): Observable<AlertCondition[]>{
        return this.httpClient.get<AlertCondition[]>('dbov2/workflow-logic-operator')
        .pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error')
        }));
    }

    getComponentsByAlertCriteria(criteriaId :string){
      return this.httpClient.get<AlertComponent[]>('dbov2/alert-rule/elements-ui', { params:{ criteriaId } })
      .pipe(catchError((error:HttpErrorResponse)=>{
          throw new Error(error?.message || 'Server error')
      }));
    }

    deleteAlert(workflowId:number): Observable<void>{
      return this.httpClient.delete<void>(`dbov2/alert-rule`, { params: { workflowId } })
      .pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error.message || 'Server error')
      }));
    }
}

