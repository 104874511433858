<form [formGroup]="paymentForm" (submit)="createPayment()">
    <modal-panel
        id="payment-form-modal"
        class="filter-panel"
        [title]="'[MOCK] Create new payment'"
        (close)="closePaymentForm();"
        >
        <div id="payment-form-modal-content" class="filter__fields items-space-between">
            <div style="width: 40%;" >
                <mat-form-field>
                    <mat-label>Amount</mat-label>
                    <input matInput placeholder="Amount" value="" formControlName="amount" type="number" step="1" min="0" (keypress)="checkInputAmount($event)">
                    @if(paymentForm.controls['amount'].value){
                        <button 
                            id="payment-form-modal-amount-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('amount')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>


                @let payTypeList = payTypeList$ | async;
                <mat-form-field>
                    <mat-label>Pay type</mat-label>
                    <mat-select formControlName="payType">
                        @for (payType of payTypeList?.items; track payType?.id) {
                            <mat-option [value]="payType?.id">{{payType?.payTypeName}}</mat-option>
                        } 
                    </mat-select>
                    @if(paymentForm.controls['payType'].value && !paymentForm.controls['payType'].disabled ){
                        <button 
                            id="payment-form-modal-amount-pay-type-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('payType')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Bank</mat-label>
                    <input type="text" matInput formControlName="bank" [matAutocomplete]="bankAuto">
                    <mat-autocomplete #bankAuto="matAutocomplete" [displayWith]="displayWithBank">
                        @for (bank of filteredBank$ | async; track bank?.id) {
                            <mat-option [value]="bank">{{bank?.name}}</mat-option>
                        }    
                    </mat-autocomplete>
                    @if(paymentForm.controls['bank'].value){
                        <button 
                            id="payment-form-modal-bank-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('bank')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Branch</mat-label>
                    <input type="text" matInput formControlName="branch" [matAutocomplete]="branchAuto">
                    <mat-autocomplete #branchAuto="matAutocomplete" [displayWith]="displayWithBranch">
                        @for (branch of filteredBranch$ | async; track branch?.id) {
                            <mat-option [value]="branch">{{branch?.name}}</mat-option>
                        }
                    </mat-autocomplete>
                    @if(paymentForm.controls['branch'].value){
                        <button 
                            id="payment-form-modal-branch-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('branch')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Pay method</mat-label>
                    <input type="text" matInput formControlName="payMethod" [matAutocomplete]="payMethodAuto">
                    <mat-autocomplete #payMethodAuto="matAutocomplete" [displayWith]="displayWithPayMethod">
                        @for (payMethod of filteredPayMethod$ | async; track payMethod?.id) {
                            <mat-option [value]="payMethod">{{payMethod?.paymethodName}}</mat-option>
                        }
                    </mat-autocomplete>
                    @if(paymentForm.controls['payMethod'].value){
                        <button 
                            id="payment-form-modal-pay-method-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('payMethod')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>


                <mat-form-field>
                    <mat-label>Profile type</mat-label>
                    <input type="text" matInput formControlName="profileType" [matAutocomplete]="profileTypeAuto">
                    <mat-autocomplete #profileTypeAuto="matAutocomplete" [displayWith]="displayWithProfileType">
                        @for (profileType of filteredProfileType$ | async; track profileType?.id) {
                            <mat-option (click)="setAutocompleteMode(profileType)" [value]="profileType">{{profileType?.profileTypeName}}</mat-option>
                        }
                    </mat-autocomplete>
                    @if(paymentForm.controls['profileType'].value){
                        <button 
                            id="payment-form-modal-profile-type-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('profileType')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Submit to</mat-label>
                    <input type="text" matInput formControlName="submitTo" [matAutocomplete]="submitToAuto">
                    <mat-autocomplete #submitToAuto="matAutocomplete" [displayWith]="displayWithSubmitTo">
                        @for (submitTo of filteredSubmitTo$ | async; track submitTo?.id) {
                            <mat-option [value]="submitTo">{{submitTo?.submitToLink}}</mat-option>
                        }
                    </mat-autocomplete>
                    @if(paymentForm.controls['submitTo'].value){
                        <button 
                            id="payment-form-modal-submit-to-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('submitTo')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>


            </div>

            <div style="width: 55%;">
                <mat-form-field>
                    <mat-label>Payment date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="paymentDate">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker #picker></mat-datepicker>
                    @if(paymentForm.controls['paymentDate'].value){
                        <button 
                            id="payment-form-modal-payment-date-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('paymentDate')">
                                <mat-icon>close</mat-icon>
                        </button>
                    } @else {
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    }
                </mat-form-field>


                <mat-form-field>
                    <mat-label>Invoice reference</mat-label>
                    <input matInput placeholder="Invoice reference" value="" formControlName="invoiceReference" type="number" step="1" min="0" (keypress)="checkInput($event)">
                    @if(paymentForm.controls['invoiceReference'].value){
                        <button 
                            id="payment-form-modal-invoice-reference-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('invoiceReference')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>


                <mat-form-field>
                    <mat-label>Remarks</mat-label>
                    <textarea id="financial-alerts-description-input" matInput formControlName="remarks" style="height: 102px;"></textarea>
                    @if(paymentForm.controls['remarks'].value){
                        <button 
                            id="payment-form-modal-remarks-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('remarks')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>

                <mat-form-field>
                    <mat-label>CK/CC/Other No</mat-label>
                    <input matInput placeholder="CK/CC/Other No" value="" formControlName="number">
                    @if(paymentForm.controls['number'].value){
                        <button 
                            id="payment-form-modal-number-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('number')">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>

                <autocomplete [autocompleteMode]="autocompleteMode" formControlName="name"></autocomplete>

                <mat-form-field>
                    <mat-label>Currency</mat-label>
                    <input type="text" matInput formControlName="currency" [matAutocomplete]="currencyAuto">
                    <mat-autocomplete #currencyAuto="matAutocomplete" [displayWith]="displayWithCurrencyList">
                        @for (currency of filteredCurrency$ | async; track currency?.id) {
                            <mat-option [value]="currency">
                                {{currency?.currencyName}} {{currency?.symbol ? '('+currency?.symbol+')' : ''}}
                            </mat-option>
                        }
                    </mat-autocomplete>
                    @if(paymentForm.controls['currency'].value){
                        <button 
                            id="payment-form-modal-currency-clear-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFields('currency'); $event.stopPropagation()">
                                <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>


            </div>
        </div>

        <ng-container id="payment-form-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button id="payment-form-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                    <mat-icon id="payment-form-modal-icon-clear-all-filter-button-icon">delete_outline</mat-icon>
                    Clear All
                </button>
                <button id="payment-form-modal-submit-button" mat-raised-button color="primary" type="submit" [disabled]="paymentForm.invalid">Create</button>
            </div>
        </ng-container>
    </modal-panel>
</form>