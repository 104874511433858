import {DatePipe} from '@angular/common';
import {Component, Input} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'app-adx-booking-segment-info',
  templateUrl: './adx-booking-segment-info.component.html'
})

@AutoUnsubscribe()
export class AdxBookingSegmentInfoComponent {
  @Input() public data: any;
  _adxBookingSegment : any;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(){
    this.getAdxBookingSegmentInfo();
  }

  getAdxBookingSegmentInfo() {
    if (this.data != null){
      this._adxBookingSegment = this.data;
    }
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy HH:mm') || '';
  }
}