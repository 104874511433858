import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {MatSort} from '@angular/material/sort';
import {Pagination} from '../../../../infrastructure/pagination/model/pagination';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';
import {AdxInvoice} from '../../model/adx-invoice';
import {AdxInvoiceService} from '../../service/adx-invoice.service';
import {SearchPaginatedAdxInvoiceResponse} from '../../model/search-paginated-adx-invoice-response';
import {AdxInvoiceFilter} from '../../model/adx-invoice-filter';
import {AdxInvoiceFilterResult} from '../../model/adx-invoice-filter-result';
import {MatDialog } from '@angular/material/dialog';
import {HistoryComponent} from 'src/app/history/components/history/history.component';

@Component({
  selector: 'adx-invoice-list',
  templateUrl: './adx-invoice-list.component.html',
})

@AutoUnsubscribe()
export class AdxInvoiceListComponent implements OnInit, AfterViewInit {
  @Input() public data: any;

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  public dataSource: MatTableDataSource<AdxInvoice> = new MatTableDataSource<AdxInvoice>([]);

  public displayedColumns: Array<string> = [
    'id',
    'recordLocator',	
    'element.branch.name',	
    'element.client.profileName',
    'invoiceTypeCode',
    'invoiceGroup',
    'issueDate',
    'action'
  ];

  isLoading: boolean = false;

  public pagination: Pagination = {} as Pagination;
  public adxInvoiceFilter: AdxInvoiceFilter = {} as AdxInvoiceFilter;

  constructor(
    private adxInvoiceService: AdxInvoiceService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog)
    {  }

  public ngOnInit(): void {
    if (this.data == undefined || this.data.pageSize == null) {
      this.data = {
        tripId: null,
        pageSize: 25
      }
    }
    this.adxInvoiceService.getAdxInvoiceFilter().subscribe((filter: AdxInvoiceFilterResult) => {
      if (this.paginator !== undefined) {
        this.paginator.pageIndex = 0;
        this.adxInvoiceFilter.pageNumber = 1;
      }

      filter.tripId = this.data.tripId;
      this.adxInvoiceFilter.tripId = filter.tripId;
      this.adxInvoiceFilter.pageSize = this.data.pageSize;

      this.searchAdxInvoices(new PageEvent(), false);
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public searchAdxInvoices(pageEvent: PageEvent, skipPager: boolean): void {
    if (!skipPager) {
      this.setPaginationAfterPageChanged(pageEvent);
    }
    this.adxInvoiceService
      .searchPaginatedAdxInvoices(this.adxInvoiceFilter)
      .subscribe((data: SearchPaginatedAdxInvoiceResponse) => {
        if (data) {
          this.pagination = data.pagination;
          this.adxInvoiceFilter.pageNumber = data.pagination.pageNumber;
          this.adxInvoiceFilter.pageSize = data.pagination.pageSize;
          this.dataSource = new MatTableDataSource<AdxInvoice>(data.items);
          this.isLoading = false;
        }
      });
  }

  public pageChanged(event: PageEvent): void {
    this.searchAdxInvoices(event, false);
  }

  public setPaginationAfterPageChanged(pageEvent: PageEvent): void {
    this.adxInvoiceFilter.pageSize = this.data.pageSize;

    const isStepForward = pageEvent.pageIndex > pageEvent.previousPageIndex!;

    if (isStepForward) {
      this.adxInvoiceFilter.pageNumber += 1;
    } else {
      this.adxInvoiceFilter.pageNumber = this.adxInvoiceFilter.pageNumber !== undefined &&  this.adxInvoiceFilter.pageNumber > 1 ? this.adxInvoiceFilter.pageNumber-1 : 1;
    }
  }

  public handleSortChange(sort: any): void {
    this.adxInvoiceFilter.propertyName = sort.active;
    this.adxInvoiceFilter.orderKey = sort.direction.toUpperCase();

    this.searchAdxInvoices(new PageEvent(), false);
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.adxInvoiceService
      .searchPaginatedAdxInvoices(this.adxInvoiceFilter).subscribe((data: SearchPaginatedAdxInvoiceResponse) => {
        this.exportGridService.getByDataSource(
          'dbov2/adx-invoice',
          'adx-invoices',
          {
            ... this.adxInvoiceFilter,
            pageSize: data?.pagination?.totalItemCount || 100,
          },
          (data)=>{
            const record = data as AdxInvoice;
            return {
              'Id': record?.id,
              'Record Locator': record?.recordLocator,
              'Branch Name': record?.branch?.name,
              'Client Name': record?.client?.profileName,
              'Type Code': record?.invoiceTypeCode,
              'Group': record?.invoiceGroup,
              'Issue Date': this.datePipe.transform(record?.issueDate, 'M/d/yyyy'),
            };
          }
        );
        downloadSubs$?.unsubscribe();
      });
  }

  replayGrid (){
    this.searchAdxInvoices(new PageEvent(), false);
  }

  handleInvoiceHistoryClick(adxInvoiceId : number){
    this.adxInvoiceService.getInvoiceHistoryById(adxInvoiceId)
      .subscribe((data: any) => {
        if(data.length == 0){
          this._snackBar.open('No Invoice history found for Id: ' + adxInvoiceId, 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['message-position', 'app-notification-success']
          });
        }
        else
        {
          let dialogConfirmationRef = 
            this.dialog.open(HistoryComponent, {
              width: '60%',
              data: {
                mode: 'Invoice',
                historyData: data,
                id: adxInvoiceId,
              }
          })
          dialogConfirmationRef.afterClosed().subscribe(
            (result: boolean) => {
              if (result != null && result){
                console.log(result);
              }
            }
          );
        }
      });
  }
}
