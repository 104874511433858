import {Component, Input} from '@angular/core';
import {AdxInvoiceService} from '../../service/adx-invoice.service';
import {AdxInvoiceDetailResponse} from '../../model/adx-invoice-detail-response';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-adx-invoice-info',
  templateUrl: './adx-invoice-info.component.html'
})

@AutoUnsubscribe()
export class AdxInvoiceInfoComponent {
  @Input() public data: any;
  _adxInvoice!: any;

  constructor(
    private datePipe: DatePipe,
    private adxInvoiceService: AdxInvoiceService) {
  }

  ngOnInit(){
    this.getAdxInvoiceInfo();
  }

  getAdxInvoiceInfo() {
    if (this.data != null){
      this.adxInvoiceService
        .searchByQueryAdxInvoices({ id: this.data.adxInvoiceId })
        .subscribe((data: AdxInvoiceDetailResponse[]) => {
          if (data.length > 0){
            this._adxInvoice = data[0];
          }
        });
    }
  } 

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }
}
