<form data-testid="adx-booking-udid-info-form" class="template-form adx-booking-info-form">  
    <div data-testid="adx-booking-udid-info-main-div" class="filter__fields">
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-booking-udid-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="90">
                    @for (adxBookingUdid of _adxBookingUdids; track adxBookingUdid) {
                        <mat-grid-tile data-testid="adx-booking-udid-info-value-tile">
                            <read-only-form-field title="Number: {{adxBookingUdid.udidNo}}" testId="adx-booking-udid-info-value-field" value="{{adxBookingUdid.udidValue}}" />
                        </mat-grid-tile>
                    }
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>