import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError, throwError} from 'rxjs';
import {SearchPaginatedAdxPaymentResponse} from '../model/search-paginated-adx-payment-response';
import {AdxPaymentFilterResult} from '../model/adx-payment-filter-result';
import {AdxPaymentResponse} from '../model/adx-payment-response';

@Injectable({
  providedIn: 'root'
})

export class AdxPaymentService {
  private resourcePath: string = 'dbov2/adx-payment';
  private _adxPaymentFilterSubject: BehaviorSubject<AdxPaymentFilterResult> = new BehaviorSubject<AdxPaymentFilterResult>({});

  constructor(private httpClient: HttpClient) {
  }

  public getAdxPaymentById(id: number): Observable<AdxPaymentResponse> {
    return this.httpClient
      .get<AdxPaymentResponse>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  searchPaginatedAdxPayments(adxPaymentFilter: any): Observable<SearchPaginatedAdxPaymentResponse> {
    let httpParams = new HttpParams();
    Object
      .keys(adxPaymentFilter)
      .forEach((key) => {
        if (adxPaymentFilter[key]) {
          httpParams = httpParams.append(key, adxPaymentFilter[key]);
        }
      });
      return this.httpClient
        .get<SearchPaginatedAdxPaymentResponse>(`${this.resourcePath}/search`, {params: httpParams})
        .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message || 'Server Error'));
  }

  getAdxPaymentFilter(): Observable<AdxPaymentFilterResult> {
    return this._adxPaymentFilterSubject.asObservable();
  }

  setAdxPaymentFilter(value: AdxPaymentFilterResult): void {
    this._adxPaymentFilterSubject.next(value);
  }
}