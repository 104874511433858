<div id="trip-profitability-agency-pie-chart" class="trip-profitability-pie-chart" *ngIf="this.tripProfitabilityDirectorReportData.length !== 0">
    <ng-container id="trip-profitability-agency-chart-container">
      <chart id="trip-profitability-agency-pie-chart" [dataSet]="tripProfitabilityPieChartData" [options]="tripProfitabilityPieChartOptions"
        [chartType]="'pie'"></chart>
    </ng-container>
  </div>
  <br>

  <div id="trip-profitability-agency-container" class="trip-profitability-container" *ngIf="this.isUserHasDirectorRole">
    <div id="trip-profitability-agency-table-feed" class="table-feed" *ngIf="this.tripProfitabilityDirectorReportData.length === 0">
      <div id="trip-profitability-agency-table-feed-text" class="table-feed-text" style="margin-top: 2%;">
        There are no records to display.
      </div>
    </div>
    <div class="grid-title-event">
      <h2 data-testid="trip-profitability-list-title">Trip Profitability With Agency</h2>
  
      <div class="events-container">
          @if(dataSourceDirector.filteredData.length){
              <button  data-testid="trip-profitability-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getTripProfitabilityReport()">
                  <mat-icon fontIcon="replay"></mat-icon>
              </button>
              <button color="primary" data-testid="download-trip-profitability-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
                  <mat-icon>download</mat-icon>
              </button>
          }
      </div>
    </div>
    <div id="trip-profitability-agency-director-grid" class="trip-profitability-director-grid mat-elevation-z8">
      <mat-table
        id="trip-profitability-agency-table"
        [dataSource]="dataSourceDirector"
        (matSortChange)="handleSortChange($event)"
        matSort>
        <ng-container id="trip-profitability-agency-no-dataRow" *matNoDataRow>
          <mat-cell id="trip-profitability-agency-empty-dataCell" [attr.colspan]="displayedColumnsDirector.length">Empty data</mat-cell>
        </ng-container>

        <ng-container id="trip-profitability-agency-trip-reference-column" matColumnDef="tripReference">
          <mat-header-cell id="trip-profitability-agency-trip-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Trip Reference</mat-header-cell>
          <mat-cell id="trip-profitability-agency-trip-reference-cell" *matCellDef="let row">{{row.tripReference}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-agency-date-created-column" matColumnDef="dateCreated">
          <mat-header-cell id="trip-profitability-agency-date-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
          <mat-cell id="trip-profitability-agency-date-created-cell" *matCellDef="let element">{{element.dateCreated  | date: 'M/d/yyyy'}}</mat-cell>
      </ng-container>


      <ng-container id="trip-profitability-agency-advisor-name-column" matColumnDef="advisorName">
        <mat-header-cell id="trip-profitability-agency-advisor-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Advisor Name</mat-header-cell>
        <mat-cell id="trip-profitability-agency-advisor-name-cell" *matCellDef="let row">{{row.advisorName}}</mat-cell>
    </ng-container>

    <ng-container id="trip-profitability-agency-agent-split-column" matColumnDef="agentSplit">
        <mat-header-cell id="trip-profitability-agency-agent-split-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Agent Split</mat-header-cell>
        <mat-cell id="trip-profitability-agency-agent-split-cell" *matCellDef="let row">{{row.agentSplit}}</mat-cell>
    </ng-container>

        <ng-container id="trip-profitability-agency-agency-split-column" matColumnDef="agencySplit">
          <mat-header-cell id="trip-profitability-agency-agency-split-header"  class="mat-header-cell" *matHeaderCellDef mat-sort-header>Agency Split</mat-header-cell>
          <mat-cell id="trip-profitability-agency-agency-split-cell" *matCellDef="let row">{{row.agencySplit}}</mat-cell>
        </ng-container>

        <ng-container id="trip-profitability-agency-margin-percentage-column" matColumnDef="marginPercentage">
          <mat-header-cell id="trip-profitability-agency-margin-percentage-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header disabled>Margin Percentage</mat-header-cell>
          <mat-cell id="trip-profitability-agency-margin-percentage-cell" *matCellDef="let row">{{row.marginPercentage.toFixed(2)}}</mat-cell>
      </ng-container>

      <ng-container id="trip-profitability-agency-adx-booking-number-column" matColumnDef="adxBookingNumber">
          <mat-header-cell id="trip-profitability-agency-adx-booking-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Link To Trip Details</mat-header-cell>
          <mat-cell id="trip-profitability-agency-adx-booking-number-cell" *matCellDef="let row">
              <a id="trip-profitability-agency-linktoto-trip-details" href={{row.linkToTripDetails}} target="_blank" rel="noopener noreferrer">
                  <div id="trip-profitability-agency-adx-booking-number-div">
                      {{row.adxBookingNumber}}
                  </div>
              </a>
          </mat-cell>
      </ng-container>

      <mat-header-row data-testid="trip-profitability-agency-header-row"
      *matHeaderRowDef="displayedColumnsDirector; sticky: true">
      </mat-header-row>
      <mat-row id="trip-profitability-agency-row"
          *matRowDef="let row; columns: displayedColumnsDirector;">
      </mat-row>
      </mat-table>
      </div>
    </div>
    <mat-paginator
      id="trip-profitability-agency-paginator"
      [length]="this.pagination.totalItemCount"
      [hidePageSize]="true"
      [pageSize]="this.pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="pageChanged($event)">
    </mat-paginator>
