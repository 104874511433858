@if(!isLoading)
{
  @if(title)
  {
    <mat-dialog-content  class="flex-space-between">
      <mat-label id="trip-reference-label-title" class="modal-title-bold">{{this.title}}</mat-label>
      <mat-dialog-actions align="end">
        <button id="trip-reference-cancel-button" mat-icon-button color="primary" aria-label="Close Modal"
          (click)="handleTripReferenceCloseBtnClick()">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-dialog-actions>
    </mat-dialog-content>
  }
  <form id="trip-reference-viewer-form" class="template-form">
    <div id="trip-reference-main-container" class="container">
      @if(_booking.recordLocator)
      {
        <read-only-form-field title="Trip Id" testId="trip-reference-record-locator-field" value="{{_booking.recordLocator}}" />
      }
      @if(_booking.departDate)
      {
        <read-only-form-field title="Service Start Date" testId="trip-reference-service-depart-date" value="{{_booking.departDate | date: 'M/d/yyyy'}}" />
      }
      @if(_booking.returnDate)
      {
        <read-only-form-field title="Service End Date" testId="trip-reference-service-return-date" value="{{_booking.returnDate | date: 'M/d/yyyy'}}" />
      }
      @if(_booking.confirmationNumber)
      {
        <read-only-form-field title="Confirmation Number" testId="trip-reference-confirmation-number-field" value="{{_booking.confirmationNumber}}" />
      }
      @if(_booking.reservationNo)
      {
        <read-only-form-field title="Reservation No" testId="trip-reference-reservation-no" value="{{_booking.reservationNo}}" />
      }
      @if( _booking.vendor && _booking.vendor.vendorName)
      {
        <read-only-form-field title="Vendor Name" testId="trip-reference-vendor-name-field" value="{{_booking.vendor.vendorName}}" />
      }
      @if(_booking.totalFare)
      {
        <read-only-form-field title="Total Fare" testId="trip-reference-total-fare" value="{{_booking.totalFare}}" />
      }
      @if(_booking.currency.currencyName)
      {
        <read-only-form-field title="Service Cost Currency" testId="trip-reference-service-cost-currency-code" value="{{_booking.currency.currencyName}}" />
      }
      @if(_booking.tramsDataSource)
      {
        <read-only-form-field title="Trams Data Source" testId="trip-reference-trams-data-source" value="{{_booking.tramsDataSource.sourceName}}" />
      }
      @if(_booking.submitToCode)
      {
        <read-only-form-field title="Submit To Code" testId="trip-reference-submit-to-code" value="{{_booking.submitToCode}}" />
      }
    </div>
  </form>
 }
 @else{
  <div class="loading-spinner">
    @if(isLoading){
      <mat-spinner></mat-spinner>
    }
  </div>
 }
