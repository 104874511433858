import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-new-adx-booking-dialog.component',
  templateUrl: './new-adx-booking-dialog.component.html'
})
export class NewAdxBookingDialogComponent implements OnInit {
  currentAdxBookingId: number;
  newAdxBookingId: number;
  isLoadingSpinner: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    public dialogRef: MatDialogRef<NewAdxBookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loader: LoadingService
  ) {
    this.currentAdxBookingId = this.data.currentAdxBookingId;
    this.newAdxBookingId = this.data.newAdxBookingId;
  }
  ngOnInit(): void {
    this.loader.hide();

  }
  noClick(): void {
    this.dialogRef.close();
  }

  okClick(): void {
    this.isLoadingSpinner = true;
    window.location.href = `AdxBookings/${this.newAdxBookingId}`;
  }
}
