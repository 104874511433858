<div data-testid="delete-gl-dialog-confirmpopup" class="confirmpopup">
  <div data-testid="delete-gl-dialog-dialog-title" mat-dialog-title>
    <label data-testid="delete-gl-dialog-title-label" class="title">Delete GL Confirmation</label>
  </div>
  <hr data-testid="delete-gl-dialog-first-hr">
  <div data-testid="delete-gl-dialog-dialog-content" mat-dialog-content>
    <p data-testid="delete-gl-dialog-content-p1">Are you sure you want to delete this GL?</p>
    <p data-testid="delete-gl-dialog-content-p2" class="word-break-text">{{glData.glName}}</p>
  </div>
  <hr data-testid="delete-gl-dialog-second-hr">
  <div data-testid="delete-gl-dialog-dialog-actions" mat-dialog-actions style="float: right;">
    <button
      data-testid="delete-gl-dialog-ok-button"
      type="submit" mat-raised-button
      (click)="okClick()"
      color="primary"
      cdkFocusInitial matTooltip="Delete General Ledger"
      aria-label="Delete General Ledger"
      [disabled]="isLoading"
    >
      @if(isLoading){
        <mat-spinner
          data-testid="delete-gl-dialog-spinner"
          color="accent"
          diameter="30">
        </mat-spinner>
      } @else {
        <span data-testid="delete-gl-dialog-ok-span">Ok</span>
      }
    </button>
    <button
      data-testid="delete-gl-dialog-no-button"
      mat-raised-button
      (click)="onNoClick()"
      color="warn"
      [disabled]="isLoading">
        No
    </button>
  </div>
</div>
