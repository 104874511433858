import {environment} from '../environments/environment';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, DatePipe, LocationStrategy, PathLocationStrategy, NgOptimizedImage} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {MatCardModule} from '@angular/material/card';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiInterceptor} from '../infrastructure/interceptors/api.interceptor';
import {ErrorHandlerService} from '../infrastructure/services/error-handler.service';
import {VendorViewerComponent} from './vendor/component/viewer/vendor-viewer/vendor-viewer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddeditVendorComponent} from './vendor/component/addedit/addedit-vendor/addedit-vendor.component';
import {VendorLayoutComponent} from './vendor/component/layout/vendor-layout/vendor-layout.component';
import {ComponentsModule} from './home/components.module';
import {VendorTemplateAddEditComponent} from './vendor-template/component/addedit/vendor-template-addedit/vendor-template-addedit.component';
import {ConfirmationDialogVendorTemplateComponent} from './vendor-template/component/popup/confirmation-dialog/confirmation-dialog.component';
import {VendorTemplateLayoutComponent} from './vendor-template/component/layout/vendor-template.layout/vendor-template.layout.component';
import {FmsAllComponent} from './home/components/fms-all.component';
import {SearchVendorComponent} from './vendor/component/search-vendor/search-vendor.component';
import {VendorViewerModalComponent} from './vendor/component/viewer/vendor-viewer-modal/vendor-viewer-modal.component';
import {ConfirmationDialogComponent} from './vendor/popup/confirmation-dialog/confirmation-dialog.component';
import {TermsConditionsRuleComponent} from './terms-conditions/components/terms-conditions-rule.component';
import {TemplateLayoutComponent} from './template/components/template-layout/template-layout.component';
import {TemplateConfirmationDialogComponent} from './template/popup/confirmation-dialog/template-confirmation-dialog.component';
import {InteractionType, PublicClientApplication, IPublicClientApplication} from '@azure/msal-browser';
import {MSAL_INTERCEPTOR_CONFIG, MsalGuard, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration} from '@azure/msal-angular';
import {msalConfig, loginRequest} from './auth-config';
import {AddEditVendorModalComponent} from './vendor/component/addedit/addedit-vendor-modal/addedit-vendor-modal.component';
import {RequestActionLayoutComponent} from './request-action/components/layout/request-action-layout.component';
import {VendorTemplateAddEditModalComponent} from './vendor-template/component/addedit/vendor-template-addedit-modal/vendor-template-addedit-modal.component';
import {TransactionBookingViewerComponent} from './transaction-booking/components/viewer/transaction-booking-viewer.component';
import {TemplateViewerComponent} from './template/components/template-viewer/template-viewer.component';
import {SearchTemplateComponent} from './template/components/search-template/search-template.component';
import {TransactionBookingLayoutComponent} from './transaction-booking/components/layout/transaction-booking-layout.component';
import {SearchTransactionBookingComponent} from './transaction-booking/components/search-transaction-booking/search-transaction-booking.component';
import {VendorListComponent} from './vendor/component/list/vendor-list.component';
import {TransactionBookingListComponent} from './transaction-booking/components/list/transaction-booking-list.component';
import {TransactionBookingViewerModalComponent} from './transaction-booking/components/viewer/transaction-booking-viewer-modal/transaction-booking-viewer-modal.component';
import {SharedModule} from './shared/shared.module';
import {FileUploadComponent} from './nav/upload/components/file-upload.component';
import {AhtWeeklyCalculationComponent} from './dashboard/components/aht-weekly-calculation/aht-weekly-calculation.component';
import {UtilizationWeeklyCalculationComponent} from './dashboard/components/utilization-weekly-calculation/utilization-weekly-calculation.component';
import {TicketsSummaryListComponent} from './summary/components/tickets-summary-list/tickets-summary-list.component';
import {TransactionsComponent} from './dashboard/layout/transactions/transactions.component';
import {DashboardComponent} from './dashboard/layout/dashboard/dashboard.component';
import {SummaryListComponent} from './summary/components/summary-list/summary-list.component';
import {BookingTemplateAddEditComponent} from './booking-template/component/addedit/booking-template-addedit/booking-template-addedit.component';
import {ConfirmationDialogBookingTemplateComponent} from './booking-template/component/popup/confirmation-dialog/confirmation-dialog.component';
import {BookingTemplateLayoutComponent} from './booking-template/component/layout/booking-template.layout/booking-template.layout.component';
import {FinanceReportComponent} from './finance-report/layout/finance-report/finance-report.component';
import {CommissionReportComponent} from './finance-report/components/commission-report/commission-report.component';
import {CommissionQueueComponent} from './finance-report/components/commission-queue/commission-queue.component';
import {PaymentQueueComponent} from './finance-report/components/payment-queue/payment-queue.component';
import {TripProfitabilityComponent} from './finance-report/components/trip-profitability/trip-profitability.component';
import {VccDailySummaryReportComponent} from './finance-report/components/vcc-daily-summary-report/vcc-daily-summary-report.component';
import {NoAccessComponent} from './unauthorized-access/component/no-access/no-access.component';
import {TransactionBookingTCViewerComponent} from './transaction-booking/components/viewer/transaction-booking-tc-viewer.component';
import {ProblemDetailsResponseInterceptor} from '../infrastructure/interceptors/problem-details-response.interceptor';
import {HomeLayoutComponent} from './home/components/layout/home-layout.component';
import {VendorTCViewerComponent} from './vendor/component/viewer/vendor-tc-viewer/vendor-tc-viewer.component';
import {AngularMaterialDesignModule} from './angular-material-design/module/angular-material-design.module';
import {AzureAuthService} from '../infrastructure/services/azure-auth.service';
import {SummaryListByServiceTypeComponent} from './summary/components/summary-list-by-service-type/summary-list-by-service-type.component';
import {FinanceComponent} from './dashboard/layout/finance/finance.component';
import {TripProfitabilityWithAgencyComponent} from './finance-report/components/trip-profitability-with-agency/trip-profitability-with-agency.component';
import {TraceComponent} from './trace/component/trace.component';
import {HEALTH_CHECK_PROVIDER} from '../infrastructure/healthcheck/provider/health-check.provider';
import {ConfirmationDialogIssueVccComponent} from './virtual-credit-card/component/popup/confirmation-dialog/confirmation-dialog-issue-vcc/confirmation-dialog-issue-vcc.component';
import {ApiVersionInterceptor} from '../infrastructure/interceptors/api.version.interceptor';
import {ConfirmationDialogUpdateVccComponent} from './virtual-credit-card/component/popup/confirmation-dialog/confirmation-dialog-update-vcc/confirmation-dialog-update-vcc.component';
import {VccViewerComponent} from './virtual-credit-card/component/viewer/vcc-viewer.component';
import {VccPaymentGatewayViewerComponent} from './virtual-credit-card/component/payment-gateway/viewer/vcc-payment-gateway-viewer.component';
import {MatTabsModule} from '@angular/material/tabs';
import {ConfirmationDialogDeleteVccComponent} from './virtual-credit-card/component/popup/confirmation-dialog/confirmation-dialog-delete-vcc/confirmation-dialog-delete-vcc.component';
import {InvoicesComponent} from './invoices/layout/invoices/invoices.component';
import {PaymentsComponent} from './payments/layout/payments/payments.component';
import {AdxInvoiceLineItemsComponent} from './invoices/component/adx-invoice-line-items/adx-invoice-line-items.component';
import {TramsInvoicesComponent} from './invoices/component/trams-invoices/trams-invoices.component';
import {AdxVccComponent} from './finance-resource/component/adx-vcc/adx-vcc.component';
import {FmsVccComponent} from './finance-resource/component/fms-vcc/fms-vcc.component';
import {InvoiceDetailComponent} from './invoices/layout/invoice-detail/invoice-detail.component';
import {InvoiceInfoComponent} from './invoices/component/invoice-info/invoice-info.component';
import {BookingUdidInfoComponent} from './invoices/component/popup/booking-udid-info/booking-udid-info.component';
import {AgentInfoComponent} from './invoices/component/popup/agent-info/agent-info.component';
import {FmsVccPayComponent} from './finance-resource/component/fms-vcc-pay/fms-vcc-pay.component';
import {PipesModule} from 'src/infrastructure/pipes/pipes.module';
import {AdxVccViewerComponent} from './virtual-credit-card/component/adx/viewer/adx-vcc-viewer.component';
import {VccComponent} from './dashboard/layout/vcc/components/vcc.component';
import {BankAccountModalComponent} from './dashboard/layout/vcc/bank-account-modal/bank-account-modal.component';
import {VccReportComponent} from './dashboard/layout/vcc/components/vcc-report/vcc-report.component';
import {TransactionHistoryDialog} from './finance-resource/component/transaction-history-dialog/transaction-history-dialog.component';
import {TransactionHistoryList} from './finance-resource/component/transaction-history-list/transaction-history-list.component';
import {MatchingVccChartComponent} from './dashboard/layout/vcc/components/matching-vcc-chart/matching-vcc-chart.component';
import {MatchingVccComponent} from './finance-resource/component/matching-vcc/matching-vcc.component';
import {CirculationVccChartComponent} from './dashboard/layout/vcc/components/circulation-vcc-chart/circulation-vcc-chart.component';
import {FundingVccChartComponent} from 'src/app/dashboard/layout/vcc/components/funding-vcc-chart/funding-vcc-chart.component';
import {ConfirmationDialogMatchingVccComnponent} from './finance-resource/component/confirmation-dialog-matching-vcc/confirmation-dialog-matching-vcc.component';
import {FinancialBalancesVccListComponent} from './dashboard/layout/vcc/components/financial-balances/financial-balances-vcc-list.component';
import {ConfirmationDialogRevertMatchingVccComnponent} from './finance-resource/component/confirmation-dialog-revert-matching-vcc/confirmation-dialog-revert-matching-vcc.component';
import {MatchingVccDetailComponent} from './finance-resource/component/matching-vcc-detail/matching-vcc-detail.component';
import {AggregatedVccChartComponent} from './dashboard/layout/vcc/components/aggregated-vcc-chart/aggregated-vcc-chart.component';
import {AlertsGridComponent} from './finance-resource/component/alerts/alerts.component';
import {TripListComponent} from './trip/components/trip-list/trip-list.component';
import {TripDetailsComponent} from './trip/components/trip-details/trip-details.component';
import {AdxInvoiceListComponent} from './adx-invoice/components/adx-invoice-list/adx-invoice-list.component';
import {TripInfoComponent} from './trip/components/trip-info/trip-info.component';
import {AdxInvoiceDetailsComponent} from './adx-invoice/components/adx-invoice-details/adx-invoice-details.component';
import {ClientListComponent} from './client/component/list/client-list.component';
import {ClientModalComponent} from './client/component/modal/client-modal.component';
import {ClientService} from './client/service/client.service';
import {ClientFilterModalComponent} from './client/component/filter-modal/client-filter-modal.component';
import {PaymentTabDetailComponent} from './payments/components/payment-tab-detail/payment-tab-detail';
import {TramsInvoiceComponent} from './invoices/component/layout/trams-invoice.component';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {AgentFilterModalComponent} from './agent/component/filter-modal/agent-filter-modal.component';
import {AgentListComponent} from './agent/component/list/agent-list.component';
import {AgentModalComponent} from './agent/component/modal/agent-modal.component';
import {AgentInfoModalComponent} from './agent/component/info-modal/agent-info-modal.component';
import {AgentService} from './agent/component/service/agent.service';
import {ConfirmationDialogDeleteAlertComnponent} from './finance-resource/component/confirmation-dialog-delete-alert/confirmation-dialog-delete-alert.component';
import {MERCHANTS_PROVIDER} from 'src/infrastructure/merchants/provider/merchants.provider';
import {BusinessErrorsDialog} from './finance-resource/component/business-errors-dialog/business-errors-dialog.component';
import {MatchingVccV2Component} from './finance-resource/component/matching-vcc-v2/matching-vcc-v2.component';
import {MatchingVccDetailV2Component} from './finance-resource/component/matching-vcc-detail-v2/matching-vcc-detail-v2.component';
import {ChatbotComponent} from "./open-ai/chatbot/chatbot.component";
import {PromptComponent} from "./open-ai/chatbot/prompt/prompt.component";
import {ConversationComponent} from "./open-ai/chatbot/conversation/conversation.component";
import {ConfirmationDialogCopyAdxBookingComponent} from './adx-booking/components/popup/confirmation-dialog/confirmation-dialog-copy-adxbooking.component';
import {HsProcessingComponent} from './trx-team-resource/components/hs-processing/hs-processing.component';
import {AdxInvoiceInfoComponent} from './adx-invoice/components/adx-invoice-info/adx-invoice-info.component';
import {AdxBookingListComponent} from './adx-booking/components/adx-booking-list/adx-booking-list.component';
import {AdxBookingDetailsComponent} from './adx-booking/components/adx-booking-details/adx-booking-details.component';
import {AdxBookingInfoComponent} from './adx-booking/components/adx-booking-info/adx-booking-info.component';
import {AdxAgentBookingInfoComponent} from './adx-agent-booking/components/adx-agent-booking-info/adx-agent-booking-info.component';
import {AdxBookingUdidInfoComponent} from './adx-booking-udid/components/adx-booking-udid-info/adx-booking-udid-info.component';
import {SegmentInfoComponent} from './invoices/component/popup/segment-info/segment-info.component';
import {AdxBookingDetailsModalComponent} from './adx-booking/components/adx-booking-details-modal/adx-booking-details-modal.component';
import {NewAdxBookingDialogComponent} from './adx-booking/components/popup/new-adx-booking-dialog/new-adx-booking-dialog.component';
import {PaymentsMadeGridComponent} from './payments/components/payments-made-grid/payments-made-grid.component';
import {PaymentsReceivedGridComponent} from './payments/components/payments-received-grid/payments-received-grid.component';
import {AdxBookingSegmentInfoComponent} from './adx-booking-segment/components/adx-booking-segment-info/adx-booking-segment-info.component';
import {AdxPaymentDetailsComponent} from './adx-payment/components/adx-payment-details/adx-payment-details.component';
import {AdxPaymentDetailsModalComponent} from './adx-payment/components/adx-payment-details-modal/adx-payment-details-modal.component';
import {AdxPaymentInfoComponent} from './adx-payment/components/adx-payment-info/adx-payment-info.component';
import {HistoryComponent} from './history/components/history/history.component';
import {GeneralLedgerComponent} from './general-ledger/layout/general-ledger.component';
import {ChartOfAccountsComponent} from './general-ledger/components/chart-of-accounts/chart-of-accounts.component';
import {ConfirmationDialogDeleteAdxBookingComponent} from './adx-booking/components/confirmation-dialog-delete-adx-booking/confirmation-dialog-delete-adx-booking.component';
import {GLTravelTypeMappingComponent} from './general-ledger/components/travel-type-mapping/travel-type-mapping.component';
import {AdxPaymentGridComponent} from './adx-payment/components/adx-payment-grid/adx-payment-grid.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  msalConfig.auth.clientId = environment.clientId;
  msalConfig.auth.authority = environment.authority;
  msalConfig.auth.redirectUri = environment.redirectUri;

  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(environment.apiUrl, [environment.apiUrl, environment.fmsApiScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ClientListComponent,
    ClientModalComponent,
    ClientFilterModalComponent,
    VendorViewerComponent,
    AddeditVendorComponent,
    VendorLayoutComponent,
    ConfirmationDialogComponent,
    VendorTemplateAddEditComponent,
    VendorTemplateLayoutComponent,
    ConfirmationDialogVendorTemplateComponent,
    FmsAllComponent,
    SearchVendorComponent,
    VendorViewerModalComponent,
    TermsConditionsRuleComponent,
    TemplateLayoutComponent,
    TemplateConfirmationDialogComponent,
    AddEditVendorModalComponent,
    RequestActionLayoutComponent,
    VendorTemplateAddEditModalComponent,
    TransactionBookingViewerComponent,
    TemplateViewerComponent,
    SearchTemplateComponent,
    TransactionBookingLayoutComponent,
    SearchTransactionBookingComponent,
    VendorListComponent,
    TransactionBookingListComponent,
    TransactionBookingViewerModalComponent,
    FileUploadComponent,
    AhtWeeklyCalculationComponent,
    UtilizationWeeklyCalculationComponent,
    TicketsSummaryListComponent,
    TransactionsComponent,
    FinanceComponent,
    ChatbotComponent,
    PromptComponent,
    ConversationComponent,
    VccComponent,
    DashboardComponent,
    SummaryListComponent,
    BookingTemplateAddEditComponent,
    BookingTemplateLayoutComponent,
    ConfirmationDialogBookingTemplateComponent,
    CommissionReportComponent,
    CommissionQueueComponent,
    PaymentQueueComponent,
    TripProfitabilityComponent,
    VccDailySummaryReportComponent,
    FinanceReportComponent,
    NoAccessComponent,
    TransactionBookingTCViewerComponent,
    VendorTCViewerComponent,
    TransactionBookingTCViewerComponent,
    HomeLayoutComponent,
    SummaryListByServiceTypeComponent,
    TripProfitabilityWithAgencyComponent,
    TraceComponent,
    ConfirmationDialogIssueVccComponent,
    ConfirmationDialogUpdateVccComponent,
    VccViewerComponent,
    VccPaymentGatewayViewerComponent,
    ConfirmationDialogDeleteVccComponent,
    InvoicesComponent,
    AdxInvoiceLineItemsComponent,
    TramsInvoicesComponent,
    PaymentsComponent,
    FmsVccComponent,
    FmsVccPayComponent,
    InvoiceDetailComponent,
    InvoiceInfoComponent,
    BookingUdidInfoComponent,
    AgentInfoComponent,
    SegmentInfoComponent,
    HistoryComponent,
    AdxVccComponent,
    AdxVccViewerComponent,
    BankAccountModalComponent,
    VccReportComponent,
    TransactionHistoryDialog,
    TransactionHistoryList,
    MatchingVccChartComponent,
    MatchingVccComponent,
    CirculationVccChartComponent,
    FundingVccChartComponent,
    ConfirmationDialogMatchingVccComnponent,
    FinancialBalancesVccListComponent,
    ConfirmationDialogRevertMatchingVccComnponent,
    MatchingVccDetailComponent,
    AggregatedVccChartComponent,
    AlertsGridComponent,
    TripListComponent,
    TripDetailsComponent,
    AdxInvoiceListComponent,
    TripInfoComponent,
    AdxInvoiceDetailsComponent,
    PaymentTabDetailComponent,
    TramsInvoiceComponent,
    AgentFilterModalComponent,
    AgentListComponent,
    AgentModalComponent,
    AgentInfoModalComponent,
    ConfirmationDialogDeleteAlertComnponent,
    BusinessErrorsDialog,
    MatchingVccV2Component,
    MatchingVccDetailV2Component,
    ConfirmationDialogCopyAdxBookingComponent,
    HsProcessingComponent,
    AdxInvoiceInfoComponent,
    AdxBookingListComponent,
    AdxBookingDetailsComponent,
    AdxBookingInfoComponent,
    AdxAgentBookingInfoComponent,
    AdxBookingUdidInfoComponent,
    AdxBookingDetailsModalComponent,
    NewAdxBookingDialogComponent,
    PaymentsMadeGridComponent,
    PaymentsReceivedGridComponent,
    AdxBookingSegmentInfoComponent,
    AdxPaymentDetailsComponent,
    AdxPaymentDetailsModalComponent,
    AdxPaymentInfoComponent,
    GeneralLedgerComponent,
    ChartOfAccountsComponent,
    ConfirmationDialogDeleteAdxBookingComponent,
    GLTravelTypeMappingComponent,
    AdxPaymentGridComponent,
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    CommonModule,
    NgOptimizedImage,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    BrowserAnimationsModule,
    AngularMaterialDesignModule,
    FormsModule,
    ComponentsModule,
    SharedModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MatTabsModule,
    PipesModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  exports: [
    ClientFilterModalComponent,
    ClientListComponent
  ],
  providers: [
    HEALTH_CHECK_PROVIDER(),
    MERCHANTS_PROVIDER(),
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    ExportGridService,
    ClientService,
    AgentService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AzureAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiVersionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProblemDetailsResponseInterceptor,
      multi: true
    },
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule {
}
