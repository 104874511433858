import {Component, Optional, OnInit, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: []
})
export class AgentInfoComponent implements OnInit {
    @Input() public data: any;
    _agent: any;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AgentInfoComponent>,
  ) {
  }

  ngOnInit(): void {
    this.getAgentInfo();
  }

  getAgentInfo() {
    if (this.data != null){
      this._agent = this.data;
    }
  }

  public formatAddress(address: any): string {
    if (!address) {
      return '';
    }
    const addressLine1 = address.addressLine1 || '';
    const city = address.city || '';
    const zipCode = address.zipCode || '';
    return `${addressLine1}, ${city}, ${zipCode}`.trim();
  }
}
