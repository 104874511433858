<modal-panel data-testid="client-filter-modal-panel" class="client-filter-modal-panel-cls" [title]="'Filter'" (close)="handleClientFiltersFormCloseEvent()">
  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Client Name</mat-label>
        <input matInput formControlName="clientName" data-testid="client-filter-modal-panel-client-name-input">
        <button *ngIf="form.controls['clientName'].value" matSuffix mat-icon-button (click)="clearClientFilters('clientName')" data-testid="client-filter-modal-panel-client-name-clear-button">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Agent Name</mat-label>
        <input matInput formControlName="agentName" data-testid="client-filter-modal-panel-agent-name-input">
        <button *ngIf="form.controls['agentName'].value" matSuffix mat-icon-button (click)="clearClientFilters('agentName')" data-testid="client-filter-modal-panel-agent-name-clear-button">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end" style="display: flex; justify-content: space-between;">
    <button mat-stroked-button color="primary" (click)="clearClientFilters('all')" style="margin-right: auto;" data-testid="client-filter-modal-panel-clear-all-button">
      <mat-icon>delete_outline</mat-icon>
      Clear All
    </button>
    <button mat-raised-button color="primary" (click)="applyClientFilters()" data-testid="client-filter-modal-panel-apply-button">Apply</button>
  </mat-dialog-actions>
</modal-panel>
