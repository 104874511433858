import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReconciliationModel } from '../../models/client-reconciliation';
import { AdxPaymentService } from '../../service/adx-payment.service';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { MaskedCardPipe } from 'src/infrastructure/pipes/masked-card.pipe';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import { MatSnackBar } from '@angular/material/snack-bar';

@AutoUnsubscribe()
@Component({
  selector: 'client-payments-edit-modal',
  templateUrl: './client-payments-edit-modal.component.html',
  styleUrls: ['./client-payments-edit-modal.component.scss']
})
export class ClientPaymentsEditModalComponent implements OnInit {

  displayedColumns: string[] = [
    'adxInvoiceId',
    'adxBookingId',
    'adxBookingTravelTypeName',
    'adxBookingVendorName',
    'totalFare',
    'vendorPaymentMadeAmt',
    'clientPaymentRecievedAmt'
  ];

  loadedData = false;
  submitedData = true;
  dataSource: any[] = [];
  formControlNames: { [key: string]: { totalFare: string, vendorPaymentMadeAmt: string, clientPaymentRecievedAmt: string } } = {};

  formChanges$?: Subscription;
  dynamicFormGroup?: FormGroup;
  initialValues: Record<string, string> = {};
  hasChanges = false;

  amountPattern = /^-?\d{1,10}(\.\d{1,4})?$/;

  pagination: Partial<PageEvent & { totalItemCount?: number }> = {};
  sorting: Partial<Sort> = {};

  constructor(
    @Optional() public dialogRef: MatDialogRef<ClientPaymentsEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReconciliationModel,
    private adxPaymentService: AdxPaymentService,
    private maskedCardPipe: MaskedCardPipe,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if (!this.data) {
      this.dialogRef.close();
    }

    this.pagination = {
      pageSize: this.pagination.pageSize || 25,
      pageIndex: 0,
      totalItemCount: 0,
    };

    this.getItems();
  }

  buildForm(): void {
    const formControllers = this.getFormControlers();
    this.dynamicFormGroup = new FormGroup(formControllers);

    this.dataSource.forEach((payment) => {
      const adxBookingId = payment.adxBookingId;

      if (!this.formControlNames[adxBookingId]) {
        this.formControlNames[adxBookingId] = {
          totalFare: `totalFare_${adxBookingId}`,
          vendorPaymentMadeAmt: `vendorPaymentMadeAmt_${adxBookingId}`,
          clientPaymentRecievedAmt: `clientPaymentRecievedAmt_${adxBookingId}`
        };
      }

      const formControlNames = this.formControlNames[adxBookingId];

      if (this.dynamicFormGroup) {
        this.initialValues[`totalFare_${adxBookingId}`] = payment.totalFare;
        this.initialValues[`vendorPaymentMadeAmt_${adxBookingId}`] = payment.vendorPaymentMadeAmt;
        this.initialValues[`clientPaymentRecievedAmt_${adxBookingId}`] = payment.clientPaymentRecievedAmt;

        this.dynamicFormGroup.addControl(formControlNames.totalFare, new FormControl(payment.totalFare));
        this.dynamicFormGroup.addControl(formControlNames.vendorPaymentMadeAmt, new FormControl(payment.vendorPaymentMadeAmt));
        this.dynamicFormGroup.addControl(formControlNames.clientPaymentRecievedAmt, new FormControl(payment.clientPaymentRecievedAmt));
      }
    });
  }

  getFormControlers(): Record<string, FormControl> {
    const formList: Record<string, FormControl> = {};
    this.dataSource.forEach((payment) => {
      const adxBookingId = payment.adxBookingId;
      formList[`totalFare_${adxBookingId}`] = new FormControl('', Validators.pattern(this.amountPattern));
      formList[`vendorPaymentMadeAmt_${adxBookingId}`] = new FormControl('', Validators.pattern(this.amountPattern));
      formList[`clientPaymentRecievedAmt_${adxBookingId}`] = new FormControl('', Validators.pattern(this.amountPattern));
    });

    return formList;
  }

  handleSortChange(sort: Sort): void {
    this.sorting = sort;

    if (!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = {};
    }

    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  getItems(): void {
    this.loadedData = false;
    console.log(this.data.reconType);
    this.adxPaymentService.searchAdxReconciliation({
      filters: {
        ccNoLastFourDigits: this.maskedCardPipe.transform(this.data.lastFour, ''),
        tripExternalReference: this.data.adxReference,
        vendorName: this.data.vendorName,
        reconType: this.data.reconType
      },
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((data: { items: any[]; pagination: { pageNumber: number; pageSize: any; totalItemCount: any; }; }) => {
      this.dataSource = [];
      data?.items.forEach((item: any) => {
        this.dataSource.push(item);
      });
      this.pagination = {
        ...this.pagination,
        pageIndex: data.pagination?.pageNumber ? data.pagination.pageNumber - 1 : 0,
        pageSize: data.pagination?.pageSize || this.pagination.pageSize || 25,
        totalItemCount: data.pagination?.totalItemCount || this.dataSource.length,
      };
      this.loadedData = true;
      this.buildForm();
    });
  }

  isDisabled(): boolean {
    return !this.dataSource.some((payment) => {
      const adxBookingId = payment.adxBookingId;
      const formControlNames = this.formControlNames[adxBookingId];

      const totalFareValue = this.dynamicFormGroup?.get(formControlNames.totalFare)?.value;
      const vendorPaymentMadeAmtValue = this.dynamicFormGroup?.get(formControlNames.vendorPaymentMadeAmt)?.value;
      const clientPaymentRecievedAmtValue = this.dynamicFormGroup?.get(formControlNames.clientPaymentRecievedAmt)?.value;

      const initialTotalFare = Number(this.initialValues[`totalFare_${adxBookingId}`]);
      const initialVendorPaymentMadeAmt = Number(this.initialValues[`vendorPaymentMadeAmt_${adxBookingId}`]);
      const initialClientPaymentRecievedAmt = Number(this.initialValues[`clientPaymentRecievedAmt_${adxBookingId}`]);

      const totalFare = totalFareValue !== '' && totalFareValue !== null ? Number(totalFareValue) : initialTotalFare;
      const vendorPaymentMadeAmt = vendorPaymentMadeAmtValue !== '' && vendorPaymentMadeAmtValue !== null ? Number(vendorPaymentMadeAmtValue) : initialVendorPaymentMadeAmt;
      const clientPaymentRecievedAmt = clientPaymentRecievedAmtValue !== '' && clientPaymentRecievedAmtValue !== null ? Number(clientPaymentRecievedAmtValue) : initialClientPaymentRecievedAmt;

      return totalFare !== initialTotalFare ||
        vendorPaymentMadeAmt !== initialVendorPaymentMadeAmt ||
        clientPaymentRecievedAmt !== initialClientPaymentRecievedAmt;
    });
  }

  updateAmounts(): void {
    this.submitedData = false;
    const updates$: Observable<any>[] = [];

    const changedPayments = this.dataSource.filter((payment) => {
      const adxBookingId = payment.adxBookingId;
      const formControlNames = this.formControlNames[adxBookingId];

      const totalFareValue = this.dynamicFormGroup?.get(formControlNames.totalFare)?.value;
      const vendorPaymentMadeAmtValue = this.dynamicFormGroup?.get(formControlNames.vendorPaymentMadeAmt)?.value;
      const clientPaymentRecievedAmtValue = this.dynamicFormGroup?.get(formControlNames.clientPaymentRecievedAmt)?.value;

      const initialTotalFare = Number(this.initialValues[`totalFare_${adxBookingId}`]);
      const initialVendorPaymentMadeAmt = Number(this.initialValues[`vendorPaymentMadeAmt_${adxBookingId}`]);
      const initialClientPaymentRecievedAmt = Number(this.initialValues[`clientPaymentRecievedAmt_${adxBookingId}`]);

      const totalFare = totalFareValue !== '' && totalFareValue !== null ? Number(totalFareValue) : initialTotalFare;
      const vendorPaymentMadeAmt = vendorPaymentMadeAmtValue !== '' && vendorPaymentMadeAmtValue !== null ? Number(vendorPaymentMadeAmtValue) : initialVendorPaymentMadeAmt;
      const clientPaymentRecievedAmt = clientPaymentRecievedAmtValue !== '' && clientPaymentRecievedAmtValue !== null ? Number(clientPaymentRecievedAmtValue) : initialClientPaymentRecievedAmt;

      var compare =  totalFare !== initialTotalFare ||
        vendorPaymentMadeAmt !== initialVendorPaymentMadeAmt ||
        clientPaymentRecievedAmt !== initialClientPaymentRecievedAmt;

      return compare;
    });

    changedPayments.forEach((payment) => {
      const adxBookingId = payment.adxBookingId;
      const formControlNames = this.formControlNames[adxBookingId];

      const totalFareValue = this.dynamicFormGroup?.get(formControlNames.totalFare)?.value;
      const vendorPaymentMadeAmtValue = this.dynamicFormGroup?.get(formControlNames.vendorPaymentMadeAmt)?.value;
      const clientPaymentRecievedAmtValue = this.dynamicFormGroup?.get(formControlNames.clientPaymentRecievedAmt)?.value;

      const initialTotalFare = Number(this.initialValues[`totalFare_${adxBookingId}`]);
      const initialVendorPaymentMadeAmt = Number(this.initialValues[`vendorPaymentMadeAmt_${adxBookingId}`]);
      const initialClientPaymentRecievedAmt = Number(this.initialValues[`clientPaymentRecievedAmt_${adxBookingId}`]);

      const totalFare = totalFareValue !== '' && totalFareValue !== null ? Number(totalFareValue) : initialTotalFare;
      const vendorPaymentMadeAmt = vendorPaymentMadeAmtValue !== '' && vendorPaymentMadeAmtValue !== null ? Number(vendorPaymentMadeAmtValue) : initialVendorPaymentMadeAmt;
      const clientPaymentRecievedAmt = clientPaymentRecievedAmtValue !== '' && clientPaymentRecievedAmtValue !== null ? Number(clientPaymentRecievedAmtValue) : initialClientPaymentRecievedAmt;
 
      updates$.push(
          this.adxPaymentService.adjustmentAdxPaymentReconciliation({
            adxBookingId: payment.adxBookingId,
            amount: totalFare !== initialTotalFare? totalFare - initialTotalFare : null,
            adxTripId: this.dataSource[0].tripId,
            bankId: this.data.fmsBankId,
            branchId: this.data.fmsBranchId,
            ckCcNo: this.data.originalCkCcNo,
            payeeName: this.data.fmsPayeeName,
            vendorPaymentMadeAmt: vendorPaymentMadeAmt !== initialVendorPaymentMadeAmt ? vendorPaymentMadeAmt - initialVendorPaymentMadeAmt : null,
            clientPaymentRecievedAmt: clientPaymentRecievedAmt !== initialClientPaymentRecievedAmt ? clientPaymentRecievedAmt - initialClientPaymentRecievedAmt : null
          })
        );
      }
    );

    if (updates$.length > 0) {
      forkJoin(updates$).subscribe({
        next: () => {
          this.snackBar.open('Service adjusted successfully', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
          });
          this.getItems();
          this.submitedData = true;
        },
        error: () => {
          this.submitedData = true;
        }
      });
    } else {
      this.snackBar.open('No changes detected', 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      this.submitedData = true;
    }
  }


  closeModal(): void {
    this.dialogRef.close();
  }
}
