<form class="filter" [formGroup]="formSendModal" (submit)="submitForm()">
    <modal-panel
        data-testid="nav-export-send-modal-panel"
        class="nav-export-send-modal-panel-cls filter-panel"
        title="Send to Nav"
        (close)="closeModal();"
        >
            <div data-testid="nav-export-send-filter-modal-div" class="filter__fields">
                <mat-grid-list class="modal-filter-col-flex" [cols]="1" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>User Name</mat-label>
                            <input matInput placeholder="User Name" value="" formControlName="userName">
                            @if(formSendModal.controls['userName'].value){
                                <button 
                                    data-testid="nav-export-send-user-name-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFormFields('userName')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Password</mat-label>
                            <input matInput placeholder="Password" value="" [type]="showPassword ? 'text' : 'password'" formControlName="password">
                            <mat-icon matSuffix (click)="togglePasswordVisibility()">
                                {{ showPassword ? 'visibility' : 'visibility_off' }}
                            </mat-icon>
                            @if(formSendModal.controls['password'].value){
                                <button 
                                    data-testid="nav-export-send-password-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFormFields('password')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Batch</mat-label>
                            <input matInput placeholder="Batch" value="" formControlName="batch">
                            @if(formSendModal.controls['batch'].value){
                                <button 
                                    data-testid="nav-export-send-batch-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFormFields('batch')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>

            <ng-container data-testid="nav-export-send-modal-footer-container" modalPanelFooter>
                <div class="flex-end">
                    <button data-testid="nav-export-send-modal-button" mat-raised-button color="primary" type="submit" [disabled]="formSendModal.invalid">Send</button>
                </div>
            </ng-container>
    </modal-panel>
</form>