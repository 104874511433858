import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable, signal} from '@angular/core';
import {
  ClientBalanceFilterParams,
  ClientBalanceResponse,
} from '../models/client-balance';

@Injectable({
  providedIn: 'root',
})
export class ClientBalanceFilterService {
  private readonly httpClient = inject(HttpClient);

  public readonly triggerModal = signal(false);

  public listClientBalances(queryParams: ClientBalanceFilterParams) {
    const params = new HttpParams({ fromObject: { ...queryParams } });
    return this.httpClient.get<ClientBalanceResponse>('dbov2/client-balances', {
      params,
    });
  }
}
