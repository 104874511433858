<div class="grid-title-event">
    <h2 data-testid="chart-of-accounts-title">Chart of Accounts</h2>
        <div class="events-container">
            <button  data-testid="chart-of-accounts-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="chart-of-accounts-add-gl-button" aria-label="Create GL" mat-stroked-button (click)="opanAddNewGLModal()">
                <mat-icon fontIcon="note_add"></mat-icon> Add New GL
            </button>
            @if (items.length){
                <button class="btn-download" color="primary" data-testid="chart-of-accounts-download-button" aria-label="Download Chart Of Accounts File" mat-stroked-button (click)="downloadGridToFile()">
                    <mat-icon>download</mat-icon>
                </button>
            }
        </div>

</div>
<mat-table data-testid="chart-of-accounts-table" class="compact-mat-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>
    <ng-container matColumnDef="id">
        <mat-header-cell data-testid="char-of-accounts-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            id
        </mat-header-cell>
        <mat-cell data-testid="char-of-accounts-id-cell" *matCellDef="let element">
            <span>{{element?.id}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="glNo">
        <mat-header-cell data-testid="char-of-accounts-glNo-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            GL Number
        </mat-header-cell>
        <mat-cell data-testid="char-of-accounts-glNo-cell" *matCellDef="let element">
            <span>{{element?.glNo}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="glName">
        <mat-header-cell data-testid="char-of-accounts-glName-header" class="mat-header-cell mat-column-extra-width" *matHeaderCellDef mat-sort-header>                
            GL Name
        </mat-header-cell>
        <mat-cell data-testid="char-of-accounts-glName-cell" class="mat-column-extra-width mat-column-break-word" *matCellDef="let element">
            <span class="mat-column-wrap">{{element?.glName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="glType">
        <mat-header-cell data-testid="char-of-accounts-glType-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            GL Type
        </mat-header-cell>
        <mat-cell data-testid="char-of-accounts-glType-cell" *matCellDef="let element">
            <span>{{element?.glType}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
        <mat-header-cell data-testid="char-of-accounts-dateCreated-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Date Created
        </mat-header-cell>
        <mat-cell data-testid="char-of-accounts-dateCreated-cell" *matCellDef="let element">
            <span>{{element?.dateCreated | date:'M/dd/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userCreated">
        <mat-header-cell data-testid="char-of-accounts-userCreated-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            User Created
        </mat-header-cell>
        <mat-cell data-testid="char-of-accounts-userCreated-cell" *matCellDef="let element">
            <span>{{element?.userCreated}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateUpdated">
        <mat-header-cell data-testid="char-of-accounts-dateUpdated-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Date Updated
        </mat-header-cell>
        <mat-cell data-testid="char-of-accounts-dateUpdated-cell" *matCellDef="let element">
            <span>{{element?.dateUpdated | date:'M/dd/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userupdated">
        <mat-header-cell data-testid="char-of-accounts-userUpdated-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            User Updated
        </mat-header-cell>
        <mat-cell data-testid="char-of-accounts-userUpdated-cell" *matCellDef="let element">
            <span>{{element?.userUpdated}}</span>
        </mat-cell>
    </ng-container>

    @if(isManagerOrFinance()){
        <ng-container matColumnDef="action">
            <mat-header-cell data-testid="char-of-accounts-action-header" class="mat-header-cell" *matHeaderCellDef>
                Action
            </mat-header-cell>
            <mat-cell data-testid="char-of-accounts-action-cell" *matCellDef="let element">
                <button mat-icon-button aria-label="delete alert" (click)="deleteRecord(element)">
                    <mat-icon fontIcon="delete"/>
                </button>
            </mat-cell>
        </ng-container>
    }

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index">
    </mat-row>
</mat-table>
<mat-paginator data-testid="char-of-accounts-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>