import { Component } from '@angular/core';

@Component({
  selector: 'app-arc-bsp-balance',
  standalone: true,
  imports: [],
  templateUrl: './arc-bsp-balance.component.html'
})
export class ArcBspBalanceComponent {

}
