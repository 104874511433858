import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BraintreeMatchedVccFilter } from '../../model/braintree-matching-vcc';
import { BraintreeMatchingVccService } from '../../service/braintree-matching-vcc.service';

@AutoUnsubscribe()
@Component({
  selector: 'braintree-matching-vcc-filter',
  templateUrl: './braintree-matching-vcc-filter.component.html',
})
export class BraintreeMatchingVccFilterComponent implements OnInit {
  @Input() config!: BraintreeMatchedVccFilter;

  braintreeMatchingFilterForm!: FormGroup;

  breakPoint$?: Subscription;
  isSmall: boolean = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<BraintreeMatchingVccFilterComponent>,
    private formBuilder: FormBuilder,
    private braintreeMatchingVccService: BraintreeMatchingVccService,
    breakpointObserver: BreakpointObserver,
  ) {
    this.braintreeMatchingFilterForm = this.formBuilder.group({
      releasedDateFrom: null,
      releasedDateTo: null,
      lastFourCC: null,
      adxTripReference: null,
      tramsInvoiceNumber: null,
      vendorName: null,
      isMatched: null,
    });

    this.breakPoint$ = breakpointObserver.observe([
      Breakpoints.Medium,
      Breakpoints.Small,
      Breakpoints.XSmall
    ]).subscribe((result: { matches: boolean; }) => {
      this.isSmall = result.matches;
    });
  }

  ngOnInit(): void {
    this.braintreeMatchingVccService.getBraintreeMatchingFilter().subscribe((filter: BraintreeMatchedVccFilter) => {
      this.config = filter;
      this.braintreeMatchingFilterForm.patchValue(this.config || {});
    });
  }

  applyBaintreeMatchingFilters() {
    const formValue = this.braintreeMatchingFilterForm.value;
    this.config = {
      ...formValue,
      releasedDateFrom: formValue.releasedDateFrom,
      releasedDateTo: formValue.releasedDateTo
    } as BraintreeMatchedVccFilter;

    this.config.tramsInvoiceNumber = this.config.tramsInvoiceNumber?.trim();
    this.config.adxTripReference = this.config.adxTripReference?.trim();
    this.config.vendorName = this.config.vendorName?.trim();
    console.log(this.config);
    this.braintreeMatchingVccService.setBraintreeMatchingFilter(this.config);

    this.dialogRef.close(this.config);
  }

  closeForm() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  clearMatchingFields(...args: string[]) {
    if (!args.length) {
      this.braintreeMatchingFilterForm.reset();
      return;
    }
    args.forEach((field) => {
      this.braintreeMatchingFilterForm.controls[field].reset();
    });
  }

  checkInput(event: KeyboardEvent): void {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
}
