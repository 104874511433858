import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, catchError, Observable, map } from "rxjs";
import { removeEmptyParams } from "src/infrastructure/helper/remove-empty-params";
import { SearchVendorBalanceParams, VendorBalanceFilter, VendorBalenceResponse } from "../models/vendor-balances";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class VendorBalanceService {

  private _dataSourceVendorBalance$ = new BehaviorSubject<VendorBalanceFilter | {}>({
    vendorPayStatusId: 3
  });

  constructor(private http: HttpClient) { }

  getVendorBalanceFilter(): Observable<VendorBalanceFilter | {}> {
    return this._dataSourceVendorBalance$.asObservable();
  }

  setVendorBalanceFilter(value: VendorBalanceFilter): void {
    this._dataSourceVendorBalance$.next(value || null);
  }

  searchVendorBalance({ filters, page, sort }: SearchVendorBalanceParams): Observable<VendorBalenceResponse> {
    const params: Record<string, string | number | boolean> = {
      pageSize: page?.pageSize || 25,
      pageNumber: (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',

      paymentDate: filters?.paymentDate ? moment(filters?.paymentDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      vendorName: filters?.vendorName ?? '',
      minOutstandingBalance: filters?.minOutstandingBalance ?? '',
      maxOutstandingBalance: filters?.maxOutstandingBalance ?? '',
      minTotalBilled: filters?.minTotalBilled ?? '',
      maxTotalBilled: filters?.maxTotalBilled ?? '',
      dataSourceId: filters?.dataSourceId ?? '',
      vendorPayStatusId: filters?.vendorPayStatusId ?? '',
    };

    removeEmptyParams(params);

    return this.http.get<VendorBalenceResponse>('dbov2/vendor-balances',
      { params: { ...params } }
    ).pipe(catchError((error: HttpErrorResponse) => {
      throw new Error(error?.message || 'Server error');
    }));
  }

  downloadVendorBalancePdf({ filters, page, sort }: SearchVendorBalanceParams): Observable<void> {
    const params: Record<string, string | number | boolean> = {
      exportToPdf: true,
      format: 'pdf',
      pageSize: page?.pageSize || 25,
      pageNumber: (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',

      paymentDate: filters?.paymentDate ? moment(filters?.paymentDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      vendorName: filters?.vendorName ?? '',
      minOutstandingBalance: filters?.minOutstandingBalance ?? '',
      maxOutstandingBalance: filters?.maxOutstandingBalance ?? '',
      minTotalBilled: filters?.minTotalBilled ?? '',
      maxTotalBilled: filters?.maxTotalBilled ?? '',
      dataSourceId: filters?.dataSourceId ?? '',
      vendorPayStatusId: filters?.vendorPayStatusId ?? '',
    };

    removeEmptyParams(params);

    return this.http.get<{ pdfBase64: string }>('dbov2/vendor-balances', {
      params: { ...params }
    }).pipe(
      map(response => {
        if (response && response.pdfBase64) {
          console.log('PDF Base64 content received:', response.pdfBase64.substring(0, 100)); // Log the first 100 characters
          this.savePdfFromBase64(response.pdfBase64, 'vendor-balance.pdf');
          return;
        } else {
          throw new Error('Invalid PDF response from server');
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('PDF download error:', error);
        throw new Error(error?.message || 'PDF generation failed');
      })
    );
  }

  /**
   * Converts a Base64 string to a Blob and triggers download
   * @param base64String The Base64 encoded PDF content
   * @param fileName The name to use for the downloaded file
   */
  private savePdfFromBase64(base64String: string, fileName: string): void {
    try {
      console.log('Starting PDF conversion process');
      // Remove data URI prefix if present
      const base64Content = base64String.replace(/^data:application\/pdf;base64,/, "");
      console.log('Base64 content prepared, length:', base64Content.length);

      // Convert Base64 to Blob
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      console.log('Created PDF blob, size:', blob.size);

      // Create download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName || 'document.pdf';
      console.log('Download link created, triggering click');

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        console.log('Download cleanup completed');
      }, 100);
    } catch (error) {
      console.error('Error processing PDF:', error);
    }
  }
}

