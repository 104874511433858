<div class="grid-title-event">
    <h2 data-testid="travel-type-mapping-title">Travel Type Mapping</h2>

        <div class="events-container">
            <button  data-testid="travel-type-mapping-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="travel-type-mapping-new-mapping-button" aria-label="New Mapping" mat-stroked-button (click)="openNewMappingModal()">
                Add New Mapping
            </button>
            @if (items.length){
                <button class="btn-download" color="primary" data-testid="travel-type-mapping-download-button" aria-label="Download Travel Type Mapping File" mat-stroked-button (click)="downloadGridToFile()">
                    <mat-icon>download</mat-icon>
                </button>
            }
        </div>
</div>
<mat-table data-testid="travel-type-mapping-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>
    <ng-container matColumnDef="id">
        <mat-header-cell data-testid="travel-type-mapping-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            id
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-id-cell" *matCellDef="let element">
            <span>{{element?.id}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="glNo">
        <mat-header-cell data-testid="travel-type-mapping-gl-no-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            GL Number
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-gl-no-cell" *matCellDef="let element">
            <span>{{element?.generalLedger?.glNo}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="glName">
        <mat-header-cell data-testid="travel-type-mapping-gl-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            GL Name
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-gl-name-cell" *matCellDef="let element">
            <span>{{element?.generalLedger?.glName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="travelTypeName">
        <mat-header-cell data-testid="travel-type-mapping-travel-type-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Travel Type Name
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-travel-type-name-cell" *matCellDef="let element">
            <span>{{element?.travelType?.travelTypeName}}</span>
        </mat-cell>
    </ng-container>


    <ng-container matColumnDef="dateCreated">
        <mat-header-cell data-testid="travel-type-mapping-date-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Date Created
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-date-created-cell" *matCellDef="let element">
            <span>{{element?.dateCreated | date:'M/dd/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userCreated">
        <mat-header-cell data-testid="travel-type-mapping-user-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            User Created
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-user-created-cell" *matCellDef="let element">
            <span>{{element?.userCreated}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateUpdated">
        <mat-header-cell data-testid="travel-type-mapping-date-updated-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Date Updated
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-date-updated-cell" *matCellDef="let element">
            <span>{{element?.dateUpdated | date:'M/dd/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userupdated">
        <mat-header-cell data-testid="travel-type-mapping-user-updated-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            User Updated
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-user-updated-cell" *matCellDef="let element">
            <span>{{element?.userUpdated}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
        <mat-header-cell data-testid="travel-type-mapping-action-header" class="mat-header-cell" *matHeaderCellDef>
            Action
        </mat-header-cell>
        <mat-cell data-testid="travel-type-mapping-action-cell" *matCellDef="let element">
            <button mat-icon-button aria-label="edit mapping" (click)="editMappingModal(element)">
                <mat-icon fontIcon="edit"/>
            </button>
            <button mat-icon-button aria-label="delete mapping" (click)="deleteMappingModal(element)">
                <mat-icon fontIcon="delete"/>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index">
    </mat-row>
</mat-table>
<mat-paginator data-testid="travel-type-mapping-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>