import {Component, ViewChild} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {MatchingVccComponent} from '../matching-vcc/matching-vcc.component';
import {ComponentType} from '@angular/cdk/portal';
import {MatDialogRef} from '@angular/material/dialog';

@AutoUnsubscribe()
@Component({
  selector: 'matching-vcc',
  templateUrl: './matching-vcc-v2.component.html',
})
export class MatchingVccV2Component extends MatchingVccComponent {

  override displayedColumns: Array<string> = [
    'clientName',
    'orderNumber',
    'merchantName',
    'releasedDate',
    'connexPayAccountingDailyDetailAmount',
    'tramsBookingTotalFare',
    'tramsBookingCommAmt',
    'cogs',
    'varianceAmount',
    'revertButton',
  ];

  matDialogRef?:MatDialogRef<unknown>;

  @ViewChild('modalDetailRef') modalDetailRef !: ComponentType<unknown>;

  openDetailDialog(vccId?: string): void {
    if(vccId){
      this.matDialogRef = this['modalService'].open(this.modalDetailRef,{
        width: '50vw',
        data: vccId,
      });
    }
  }

  closeDetailDialog(): void {
    this.matDialogRef?.close();
  }
}
