import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AdxVccService} from '../../service/adx-vcc.service';
import {AdxVccResponse} from '../../model/adx-data';

@Component({
    selector: 'transaction-history-details',
    templateUrl: './transaction-history-details.component.html',
})

@AutoUnsubscribe()
export class TransactionHistoryDetailsComponent implements OnInit  {
    @Input() data!: any;
    @Input() title!: string;
    guid!: any;
    vcc!: any;

    isLoaderVisible = true;

    constructor(
        private route: ActivatedRoute,
        private adxVccService: AdxVccService
    ){
    }

    public ngOnInit(): void {
        if (this.data == null) {
            this.route.paramMap.subscribe(params => {
                this.guid = params.get('guid');
                this.vcc = this.getVccInformation();
                this.title = 'Virtual Credit Card Information'
            });
        }
        else {
            this.guid = this.data.guid;
            this.vcc = this.data.vcc;
        }
    }

  getVccInformation(){
    this.adxVccService
        .getAdxVccByCardGuid(this.guid)
        .subscribe((data: AdxVccResponse) => {
            this.vcc = data;
            this.vcc.isPage = this.data == null;
        });
  }

  handleLoadState(event:boolean){
        this.isLoaderVisible = event;
  }
}