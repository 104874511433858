import { Component } from '@angular/core';

@Component({
  selector: 'app-vendor-balance',
  standalone: true,
  imports: [],
  templateUrl: './vendor-balance.component.html'
})
export class VendorBalanceComponent {

}
