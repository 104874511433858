<div id="issue-vcc-dialog-confirmpopup" class="confirmpopup">
    <div id="issue-vcc-dialog-dialog-title" mat-dialog-title>
      <label id="issue-vcc-dialog-title-label" class="title">Issue VCC: Confirmation</label>
    </div>
    <hr id="issue-vcc-dialog-first-hr">
    <div id="issue-vcc-dialog-dialog-content" mat-dialog-content>
      <p id="issue-vcc-dialog-content-p">Are you sure you want to issue virtual credit card for the selected service '{{data.adxBookingId}}'?</p>
    </div>
    <hr id="issue-vcc-dialog-second-hr">
    <div id="issue-vcc-dialog-dialog-actions" mat-dialog-actions style="float: right;">
      <button
        id="issue-vcc-dialog-ok-button"
        type="submit" mat-raised-button
        (click)="okClick()"
        color="primary"
        cdkFocusInitial matTooltip="Issue Virtual Credit Card"
        aria-label="Issue Virtual Credit Card">
      <mat-spinner
        id="issue-vcc-dialog-spinner"
        color="accent"
        *ngIf="isLoadingSpinner"
        diameter="30">
      </mat-spinner>
        <span id="issue-vcc-dialog-ok-span" *ngIf="!isLoadingSpinner">Ok</span>
      </button>
      <button
        id="issue-vcc-dialog-no-button"
        mat-raised-button
        (click)="onNoClick()"
        color="warn">
        No Thanks
      </button>
    </div>
  </div>
