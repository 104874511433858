import {Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AdxBookingResponse} from 'src/app/adx-booking/model/adx-booking-response';
import {AdxBookingService} from 'src/app/adx-booking/service/adx-booking.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'trip-reference-details',
  templateUrl: './trip-reference-details.component.html'
})

@AutoUnsubscribe()
export class TripReferenceDetailsComponent {
  @Input() public data: any;
  travelServiceId: any;
  _booking: any;

  constructor(
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private adxBookingService: AdxBookingService) {
  }

  public ngOnInit(): void {
    if (this.data == null){
      this.route.paramMap.subscribe(params => {
        this.travelServiceId = params.get('travelServiceId');
      });
    }
    else{
      this.travelServiceId = this.data;
    }
    
    this.getAdxBookingByTravelServiceId();
  }

  getAdxBookingByTravelServiceId() {
    this.adxBookingService.getAdxBookingByTravelServiceId(this.travelServiceId)
    .subscribe((booking: AdxBookingResponse)=>{
      if(booking)
        {
          this._booking = booking;
          this._booking.isModal = this.data != null;
        }else {
          const message = "No related records found.";
          this._snackBar.open(message, 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['message-position', 'app-notification-success']
          });
        }
    });
  }
}
