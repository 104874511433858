<form data-testid="adx-booking-segment-info-form" class="template-form adx-booking-segment-info-form">  
    <div data-testid="adx-booking-segment-info-main-div" class="filter__fields">
        <h2 data-testid="adx-booking-segment-info-title" class="page-details-title-style">Segment {{_adxBookingSegment.id}}</h2>
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-booking-segment-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile data-testid="adx-booking-segment-info-airline-code-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-airline-code-field">
                            <mat-label data-testid="adx-booking-segment-info-airline-code-field-title">Airline Code: </mat-label>
                            <input data-testid="adx-booking-segment-info-airline-code-input-field" matInput value="{{_adxBookingSegment.airlineCode}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-flight-number-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-flight-number-field">
                            <mat-label data-testid="adx-booking-segment-info-flight-number-field-title">Flight Number: </mat-label>
                            <input data-testid="adx-booking-segment-info-flight-number-input-field" matInput value="{{_adxBookingSegment.flightNumber}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-departure-city-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-departure-city-field">
                            <mat-label data-testid="adx-booking-segment-info-departure-city-field-title">Departure City: </mat-label>
                            <input data-testid="adx-booking-segment-info-departure-city-input-field" matInput value="{{_adxBookingSegment.departureCity}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-depart-date-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-depart-date-field">
                            <mat-label data-testid="adx-booking-segment-info-depart-date-field-title">Depart Date: </mat-label>
                            <input data-testid="adx-booking-segment-info-depart-date-input-field" matInput value="{{formatDate(_adxBookingSegment.departDateTime)}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-arrival-city-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-arrival-city-field">
                            <mat-label data-testid="adx-booking-segment-info-arrival-city-field-title">Arrival City: </mat-label>
                            <input data-testid="adx-booking-segment-info-arrival-city-input-field" matInput value="{{_adxBookingSegment.arrivalCity}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-arrival-date-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-arrival-date-field">
                            <mat-label data-testid="adx-booking-segment-info-arrival-date-field-title">Arrival Date: </mat-label>
                            <input data-testid="adx-booking-segment-info-arrival-date-input-field" matInput value="{{formatDate(_adxBookingSegment.arrivalDateTime)}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-fare-basecode-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-fare-basecode-field">
                            <mat-label data-testid="adx-booking-segment-info-fare-basecode-field-title">Fare Base Code: </mat-label>
                            <input data-testid="adx-booking-segment-info-fare-basecode-input-field" matInput value="{{_adxBookingSegment.fareBasesCode}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-fare-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-fare-field">
                            <mat-label data-testid="adx-booking-segment-info-fare-field-title">Fare: </mat-label>
                            <input data-testid="adx-booking-segment-info-fare-input-field" matInput value="{{_adxBookingSegment.fare}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-class-of-service-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-class-of-service-field">
                            <mat-label data-testid="adx-booking-segment-info-class-of-service-field-title">Class of Service: </mat-label>
                            <input data-testid="adx-booking-segment-info-class-of-service-input-field" matInput value="{{_adxBookingSegment.classOfService}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-mileage-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-mileage-field">
                            <mat-label data-testid="adx-booking-segment-info-mileage-field-title">Mileage: </mat-label>
                            <input data-testid="adx-booking-segment-info-mileage-input-field" matInput value="{{_adxBookingSegment.mileage}}">
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-segment-info-origin-conn-indicator-grid-tile">
                        <mat-form-field data-testid="adx-booking-segment-info-origin-conn-indicator-field">
                            <mat-label data-testid="adx-booking-segment-info-origin-conn-indicator-field-title">Origin Connection Indicator: </mat-label>
                            <input data-testid="adx-booking-segment-info-origin-conn-indicator-input-field" matInput value="{{_adxBookingSegment.originConnectionIndicator}}">
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>