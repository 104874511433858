import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {FmsVccFilterResult} from '../model/fms-vcc-filter-result';

@Injectable({
  providedIn: 'root'
})

export class FmsVccService {
  private resourcePath: string = 'dbov2/virtual-credit-card/details';
  private _fmsVccFilterSubject: BehaviorSubject<FmsVccFilterResult> = new BehaviorSubject<FmsVccFilterResult>({});
  constructor(private httpClient: HttpClient) {
  }

  getFmsVccFilter(): Observable<FmsVccFilterResult> {
    return this._fmsVccFilterSubject.asObservable();
  }

  setFmsVccFilter(value: FmsVccFilterResult): void {
    this._fmsVccFilterSubject.next(value);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  searchFmsVcc(fmsVccFilter: any): Observable<any> {
    let httpParams = new HttpParams();
    Object
      .keys(fmsVccFilter)
      .forEach((key) => {
        if (fmsVccFilter[key]) {
          httpParams = httpParams.append(key, fmsVccFilter[key]);
        }
      });
      return this.httpClient
      .get<any>(`${this.resourcePath}`, {params: httpParams})
  }

}