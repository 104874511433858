import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'split-rate-details',
  templateUrl: './split-rate-details.component.html'
})

export class SplitRateDetailsComponent implements OnInit {
    agentId: any;

   constructor(
       private route: ActivatedRoute){}

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.agentId = params.get('agentId');
        });
    }
}
