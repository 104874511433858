import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {Agent, AgentData, AgentFilterResult, AgentSplitRateData, AgentSplitRateParams} from './agent.model';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  private baseUrl = 'dbov2/agent';
  private agentFilter: AgentFilterResult = {};

  constructor(private http: HttpClient) { }

  getAgents(pageNumber: number = 1, pageSize: number = 25, filters: AgentFilterResult = {}): Observable<AgentData> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (filters.agentName) {
      params = params.set('AgentName', filters.agentName);
    }

    if (filters.agentCode) {
      params = params.set('AgentCode', filters.agentCode);
    }

    if (filters.dataSourceId) {
      params = params.set('DataSourceId', filters.dataSourceId);
    }

    const options = { params };
    return this.http.get<AgentData>(`${this.baseUrl}/search`, options);
  }

  setAgentFilter(filter: AgentFilterResult): void {
    this.agentFilter = filter;
  }

  getAgentFilter(): AgentFilterResult {
    return this.agentFilter;
  }

  getAgentSplitRate({filters,page,sort}:AgentSplitRateParams): Observable<AgentSplitRateData> {
    const request = {
      propertyName: sort?.active || '',
      orderKey: sort?.direction?.toUpperCase() || '',
      pageSize:  page?.pageSize || 25,
      pageNumber : (page?.pageIndex || 0) + 1,
      ...filters,
    };

    removeEmptyParams(request);
    
    return this.http.get<AgentSplitRateData>(`dbov2/agent-split-rate`, {
      params: {...request}
    }).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

   public getAgentById(id: number): Observable<Agent> {
      return this.http
        .get<Agent>(`${this.baseUrl}/${id}`)
        .pipe(catchError((error:HttpErrorResponse)=>{
          throw new Error(error?.message || 'Server error');
      }));
    }
}
