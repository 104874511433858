export const environment = {
  apiUrl: '',
  dboV2ApiUrl: '',
  version: '1.1.2294-beta',
  environmentName: '',
  healthCheckInterval: '',
  instrumentationKey: '',
  appConfigConnectionString: 'Endpoint=https://triparc-fms-dev-appconfig-fmsservices.azconfig.io;Id=pfat;Secret=Gakq5tBwaNiMO1+7Dyk69hDC2CRGOkAHimhDS4nLxfE=',
  redirectUri:'',
  clientId:'',
  authority:'',
  postLogoutUrl:'',
  cacheLocation:'',
  graphApiUrl: '',
  fmsApiScope: '',
  graphApiScope:'',
  openAiApiKey: '',
  openAiEndpoint: '',
  openAiDeploymentName: ''
};
