import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, Observable, Subject} from 'rxjs';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import {AdxPaymentFilter, AdxPaymentResponse, CreateAdxPaymentModel, SearchAdxPaymentParams} from '../models/adx-payment';
import * as moment from 'moment';
import {AdxPaymentReconciliationAdjustReq, AdxPaymentReconciliationAdjustmentReq, AdxPaymentReconciliationResponse, PaymentReconciliationFilter, SearchAdxPaymentReconciliationParams, SearchReconciliationParams} from '../models/client-reconciliation';

@Injectable({
    providedIn: 'root',
})
export class AdxPaymentService {
    
    private _dataSourceReceived$ = new BehaviorSubject<AdxPaymentFilter | {}>({});
    private _dataSourceMade$ = new BehaviorSubject<AdxPaymentFilter | {}>({});
    private refreshAdxPaymentTrigger = new Subject<void>();
    refreshAdxPaymentGrid$ = this.refreshAdxPaymentTrigger.asObservable();
    private _dataSourceReconciliation$ = new BehaviorSubject<PaymentReconciliationFilter | {}>({
      processorIds: [1, 2]
    });

    private readonly reconSearchUrl = 'dbov2/payment-reconciliation/recon-search';

    constructor(private http:HttpClient) {}

    getPaymentReceivedFilter(): Observable<AdxPaymentFilter | {}> {
        return this._dataSourceReceived$.asObservable();
    }
    
    setPaymentReceivedFilter(value:AdxPaymentFilter): void {
        this._dataSourceReceived$.next(value || null);
    }

    getPaymentMadeFilter(): Observable<AdxPaymentFilter | {}> {
        return this._dataSourceMade$.asObservable();
    }
    
    setPaymentMadeFilter(value:AdxPaymentFilter): void {
        this._dataSourceMade$.next(value || null);
    }

    getPaymentsReconciliationFilter(): Observable<PaymentReconciliationFilter | {}> {
      return this._dataSourceReconciliation$.asObservable();
    }
    
    setPaymentsReconciliationFilter(value:PaymentReconciliationFilter): void {
        this._dataSourceReconciliation$.next(value || null);
    }

    getAdxPaymentFilters(filters?: AdxPaymentFilter): Record<string, string | boolean| number> {
        return {
            paymentDateFrom: filters?.paymentDateFrom ? moment(filters?.paymentDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
            paymentDateTo: filters?.paymentDateTo ? moment(filters?.paymentDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
            profileTypeCode: filters?.profileTypeCode ?? '',
            payTypeId: filters?.payTypeId ?? '',
            payMethodId: filters?.payMethod ?? '',
            branchId: filters?.branch ?? '',
            bankId: filters?.bank ?? '',
            adxInvoiceId: filters?.invoiceId ?? '',
            clientName: filters?.payeeName ?? '',
            tripId: filters?.tripId ?? '',
            adxInvoiceRecordLocator: filters?.adxInvoiceRecordLocator ?? '',
        }
    }

    searchAdxPayment({filters,page,sort}:SearchAdxPaymentParams): Observable<AdxPaymentResponse> {
        const params:Record<string, string | number | boolean> = {
            pageSize:  page?.pageSize || 25,
            pageNumber : (page?.pageIndex || 0) + 1,
            propertyName: this.getMappedPaymentsTblPropertyName(sort?.active|| ''),
            orderKey: sort?.direction?.toUpperCase() || '',

            ...this.getAdxPaymentFilters(filters),

        };
      
        removeEmptyParams(params);

        return  this.http.get<AdxPaymentResponse>('dbov2/adx-payment-detail/search', 
            { params: {...params}
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }

    createAdxPayment(params: CreateAdxPaymentModel): Observable<any> {
        return  this.http.post<any>('dbov2/adx-payment-detail',{ ...params }
        ).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error');
        }));
    }

    triggerAdxPaymentGridRefresh() {
        this.refreshAdxPaymentTrigger.next();
    }

    getMappedPaymentsTblPropertyName(propertyName: string): string {
        switch (propertyName) {
          case 'fmsAdxPaymentDetailId':
            return 'Id';
          case 'vendorName':
            return 'adxBooking.Vendor.VendorName';
          case 'payTypeName':
            return 'payType.PayTypeName';
          case 'payMethodName':
            return 'payMethod.PaymethodName';
          case 'profileTypeName':
            return 'profileType.ProfileTypeName';
          case 'branchName':
            return 'branch.Name';
          case 'bankName':
            return 'bank.Name';
          case 'tripExternalReference':
            return 'trip.ExternalReference';
          default:
            return propertyName;
        }
    }


    searchAdxPaymentReconciliation({filters,page,sort}:SearchAdxPaymentReconciliationParams): Observable<AdxPaymentReconciliationResponse> {
      const params:Record<string, string | number | boolean> = {
          pageSize:  page?.pageSize || 25,
          pageNumber : (page?.pageIndex || 0) + 1,
          propertyName: this.getMappedPaymentsTblPropertyName(sort?.active|| ''),
          orderKey: sort?.direction?.toUpperCase() || '',

          ...filters,

      };
    
      removeEmptyParams(params);

      return  this.http.get<AdxPaymentReconciliationResponse>('dbov2/adx-payment-detail/recon', 
        { params: {...params} }
      ).pipe(catchError((error:HttpErrorResponse)=>{
          throw new Error(error?.message || 'Server error');
      }));
  }

  searchAdxReconciliation({ filters, page, sort }: SearchAdxPaymentReconciliationParams): Observable<any> {
    const params: Record<string, string | number | boolean> = {
      pageSize: page?.pageSize || 25,
      pageNumber: (page?.pageIndex || 0) + 1,
      orderKey: sort?.direction?.toUpperCase() || '',

      ...filters,

    };

    removeEmptyParams(params);

    return this.http.get<any>('dbov2/adx-payment-detail/reconciliation',
      { params: { ...params } }
    ).pipe(catchError((error: HttpErrorResponse) => {
      throw new Error(error?.message || 'Server error');
    }));
  }

    adjustAdxPaymentReconciliation(elementAdjust: AdxPaymentReconciliationAdjustReq): Observable<any>{
      return this.http.post('dbov2/adx-payment-detail/adjust',
        { ...elementAdjust }
      ).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
      }));
  }

  adjustmentAdxPaymentReconciliation(elementAdjust: AdxPaymentReconciliationAdjustmentReq): Observable<any> {
    return this.http.post('dbov2/adx-payment-detail/reconciliation-adjustment',
      { ...elementAdjust }
    ).pipe(catchError((error: HttpErrorResponse) => {
      throw new Error(error?.message || 'Server error');
    }));
  }

  getReconciliation({ filters, page, sort }: SearchReconciliationParams): Observable<any> {

    const params: Record<string, string | number | boolean> = {
      pageSize: page?.pageSize || 25,
      pageNumber: (page?.pageIndex || 0) + 1,
      orderKey: sort?.direction?.toUpperCase() || '',

      ...filters as any, //Angular marks processor id as an error

    };

    removeEmptyParams(params);

    let url = `${this.reconSearchUrl}`;

    if(params['releaseDateFrom'] || params['releaseDateTo']) {
      url = `${this.reconSearchUrl}-extended`;
    }

    return this.http.get<any>(url,
      { params: { ...params } }
    ).pipe(catchError((error: HttpErrorResponse) => {
      throw new Error(error?.message || 'Server error');
    }));
  }

}
