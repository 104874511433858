<modal-panel
  [title]="'Vouchers'"
  (close)="closeModal()"
  data-testid="client-vouchers-modal-panel"
>
  <div class="filter__fields " [ngClass]="{'loading-spinner': !loadedData || !dataSource.length}">
    @if (!loadedData) {
      <mat-spinner data-testid="client-vouchers-modal-panel-loading-spinner" ></mat-spinner>
    }
    @if (!dataSource.length && loadedData) {
      <span data-testid="client-vouchers-modal-panel-no-result-message">There are no records to display.</span>
    }
    @if (dataSource.length) {
      <div class="clients-modal-container mat-elevation-z8" data-testid="client-vouchers-modal-panel-container">
        <mat-table [dataSource]="dataSource" matSort data-testid="client-vouchers-modal-panel-table">
          <ng-container matColumnDef="recordLocator">
            <mat-header-cell data-testid="client-vouchers-modal-record-locator-header" class="mat-header-cell" *matHeaderCellDef>Adx Trip Reference</mat-header-cell>
            <mat-cell data-testid="client-vouchers-modal-record-locator-cell" *matCellDef="let invoice">{{ invoice.recordLocator }}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="issueDate">
            <mat-header-cell data-testid="client-vouchers-modal-issue-date-header" class="mat-header-cell" *matHeaderCellDef>Issue Date</mat-header-cell>
            <mat-cell data-testid="client-vouchers-modal-issue-date-cell" *matCellDef="let invoice">{{ invoice.issueDate | date: 'M/d/yyyy' }}</mat-cell>
          </ng-container>
  
  
          <ng-container matColumnDef="usdTotal">
            <mat-header-cell data-testid="client-vouchers-modal-usd-total-header" class="mat-header-cell" *matHeaderCellDef>USD Total</mat-header-cell>
            <mat-cell data-testid="client-vouchers-modal-usd-total-cell" *matCellDef="let invoice">{{ getUsdSum(invoice) }}</mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="cadTotal">
            <mat-header-cell data-testid="client-vouchers-modal-cad-total-header" class="mat-header-cell" *matHeaderCellDef>CAD Total</mat-header-cell>
            <mat-cell data-testid="client-vouchers-modal-cad-total-cell" *matCellDef="let invoice">{{ getCadSum(invoice) }}</mat-cell>
          </ng-container>
      
          <mat-header-row data-testid="client-vouchers-modal-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row data-testid="client-vouchers-modal-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    }
  </div>

  <ng-container modalPanelFooter data-testid="client-vouchers-modal-panel-footer">
    <button mat-button mat-raised-button color="primary" (click)="closeModal()" data-testid="client-vouchers-modal-panel-close-button">Close</button>
  </ng-container>
  
</modal-panel>
