<div
  class="chatbot-icon"
  (click)="toggleChatbox()">
  <mat-icon
    aria-hidden="false"
    fontIcon="chat">
  </mat-icon>
</div>
<div
  class="chatbox"
  [ngClass]="{ open: chatboxOpen }">
  <app-conversation
    class="chatbox-conversation"
    [messages]="messages">
  </app-conversation>
  <div class="footer">
    <app-prompt
      (textChange)="handlePromptChange($event)">
    </app-prompt>
  </div>
</div>
