import {Component, Input} from '@angular/core';
import {DatePipe} from '@angular/common';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'app-adx-payment-info',
  templateUrl: './adx-payment-info.component.html'
})

@AutoUnsubscribe()
export class AdxPaymentInfoComponent {
  @Input() public data: any;
  _adxPayment : any;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(){
    this.getAdxPaymentInfo();
  }

  getAdxPaymentInfo() {
    if (this.data != null){
      this._adxPayment = this.data;
    }
  } 

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }

  redirectToInvoice(): void {
    if(this._adxPayment.adxPaydtls[0]?.adxInvoiceId && !this._adxPayment.isModal) {
      location.href = `AdxInvoices/${this._adxPayment.adxPaydtls[0]?.adxInvoiceId}`;
    }
  }

  redirectToBooking(): void {
    if(this._adxPayment.adxPaydtls[0]?.adxBookingId && !this._adxPayment.isModal) {
      location.href = `AdxBookings/${this._adxPayment.adxPaydtls[0]?.adxBookingId}`;
    }
  }
}