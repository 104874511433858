<div class="grid-title-event">
    <h2 data-testid="air-matching-report-list-title">Air Matching Report</h2>

        <div class="events-container">
            <button  data-testid="air-matching-report-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            @if (items.length){
                @if(isDirectorFinance()) {
                    <button color="primary" data-testid="air-matching-report-approve-update-button" aria-label="Approve Update Air Matching Vcc" mat-stroked-button (click)="approveUpdate()">Approve Update</button>
                }
                <button color="primary" class="btn-download" data-testid="download-air-matching-report-button" aria-label="Download Air Matching Report File" mat-stroked-button (click)="downloadGridToFile()">
                    <mat-icon>download</mat-icon>
                </button>
            }
        </div>
</div>
<mat-table data-testid="air-matching-report-table" class="compact-mat-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>

    <ng-container matColumnDef="fmsInvoiceNumber">
        <mat-header-cell data-testid="air-matching-report-fms-invoice-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Fms Invoice Number
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-fms-invoice-number-cell" *matCellDef="let element">
            <span>{{element?.fmsInvoiceNumber}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fmsBookingTotalFare">
        <mat-header-cell data-testid="air-matching-report-fms-invoice-total-fare-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Fms Invoice Total Fare
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-fms-invoice-total-fare-cell" *matCellDef="let element">
            <span>{{element?.fmsBookingTotalFare}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="adxReference">
        <mat-header-cell data-testid="air-matching-report-adx-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Adx Reference
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-adx-reference-cell" *matCellDef="let element">
            <span>{{element?.adxReference}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sabreInvoiceNumber">
        <mat-header-cell data-testid="air-matching-report-sabre-invoice-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Sabre Invoice Number
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-sabre-invoice-number-cell" *matCellDef="let element">
            <span>{{element?.sabreInvoiceNumber}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sabreTotalFare">
        <mat-header-cell data-testid="air-matching-report-sabre-total-fare-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Sabre Total Fare
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-sabre-total-fare-cell" *matCellDef="let element">
            <span>{{element?.sabreTotalFare}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sabreReference">
        <mat-header-cell data-testid="air-matching-report-sabre-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Sabre Reference
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-sabre-reference-cell" *matCellDef="let element">
            <span>{{element?.sabreReference}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sabreInvoiceIssueDate">
        <mat-header-cell data-testid="air-matching-report-sabre-invoice-issue-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Sabre Invoice Issue Date
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-sabre-invoice-issue-date-cell" *matCellDef="let element">
            <span>{{element?.sabreInvoiceIssueDate | date:'M/dd/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="invoiceDataSourceName">
        <mat-header-cell data-testid="air-matching-report-invoice-data-source-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Invoice Data Source Name
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-invoice-data-source-name-cell" *matCellDef="let element">
            <span>{{element?.invoiceDataSourceName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="vendorName">
        <mat-header-cell data-testid="air-matching-report-vendor-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Vendor Name
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-vendor-name-cell" *matCellDef="let element">
            <span>{{element?.vendorName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="agentName">
        <mat-header-cell data-testid="air-matching-report-agent-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Agent Name
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-agent-name-cell" *matCellDef="let element">
            <span>{{element?.agentName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientName">
        <mat-header-cell data-testid="air-matching-report-client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Client Name
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-client-name-cell" *matCellDef="let element">
            <span>{{element?.clientName}}</span>
        </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="actions">
        <mat-header-cell data-testid="air-matching-report-button-header" class="mat-header-cell" *matHeaderCellDef>
            Actions
        </mat-header-cell>
        <mat-cell data-testid="air-matching-report-button-cell" *matCellDef="let element">
            @if(!element?.isAdjusted) {
                <div matTooltip="Only one selection per approval allowed" [matTooltipDisabled]="!(itemToSave && element !== itemToSave)">
                    <mat-checkbox [disabled]="itemToSave && element !== itemToSave" (change)="updateItemToSave(element)"/>
                </div>
            }
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row *matRowDef="let row; columns: displayedColumns; let i = index" />
</mat-table>
<mat-paginator 
    data-testid="air-matching-report-paginator"
    [length]="pagination.totalItemCount"
    [pageSizeOptions]="[25, 50, 75, 100]"
    [pageSize]="pagination.pageSize"
    [pageIndex]="pagination.pageIndex"
    (page)="handlePageChanged($event)"
/>