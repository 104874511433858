<div class="grid-title-event">
    <h2 style="text-align:center" id="payments-made-list-title">Payments Made</h2>
    @if (items.length){
        <div class="events-container">
            <button  id="payments-made-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" id="new-alert-button" aria-label="New alert button" mat-stroked-button (click)="handleCreatePaymentDialog()">
                <mat-icon fontIcon="attach_money"></mat-icon>
                Create Payment
            </button>
            <button color="primary" id="download-payments-made-button" aria-label="Download Payments Made File" mat-stroked-button (click)="downloadGridToFile()" style="justify-content:flex-end">
                <mat-icon>download</mat-icon>
            </button>
        </div>
    }
</div>
<mat-table id="payments-made-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>

    <ng-container matColumnDef="id">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Payment Fms Id
        </mat-header-cell>
        <mat-cell id="client-name-cell" class="mat-cell" *matCellDef="let element">
            <span matTooltip={{element.id}}><a href="AdxPayments/{{element?.id}}" (click)="handleIdClick($event, element)">{{element.id}}</a></span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="paymentDate">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Payment Date
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.paymentDate | date:'M/d/yyyy'}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payeeName">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Client Name
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.payeeName}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payTypeId">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Pay Type
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.payType?.payTypeName}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="payMethodId">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Pay Method
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.payMethod?.paymethodName}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="amount">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Amount
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.amount}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="ckCcNo">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            ckCcNo
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.ckCcNo | maskedCard}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="branchId">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Branch
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.branch?.name}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="bankId">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Bank
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.bank?.name}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="paymentGroup">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Payment Group
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.paymentGroup}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="remarks">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Remarks
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.remarks}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="invoiceRef">
        <mat-header-cell id="client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            ADX Trip Ref
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element">
            <span>{{element?.invoiceRef}}</span>
        </mat-cell>
    </ng-container>

    
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index">
    </mat-row>
</mat-table>
<mat-paginator id="payments-made-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>