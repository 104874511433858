import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'trip-reference-modal',
  templateUrl: './trip-reference-modal.component.html'
})

export class TripReferenceModalComponent implements OnInit  {
  title: string;
  travelServiceId: any;

  constructor(
    @Optional() public dialogRef: MatDialogRef<TripReferenceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, travelServiceId: any },
    public dialog: MatDialog
  ){
    this.title = data.title;
    this.travelServiceId = data.travelServiceId;
  }

  ngOnInit(): void {
    if (!this.data) {
      console.error('Data is missing');
    }
  }

  public closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
  }
}
