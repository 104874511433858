import {AbstractControl} from "@angular/forms";
import {map, Observable, shareReplay, startWith, switchMap} from "rxjs";

export const autocompleteFormFilter = (formControl: AbstractControl, nameProp:string, listObservable: Observable<any[]>): Observable<any> => {
    return formControl.valueChanges.pipe(
        startWith(''),
        switchMap((text:string) => {
            return listObservable.pipe(
                map((list) => {
                    return list?.filter((element) =>
                        element[nameProp]?.toLowerCase().includes(text?.toLowerCase())
                    );
                }),
            );
        })
    );
}

export const replayHttpQuery = (getObservable: Observable<any>): Observable<any>=>{
    return getObservable.pipe(
        shareReplay(),
        map((res)=>{
            return res.items;
        },
    ));
}