import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { VccDailySummaryReportResponse } from '../model/vcc-daily-summary-report-response';


@Injectable({
  providedIn: 'root',
})
export class VccDailySummaryReportService {
    private resourcePath: string = 'dbov2/connex-pay-report';
    constructor(private httpClient: HttpClient) {
    }

  public getVccDailySummaryReport(VccDailySummaryReportFilter: any): Observable<VccDailySummaryReportResponse> {
      let httpParams = new HttpParams();
      Object
        .keys(VccDailySummaryReportFilter)
        .forEach((key) => {
          if (VccDailySummaryReportFilter[key]) {
            httpParams = httpParams.append(key, VccDailySummaryReportFilter[key]);
          }
        });
    return this.httpClient
      .get<VccDailySummaryReportResponse>(`${this.resourcePath}/daily-summary-report`, { params: httpParams })
    }
}
