import {Component, OnInit} from '@angular/core';
import {ChartOptions, ChartDataSet} from '../../../../../shared/components/chart/chart.component';
import {ModalService} from '../../../../../shared/service/modal/modal.service';
import {BankAccountModalComponent} from '../../bank-account-modal/bank-account-modal.component';
import {ChartsVccService} from '../../../../service/charts-vcc.service';
import {VccChartFilter} from '../../../../model/funding-vcc-chart-filter';
import * as moment from 'moment';
import {VccChartModel} from '../../../../model/vcc-chart-model';
import {TooltipItem} from 'chart.js';
import {MerchantsService} from 'src/infrastructure/merchants/service/merchants.service';
import {Merchant} from 'src/app/dashboard/model/merchant-guid';

@Component({
    selector: 'funding-vcc-chart',
    templateUrl: './funding-vcc-chart.component.html'
})

export class FundingVccChartComponent implements OnInit {

  public shortLabels: string[] = [];
  merchantGuids: Merchant[] = [];
  public vccChartFilter: VccChartFilter = {} as VccChartFilter;
  public dataChart!: ChartDataSet;
  originalValues: number[] = [];
  limitPercentage: number = 0;
  baseLimit: number = 0.09;
  adjustedValue: number = 0;
  adjustedLimit: number = 0.5;
  public fundingData: VccChartModel[] = [];
  public fundsVccChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
        tooltip: {
            callbacks: {
                label: (tooltipItem: TooltipItem<'doughnut'>) => {
                  const originalValue = this.originalValues[tooltipItem.dataIndex];
                  return `${tooltipItem.label}: ${originalValue}`;
                }
            }
        },
        datalabels: {
          display: 'auto',
          font: {
            weight: 'bolder'
          },
          anchor: 'end',
          align:  'end',
          formatter: (value, context) => {
            const originalValue = this.originalValues[context.dataIndex];
            if (value === originalValue) {
              return value;
            }
            return `${originalValue}`;
          }
        }
    },
    scales: {
      y: {
        display: false
      },
    },
    layout: {
      padding: {
        top: 8,
        bottom: 20,
        left: 8,
        right: 8
      
      }
    }
  };

  constructor(private modalService: ModalService,
              private chartsVccService: ChartsVccService,
              private merchantsService: MerchantsService,
  ) {
  }
  
  ngOnInit() {
    const today = moment();
    this.chartsVccService.getLatestDate().subscribe((latestReleasedDate) => {
      this.vccChartFilter.propertyName = 'releasedDateFrom';
      this.vccChartFilter.orderKey = 'DESC';
      this.vccChartFilter.releasedDateFrom = latestReleasedDate;
      this.vccChartFilter.releasedDateTo = today.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  
      this.chartsVccService.getVccChartFilter().subscribe((filter) => {
        this.vccChartFilter.merchantGuid = filter.merchantGuid;
        this.vccChartFilter.releasedDateFrom = filter.releasedDateFrom ? moment(filter.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : this.vccChartFilter.releasedDateFrom;
        this.vccChartFilter.releasedDateTo = filter.releasedDateTo ? moment(filter.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : this.vccChartFilter.releasedDateTo;
        
        this.merchantsService.merchants$.subscribe((data)=>{
          this.merchantGuids = data;
          this.getFundingData();
        });
      });
    });
  }

  get dateFilterText() {
    return this.chartsVccService.getDateSubTitle();
  }

  getFundingData(): void {
    this.chartsVccService.searchFundingsVcc(this.vccChartFilter).subscribe((data: any) => {
      this.fundingData = data;
      this.chartsVccService.setFundingData(data);
      this.buildChartDataSet(data);
    });
  }

  buildChartDataSet(fundingData: VccChartModel[]) {
    const filteredFundingData = fundingData.filter((item: VccChartModel) => item.newCashBalance !== 0);
    this.shortLabels = fundingData.map(item => {
      const merchant = this.merchantGuids.find(m => m.merchantGuid === item.merchantGuid);
      return merchant ? merchant.shortName : '';
    });
    this.originalValues = filteredFundingData
      .map(item => {
        if (item.totalAti === 0 ) {
          return parseFloat(item.totalAta?.toFixed(2) ?? '0');
        } else if (item.totalAta === 0){
          return parseFloat(item.totalAti?.toFixed(2) ?? '0');
        }
        return;
      })
      .filter(value => value !== undefined) as number[];
    
    const maxCashBalance = Math.max(...filteredFundingData.map(item => item.newCashBalance));
    this.limitPercentage = maxCashBalance * this.baseLimit;

    const adjustedCashBalance = filteredFundingData.map((item: VccChartModel) => {
      if (item.totalAti === 0 ) {
        this.adjustedValue = parseFloat(item.totalAta?.toFixed(2) ?? '0');
      } else if (item.totalAta === 0){
        this.adjustedValue = parseFloat(item.totalAti?.toFixed(2) ?? '0');
      }
      if (this.adjustedValue < this.limitPercentage) {
        this.adjustedValue += (this.limitPercentage - this.adjustedValue) * this.adjustedLimit;
      }
      return this.adjustedValue;
    });

    this.dataChart = {
      labels: this.shortLabels,
      datasets: [{
        data: adjustedCashBalance,
        backgroundColor: [
          '#a2798f',
          '#e5fffa',
          '#509c87',
          '#004242',
          '#989898',
          '#6fa2d0',
          '#4c516d',
          '#eac086',
          '#708090',
          '#eb9c1c',
          '#a3c1ad',
          '#5f9ea0',
          '#aec6cf',
          '#cbb87b',
          '#073763',
          '#9f8a01',
          '#30bea4',
          '#49796b',
          '#b3503a',
        ],
        borderColor: [
          '#a2798f',
          '#e5fffa',
          '#509c87',
          '#004242',
          '#989898',
          '#6fa2d0',
          '#4c516d',
          '#eac086',
          '#708090',
          '#eb9c1c',
          '#a3c1ad',
          '#5f9ea0',
          '#aec6cf',
          '#cbb87b',
          '#073763',
          '#9f8a01',
          '#30bea4',
          '#49796b',
          '#b3503a',
        ],
        borderWidth: 1
      }]
    } 
  }

  FundsVccClickHandler(event: any) {
    const selectedMerchant = this.merchantGuids.find(merchant => merchant.shortName === event.label);
    const filteredFundingData = this.fundingData.filter(item => item.merchantGuid === selectedMerchant?.merchantGuid);

    const data = {
      ...event,
      fundingData: filteredFundingData,
      dateRange: this.dateFilterText
    }
    // Open modal
    if (event){
      const modalRef = this.modalService.open(BankAccountModalComponent, { panelClass: 'overflowAuto' });
      modalRef.componentInstance.data = data;
      modalRef.componentInstance.title = 'Bank account detail'
    }
  }
}