import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AutoUnsubscribe} from '../../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {VendorService} from 'src/app/vendor/service/vendor.service';
import {HttpParams} from '@angular/common/http';

@AutoUnsubscribe()
@Component({
  selector: 'app-vendor-viewer',
  templateUrl: './vendor-viewer.component.html'
})
export class VendorViewerComponent {
  @Input()
  public data: any;

  @Input()
  public update: any;

  public vendorId: any;

  public _vendor: any;

  public tramsCentralDocumentId: any;

  constructor(private route: ActivatedRoute,
    private vendorService: VendorService) {	
  }

  public ngOnInit(): void {
    if (this.data == null) {
      this.route.paramMap.subscribe(params => {
        this.tramsCentralDocumentId = params.get('tramsCentralDocumentId');
        this.getVendorInfoByKey();
      });
    }
    else {
      this.getVendorInfo();
    }
  }

  public ngOnChanges(): void {
    this.getVendorInfo();
  }

  public getVendorInfo(): void {
    this._vendor = this.data;
  }

  public getVendorInfoByKey(): void {
    const params = new HttpParams().set('tramsCentralDocumentId', this.tramsCentralDocumentId);
    this.vendorService.getVendorByKey(params).subscribe((data) => {
      this._vendor = data.items[0];
      this._vendor.isModal = this.data != null;
      this._vendor.title = "Vendor Information"
    });
  }
}
