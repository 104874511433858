import {Injectable} from '@angular/core';
import {ChartOptions} from './chart.component';
import {ChartType} from 'chart.js';

const CHART_OPTIONS_DEFAULT: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      grid: {
        color: '#FFFFFF',
        tickColor: '#00241A',
        tickBorderDash: [5, 5]
      },
      border: {dash: [4, 4]},
      ticks: {
        color: '#A1A5AA'
      }
    },
    x: {
      grid: {
        display: false
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#A1A5AA'
      }
    }
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
    subtitle: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      anchor: 'end',
      align: 'end',
    },
  },
};

const LINE_CHART_OPTIONS: ChartOptions = {
  elements: {
    line: {
      tension: 0.5,
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class ChartOptionsService {
  public getDefaultOptions(
    chartType: ChartType
  ): ChartOptions {
    const options: ChartOptions = CHART_OPTIONS_DEFAULT;
    switch (chartType) {
      case 'line':
        return {...options, ...LINE_CHART_OPTIONS};
      case 'bar':
        return options;
      default:
        return options;
    }
  }

  public getChartOptions(chartType: ChartType, overrides: ChartOptions) {
    let defaultOptions = this.getDefaultOptions(chartType);
    return this._merge(defaultOptions, overrides ?? {});
  }

  private _merge(defaultObj: any, overrideObj: any) {
    const mergedObj = {...defaultObj};

    for (const key in overrideObj) {
      if (
        defaultObj.hasOwnProperty(key) &&
        typeof defaultObj[key] === 'object' &&
        typeof overrideObj[key] === 'object'
      ) {
        mergedObj[key] = {...defaultObj[key], ...overrideObj[key]};
        if (key === 'scales' || key === 'plugins') {
          for (const subKey in overrideObj[key]) {
            if (
              defaultObj[key].hasOwnProperty(subKey) &&
              typeof defaultObj[key][subKey] === 'object' &&
              typeof overrideObj[key][subKey] === 'object'
            ) {
              mergedObj[key][subKey] = {
                ...defaultObj[key][subKey],
                ...overrideObj[key][subKey],
              };
            }
          }
        }
      } else {
        mergedObj[key] = overrideObj[key];
      }
    }

    return mergedObj;
  }
}
