<mat-stepper [linear]="true" #stepper class="payment-stepper-form" (selectionChange)="preventFormValidation($event, stepper);">
    <mat-step [completed]="(!this.invoiceForm.invalid) && invoiceData.adxInvoiceId && invoiceData.dataSourceId">
      <form [formGroup]="invoiceForm">
        <ng-template matStepLabel>Fill FMS invoice ID</ng-template>
        <mat-form-field>
          <mat-label>FMS Invoice</mat-label>
          <input matInput formControlName="invoiceInput" placeholder="FMS Invoice" (input)="clearInvoice()" type="number" min="0" (keypress)="checkInput($event)">
          @if (invoiceForm.get('invoiceInput')?.hasError('incorrectInvoice')) {
            <mat-error>Invoice not found</mat-error>
          }
        </mat-form-field> 
        <div class="items-space-between">
          <button color="primary" mat-stroked-button (click)="closeModal()" type="button">Close</button>
          <button color="primary" mat-stroked-button (click)="validateInvoice()" [disabled]="invoiceForm.invalid || isLoading" type="button">Next</button>
        </div>
      </form>
    </mat-step>


    <mat-step [editable]="false">
        <ng-template matStepLabel>Fill Pay Information</ng-template>
    
        <mat-card appearance="outlined">
            <mat-card-content>
              <payment-form [stepperInformation]="invoiceData"></payment-form>
            </mat-card-content>
        </mat-card>

        <div>
            <button color="primary" mat-stroked-button matStepperPrevious type="button">Back</button>
        </div>
    </mat-step>


</mat-stepper>


