<modal-panel [title]="title" (close)="closeDialog()" id="agent-info-modal-panel">
  <ng-container id="agent-viewer-modal-agent-container">
    <div class="agent-info-content" id="agent-info-modal-content">

      <read-only-form-field title="Agent Name" testId="agent-info-modal-agent-name" [value]="agentData.agentName || ''" />
      
      <read-only-form-field title="Agent Code" testId="agent-info-modal-agent-code" [value]="agentData.interfaceId || ''" />
      
      <read-only-form-field title="Email" testId="agent-info-modal-email" [value]="agentData.email || ''" />
      
      <read-only-form-field title="Data Source Name" testId="agent-info-modal-data-source-name" [value]="agentData.tramsDataSource?.displayName || ''" />

      <read-only-form-field title="Trams Central Document Id" testId="agent-info-modal-trams-document-id" [value]="agentData.tramsCentralDocumentId || ''" />

      <read-only-form-field title="Address" testId="agent-info-modal-address" [value]="formatAddress(agentData.address)" />
      
      <read-only-form-field title="Phone" testId="agent-info-modal-phone" [value]="agentData.phone || ''" />

    </div>
  </ng-container>
  <ng-container modalPanelFooter id="agent-info-modal-footer">
    <button mat-raised-button color="primary" (click)="closeDialog()" id="agent-info-modal-close-button">Close</button>
  </ng-container>
</modal-panel>
