@if(adxBookingInfoForm){
    <form data-testid="adx-booking-info-form" class="template-form adx-booking-info-form" [formGroup]="adxBookingInfoForm">  
        <div data-testid="adx-booking-info-main-div" class="filter__fields">
          <div class="items-space-between m-t-8 m-b-5">
            <span>
              <button data-testid="adx-booking-detail-edit-button"
                      mat-stroked-button
                      color="primary"
                      [disabled]="_adxBooking?.isClosedPeriod"
                      (click)="toggleEdit()"
                      style="margin-right: 10px;">
                {{ isEdit ? 'Cancel' : 'Edit' }}
              </button>
              
              @if(isEdit) {
                <button data-testid="adx-booking-detail-update-button" mat-raised-button color="primary"
                (click)="updateBooking();" [disabled]="adxBookingInfoForm.invalid">Update</button>
              }
              @else{
                <button *ngIf="isLoggedInUserHasManagerRole" data-testid="adx-booking-detail-copy-button" mat-stroked-button color="primary"
                (click)="openCopyConfirmationDialog(_adxBooking.id)">Copy service</button>
              } 
            </span>
          </div>

            <mat-card class="mat-elevation-z8">
                <mat-card-content>
                  <mat-grid-list data-testid="adx-booking-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="90">
                    <mat-grid-tile data-testid="adx-booking-info-vendor-name-tile">
                      <read-only-form-field title="Vendor Name" testId="adx-booking-info-vendor-name" value="{{_adxBooking?.vendor?.vendorName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-data-source-grid-tile">
                      <read-only-form-field title="Data Source" testId="adx-booking-info-data-source" value="{{_adxBooking?.tramsDataSource?.sourceName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-record-locator-grid-tile">
                      <read-only-form-field title="Record Locator" testId="adx-booking-info-record-locator" value="{{_adxBooking?.recordLocator}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-reservation-number-grid-tile">
                      <read-only-form-field title="Reservation Number" testId="adx-booking-info-reservation-number" value="{{_adxBooking?.reservationNo}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-invoice-id-grid-tile">
                      <read-only-form-field title="Invoice Id"
                                            testId="adx-booking-info-invoice-id"
                                            value="{{_adxBooking?.adxInvoiceId}}"
                                            [buttonIcon]="hasRedirect() ? infoInvoiceField : undefined"
                                            (buttonClick)="redirectToInvoice()" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-submit-grid-tile">
                      <read-only-form-field title="Submit to code" testId="adx-booking-info-submit" value="{{_adxBooking?.submitToCode}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-depart-date-grid-tile">
                      <read-only-form-field title="Depart Date" testId="adx-booking-info-depart-date" value="{{formatDate(_adxBooking?.departDate)}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-return-date-grid-tile">
                      <read-only-form-field title="Return Date" testId="adx-booking-info-return-date" value="{{formatDate(_adxBooking?.returnDate)}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-confirmation-number-grid-tile">
                      <read-only-form-field title="Confirmation Number" testId="adx-booking-info-confirmation" value="{{_adxBooking?.confirmationNumber}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-travel-type-grid-tile">
                      <read-only-form-field title="Travel Type" testId="adx-booking-info-travel-type" value="{{_adxBooking?.travelType?.travelTypeName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-number-of-pax-grid-tile">
                      <read-only-form-field title="Number Of Pax" testId="adx-booking-info-number-of-pax" value="{{_adxBooking?.numberOfPax}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-currency-grid-tile">
                      <read-only-form-field title="Currency" testId="adx-booking-info-currency" value="{{_adxBooking?.currency?.currencyName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-comm-amt-grid-tile">
                      @let commissionAmountTitle = 'Commission Amount';
                      <read-only-form-field [readonly]="!isEdit" [title]="commissionAmountTitle" testId="adx-booking-info-comm-amt">
                        <mat-form-field data-testid="adx-booking-info-comm-amt-field">
                          <mat-label data-testid="adx-booking-info-comm-amt-field-title">{{commissionAmountTitle}}</mat-label>
                          <input data-testid="adx-booking-info-comm-amt-input-field" matInput formControlName="commAmt">
                        </mat-form-field>
                      </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-comm-rate-grid-tile">
                      <read-only-form-field title="Commission Rate" testId="adx-booking-info-comm-rate" value="{{_adxBooking?.commRate}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-tax1-grid-tile">
                      @let taxOneTitle = 'Tax 1';
                      <read-only-form-field [readonly]="!isEdit" [title]="taxOneTitle" testId="adx-booking-info-tax1">
                        <mat-form-field data-testid="adx-booking-info-tax1-field">
                          <mat-label data-testid="adx-booking-info-tax1-field-title">{{taxOneTitle}}</mat-label>
                          <input data-testid="adx-booking-info-tax1-input-field" matInput formControlName="tax1Amt">
                        </mat-form-field>
                      </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-tax2-grid-tile">
                      @let taxTwoTitle = 'Tax 2';
                      <read-only-form-field [readonly]="!isEdit" [title]="taxTwoTitle" testId="adx-booking-info-tax2">
                        <mat-form-field data-testid="adx-booking-info-tax2-field">
                          <mat-label data-testid="adx-booking-info-tax2-field-title">{{taxTwoTitle}}</mat-label>
                          <input data-testid="adx-booking-info-tax2-input-field" matInput formControlName="tax2Amt">
                        </mat-form-field>
                      </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-tax3-grid-tile">
                      @let taxThreeTitle = 'Tax 3';
                      <read-only-form-field [readonly]="!isEdit" [title]="taxThreeTitle" testId="adx-booking-info-tax3">
                        <mat-form-field data-testid="adx-booking-info-tax3-field">
                          <mat-label data-testid="adx-booking-info-tax3-field-title">{{taxThreeTitle}}</mat-label>
                          <input data-testid="adx-booking-info-tax3-input-field" matInput formControlName="tax3Amt">
                        </mat-form-field>
                      </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-tax4-grid-tile">
                      @let taxFourTitle = 'Tax 4';
                      <read-only-form-field [readonly]="!isEdit" [title]="taxFourTitle" testId="adx-booking-info-tax4">
                        <mat-form-field data-testid="adx-booking-info-tax4-field">
                          <mat-label data-testid="adx-booking-info-tax4-field-title">{{taxFourTitle}}</mat-label>
                          <input data-testid="adx-booking-info-tax4-input-field" matInput formControlName="tax4Amt">
                        </mat-form-field>
                      </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-gst-amt-grid-tile">
                      <read-only-form-field title="Gst Amount" testId="adx-booking-info-gst-amt" value="{{_adxBooking?.gstAmt}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-qst-amt-grid-tile">
                      <read-only-form-field title="Qst Amount" testId="adx-booking-info-qst-amt" value="{{_adxBooking?.qstAmt}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-high-fare-grid-tile">
                      <read-only-form-field title="High Fare" testId="adx-booking-info-high-fare" value="{{_adxBooking?.highFare}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-low-fare-grid-tile">
                      <read-only-form-field title="Low Fare" testId="adx-booking-info-low-fare" value="{{_adxBooking?.lowFare}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-total-fare-grid-tile">
                      @let totalFareTitle = 'Total Fare';
                      <read-only-form-field [readonly]="!isEdit" [title]="totalFareTitle" testId="adx-booking-info-total-fare">
                        <mat-form-field data-testid="adx-booking-info-total-fare-field">
                          <mat-label data-testid="adx-booking-info-total-fare-field-title">{{totalFareTitle}}</mat-label>
                          <input data-testid="adx-booking-info-total-fare-input-field" matInput formControlName="totalFare">
                        </mat-form-field>
                      </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-total-cost-grid-tile">
                      @let totalCostTitle = 'Total Cost';
                      <read-only-form-field [readonly]="!isEdit" [title]="totalCostTitle" testId="adx-booking-info-total-cost">
                        <mat-form-field data-testid="adx-booking-info-total-cost-field">
                          <mat-label data-testid="adx-booking-info-total-cost-field-title">{{totalCostTitle}}</mat-label>
                          <input data-testid="adx-booking-info-total-cost-input-field" matInput formControlName="totalCost">
                        </mat-form-field>
                      </read-only-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-client-gst-amt-grid-tile">
                      <read-only-form-field title="Client Gst Amount" testId="adx-booking-info-client-gst-amt" value="{{_adxBooking?.clientGstAmt}}" />
                    </mat-grid-tile>
                  </mat-grid-list>

                  <mat-grid-list data-testid="adx-booking-info-grid-list-pay-status" [cols]="2" gutterSize="10px" rowHeight="90">
                    <mat-grid-tile data-testid="adx-booking-info-client-pay-status-grid-tile">
                      <ng-container *ngIf="isEdit; else viewClientPayStatus">
                        <mat-form-field>
                          <mat-label>Client Pay Status</mat-label>
                          <mat-select formControlName="clientPayStatusId">
                            @for (payStatus of payStatusesOptions; track payStatus?.id) {
                            <mat-option [value]="payStatus?.id">{{payStatus?.payStatusName}}</mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </ng-container>
                      <ng-template #viewClientPayStatus>
                        <read-only-form-field title="Client Pay Status"
                                              testId="adx-booking-info-client-pay-status"
                                              [value]="this.clientPayStatusName"></read-only-form-field>
                      </ng-template>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-booking-info-vendor-pay-status-grid-tile">
                      <ng-container *ngIf="isEdit; else viewVendorPayStatus">
                        <mat-form-field>
                          <mat-label>Vendor Pay Status</mat-label>
                          <mat-select formControlName="vendorPayStatusId">
                            @for (payStatus of payStatusesOptions; track payStatus?.id) {
                            <mat-option [value]="payStatus?.id">{{payStatus?.payStatusName}}</mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </ng-container>
                      <ng-template #viewVendorPayStatus>
                        <read-only-form-field title="Vendor Pay Status"
                                              testId="adx-booking-info-vendor-pay-status"
                                              [value]="this.vendorPayStatusName">
                        </read-only-form-field>
                      </ng-template>
                    </mat-grid-tile>
                  </mat-grid-list>

                  @if(clientPayStatusName === 'Closed'){
                    <mat-grid-list data-testid="adx-booking-info-payments-link" [cols]="2" gutterSize="10px" rowHeight="20">
                      <mat-grid-tile data-testid="adx-booking-info-payments-link-list">
                        <a href="#" (click)="openPaymentsLinkModal($event)">Payments</a>
                      </mat-grid-tile>
                    </mat-grid-list>
                  }

                </mat-card-content>
            </mat-card>

            <div class="flex-start m-t-8">
              <button data-testid="adx-booking-detail-edit-button-bottom"
                mat-stroked-button
                color="primary"
                [disabled]="_adxBooking?.isClosedPeriod"
                (click)="toggleEdit()"
                style="margin-right: 10px;">
                {{ isEdit ? 'Cancel' : 'Edit' }}
              </button>

              @if(isEdit) {
                  <button data-testid="adx-booking-detail-update-button-bottom" mat-raised-button color="primary" (click)="updateBooking();" [disabled]="adxBookingInfoForm.invalid">
                      Update
                  </button>
              }
            </div>
        </div>
    </form>
}
