import {Component, OnInit} from '@angular/core';
import {ChartDataSet, ChartOptions} from '../../../../../shared/components/chart/chart.component';
import {ChartsVccService} from '../../../../service/charts-vcc.service';
import * as moment from 'moment';
import {VccChartFilter} from '../../../../model/funding-vcc-chart-filter';
import {CirculationChartModel} from '../../../../model/vcc-chart-model';
import {Merchant} from '../../../../model/merchant-guid';
import {MerchantsService} from 'src/infrastructure/merchants/service/merchants.service';

@Component({
    selector: 'circulation-vcc-chart',
    templateUrl: './circulation-vcc-chart.component.html'
})

export class CirculationVccChartComponent implements OnInit {
    
    public shortLabels: string[] = [];
    merchantGuids: Merchant[] = [];
    public dataCirculation!: ChartDataSet;
    public vccChartFilter: VccChartFilter = {} as VccChartFilter;
    public circulationVccChartOptions: ChartOptions = {
      responsive: true,
      plugins: {
          legend: {
            display: true,
            align: 'center'
          },
          datalabels: {
            display: 'auto',
            anchor: 'end',
            align: 'end',
            font: {
              weight: 'bold'
            },
            formatter: function(value) {
              if(value === 0 ){
                value = '';
                return value;
              }
            }
          }
      }
    }

    constructor(private chartsVccService: ChartsVccService,
      private merchantsService: MerchantsService,
    ) { }
    
    ngOnInit() {
      const today = moment();
      this.chartsVccService.getLatestDate().subscribe((latestReleasedDate) => {
        this.vccChartFilter.propertyName = 'releasedDateFrom';
        this.vccChartFilter.orderKey = 'DESC';
        this.vccChartFilter.releasedDateFrom = latestReleasedDate;
        this.vccChartFilter.releasedDateTo = today.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    
        this.chartsVccService.getVccChartFilter().subscribe((filter) => {
          this.vccChartFilter.merchantGuid = filter.merchantGuid;
          this.vccChartFilter.releasedDateFrom = filter.releasedDateFrom ? moment(filter.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : this.vccChartFilter.releasedDateFrom;
          this.vccChartFilter.releasedDateTo = filter.releasedDateTo ? moment(filter.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : this.vccChartFilter.releasedDateTo;
          
          this.merchantsService.merchants$.subscribe((data)=>{
            this.merchantGuids = data;
            this.getCirculationData();
          });
        });
      });
    }

    get dateFilterText() {
      return this.chartsVccService.getDateSubTitle();
    }
    
    getCirculationData() {
      this.chartsVccService.searchCirculationVcc(this.vccChartFilter).subscribe((circulationResponse: CirculationChartModel[]) => {
        this.buildCirculationDataSet(circulationResponse);
      });
    }

    buildCirculationDataSet(circulationData: CirculationChartModel[]) {
      this.shortLabels = circulationData.map(item => {
        const merchant = this.merchantGuids.find(m => m.merchantGuid === item.merchantGuid);
        return merchant ? merchant.shortName : '';
      });

      const activated = circulationData.map((item) => item.activated);
      const outDated = circulationData.map((item) => item.outDated);

      this.dataCirculation = {
        labels: this.shortLabels,
        datasets: [{
            data: activated,
            label: 'Activated',
            backgroundColor: '#a2798f'
          },
          {
            data: outDated,
            label: 'Outdated Cards',
            backgroundColor: '#4c516d'
          }
        ]
      };
    }
}