import {Component} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {VendorBalanceService} from '../../service/vendor-balance.service';
import {VendorBalanceFilter, VendorBalanceModel} from '../../models/vendor-balances';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';

declare function downloadPdf(pdfBytes: any, fileName: string): void;

@AutoUnsubscribe()
@Component({
  selector: 'app-vendor-balance',
  templateUrl: './vendor-balance.component.html'
})
export class VendorBalanceComponent {

  displayedColumns = [
    "vendorName",
    "id",
    "email",
    "phoneNumber",
    "totalBilled",
    "outstandingBalance",
    "dataSourceName",
  ];

  vendorBalanceFilter$?: Subscription;

  items: VendorBalanceModel[] = [];
  filters: VendorBalanceFilter = {};
  pagination: Partial<PageEvent & { totalItemCount?: number }> = {};
  sorting: Partial<Sort> = {};

  constructor(
    private vendorBalanceService: VendorBalanceService,
    private exportGridService: ExportGridService,
  ) { }

  ngOnInit(): void {
    this.vendorBalanceFilter$ = this.vendorBalanceService.getVendorBalanceFilter().subscribe((filter: VendorBalanceFilter) => {
      this.filters = filter;
      this.pagination = {
        pageSize: this.pagination.pageSize || 25,
        pageIndex: 0,
        totalItemCount: 0,
      };
      this.getItems();
    });
  }

  handleSortChange(sort: Sort): void {
    this.sorting = sort;

    if (!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = {};
    }

    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  getItems(): void {
    this.vendorBalanceService.searchVendorBalance({
      filters: {
        ...this.filters,
      },
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((data) => {
      this.items = data?.items;
      this.pagination = {
        ... this.pagination,
        pageIndex: data.pagination.pageNumber - 1,
        totalItemCount: data.pagination.totalItemCount,
      };
    });
  }

  downloadGridToPdf(): void {
    if (!this.items || this.items.length === 0) {
      console.log('No records available to export to PDF. Please adjust your filters and try again.');
      return;
    }

    this.vendorBalanceService.downloadVendorBalancePdf({
      filters: { ...this.filters },
      page: { pageSize: this.pagination.totalItemCount },
      sort: this.sorting,
    }).subscribe(
      () => {
        console.log('PDF download initiated successfully');
      },
      (error) => {
        console.error('Error downloading PDF:', error);
      }
    );
  }


  downloadGridToFile(): void {
    this.vendorBalanceService.searchVendorBalance({
      filters: {
        ...this.filters,
      },
      page: { pageSize: this.pagination.totalItemCount },
      sort: this.sorting,
    }).subscribe((data) => {
      this.exportGridService.downloadDataSource(
        data,
        'vendor-balance',
        (data) => {
          const record = data as VendorBalanceModel;
          return {
            'Vendor Name': record.vendorName,
            'Vendor ID': record.id,
            'Vendor Email': record.email,
            'Phone Number': record.phoneNumber,
            'Invoice total': record.totalBilled,
            'Balance Due': record.outstandingBalance,
            'Data Source Name': record.dataSourceName,
          }
        }
      )
    });
  }
}
