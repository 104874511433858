@if(adxBookingInfoForm){
    <form data-testid="adx-booking-info-form" class="template-form adx-booking-info-form" [formGroup]="adxBookingInfoForm">  
        <div data-testid="adx-booking-info-main-div" class="filter__fields">
            <div class="items-space-between m-t-8 m-b-5">
                <button data-testid="adx-booking-detail-edit-button" mat-stroked-button color="primary" (click)="toggleEdit();">
                    @if(!isEdit){
                        Edit
                    } @else {
                        Cancel
                    }
                </button>
                @if(isEdit) {
                    <button data-testid="adx-booking-detail-update-button" mat-raised-button color="primary" (click)="updateBooking();" [disabled]="adxBookingInfoForm.invalid">
                        Update
                    </button>
                }
            </div>
            <mat-card class="mat-elevation-z8">
                <mat-card-content>
                    <mat-grid-list data-testid="adx-booking-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
                        <mat-grid-tile data-testid="adx-booking-info-vendor-name-tile">
                            <mat-form-field data-testid="adx-booking-info-vendor-name-field">
                                <mat-label data-testid="adx-booking-info-vendor-name-field-title">Vendor Name: </mat-label>
                                <input data-testid="adx-booking-info-vendor-name-input-field" matInput value="{{_adxBooking?.vendor?.vendorName}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>            
                        <mat-grid-tile data-testid="adx-booking-info-data-source-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-data-source-field">
                                <mat-label data-testid="adx-booking-info-data-source-field-title">Data Source: </mat-label>
                                <input data-testid="adx-booking-info-data-source-input-field" matInput value="{{_adxBooking?.tramsDataSource?.sourceName}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-record-locator-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-record-locator-field">
                                <mat-label data-testid="adx-booking-info-record-locator-field-title">Record Locator: </mat-label>
                                <input data-testid="adx-booking-info-record-locator-input-field" matInput value="{{_adxBooking?.recordLocator}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-reservation-number-grid-tile">
                                <mat-form-field data-testid="adx-booking-info-reservation-number-field">
                                    <mat-label data-testid="adx-booking-info-reservation-number-field-title">Reservation Number: </mat-label>
                                    <input data-testid="adx-booking-info-reservation-number-input-field" matInput value="{{_adxBooking?.reservationNo}}" disabled>
                                </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-invoice-id-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-invoice-id-field">
                                <mat-label data-testid="adx-booking-info-invoice-id-field-title">Invoice Id: </mat-label>
                                <input data-testid="adx-booking-info-invoice-id-input-field" matInput value="{{_adxBooking?.adxInvoiceId}}" disabled>
                                @if(_adxBooking?.adxInvoiceId && !_adxBooking.isModal){
                                    <button matSuffix mat-icon-button aria-label="Navigate to invoice"  matTooltip="Navigate to invoice" (click)="redirectToInvoice();" type="button">
                                        <mat-icon>open_in_new</mat-icon>  
                                    </button>
                                }
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-submit-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-submit-field">
                                <mat-label data-testid="adx-booking-info-submit-field-title">Submit to code: </mat-label>
                                <input data-testid="adx-booking-info-submit-input-field" matInput value="{{_adxBooking?.submitToCode}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-depart-date-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-depart-date-field">
                                <mat-label data-testid="adx-booking-info-depart-date-field-title">Depart Date: </mat-label>
                                <input data-testid="adx-booking-info-depart-date-input-field" matInput value="{{formatDate(_adxBooking?.departDate)}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-return-date-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-return-date-field">
                                <mat-label data-testid="adx-booking-info-return-date-field-title">Return Date: </mat-label>
                                <input data-testid="adx-booking-info-return-date-input-field" matInput value="{{formatDate(_adxBooking?.returnDate)}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-confirmation-number-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-confirmation-number-field">
                                <mat-label data-testid="adx-booking-info-confirmation-number-field-title">Confirmation Number: </mat-label>
                                <input data-testid="adx-booking-info-confirmation-number-input-field" matInput value="{{_adxBooking?.confirmationNumber}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-travel-type-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-travel-type-field">
                                <mat-label data-testid="adx-booking-info-travel-type-field-title">Travel Type: </mat-label>
                                <input data-testid="adx-booking-info-travel-type-input-field" matInput value="{{_adxBooking?.travelTypeId}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-number-of-pax-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-number-of-pax-field">
                                <mat-label data-testid="adx-booking-info-number-of-pax-field-title">Number Of Pax: </mat-label>
                                <input data-testid="adx-booking-info-number-of-pax-input-field" matInput value="{{_adxBooking?.numberOfPax}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-currency-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-currency-field">
                                <mat-label data-testid="adx-booking-info-currency-field-title">Currency: </mat-label>
                                <input data-testid="adx-booking-info-currency-input-field" matInput value="{{_adxBooking?.currency?.currencyName}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-comm-amt-grid-tile">
                            @let commissionAmountTitle = 'Commission Amount';
                            <editable-form-field [readonly]="!isEdit" [title]="commissionAmountTitle" testId="adx-booking-info-comm-amt">
                                <mat-form-field data-testid="adx-booking-info-comm-amt-field">
                                    <mat-label data-testid="adx-booking-info-comm-amt-field-title">{{commissionAmountTitle}}</mat-label>
                                    <input data-testid="adx-booking-info-comm-amt-input-field" matInput formControlName="commAmt">
                                </mat-form-field>
                            </editable-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-comm-rate-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-comm-rate-field">
                                <mat-label data-testid="adx-booking-info-comm-rate-field-title">Commission Rate: </mat-label>
                                <input data-testid="adx-booking-info-comm-rate-input-field" matInput value="{{_adxBooking?.commRate}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-tax1-grid-tile">
                            @let taxOneTitle = 'Tax 1';
                            <editable-form-field [readonly]="!isEdit" [title]="taxOneTitle" testId="adx-booking-info-tax1">
                                <mat-form-field data-testid="adx-booking-info-tax1-field">
                                    <mat-label data-testid="adx-booking-info-tax1-field-title">{{taxOneTitle}}</mat-label>
                                    <input data-testid="adx-booking-info-tax1-input-field" matInput formControlName="tax1Amt">
                                </mat-form-field>
                            </editable-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-tax2-grid-tile">
                            @let taxTwoTitle = 'Tax 2';
                            <editable-form-field [readonly]="!isEdit" [title]="taxTwoTitle" testId="adx-booking-info-tax2">
                                <mat-form-field data-testid="adx-booking-info-tax2-field">
                                    <mat-label data-testid="adx-booking-info-tax2-field-title">{{taxTwoTitle}}</mat-label>
                                    <input data-testid="adx-booking-info-tax2-input-field" matInput formControlName="tax2Amt">
                                </mat-form-field>
                            </editable-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-tax3-grid-tile">
                            @let taxThreeTitle = 'Tax 3';
                            <editable-form-field [readonly]="!isEdit" [title]="taxThreeTitle" testId="adx-booking-info-tax3">
                                <mat-form-field data-testid="adx-booking-info-tax3-field">
                                    <mat-label data-testid="adx-booking-info-tax3-field-title">{{taxThreeTitle}}</mat-label>
                                    <input data-testid="adx-booking-info-tax3-input-field" matInput formControlName="tax3Amt">
                                </mat-form-field>
                            </editable-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-tax4-grid-tile">
                            @let taxFourTitle = 'Tax 4';
                            <editable-form-field [readonly]="!isEdit" [title]="taxFourTitle" testId="adx-booking-info-tax4">
                                <mat-form-field data-testid="adx-booking-info-tax4-field">
                                    <mat-label data-testid="adx-booking-info-tax4-field-title">{{taxFourTitle}}</mat-label>
                                    <input data-testid="adx-booking-info-tax4-input-field" matInput formControlName="tax4Amt">
                                </mat-form-field>
                            </editable-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-gst-amt-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-gst-amt-field">
                                <mat-label data-testid="adx-booking-info-gst-amt-field-title">Gst Amount: </mat-label>
                                <input data-testid="adx-booking-info-gst-amt-input-field" matInput value="{{_adxBooking?.gstAmt}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-qst-amt-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-qst-amt-field">
                                <mat-label data-testid="adx-booking-info-qst-amt-field-title">Qst Amount: </mat-label>
                                <input data-testid="adx-booking-info-qst-amt-input-field" matInput value="{{_adxBooking?.qstAmt}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-high-fare-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-high-fare-field">
                                <mat-label data-testid="adx-booking-info-high-fare-field-title">High Fare: </mat-label>
                                <input data-testid="adx-booking-info-high-fare-input-field" matInput value="{{_adxBooking?.highFare}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-low-fare-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-low-fare-field">
                                <mat-label data-testid="adx-booking-info-low-fare-field-title">Low Fare: </mat-label>
                                <input data-testid="adx-booking-info-low-fare-input-field" matInput value="{{_adxBooking?.lowFare}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-total-fare-grid-tile">
                            @let totalFareTitle = 'Total Fare';
                            <editable-form-field [readonly]="!isEdit" [title]="totalFareTitle" testId="adx-booking-info-total-fare">
                                <mat-form-field data-testid="adx-booking-info-total-fare-field">
                                    <mat-label data-testid="adx-booking-info-total-fare-field-title">{{totalFareTitle}}</mat-label>
                                    <input data-testid="adx-booking-info-total-fare-input-field" matInput formControlName="totalFare">
                                </mat-form-field>
                            </editable-form-field>
                        </mat-grid-tile>
                        <mat-grid-tile data-testid="adx-booking-info-client-gst-amt-grid-tile">
                            <mat-form-field data-testid="adx-booking-info-client-gst-amt-field">
                                <mat-label data-testid="adx-booking-info-client-gst-amt-field-title">Client Gst Amount: </mat-label>
                                <input data-testid="adx-booking-info-client-gst-amt-input-field" matInput value="{{_adxBooking?.clientGstAmt}}" disabled>
                            </mat-form-field>
                        </mat-grid-tile>
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
}