<mat-dialog-content style="overflow: inherit;">
    <div id="transaction-history-container" style="display: flex; justify-content: center; align-items: center;">
    @if(transactions){
            @if (transactions.length) {
                <mat-tab-group style="min-width: 100%;">
                @for (transaction of transactions; track transaction) {
                    <mat-tab label="{{transaction?.amount}} - {{transaction.status | capitalize}}">
                      <form id="transaction-history-viewer-form" class="template-form">
                        <div id="transaction-history-main-container" class="container">
                          <read-only-form-field title="Date" testId="transaction-history-timestamp" value="{{transaction?.timestamp | date:'M/d/yyyy'}} {{transaction?.timestamp | date:'shortTime'}}" />

                          <read-only-form-field title="Amount" testId="transaction-history-amount" value="{{transaction?.amount}}" />

                          <read-only-form-field title="Status" testId="transaction-history-status" value="{{transaction?.status}}" />

                          <read-only-form-field title="Description" testId="transaction-history-description" value="{{transaction?.description}}" />

                          <read-only-form-field title="Currency" testId="transaction-history-currency" value="{{transaction?.currencyCode}}" />

                          <read-only-form-field title="Card Guid" testId="transaction-history-card-guid" value="{{transaction?.cardGuid}}" />

                          <read-only-form-field title="Merchant Name" testId="transaction-history-merchant-name" value="{{transaction?.merchantName}}" />

                          <read-only-form-field title="Merchant Mcc" testId="transaction-history-merchant-mcc" value="{{transaction?.merchantMcc}}" />

                          <read-only-form-field title="Approval Code" testId="transaction-history-approval-code" value="{{transaction?.approvalCode}}" />

                          <read-only-form-field title="Card Acceptor" testId="transaction-history-card-acceptor-mid" value="{{transaction?.cardAcceptorMid}}" />

                          <read-only-form-field title="Transaction Guid" testId="transaction-history-transaction-guid" value="{{transaction?.transactionGuid}}" />

                          <read-only-form-field title="Network" testId="transaction-history-net-work" value="{{transaction?.netWork}}" />

                          <read-only-form-field title="Type" testId="transaction-history-type" value="{{transaction?.type}}" />

                          @if (transaction?.precedingRelatedToken) {
                            <read-only-form-field title="Preceding Related Token" testId="transaction-history-preceding-related-token" value="{{transaction?.precedingRelatedToken}}" />
                          }

                          @if (transaction?.merchantAlias) {
                            <read-only-form-field title="Merchant Alias" testId="transaction-history-merchant-alias" value="{{transaction?.merchantAlias}}" />
                          }
                        </div>
                      </form>
                    </mat-tab>
                }
                </mat-tab-group>
            } @else {
                
                <span>There are no transactions to display.</span>
            }
    } @else {
            @if (isLoading) {
                <div class="loading-spinner">
                    <mat-spinner></mat-spinner>
                </div>
            } @else {
                <button mat-raised-button color="primary" (click)="loadHistory()">History</button>
            }
    }
    </div>
</mat-dialog-content>