import {Component, OnInit} from '@angular/core';
import {VccChartFilter} from '../../../../model/funding-vcc-chart-filter';
import {VccChartModel} from '../../../../model/vcc-chart-model';
import {ChartsVccService} from '../../../../service/charts-vcc.service';
import {Sort} from '@angular/material/sort';
import {MerchantsService} from 'src/infrastructure/merchants/service/merchants.service';
 
@Component({
    selector: 'financial-balances-vcc-list',
    templateUrl: './financial-balances-vcc-list.component.html'
})

export class FinancialBalancesVccListComponent implements OnInit{

    displayedColumns: Array<string> = [
        'merchantName',
        'totalBalance',
        'outstandingLiability',
    ];
    public vccChartFilter: VccChartFilter = {} as VccChartFilter;
    items: VccChartModel[] = [];
    sorting: Partial<Sort> = {};
    accAvailableFundsUSD: number[]= [];
    totalFundsUSD: number = 0;
    accAvailableFundsCAD: number[] = [];
    totalFundsCAD: number = 0;

    constructor(
        private chartsVccService: ChartsVccService,
        private merchantsService: MerchantsService,
    ) {}

    ngOnInit(): void {

        this.vccChartFilter.propertyName = 'releasedDateFrom';
        this.vccChartFilter.orderKey = 'DESC';
        this.chartsVccService.getVccChartFilter().subscribe(() => {
            this.getItems();
        });
    }

    get dateFilterText() {
        return this.chartsVccService.getDateSubTitle();
    }

    handleSortChange(sort: Sort): void { 
        this.sorting = sort;
        if(!this.sorting?.active || !this.sorting?.direction) {
          this.sorting = {};
        }
        this.getItems();
    }

    getItems(){
        this.chartsVccService.getFundingData().subscribe((data) => {
            this.items = data.map((item) => {
                return {
                    merchantGuid: item.merchantGuid,
                    newCashBalance: item.newCashBalance,
                    priorDayCashBalance: item.priorDayCashBalance,
                    totalAti: item.totalAti,
                    totalAta: item.totalAta,
                    outstandingLiability: item.outstandingLiability,
                    outstandingAuthorization: item.outstandingAuthorization,
                }
            });
            
            this.merchantsService.merchants$.subscribe((data) => {
                this.items = this.items.map(item => {
                    const merchant = data.find(merchant => merchant.merchantGuid === item.merchantGuid);
                    return {
                        ...item,
                        merchantName: merchant ? merchant.fullName : '',
                        currencyCode: merchant?.currencyName,
                        isIssueTypeAccount: merchant?.isIssueTypeAccount,
                        isAuthorizeTypeAccount: merchant?.isAuthorizeTypeAccount
                    };
                });
            });
            this.accAvailableFundsUSD = [];
            this.accAvailableFundsCAD = [];
            this.items.map((item) => {
                if (item.currencyCode === 'USD'){
                    if(item.isAuthorizeTypeAccount){
                        if (item.totalAta) {
                            this.accAvailableFundsUSD.push(item.totalAta);
                        }
                    } else if (item.isIssueTypeAccount){
                        if (item.totalAti) {
                            this.accAvailableFundsUSD.push(item.totalAti);
                        }
                    }
                }else {
                    if(item.isAuthorizeTypeAccount){
                        if (item.totalAta) {
                            this.accAvailableFundsCAD.push(item.totalAta);
                        }
                    } else if (item.isIssueTypeAccount){
                        if (item.totalAti) {
                            this.accAvailableFundsCAD.push(item.totalAti);
                        }
                    }
                }
            });
            this.totalFundsUSD = this.accAvailableFundsUSD.reduce((acc, item) => { acc += item; return acc; }, 0);
            this.totalFundsCAD = this.accAvailableFundsCAD.reduce((acc, item) => { acc += item; return acc; }, 0);
        });
    }
    
}