import {Component} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {AdxPaymentService} from '../../service/adx-payment.service';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AdxPaymentFilter, AdxPaymentModel} from '../../models/adx-payment';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';
import {PaymentFormComponent} from '../payment-form/payment-form.component';
import {MatDialog} from '@angular/material/dialog';
import {AdxPaymentDetailsModalComponent} from 'src/app/adx-payment/components/adx-payment-details-modal/adx-payment-details-modal.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-payments-made-grid',
  templateUrl: './payments-made-grid.component.html'
})
export class PaymentsMadeGridComponent {
    displayedColumns = [
        'fmsAdxPaymentDetailId',
        'payeeName',
        'vendorName',
        'payTypeName',
        'payMethodName',
        'profileTypeName',
        'amount',
        'ckCcNo',
        'branchName',
        'bankName',
        'paymentDate',
        'tripExternalReference'
    ];

    matchingFilter$?: Subscription;
    
    items: AdxPaymentModel[] = [];
    filters: AdxPaymentFilter = {};
    pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
    sorting: Partial<Sort> = {};

    constructor(
        private adxPaymentService: AdxPaymentService,
        private exportGridService: ExportGridService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
    ){}

    ngOnInit(): void {
        this.matchingFilter$ = this.adxPaymentService.getPaymentMadeFilter().subscribe((filter:AdxPaymentFilter)=>{
            this.filters = filter;
            this.pagination = {
                pageSize: this.pagination.pageSize || 25,
                pageIndex: 0,
                totalItemCount: 0,
            };
            this.getItems();
        });
    }

    handleSortChange(sort: Sort): void { 
        this.sorting = sort;
    
        if(!this.sorting?.active || !this.sorting?.direction) {
          this.sorting = {};
        }
      
        this.getItems();
    }
        
    handlePageChanged(page: PageEvent): void {
        this.pagination = {
          ...this.pagination,
          ...page,
        };
    
        this.getItems();
    }
    
    getItems(): void {
        this.adxPaymentService.searchAdxPayment({
          filters: {
            ...this.filters,
            payTypeId: 2,
          },
          page: this.pagination,
          sort: this.sorting,
        }).subscribe((data)=>{
          this.items = data?.items;
          this.pagination = {
            ... this.pagination,
            pageIndex: data.pagination.pageNumber - 1,
            totalItemCount: data.pagination.totalItemCount,
          };
        });
    }

    downloadGridToFile(): void {
        this.exportGridService.getByDataSource(
          'dbov2/adx-payment-detail/search',
          'payments-made',
          {
            ... this.adxPaymentService.getAdxPaymentFilters(this.filters),
            pageSize: this.pagination?.totalItemCount || 100,
          },
          (data) => {
            const record = data as AdxPaymentModel;
            return {
              'Payment Fms Id': record?.fmsAdxPaymentDetailId,
              'Payee Name': record?.payeeName,
              'Vendor Name': record?.vendorName,
              'Pay Type': record?.payTypeName,
              'Pay Method': record?.payMethodName,
              'Profile Type': record?.profileTypeName,
              'Amount': record?.amount,
              'ckCcNo': record?.ckCcNo,
              'Branch': record?.branchName,
              'Bank': record?.bankName,
              'Payment Date': this.datePipe.transform(record?.paymentDate, 'M/d/yyyy'),
              'ADX Trip Ref': record?.tripExternalReference
            }
          }
        );
    }

    handleCreatePaymentDialog(): void {
      this.dialog.open(PaymentFormComponent).afterClosed().subscribe((saved)=>{
        if(saved){
          this.getItems();
        }
      });
    }
      
    handleIdClick(event: Event, element: any): void {
      event.preventDefault();
      this.openDialog(element.fmsAdxPaymentDetailId, `Payment information`);
    }
  
    public openDialog(adxPaymentId: number, title: string): void {
      this.dialog.open(AdxPaymentDetailsModalComponent, {
        width: '55%',
        data: { adxPaymentId: adxPaymentId, title: title }
      });
    }
}
