import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs';
import {TramsDataSourceModel} from '../models/trams-data-source';

@Injectable({
  providedIn: 'root'
})
export class TramsDataSourceService {
  private resourcePath: string = 'dbov2/trams-data-source';
  
  constructor(private http: HttpClient) {}

  getTramsDataSourceList() {
    return this.http.get<TramsDataSourceModel[]>(`${this.resourcePath}`)
    .pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }
}
