import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { GeneralLedgerService } from '../../service/general-ledger.service';
import { ExportGridService } from 'src/app/shared/service/files/export-grid.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateTravelTypeMappingComponent } from '../create-travel-type-mapping/create-travel-type-mapping.component';
import { DeleteTtmConfirmationComnponent } from '../delete-travel-type-mapping/delete-travel-type-mapping-confirmation.component';
import { GeneralLedgerTravelTypeModel } from '../../model/general-ledger';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-gl-travel-type-mapping',
  templateUrl: './travel-type-mapping.component.html',
})
export class GLTravelTypeMappingComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'glNo',
    'glName',
    'travelTypeName',
    'dateCreated',
    'userCreated',
    'dateUpdated',
    'userupdated',
    'action',
  ];

  items: GeneralLedgerTravelTypeModel[] = [];
  pagination: Partial<PageEvent & { totalItemCount?: number }> = {};
  sorting: Partial<Sort> = {};

  constructor(
    private generalLedgerService: GeneralLedgerService,
    private exportGridService: ExportGridService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.pagination = {
      pageSize: this.pagination.pageSize || 25,
      pageIndex: 0,
      totalItemCount: 0,
    };
    this.getItems();
  }

  getItems() {
    this.generalLedgerService.searchGlTravelType({
      filters: {},
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((data) => {
      this.items = data?.items;
      this.pagination = {
        ...this.pagination,
        pageIndex: data.pagination.pageNumber - 1,
        totalItemCount: data.pagination.totalItemCount,
      };
    });
  }

  handleSortChange(sort: Sort): void {
    // Transform the sort.active value to match the API's nested property structure.
    let active = sort.active;
    if (active === 'glNo' || active === 'glName') {
      active = `generalLedger.${active}`;
    } else if (active === 'travelTypeName') {
      active = 'travelType.travelTypeName';
    }

    this.sorting = {
      active: active,
      direction: sort.direction,
    };

    if (!this.sorting.active || !this.sorting.direction) {
      this.sorting = {};
    }

    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  downloadGridToFile(): void {
    this.exportGridService.getByDataSource(
      'dbov2/general-ledger-travel-type',
      'general-ledger-travel-type',
      {
        pageSize: this.pagination?.totalItemCount || 100,
      },
      (data) => {
        const record = data as GeneralLedgerTravelTypeModel;
        return {
          'id': record?.id,
          'GL Number': record?.generalLedger?.glNo,
          'GL Name': record?.generalLedger?.glName,
          'Travel Type Name': record?.travelType?.travelTypeName,
          'Date Created': this.datePipe.transform(record?.dateCreated, 'M/dd/yyyy'),
          'User Created': record?.userCreated,
          'Date Updated': this.datePipe.transform(record?.dateUpdated, 'M/dd/yyyy'),
          'User Updated': record?.userUpdated,
        }
      }
    );
  }

  openNewMappingModal(): void {
    this.dialog.open(CreateTravelTypeMappingComponent).afterClosed().subscribe((saved) => {
      if (saved) {
        this.getItems();
      }
    });
  }

  editMappingModal(element: GeneralLedgerTravelTypeModel): void {
    if (element) {
      this.dialog.open(CreateTravelTypeMappingComponent, { data: element })
        .afterClosed()
        .subscribe((saved) => {
          if (saved) {
            this.getItems();
          }
        });
    }
  }

  deleteMappingModal(element: GeneralLedgerTravelTypeModel): void {
    if (element) {
      this.dialog.open(DeleteTtmConfirmationComnponent, { data: element })
        .afterClosed()
        .subscribe((saved) => {
          if (saved) {
            this.getItems();
          }
        });
    }
  }
}
