import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ActivatedRoute} from '@angular/router';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-adx-invoice-details',
  templateUrl: './adx-invoice-details.component.html'
})

@AutoUnsubscribe()
export class AdxInvoiceDetailsComponent implements OnInit {
  data: any;
  loading$: Observable<boolean>;
  constructor(private route: ActivatedRoute,
    public loader: LoadingService
  ) {
    this.loading$ = this.loader.loading$;
  }

  public ngOnInit(): void{
    this.route.paramMap.subscribe(params => {
      this.data = {
        adxInvoiceId: params.get('id'),
        pageSize: 25
      }
    });
  }
}
