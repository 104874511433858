import {Component, effect, Input, input, output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdxBookingService} from '../../service/adx-booking.service';
import {PayStatusesResponse} from '../../model/pay-statuses-response';

@Component({
  selector: 'app-adx-booking-info',
  templateUrl: './adx-booking-info.component.html',
})
@AutoUnsubscribe()
export class AdxBookingInfoComponent {
  @Input() payStatusesOptions?: PayStatusesResponse[];
  data = input<any>();
  submitedData = output<boolean>();
  _adxBooking: any;
  adxBookingInfoForm?: FormGroup;
  isEdit = false;
  regex = /^\d{1,10}(\.\d{1,4})?$/;
  infoInvoiceField = {
    icon: 'open_in_new',
    tooltip: 'Navigate to invoice',
  };
  payStatusOptions = [
    { id: 1, description: 'Closed' },
    { id: 2, description: 'N/A' },
    { id: 3, description: 'Open' },
    { id: 4, description: 'Voided' },
  ];
  vendorPayStatusName!: string;
  clientPayStatusName!: string;

  constructor(
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private adxBookingService: AdxBookingService
  ) {
    effect(() => {
      this.getAdxBookingInfo();
    });
  }

  ngOnInit() {
    this.getAdxBookingInfo();
    this.createForm();
  }

  getAdxBookingInfo() {
    if (this.data() != null) {
      this._adxBooking = this.data();
      this.vendorPayStatusName = this.payStatusesOptions?.find(o => o.id === this._adxBooking?.vendorPayStatusId)?.payStatusName || '';
      this.clientPayStatusName = this.payStatusesOptions?.find(o => o.id === this._adxBooking?.clientPayStatusId)?.payStatusName || '';
      this.resetForm();
    }
  }

  createForm(): void {
    this.adxBookingInfoForm = this.fb.group({
      commAmt: [
        this._adxBooking?.commAmt,
        [Validators.required, Validators.pattern(this.regex)],
      ],
      tax1Amt: [
        this._adxBooking?.tax1Amt,
        [Validators.required, Validators.pattern(this.regex)],
      ],
      tax2Amt: [
        this._adxBooking?.tax2Amt,
        [Validators.required, Validators.pattern(this.regex)],
      ],
      tax3Amt: [
        this._adxBooking?.tax3Amt,
        [Validators.required, Validators.pattern(this.regex)],
      ],
      tax4Amt: [
        this._adxBooking?.tax4Amt,
        [Validators.required, Validators.pattern(this.regex)],
      ],
      totalFare: [
        this._adxBooking?.totalFare,
        [Validators.required, Validators.pattern(this.regex)],
      ],
      totalCost: [
        this._adxBooking?.totalCost,
        [Validators.required, Validators.pattern(this.regex)],
      ],
      clientPayStatusId: [
        this._adxBooking?.clientPayStatusId,
        Validators.required,
      ],
      vendorPayStatusId: [
        this._adxBooking?.vendorPayStatusId,
        Validators.required,
      ],
    });
  }

  resetForm(): void {
    this.adxBookingInfoForm?.patchValue({
      commAmt: this._adxBooking?.commAmt ?? 0,
      tax1Amt: this._adxBooking?.tax1Amt ?? 0,
      tax2Amt: this._adxBooking?.tax2Amt ?? 0,
      tax3Amt: this._adxBooking?.tax3Amt ?? 0,
      tax4Amt: this._adxBooking?.tax4Amt ?? 0,
      totalFare: this._adxBooking?.totalFare ?? 0,
      totalCost: this._adxBooking?.totalCost ?? 0,
      clientPayStatusId: this._adxBooking?.clientPayStatus?.id ?? 'NULL',
      vendorPayStatusId: this._adxBooking?.vendorPayStatus?.id ?? 'NULL'
    });
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
    this.resetForm();
  }

  updateBooking(): void {
    const formValues = this.adxBookingInfoForm?.value;
    const updatedBooking = {
      ...this._adxBooking,
      ...formValues,
      submitToCode: this._adxBooking?.submitToCode[0] || '',
      adxInvoiceId: this._adxBooking?.adxInvoiceId,
      id: this._adxBooking?.id,
    };
    
    this.vendorPayStatusName = this.payStatusesOptions?.find(o => o.id === updatedBooking?.vendorPayStatusId)?.payStatusName || '';
    this.clientPayStatusName = this.payStatusesOptions?.find(o => o.id === updatedBooking?.clientPayStatusId)?.payStatusName || '';
    
    this.adxBookingService.updateAdxBooking(updatedBooking).subscribe(() => {
      this.isEdit = false;
      this.submitedData.emit(true);

      this._snackBar.open('Booking updated successfully', 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    });
  }

  hasRedirect(): boolean {
    return this._adxBooking?.adxInvoiceId && !this._adxBooking.isModal;
  }

  redirectToInvoice(): void {
    if (this.hasRedirect()) {
      location.href = `AdxInvoices/${this._adxBooking?.adxInvoiceId}`;
    }
  }
}
