<modal-panel id="bank-account-modal-panel" title="{{title}}"  (close)="handleBankAccountModalCloseBtnClick()">
    <form [formGroup]="bankAccountModalForm" id="bank-account-modal-form" class="template-form">
        <div id="bank-account-main-container" class="container">
            <div id="bank-account-error-div" *ngIf="bankAccountModalForm.invalid">
                <mat-error>Form is invalid</mat-error>
            </div>
            <h2> {{data.fundingData[0].clientName}} </h2>
            <h4> {{data.dateRange | async}} </h4>

            @if (data.fundingData[0].totalAta === 0) {
                <read-only-form-field title="Total Balance" testId="bank-account-total-ati" value="{{data.fundingData[0].totalAti}}" />
                <read-only-form-field title="Outstanding Liability" testId="bank-account-outstanding-liability" value="{{data.fundingData[0].outstandingLiability}}" />
            }

            @if (data.fundingData[0].totalAti === 0) {
                <read-only-form-field title="Total Balance" testId="bank-account-total-ata" value="{{data.fundingData[0].totalAta}}" />
                <read-only-form-field title="Outstanding Authorization" testId="bank-account-outstanding-authorization" value="{{data.fundingData[0].outstandingAuthorization}}" />
            }

            <read-only-form-field title="Sales Allowance" testId="bank-account-sales-allowance" value="{{data.fundingData[0].salesAllowance}}" />

            <read-only-form-field title="Overage" testId="bank-account-overage" value="{{data.fundingData[0].overage}}" />

            <read-only-form-field title="Total Activity" testId="bank-account-total-activity" value="{{data.fundingData[0].totalActivity}}" />

            <read-only-form-field title="Beginning Cash Balance" testId="bank-account-beginning-cash-balance" value="{{data.fundingData[0].beginningCashBalance}}" />

            <read-only-form-field title="Ending Cash Balance" testId="bank-account-ending-cash-balance" value="{{data.fundingData[0].endingCashBalance}}" />

            <read-only-form-field title="Minimun Cash Balance" testId="bank-account-minimum-cash-balance" value="{{data.fundingData[0].minimumCashBalance}}" />

            <read-only-form-field title="New Cash Balance" testId="bank-account-new-cash-balance-field" value="{{data.fundingData[0].newCashBalance}}" />

            <read-only-form-field title="Prior Day Cash Balance" testId="bank-account-prior-day-cash-balance-field" value="{{data.fundingData[0].priorDayCashBalance}}" />
            
            <read-only-form-field title="Credit Chargeback Amount" testId="bank-account-credit-chargeback" value="{{data.fundingData[0].creditChargebackAmount}}" />

            <read-only-form-field title="Credit Return Amount" testId="bank-account-credit-return" value="{{data.fundingData[0].creditReturnAmount}}" />

            <read-only-form-field title="Credit Sale Amount" testId="bank-account-credit-sale" value="{{data.fundingData[0].creditSaleAmount}}" />

            <read-only-form-field title="Credit Void Amount" testId="bank-account-credit-void" value="{{data.fundingData[0].creditVoidAmount}}" />

            <read-only-form-field title="Net Debit Credit To Client" testId="bank-account-net-debit-credit" value="{{data.fundingData[0].netDebitCreditToClient}}" />

            <read-only-form-field title="Purchase Card Amount" testId="bank-account-purchase-card-amount" value="{{data.fundingData[0].purchaseCardAmount}}" />

            <read-only-form-field title="Purchase Card Return Amount" testId="bank-account-purchase-card-return-amount" value="{{data.fundingData[0].purchaseCardReturnAmount}}" />

            <read-only-form-field title="Wire Ach To Fund Cash Amount" testId="bank-account-wire-ach-fund" value="{{data.fundingData[0].wireAchToFundCashAmount}}" />

        </div>
        <br>
        <div class="row">
            <ng-container #container></ng-container>
        </div>
    </form>
    <ng-container modalPanelFooter>
        <button id="bank-account-modal-close-button" mat-raised-button color="primary" (click)="handleBankAccountModalCloseBtnClick()">Close</button>
    </ng-container>
</modal-panel>