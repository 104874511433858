import {APP_INITIALIZER, Provider} from '@angular/core';
import {MerchantsService} from '../service/merchants.service';

export const MERCHANTS_PROVIDER = (): Provider => {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (merchantsService: MerchantsService)=>{
        return ()=> merchantsService.init();
      },
    deps: [MerchantsService],
  };
}
