<h2 mat-dialog-title>Manage Virtual Credit Card for Service ID: '{{inputData.id}}'</h2>
<hr>
<mat-dialog-content>
  <ng-container *ngIf="!isLoading; else loadingView">
    <mat-dialog-actions>
      <ng-container id="vcc-header-issue-container" *ngIf="isTypeUpdate">
        <button id="vcc-header-issue-button" mat-raised-button color="primary" (click)="handleIssue()">Issue</button>
      </ng-container>
    </mat-dialog-actions>
    <form [formGroup]="vccViewerForm" id="vcc-viewer-form" class="template-form vcc-viewer-form">
      <div id="vcc-main-container" class="container">
        <mat-tab-group dynamicHeight>
          <mat-tab *ngFor="let vcc of _vccs; let index = index" [label]= "vcc.id + ''">
            @if (vcc.vccDataSource !== undefined && vcc.vccDataSource !== null) {
              <read-only-form-field title="Data Source" testId="vcc-data-source" value="{{vcc.vccDataSource}}"/>
            }
            @if (vcc.virtualCreditCardUrl !== undefined && vcc.virtualCreditCardUrl !== null) {
              <read-only-form-field title="URL" testId="vcc-url" value="{{vcc.virtualCreditCardUrl}}"
                (keypress)="navigateToConnexPayUrl(vcc.virtualCreditCardUrl)"
                (click)="navigateToConnexPayUrl(vcc.virtualCreditCardUrl)"
              />
            }
            @if (vcc.vccNumber !== undefined && vcc.vccNumber !== null) {
              <read-only-form-field title="Number" testId="vcc-number" value="{{vcc.vccNumber}}"/>
            }
            @if (vcc.virtualCreditCardIssueDate !== undefined && vcc.virtualCreditCardIssueDate !== null) {
              <read-only-form-field title="Issue Date" testId="vcc-issue-date" value="{{vcc.virtualCreditCardIssueDate | date: 'M/d/yyyy'}}"/>
            }
            @if (vcc.virtualCreditCardStartActiveDate !== undefined && vcc.virtualCreditCardStartActiveDate !== null) {
              <read-only-form-field title="Active From Date" testId="vcc-start-active-date" value="{{vcc.virtualCreditCardStartActiveDate | date: 'M/d/yyyy'}}"/>
            }
            @if (vcc.virtualCreditCardEndActiveDate !== undefined && vcc.virtualCreditCardEndActiveDate !== null) {
              <read-only-form-field title="Active To Date" testId="vcc-start-active-date" value="{{vcc.virtualCreditCardEndActiveDate | date: 'M/d/yyyy'}}"/>
            }
            @if (vcc.virtualCreditCardTerminateDate !== undefined && vcc.virtualCreditCardTerminateDate !== null) {
              <read-only-form-field title="Terminate Date" testId="vcc-terminate-date" value="{{vcc.virtualCreditCardTerminateDate | date: 'M/d/yyyy'}}"/>
            }
            @if (vcc.virtualCreditCardCurrentAmt !== undefined && vcc.virtualCreditCardCurrentAmt !== null) {
              <read-only-form-field title="Current Amount" testId="vcc-current-amt" value="{{vcc.virtualCreditCardCurrentAmt}}"/>
            }
            @if (vcc.currency.currencyName !== undefined && vcc.currency.currencyName !== null) {
              <read-only-form-field title="Currency" testId="vcc-currency-name" value="{{vcc.currency.currencyName}} - {{vcc.currency.code}} ({{vcc.currency.symbol}})"/>
            }
            <button id="vcc-show-more-button" mat-raised-button color="primary" (click)="showMore(index)" [hidden]="vcc.isVccPaymentGatewayInfoLoaded">Show more</button>
            <app-vcc-payment-gateway-viewer [data]="this._vccs[index]" [isLoaderVisible]="this.isLoaderVisible" *ngIf="vcc.isVccPaymentGatewayInfoLoaded"></app-vcc-payment-gateway-viewer>
          
            <div id="vcc-footer-update-container" class="vcc-footer-update-container" *ngIf="isTypeUpdate && vcc.vccDataSource == 'Issued in FMS VCC'">
              <button id="vcc-footer-update-button" mat-raised-button color="primary" (click)="handleUpdate(index)">Update</button>
              <button id="vcc-footer-delete-button" mat-raised-button color="primary" (click)="handleDelete(index)">Delete</button>
            </div>
          </mat-tab>
        </mat-tab-group>
        <div *ngIf="this._vccs.length === 0">
          <div id="no-vcc-issued-table-feed-text" class="table-feed-text col-md-12" style="margin-top: 2%;">
            <mat-label>
              There is no virtual credit card (VCC) issued so far for the selected service.
              Please ask manager role user to issue VCC for service ID: <b>'{{ inputData.id }}'</b>.
            </mat-label>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</mat-dialog-content>
<hr>
<mat-dialog-actions class="p-l-2">
  <button mat-raised-button color="warn" mat-dialog-close>Close</button>
</mat-dialog-actions>

  <ng-template #loadingView>
    <div class="loading-spinner">
      <mat-spinner *ngIf="isLoaderVisible"></mat-spinner>
    </div>
  </ng-template>
