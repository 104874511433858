import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {throwError, Observable, BehaviorSubject, map} from 'rxjs';
import {VccChartFilterResult, VccChartFilter} from '../model/funding-vcc-chart-filter';
import {ParsedMguid} from '../model/merchant-guid';
import {VccChartModel, ChartResponse, AggregatedChartModel, CirculationChartModel} from '../model/vcc-chart-model';
import {MerchantGuidShortName} from '../model/vcc-chart-label';
import {DatePipe} from '@angular/common';
import {MatchingResult} from '../model/matching-result';
import {removeEmptyParams} from '../../../infrastructure/helper/remove-empty-params';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class ChartsVccService {
  private resourcePath: string = 'dbov2/connex-pay-accounting-daily-summary';
  private resourcePathDetail: string = 'dbov2/connex-pay-accounting-daily-detail';
  private resourcePathStatus: string = 'dbov2/connex-pay-event-detail';
  private _vccChartFilterSubject: BehaviorSubject<VccChartFilterResult> = new BehaviorSubject<VccChartFilterResult>({});
  private _fundingDataSubject: BehaviorSubject<VccChartModel[]> = new BehaviorSubject<VccChartModel[]>([]);
  private _merchantGuidDataSubject: BehaviorSubject<ParsedMguid> = new BehaviorSubject<ParsedMguid>({ merchantGuid: [] });

  public shortLabels: string[] = [];
  public parsedMguid: ParsedMguid = { merchantGuid: [] };
  filters?: VccChartFilterResult;

  constructor(private httpClient: HttpClient,
              private datePipe: DatePipe,
  ) {
  }

  getVccChartFilter(): Observable<VccChartFilterResult> {
    return this._vccChartFilterSubject.asObservable();
  }

  setVccChartFilter(value: VccChartFilterResult): void {
    this._vccChartFilterSubject.next(value);
  }

  getFundingData(): Observable<VccChartModel[]> {
    return this._fundingDataSubject.asObservable();
  }

  setFundingData(data: VccChartModel[]): void {
    this._fundingDataSubject.next(data);
  }
  
  setMerchantGuidData(data: ParsedMguid): void {
    this._merchantGuidDataSubject.next(data);
  }

  getLatestDate(): Observable<string> {
    return this.httpClient
      .get<string>(`${this.resourcePathDetail}/latest-released-date`)
  }

  searchFundingsVcc(vccChartFilter: any): Observable<any> {
    let httpParams = new HttpParams();
    Object
      .keys(vccChartFilter)
      .forEach((key) => {
        if (vccChartFilter[key]) {
          httpParams = httpParams.append(key, vccChartFilter[key]);
        }
      });
      return this.httpClient
      .get<any>(`${this.resourcePath}`, {params: httpParams})
  }

  searchMatchingVcc(vccChartFilter: VccChartFilter): Observable<MatchingResult[]> {
    this.filters = vccChartFilter || this.filters;

    const params: Record<string, string | number | boolean> = {
      releasedDateFrom: this.filters?.releasedDateFrom ?  moment(this.filters?.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      releasedDateTo: this.filters?.releasedDateTo ?  moment(this.filters?.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      merchantGuid: this.filters?.merchantGuid || ''
    }
    removeEmptyParams(params);
      return this.httpClient
      .get<MatchingResult[]>(`${this.resourcePathDetail}/count`, {params: params})
  }

  searchAggregatedVcc(vccChartFilter: VccChartFilter): Observable<AggregatedChartModel[]> {
    this.filters = vccChartFilter || this.filters;

    const params: Record<string, string | number | boolean> = {
      releasedDateFrom: this.filters?.releasedDateFrom ?  moment(this.filters?.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      releasedDateTo: this.filters?.releasedDateTo ?  moment(this.filters?.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      merchantGuid: this.filters?.merchantGuid || ''
    }
    removeEmptyParams(params);
      return this.httpClient
      .get<AggregatedChartModel[]>(`${this.resourcePathDetail}/aggregated-amount`, {params: params})
  }

  searchCirculationVcc(vccChartFilter: VccChartFilter): Observable<CirculationChartModel[]> {
    this.filters = vccChartFilter || this.filters;

    const params: Record<string, string | number | boolean> = {
      releasedDateFrom: this.filters?.releasedDateFrom ?  moment(this.filters?.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      releasedDateTo: this.filters?.releasedDateTo ?  moment(this.filters?.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      merchantGuid: this.filters?.merchantGuid || ''
    }
    removeEmptyParams(params);
      return this.httpClient
      .get<CirculationChartModel[]>(`${this.resourcePathStatus}`, {params: params})
  }
  
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
  
  formatDate(date: Date | string) {
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
    return this.datePipe.transform(parsedDate, 'EEE MMM dd yyyy');
  }

  getDateSubTitle(): Observable<any> {
    return this.getVccChartFilter()
      .pipe(map(f => f.releasedDateFrom && f.releasedDateTo ? ` (${this.formatDate(f.releasedDateFrom)} - ${this.formatDate(f.releasedDateTo)})` : ''));
  }

  getCompleteMguid (chartResponse: ChartResponse) {
    const invertedMerchantGuidShortName: { [key: string]: string } = {};
    for (const key in MerchantGuidShortName) {
      if (MerchantGuidShortName.hasOwnProperty(key)) {
        const value = MerchantGuidShortName[key as keyof typeof MerchantGuidShortName];
        invertedMerchantGuidShortName[value] = key;
      }
    }
    const merchantShortName = chartResponse.label;
    const merchantGuidSelected = invertedMerchantGuidShortName[merchantShortName] || '';
    const filteredParsedMguid = this.parsedMguid.merchantGuid.find((merchant: any) => merchant.id === merchantGuidSelected);

    return {filteredParsedMguid, merchantGuidSelected}
  }

}