<form data-testid="adx-booking-udid-info-form" class="template-form adx-booking-info-form" [formGroup]="adxBookingUdidForm">  
    <div data-testid="adx-booking-udid-info-main-div" class="filter__fields">
        <div class="items-space-between m-t-8 m-b-5">
            <span>
              <button *ngIf="_adxBookingUdids.length > 0 && !isEdit && !showAddUdidForm" data-testid="adx-booking-udid-info-edit-button" mat-stroked-button color="primary" style="margin-right: 10px;"
                (click)="toggleEdit();">Edit</button>
              <button *ngIf="isEdit || showAddUdidForm" data-testid="adx-booking-udid-info-cancel-button" mat-stroked-button color="primary" style="margin-right: 10px;"
                (click)="toggleEdit();">Cancel</button>
              <button *ngIf="isEdit" data-testid="adx-booking-udid-info-update-button" mat-raised-button color="primary"
                (click)="updateUdid();">Update</button>
              <button *ngIf="!isEdit && !showAddUdidForm" data-testid="adx-booking-udid-info-add-button" mat-raised-button color="primary"
                (click)="showAddUdidForm = true">Add</button>
              <button *ngIf="!isEdit && showAddUdidForm" data-testid="adx-booking-udid-info-save-button" mat-raised-button color="primary" 
                (click)="addUdid()" [disabled]="adxBookingUdidForm.invalid">Save</button>

              <div *ngIf="showAddUdidForm" class="add-udid-form">
                <br>
                <div style="display: flex; gap: 10px;">
                    <mat-form-field style="flex: 2;">
                        <mat-label>Udid Number</mat-label>
                        <mat-select formControlName="newUdidNumber">
                            <mat-option *ngFor="let udidInfo of _adxUdidInformation" [value]="udidInfo.udid">
                                {{udidInfo.udid}} - {{udidInfo.shortName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="flex: 1;">
                        <mat-label>Udid Value</mat-label>
                        <input matInput formControlName="newUdidValue">
                    </mat-form-field>
                </div>
              </div>
            </span>
        </div>
        <ng-container *ngIf="_adxBookingUdids.length > 0">
            <mat-card class="mat-elevation-z8">
                <mat-card-content>
                    <mat-grid-list data-testid="adx-booking-udid-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="90">
                        @for (adxBookingUdid of _adxBookingUdids; track adxBookingUdid) {
                            <mat-grid-tile data-testid="adx-booking-udid-info-value-tile">
                                <ng-container *ngIf="isEdit && !getUneditedUdids().includes(adxBookingUdid.udidNo); else viewUdidNo">
                                    <mat-form-field data-test-id="adx-booking-udid-info-value-field">
                                        <mat-label data-testid="adx-booking-udid-info-value-field-title">Number: {{adxBookingUdid.udidNo}}</mat-label>
                                        <input data-testid="adx-booking-udid-info-value-input-field" (change)="updateUdidValue(adxBookingUdid, $event)" matInput [value]="adxBookingUdid.udidValue">
                                    </mat-form-field>
                                </ng-container>
                                <ng-template #viewUdidNo>
                                    <read-only-form-field title="Number: {{adxBookingUdid.udidNo}}" data-testid="adx-booking-udid-info-value-field" value="{{adxBookingUdid.udidValue}}"/>
                                </ng-template>
                            </mat-grid-tile>
                        }
                    </mat-grid-list>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </div>
</form>