import {inject} from '@angular/core';
import type {ResolveFn} from '@angular/router';
import {TramsDataSourceService} from '../service/trams-data-source.service';
import {TramsDataSourceModel} from '../models/trams-data-source';

export const tramsDataSourceResolver: ResolveFn<TramsDataSourceModel[]> = (
  _route,
  _state
) => {
  const tramsDataSourceService = inject(TramsDataSourceService);
  return tramsDataSourceService.getTramsDataSourceList();
};
