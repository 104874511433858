<form [formGroup]="reconciliationForm" (submit)="sendFile()">
    <modal-panel
        data-testid="reconciliation-upload-modal-panel"
        class="reconciliation-upload-modal-panel-cls filter-panel"
        title="Upload File"
        (close)="formCloseEvent();"
        >
            <div data-testid="reconciliation-upload-fields" class="filter__fields">
                <mat-grid-list class="reconciliation-upload-fields-list" [cols]="1" gutterSize="10px" rowHeight="85">

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Processors</mat-label>
                            <mat-select formControlName="ccProcessorId">
                                @for (type of ccProcessors; track type.id) {
                                    <mat-option [value]="type.id">{{type.displayName}}</mat-option>
                                }
                            </mat-select>
                            @if(reconciliationForm.controls['ccProcessorId'].value){
                                <button 
                                    data-testid="reconciliation-upload-type-clear-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('ccProcessorId'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <div class="container" style="margin-top: 0;">
                            <input type="file" (change)="onFileChange($event)" accept=".xlsx"/>
                            @if(reconciliationForm.controls['file'].invalid){
                                <mat-error data-testid="reconciliation-upload-file-error">File is required</mat-error>
                            }
                        </div>
                    </mat-grid-tile>
                    
                </mat-grid-list>
            </div>
            <ng-container data-testid="reconciliation-upload-modal-footer-container" modalPanelFooter>
                <div class="modal-footer">
                    <button data-testid="reconciliation-upload-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                        <mat-icon data-testid="reconciliation-upload-modal-clear-all-button-icon">delete_outline</mat-icon>
                        Clear All
                    </button>
                    <button data-testid="reconciliation-upload-moal-button-submit" mat-raised-button color="primary" type="submit" [disabled]="reconciliationForm.invalid || isLoading">
                        @if(isLoading){
                            <mat-spinner color="accent" diameter="30"></mat-spinner>
                        } @else {
                            Upload
                        }
                    </button>
                </div>
            </ng-container>
    </modal-panel>
</form>