import {Component, Input, ViewChild, OnInit, NgZone} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {VccDailySummaryReport} from '../../model/vcc-daily-summary-report';
import {MatSort} from '@angular/material/sort';
import {VccDailySummaryReportService} from '../../service/vcc-daily-summary-report.service';
import {Pagination} from 'src/infrastructure/pagination/model/pagination';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {VccDailySummaryReportFilterResult} from 'src/app/shared/model/vcc-daily-summary-report-filter-result';
import {FilterService} from 'src/app/shared/service/filter/filter.service';
import {VccDailySummaryReportResponse} from '../../model/vcc-daily-summary-report-response';
import {BehaviorSubject} from 'rxjs';
import {DatePipe} from '@angular/common';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';
import {MerchantsService} from 'src/infrastructure/merchants/service/merchants.service'

@AutoUnsubscribe()
@Component({
  selector: 'vcc-daily-summary-report-profitability',
  templateUrl: './vcc-daily-summary-report.component.html'
})
export class VccDailySummaryReportComponent implements OnInit {
  isLoading = true;
  isError = false;
  isLoaded = false;
  @Input() filter!: VccDailySummaryReportFilterResult;
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  public pagination: Pagination = {} as Pagination;

  @ViewChild(MatSort, { static: false }) public sort!: MatSort;
  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  public VccDailySummaryReportFilter: Partial<VccDailySummaryReport> = {};
  public dataSource: MatTableDataSource<VccDailySummaryReport> = new MatTableDataSource<VccDailySummaryReport>([]);
  public dataSubject: BehaviorSubject<VccDailySummaryReport[]> = new BehaviorSubject<VccDailySummaryReport[]>([]);

  objectFields = [
    { key: 'merchantGuid', displayName: 'Merchant GUID' },
    { key: 'clientName', displayName: 'Client Name' },
    { key: 'releasedDate', displayName: 'Released Date' },
    { key: 'priorDayCashBalance', displayName: 'Prior Day Cash Balance' },
    { key: 'wireAchToFundCashAmount', displayName: 'Wire Ach To Fund Cash Amount' },
    { key: 'beginningCashBalance', displayName: 'Beginning Cash Balance' },
    { key: 'creditSaleAmount', displayName: 'Credit Sale Amount' },
    { key: 'achSaleAmount', displayName: 'Ach Sale Amount' },
    { key: 'purchaseAchAmount', displayName: 'Purchase Ach Amount' },
    { key: 'purchaseCardAmount', displayName: 'Purchase Card Amount' },
    { key: 'purchasePushToCardAmount', displayName: 'Purchase Push To Card Amount' },
    { key: 'creditVoidAmount', displayName: 'Credit Void Amount' },
    { key: 'creditReturnAmount', displayName: 'Credit Return Amount' },
    { key: 'creditChargebackAmount', displayName: 'Credit Chargeback Amount' },
    { key: 'creditAdjustmentAmount', displayName: 'Credit Adjustment Amount' },
    { key: 'achVoidAmount', displayName: 'Ach Void Amount' },
    { key: 'achReturnAmount', displayName: 'Ach Return Amount' },
    { key: 'purchaseCardReturnAmount', displayName: 'Purchase Card Return Amount' },
    { key: 'purchaseCardChargebackAmount', displayName: 'Purchase Card Chargeback Amount' },
    { key: 'purchaseAchReturnAmount', displayName: 'Purchase Ach Return Amount' },
    { key: 'totalActivity', displayName: 'Total Activity' },
    { key: 'newCashBalance', displayName: 'New Cash Balance' },
    { key: 'netDebitCreditToClient', displayName: 'Net Debit Credit To Client' },
    { key: 'endingCashBalance', displayName: 'Ending Cash Balance' },
    { key: 'minimumCashBalance', displayName: 'Minimum Cash Balance' },
    { key: 'achDebitRejectAmount', displayName: 'Ach Debit Reject Amount' },
  ];

  constructor(
    private VccDailySummaryReportService: VccDailySummaryReportService,
    private exportGridService: ExportGridService,
    private filters: FilterService,
    private mechantsService: MerchantsService,
    private ngZone: NgZone,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.mechantsService.merchants$.subscribe((merchants)=>{
      if (merchants) {
        const defaultGuid = merchants[0];

        this.filters.getVccDailySummaryReportFilter().subscribe((filter: VccDailySummaryReportFilterResult) => {
          let releasedDate = filter.releasedDate;
          if (releasedDate !== undefined) {
            releasedDate = this.datePipe.transform(filter.releasedDate, 'yyyy-MM-dd')!;
          } else {
            let previousDate = new Date();
            previousDate.setDate(previousDate.getDate() - 1);
            releasedDate = this.datePipe.transform(previousDate, 'yyyy-MM-dd')!;
          }

          this.VccDailySummaryReportFilter = {
            ...this.VccDailySummaryReportFilter,
            releasedDate: releasedDate,
            merchantGuid: filter.merchantGuid ?? defaultGuid.merchantGuid
          };
          this.getVccDailySummaryReport();
        });
      }
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getVccDailySummaryReport() {
    this.dataSubject.next([]);
    this.isLoaded = false;
    this.VccDailySummaryReportService.getVccDailySummaryReport(this.VccDailySummaryReportFilter)
      .subscribe({
        next: (data: VccDailySummaryReportResponse) => {
          if (data) {
            this.dataSubject.next([data]);
            this.dataSource.data = this.dataSubject.value;
          }
          this.isLoading = false;
          this.isError = false;
          this.isLoaded = true;
        },
        error: (_) => {
          this.ngZone.run(() => {
            this.dataSubject.next([]);
            this.dataSource.data = this.dataSubject.value;
            this.isError = true;
            this.isLoaded = true;
          });
        }
      });
  }
  
  downloadGridToFile(): void {
    const downloadSubs$ = this.VccDailySummaryReportService.getVccDailySummaryReport(this.VccDailySummaryReportFilter)
      .subscribe(() => {
        this.exportGridService.getByDataSource(
          'dbov2/connex-pay-report/daily-summary-report',
          'vcc-daily-summary-report',
          {
            ... this.VccDailySummaryReportFilter,
            pageSize: this.pagination?.totalItemCount || 100,
          },
          (data) => {
            const record = data as VccDailySummaryReport;
            return {
              
              'Merchant GUID': record?.merchantGuid,
              'Client Name': record?.clientName,
              'Released Date': this.datePipe.transform(record?.releasedDate, 'M/d/yyyy'),
              'Prior Day Cash Balance': record?.priorDayCashBalance,
              'Wire Ach To Fund Cash Amount': record?.wireAchToFundCashAmount,
              'Beginning Cash Balance': record?.beginningCashBalance,
              'Credit Sale Amount': record?.creditSaleAmount,
              'Ach Sale Amount': record?.achSaleAmount,
              'Purchase Ach Amount': record?.purchaseAchAmount,
              'Purchase Card Amount': record?.purchaseCardAmount,
              'Purchase Push To Card Amount': record?.purchasePushToCardAmount,
              'Credit Void Amount': record?.creditVoidAmount,
              'Credit Return Amount': record?.creditReturnAmount,
              'Credit Chargeback Amount': record?.creditChargebackAmount,
              'Credit Adjustment Amount': record?.creditAdjustmentAmount,
              'Ach Void Amount': record?.achVoidAmount,
              'Ach Return Amount': record?.achReturnAmount,
              'Purchase Card Return Amount': record?.purchaseCardReturnAmount,
              'Purchase Card Chargeback Amount': record?.purchaseCardChargebackAmount,
              'Purchase Ach Return Amount': record?.purchaseAchReturnAmount,
              'Total Activity': record?.totalActivity,
              'New Cash Balance': record?.newCashBalance,
              'Net Debit Credit To Client': record?.netDebitCreditToClient,
              'Ending Cash Balance': record?.endingCashBalance,
              'Minimum Cash Balance': record?.minimumCashBalance,
              'Ach Debit Reject Amount': record?.achDebitRejectAmount,
            }
          }
        );
        downloadSubs$?.unsubscribe();
      })
  }
}
