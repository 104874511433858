@if(_vendor.vendorName){
<div class="template-form" id="vendor-viewer-template-form">
  @if(data){
    <div id="vendor-viewer-title-div">
      <label class="title" id="vendor-viewer-title-label">{{data.title}}</label>
    </div>
    <br>
  }
  <div id="vendor-viewer-inputs-div">
    <read-only-form-field testId="vendor-viewer-name" title="Vendor Name" [value]="_vendor.vendorName" />
    
    <read-only-form-field testId="vendor-viewer-contact-person" title="Vendor Contact Person" [value]="_vendor.vendorContactPerson" />
    
    <read-only-form-field testId="vendor-viewer-contact-email" title="Vendor Contact Email" [value]="_vendor.vendorContactEmail" />
    
    <read-only-form-field testId="vendor-viewer-contact-phone" title="Vendor Contact Phone" [value]="_vendor.vendorContactPhone" />

    <read-only-form-field testId="vendor-viewer-full-address" title="Vendor Full Address" [value]="_vendor.vendorFullAddress" />
    
    <read-only-form-field testId="vendor-viewer-website" title="Website" [value]="_vendor.vendorWebSite" />
  </div>
</div>
}

