import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {Subscription} from 'rxjs';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {ComponentCanDeactivate} from 'src/infrastructure/guards/unsaved-changes.guard';
import {AirMatchingReportService} from '../../service/air-matching-report.service';
import {AirMatchingItemModel, AirMatchingReportFilter} from '../../model/air-matching-report';
import {ModalService} from 'src/app/shared/service/modal/modal.service';
import {ConfirmationDialogAirMatchingComnponent} from '../confirmation-dialog-air-matching/confirmation-dialog-air-matching.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';
import {DatePipe} from '@angular/common';

@AutoUnsubscribe()
@Component({
  selector: 'air-matching-report',
  templateUrl: './air-matching-report.component.html',
})
export class AirMatchingReportComponent implements OnInit, ComponentCanDeactivate{

  displayedColumns: string[] = [
    'fmsInvoiceNumber',
    'fmsBookingTotalFare',
    'adxReference',
    'sabreInvoiceNumber',
    'sabreTotalFare',
    'sabreReference',
    'sabreInvoiceIssueDate',
    'invoiceDataSourceName',
    'vendorName',
    'agentName',
    'clientName',
    'actions',
  ];

  airMatchingFilter$?: Subscription;

  items: AirMatchingItemModel[] = [];
  filters: AirMatchingReportFilter = {};
  pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
  sorting: Partial<Sort> = {};

  itemToSave?: AirMatchingItemModel;

  allowNavigate = true;

  constructor(
    private airMatchingReportService: AirMatchingReportService,
    private exportGridService: ExportGridService,
    private modalService: ModalService,
    private snackBar: MatSnackBar,
    private azureAuthService: AzureAuthService,
    private datePipe: DatePipe,
  ){}

  canDeactivate() {
    return this.allowNavigate;
  }

  ngOnInit(): void {
    this.airMatchingFilter$ = this.airMatchingReportService.getAirMatchingFilter().subscribe((filter:AirMatchingReportFilter)=>{
      this.filters = filter;
      this.pagination = {
        pageSize: this.pagination.pageSize || 25,
        pageIndex: 0,
        totalItemCount: 0,
      };
      this.getItems();
    });
  }

  handleSortChange(sort: Sort): void { 
    this.sorting = sort;

    if(!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = {};
    }
  
    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  isDirectorFinance(): boolean {
    return this.azureAuthService.checkUserAzureTokenHasDirectorRole() && this.azureAuthService.checkUserAzureTokenHasFinanceRole();
  }

  getItems(){
    this.airMatchingReportService.searchAirMatchingData({
      filters: this.filters,
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((data)=>{
      this.items = data?.items;
      this.pagination = {
        ... this.pagination,
        pageIndex: data.pagination.pageNumber - 1,
        totalItemCount: data.pagination.totalItemCount,
      };
    });
  }

  updateItemToSave(item:AirMatchingItemModel) : void {
    if(!this.itemToSave) {
      this.itemToSave = item;
      return;
    }

    if(this.itemToSave && item === this.itemToSave) {
      this.itemToSave = undefined;
      return;
    }
  }

  approveUpdate() : void {
    this.allowNavigate = false;

    if (this.itemToSave && this.isDirectorFinance()) {
      this.modalService.open(
        ConfirmationDialogAirMatchingComnponent,
        {
          data: { itemToSave: this.itemToSave},
        }
      ).afterClosed().subscribe((confirmation:boolean)=>{
        if(confirmation){
          this.getItems();
          this.itemToSave = undefined;
        }
      });
    } else {
      this.snackBar.open('No item selected to save', 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });    
    }
  }

  downloadGridToFile(): void {
    this.airMatchingReportService.searchAirMatchingData({
      filters: {
        ...this.filters,
      },
      page: { pageSize: this.pagination.totalItemCount },
      sort: this.sorting,
    }).subscribe((data) => {
      this.exportGridService.downloadDataSource(
        data,
        'air-matching-report',
        (data) => {
          const record = data as AirMatchingItemModel;
          return {
            'Fms Invoice Number': record.fmsInvoiceNumber,
            'Fms Invoice Total Fare': record.fmsBookingTotalFare,
            'Adx Reference': record.adxReference,
            'Sabre Invoice Number': record.sabreInvoiceNumber,
            'Sabre Total Fare': record.sabreTotalFare,
            'Sabre Reference': record.sabreReference,
            'Sabre Invoice Issue Date': this.datePipe.transform(record?.sabreInvoiceIssueDate, 'M/d/yyyy'),
            'Invoice Data Source Name': record?.invoiceDataSourceName,
            'Vendor Name': record.vendorName,
            'Agent Name': record.agentName,
            'Client Name': record.clientName,
          }
        }
      )
    });
  }
}
