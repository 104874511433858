import {Component, Input} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {TripService} from '../../service/trip.service';
import {Trip} from '../../model/trip';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html'
})

@AutoUnsubscribe()
export class TripInfoComponent {
  @Input() public data: any;
  _trip!: Trip;
  isEditable: boolean = false;
  currentDate: string; 

  constructor(
    private _snackBar: MatSnackBar,
    private buildr: FormBuilder,
    private tripService: TripService) {
      this.currentDate = new Date().toISOString().split('T')[0];
  }

  ngOnInit() {
    this.getTripInformation();
    this.tripInfoForm.get('tripStartDate')?.valueChanges.subscribe(() => {
      this.tripInfoForm.get('tripEndDate')?.updateValueAndValidity();
    });
  }

  getTripInformation() {
    if (this.data != null) {
      this.tripService
        .getTripById(this.data.tripId)
        .subscribe((data: Trip) => {
          if (data) {
            this._trip = data;
          }
        });
    }
  }

  tripInfoForm = this.buildr.group({
    tripName: [{ value: '', disabled: !this.isEditable },  [Validators.required, this.tripNameLengthValidator]],
    tripDescription: [{ value: '', disabled: !this.isEditable },  [this.optionalLengthValidator(2600)]],
    tripStartDate: [{ value: '', disabled: !this.isEditable }, Validators.required],
    tripEndDate: [{ value: '', disabled: !this.isEditable }, [Validators.required, this.endDateValidator.bind(this)]],
    templateNotes: this.buildr.control('')
  })

  optionalLengthValidator(maxLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value && value.length > maxLength ? { lengthTooLong: { maxLength } } : null;
    };
  }

  tripNameLengthValidator(control: AbstractControl): ValidationErrors | null {
    const tripName = control.value;
    return tripName && tripName.length > 500 ? { tripNameTooLong: true } : null;
  }

  endDateValidator(control: AbstractControl): ValidationErrors | null {
    const startDate = this.tripInfoForm?.get('tripStartDate')?.value;
    if (startDate && control.value) {
      return new Date(control.value) < new Date(startDate) ? { endDateInvalid: true } : null;
    }
    return null;
  }

  toggleTripEdit(){
    if (!this.isEditable) {
      this.isEditable = true;
      this.tripInfoForm.get('tripDescription')?.enable();
      return;
    }

    if (this.isEditable && this.tripInfoForm.pristine) {
      this._snackBar.open('No changes to save.', 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['message-position', 'app-notification-info']
      });
      return;
    }

    if (this.tripInfoForm.valid) {
      const updateTrip = {
        ...this._trip,
        tripName: this._trip.tripName,
        tripDescription: this._trip.tripDescription,
        tripStartDate: this._trip.tripStartDate ? new Date(this._trip.tripStartDate).toLocaleDateString('en-CA'): null,
        tripEndDate: this._trip.tripEndDate? new Date(this._trip.tripEndDate).toLocaleDateString('en-CA'): null,
      };

      this.tripService.updateTrip(updateTrip).subscribe({
        next: () => {
          this.isEditable = false;
          this.tripInfoForm.markAsPristine();
          this.tripInfoForm.disable();
          this._snackBar.open(
            'Successfully updated Trip information for Trip ID: ' + this._trip.id,
            'Dismiss',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              panelClass: ['message-position', 'app-notification-success']
            }
          );
        },
        error: () => {
          this._snackBar.open(
            'Error during updating the Trip information.',
            'Dismiss',
            {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              panelClass: ['message-position', 'app-notification-error']
            }
          );
        }
      });
    }
  }
}

