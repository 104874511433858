<div id="new-adx-booking-dialog-confirmpopup" class="confirmpopup">
  <div id="new-adx-booking-dialog-title" mat-dialog-title>
    <label id="new-adx-booking-dialog-title-label" class="title">New Booking: Confirmation</label>
  </div>
  <hr id="new-adx-booking-dialog-first-hr">
  <div id="new-adx-booking-dialog-content" mat-dialog-content>
    <p id="new-adx-booking-dialog-content-p">Do you want to open new booking '{{this.newAdxBookingId}}' or stay in the current one '{{this.currentAdxBookingId}}'?</p>
  </div>
  <hr id="new-adx-booking-dialog-second-hr">
  <div id="new-adx-booking-dialog-actions" mat-dialog-actions style="float: right;">
    <button
      id="new-adx-booking-dialog-ok-button"
      type="submit" mat-raised-button
      (click)="okClick()"
      color="primary"
      cdkFocusInitial matTooltip="Open new booking"
      aria-label="Open new booking">
    <mat-spinner
      id="new-adx-booking-dialog-spinner"
      color="accent"
      *ngIf="isLoadingSpinner"
      diameter="30">
    </mat-spinner>
      <span id="new-adx-booking-dialog-ok-span" *ngIf="!isLoadingSpinner">Open new booking</span>
    </button>
    <button
      id="new-adx-booking-dialog-no-button"
      mat-raised-button
      (click)="noClick()"
      color="warn">
      Stay in the current booking
    </button>
  </div>
</div>
