<form class="filter" [formGroup]="vendorBalanceFilterForm" (submit)="applyFilters()">
    <modal-panel
        data-testid="vendor-balance-filter-modal-panel"
        class="filter-panel"
        title="Filter"
        (close)="handleBookingFiltersFormCloseEvent();"
        >
            <div data-testid="vendor-balance-filter-modal" class="filter__fields">
                <mat-grid-list class="modal-filter-col-flex" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Payment date</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="paymentDate">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(vendorBalanceFilterForm.controls['paymentDate'].value){
                                <button 
                                    data-testid="vendor-balance-payment-date-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('paymentDate')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Vendor name</mat-label>
                            <input matInput placeholder="Vendor name" value="" formControlName="vendorName">
                            @if(vendorBalanceFilterForm.controls['vendorName'].value){
                                <button 
                                    data-testid="vendor-balance-vendor-name-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('vendorName')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Balance due min</mat-label>
                            <input matInput placeholder="Balance due min" formControlName="minOutstandingBalance" (keypress)="checkNumberInput($event)">
                            @if(vendorBalanceFilterForm.controls['minOutstandingBalance'].value){
                                <button 
                                    data-testid="vendor-balance-min-outstanding-balance-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('minOutstandingBalance')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Balance due max</mat-label>
                            <input matInput placeholder="Balance due max" formControlName="maxOutstandingBalance" (keypress)="checkNumberInput($event)">
                            @if(vendorBalanceFilterForm.controls['maxOutstandingBalance'].value){
                                <button 
                                    data-testid="vendor-balance-max-outstanding-balance-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('maxOutstandingBalance')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Invoice total min</mat-label>
                            <input matInput placeholder="Invoice total min" formControlName="minTotalBilled" (keypress)="checkNumberInput($event)">
                            @if(vendorBalanceFilterForm.controls['minTotalBilled'].value){
                                <button 
                                    data-testid="vendor-balance-min-total-billed-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('minTotalBilled')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Invoice total max</mat-label>
                            <input matInput placeholder="Invoice total max" formControlName="maxTotalBilled" (keypress)="checkNumberInput($event)">
                            @if(vendorBalanceFilterForm.controls['maxTotalBilled'].value){
                                <button 
                                    data-testid="vendor-balance-max-total-billed-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('maxTotalBilled')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    @let tramsDataSourceOptions = tramsDataSourceList$ | async;
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Data source</mat-label>
                            <mat-select formControlName="dataSourceId">
                              @for (dataSource of tramsDataSourceOptions; track dataSource?.id) {
                              <mat-option [value]="dataSource?.id">{{dataSource?.sourceName}}</mat-option>
                              }
                            </mat-select>
                            @if(vendorBalanceFilterForm.controls['dataSourceId'].value){
                                <button 
                                    data-testid="vendor-balance-data-source-id-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('dataSourceId'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                          </mat-form-field>
                    </mat-grid-tile>

                    @let payStatusesOptions = payStatusList$ | async;
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Vendor Pay Status</mat-label>
                            <mat-select formControlName="vendorPayStatusId">
                              @for (payStatus of payStatusesOptions; track payStatus?.id) {
                              <mat-option [value]="payStatus?.id">{{payStatus?.payStatusName}}</mat-option>
                              }
                            </mat-select>
                            @if(vendorBalanceFilterForm.controls['vendorPayStatusId'].value){
                                <button 
                                    data-testid="vendor-balance-vendor-pay-status-id-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('vendorPayStatusId'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                          </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
                
            </div>
            <ng-container data-testid="vendor-balance-modal-footer-container" modalPanelFooter>
                <div class="modal-footer">
                    <button data-testid="vendor-balance-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                        <mat-icon datatest-id="vendor-balance-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                        Clear All
                    </button>
                    <button datatest-id="vendor-balance-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
                </div>
            </ng-container>
    </modal-panel>
</form>