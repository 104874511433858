import {Component, Inject, Optional, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'split-rate-modal',
  templateUrl: './split-rate-modal.component.html'
})
export class SplitRateModalComponent implements OnInit {
    agentId: number;

    constructor(
        @Optional() public dialogRef: MatDialogRef<SplitRateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { agentId: number },
    ) {
        this.agentId = data.agentId;
    }

    ngOnInit(): void {
        if (!this.data) {
            console.error('Agent data is missing');
        }
    }

    public closeDialog(): void {
        if (this.dialogRef) {
        this.dialogRef.close();
        }
    }
}