<div class="grid-title-event">
    <h2 style="text-align:center" id="matching-vcc-list-title">VCC Matching Report</h2>
    @if (items.length){
        <div class="events-container">
            <button  id="matching-vcc-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" id="apply-update-matching-vcc-button" aria-label="Apply Update Matching Vcc" mat-stroked-button (click)="applyUpdate()">Apply Update</button>
            <button color="primary" id="download-matching-vcc-button" aria-label="Download Matching Vcc File" mat-stroked-button (click)="downloadGridToFile()" style="justify-content:flex-end">
                <mat-icon>download</mat-icon>
            </button>
        </div>
    }
</div>
<mat-table id="matching-vcc-table" class="compact-mat-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>
    <ng-container matColumnDef="clientName">
        <mat-header-cell id="client-name-header" class="mat-header-cell mat-column-extra-width" *matHeaderCellDef mat-sort-header>                
            Merchant Name
        </mat-header-cell>
        <mat-cell id="client-name-cell" *matCellDef="let element" class="mat-column-extra-width">
            <span>{{element?.clientName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="orderNumber">
        <mat-header-cell id="order-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Trip Reference
        </mat-header-cell>
        <mat-cell id="order-number-cell" class="mat-cell" *matCellDef="let element">
            <a id="matching-vcc-order-number-cell-button" href="#" (click)="openDetailDialog(element?.connexPayAccountingDailyDetailId); $event.preventDefault();">
                {{element?.orderNumber}}
            </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="merchantName">
        <mat-header-cell id="merchant-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Vendor Name
        </mat-header-cell>
        <mat-cell id="merchant-name-cell" *matCellDef="let element">
            <span>{{element?.merchantName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="releasedDate">
        <mat-header-cell id="released-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Released Date
        </mat-header-cell>
        <mat-cell id="released-date-cell" *matCellDef="let element">
            <span>{{element?.releasedDate | date:'M/d/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="connexPayAccountingDailyDetailAmount">
        <mat-header-cell id="connexpay-total-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Amount
        </mat-header-cell>
        <mat-cell id="total-cell" *matCellDef="let element">
            <span>{{element?.connexPayAccountingDailyDetailAmount}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsBookingTotalFare">
        <mat-header-cell id="trams-total-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Trams Total
        </mat-header-cell>
        <mat-cell id="total-cell" *matCellDef="let element">
            <span>{{element?.tramsBookingTotalFare}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsBookingCommAmt">
        <mat-header-cell id="comm-amt-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Agent Commission
        </mat-header-cell>
        <mat-cell id="comm-amt-cell" *matCellDef="let element">
            <span>{{element?.tramsBookingCommAmt}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cogs">
        <mat-header-cell id="cogs-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            COGS
        </mat-header-cell>
        <mat-cell id="cogs-cell" *matCellDef="let element">
            <span>{{element?.cogs}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="varianceAmount">
        <mat-header-cell id="suggested-comm-amt-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
            Suggested Adjustment Commission
        </mat-header-cell>

        <mat-cell 
            id="suggested-comm-amt-cell"
            *matCellDef="let element"
            [ngStyle]="{'background-color': itemColorStyle(element)}">
            @if(element.businessErrors){
                <a href="#" (click)="$event.preventDefault(); openErrorsDialog(element.businessErrors)" style="text-decoration: underline; color: #FF6060;">{{element?.varianceAmount}}</a>
            }
            @else{
                <span>{{element?.varianceAmount}}</span>
            }
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="revertButton">
        <mat-header-cell id="revert-button-header" class="mat-header-cell" *matHeaderCellDef>
            Actions
        </mat-header-cell>
        <mat-cell id="revert-button-cell" *matCellDef="let element">
            <div class="flexible-content">
            @if(element?.isAmountMatches && !element.userApproved && !element.dateApproved){
                <button mat-icon-button (click)="$event.stopPropagation(); applyRevert(element)" matTooltip="{{element?.revert ? 'Undo' : 'Revert'}}"  [matTooltipPosition]="'above'" [disabled]="element?.shouldResolve">
                    <mat-icon>
                        @if(element?.revert){
                            undo
                        } @else {
                            redo
                        }
                    </mat-icon>
                </button>
            }
            @if(element?.isAmountMatches){
                @if(!element?.userApproved && !element?.dateApproved){
                    <mat-checkbox  [checked]="element?.isResolved"  [disabled]="!element?.revert" (change)="setResolve(element)" matTooltip="Resolve" [matTooltipPosition]="'below'"></mat-checkbox>
                }
            } @else {
                <mat-checkbox  [checked]="element?.isResolved"  [disabled]="element?.isResolved" (change)="setResolve(element)" matTooltip="Resolve" [matTooltipPosition]="'below'"></mat-checkbox>
            }

            </div>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index">
    </mat-row>
</mat-table>
<mat-paginator id="matching-vcc-paginator" [length]="pagination.totalItemCount" [pageSizeOptions]="[25, 50, 75, 100]"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>


<ng-template #modalDetailRef let-data>
    <modal-panel
    id="matching-vcc-detail-viewer-modal"
    [title]="'Matching Vcc Detail'"
    (click)="closeDetailDialog()"
    >
        <matching-vcc-detail-v2 [id]="data"></matching-vcc-detail-v2>
        <ng-container id="matching-vcc-detail-viewer-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button id="matching-vcc-detail-viewer-modal-button" mat-raised-button color="primary" (click)="closeDetailDialog();">Close</button>
            </div>
        </ng-container>
    </modal-panel>
</ng-template>