import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicComponent} from './dynamic/dynamic.component';
import {
  ModalPanelComponent,
  ModalPanelFooterDirective,
  ModalPanelHeaderDirective
} from './components/modal-panel/modal-panel.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ChartComponent} from './components/chart/chart.component';
import {NgChartsModule} from 'ng2-charts';
import {WidgetInfoComponent} from './components/widget-info/widget-info.component';
import {WidgetActionsDirective, WidgetComponent} from './components/widget/widget.component';
import {SidebarMenuComponent} from './components/sidebar-menu/sidebar-menu.component';
import {RouterModule} from '@angular/router';
import {FilterModalComponent} from './modals/filter-modal/filter-modal.component';
import {AngularMaterialDesignModule} from '../angular-material-design/module/angular-material-design.module';
import {FinanceReportsFilterModalComponent} from './modals/finance-reports-filter-modal/finance-reports-filter-modal.component';
import {VccDailySummaryReportFilterModalComponent} from './modals/vcc-daily-summary-reports-filter-modal/vcc-daily-summary-reports-filter-modal.component';
import {VendorsFilterModalComponent} from '../vendor/component/vendors-filter-modal/vendors-filter-modal.component';
import {TransactionBookingsFilterModalComponent} from '../transaction-booking/components/transaction-bookings-filter-modal/transaction-bookings-filter-modal.component';
import {AdxInvoiceLineItemFilterModalComponent} from '../invoices/component/adx-invoice-line-items-filter-modal/adx-invoice-line-items-filter-modal.component';
import {TramsInvoiceFilterModalComponent} from '../invoices/component/trams-invoices-filter-modal/trams-invoices-filter-modal.component';
import {FmsVccFilterModalComponent} from '../finance-resource/component/fms-vccs-filter-modal/fms-vccs-filter-modal.component';
import {AdxVccFilterComponent} from '../finance-resource/component/adx-vcc-filter/adx-vcc-filter.component';
import {RefreshListComponent} from './components/refresh/refresh-list.component';
import {MatchingVccFilterComponent} from '../finance-resource/component/matching-vcc-filter/matching-vcc-filter.component';
import {VccChartFilterModalComponent} from '../dashboard/layout/vcc/components/vcc-chart-filter-modal/vcc-chart-filter-modal.component';
import {FinancialAlertsModalComponent} from '../finance-resource/component/financial-alerts/financial-alerts-modal.component';
import {TripFilterModalComponent} from '../trip/components/trip-filter-modal/trip-filter-modal.component';
import {SelectAllDirective} from './directives/select-all.directive';
import {HsProcessingFilterComponent} from '../trx-team-resource/components/hs-processing-filter/hs-processing-filter.component';
import {ProfileAutocompleteComponent} from './components/profile-autocomplete/profile-autocomplete.component';
import {AdxPaymentFilterComponent} from '../payments/components/payment-filter/payment-filter.component';
import {ReadOnlyFormFieldComponent} from './components/read-only-form-field/read-only-form-field.component';
import {PaymentFormComponent} from '../payments/components/payment-form/payment-form.component';
import {PaymentStepperFormComponent} from '../payments/components/payment-stepper-form/payment-stepper-form.component';
import {CreateGeneralLedgerComponent} from '../general-ledger/components/create-general-ledger/create-general-ledger.component';
import {DeleteGlConfirmationComnponent} from '../general-ledger/components/delete-general-ledger-confirmation/delete-general-ledger-confirmation.component';
import {CreateTravelTypeMappingComponent} from '../general-ledger/components/create-travel-type-mapping/create-travel-type-mapping.component';
import {DeleteTtmConfirmationComnponent} from '../general-ledger/components/delete-travel-type-mapping/delete-travel-type-mapping-confirmation.component';
import {GlClosePeriodFilterComponent} from '../general-ledger/components/close-period-filter/close-period-filter.component';
import {CreateClosePeriodComponent} from '../general-ledger/components/create-close-period/create-close-period.component';
import {ReconciliationUploadModalComponent} from '../payments/components/reconciliation-upload-modal/reconciliation-upload-modal.component';
import {PaymentReconciliationFilterComponent} from '../payments/components/payments-reconciliation-filter/payments-reconciliation-filter.component';
import {BraintreeMatchingVccFilterComponent} from '../finance-resource/component/braintree-matching-vcc-filter/braintree-matching-vcc-filter.component';
import {NavExportSendModalComponent} from '../general-ledger/components/nav-export-send-modal/nav-export-send-modal.component';
import {AirMatchingReportFilterComponent} from '../finance-resource/component/air-matching-report-filter/air-matching-report-filter.component';
import {VendorBalanceFilterComponent} from '../payments/components/vendor-balance-filter/vendor-balance-filter.component';

const components = [
  DynamicComponent,
  ModalPanelComponent,
  FilterModalComponent,
  FinanceReportsFilterModalComponent,
  VccDailySummaryReportFilterModalComponent,
  VendorsFilterModalComponent,
  TransactionBookingsFilterModalComponent,
  AdxInvoiceLineItemFilterModalComponent,
  TramsInvoiceFilterModalComponent,
  FmsVccFilterModalComponent,
  VccChartFilterModalComponent,
  ChartComponent,
  WidgetInfoComponent,
  WidgetComponent,
  SidebarMenuComponent,
  AdxVccFilterComponent,
  RefreshListComponent,
  MatchingVccFilterComponent,
  FinancialAlertsModalComponent,
  TripFilterModalComponent,
  HsProcessingFilterComponent,
  ProfileAutocompleteComponent,
  AdxPaymentFilterComponent,
  ReadOnlyFormFieldComponent,
  PaymentFormComponent,
  PaymentStepperFormComponent,
  CreateGeneralLedgerComponent,
  DeleteGlConfirmationComnponent,
  CreateTravelTypeMappingComponent,
  DeleteTtmConfirmationComnponent,
  GlClosePeriodFilterComponent,
  CreateClosePeriodComponent,
  ReconciliationUploadModalComponent,
  PaymentReconciliationFilterComponent,
  BraintreeMatchingVccFilterComponent,
  NavExportSendModalComponent,
  AirMatchingReportFilterComponent,
  VendorBalanceFilterComponent,
]

const directives = [
  ModalPanelFooterDirective,
  ModalPanelHeaderDirective,
  WidgetActionsDirective,
  SelectAllDirective,
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialDesignModule,
    NgChartsModule,
    RouterModule,
  ],
  declarations: [
    ...components,

    ...directives
  ],
  exports: [
    ...components,

    ...directives,
  ]
})
export class SharedModule {
}
