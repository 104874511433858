<div class="chat-prompt-input">
  <input
    type="text"
    [(ngModel)]="userInput"
    (keydown.enter)="sendMessage()"
    placeholder="Type your message...">
  <button (click)="sendMessage()">
    <span>Send</span>
    <mat-icon>send</mat-icon>
  </button>
</div>
