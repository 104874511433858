import {Component, Inject, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ConfirmationDialogIssueVccComponent} from '../popup/confirmation-dialog/confirmation-dialog-issue-vcc/confirmation-dialog-issue-vcc.component';
import {ConfirmationDialogUpdateVccComponent} from '../popup/confirmation-dialog/confirmation-dialog-update-vcc/confirmation-dialog-update-vcc.component';
import {ConfirmationDialogDeleteVccComponent} from '../popup/confirmation-dialog/confirmation-dialog-delete-vcc/confirmation-dialog-delete-vcc.component';
import {BookingVirtualCreditCardService} from '../../../booking-virtual-credit-card/service/booking-template.service';
import {BookingVirtualCreditCard} from '../../../booking-virtual-credit-card/model/booking-virtual-credit-card';
import {VccViewerData} from '../../model/vcc-viewer-data';
import {ConfirmationDialogVccData} from '../../model/confirmation-dialog-vcc-data';

export enum VccViewerType{
  Info = "info",
  Update = "update"
}

@Component({
  selector: 'app-vcc-viewer',
  templateUrl: './vcc-viewer.component.html'
})

@AutoUnsubscribe()
export class VccViewerComponent {
  @Input() public type: VccViewerType = VccViewerType.Info;
  @Input() public data: any;
  @Input() public isLoaderVisible: boolean = true;
  _vccs: any;
  isLoading: boolean = true;

  get isTypeInfo(){
    return this.type === VccViewerType.Info;
  }

  get isTypeUpdate(){
    return this.type === VccViewerType.Update;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: VccViewerData,
    private buildr: FormBuilder,
    private bookingVirtualCreditCardService: BookingVirtualCreditCardService,
    public dialog: MatDialog) {
  }

  ngOnInit(){
    this.getBookingVirtualCreditCardInformation();
  }

  getBookingVirtualCreditCardInformation() {
    this.isLoading = true; 
    this.type = this.inputData.type;
    this.bookingVirtualCreditCardService.getAdxBookingVirtualCreditCardQuery(this.inputData.id)
      .subscribe((data: BookingVirtualCreditCard[]) => {
        if (data) {
          this._vccs = data.map(x => x.virtualCreditCard);
          this.isLoading = false;
        } 
    });
  }

  vccViewerForm = this.buildr.group({
    action: this.buildr.control(''),
    purchaseLogUniqueID: this.buildr.control(''),
    cardGuid: this.buildr.control(''),
  })

  showMore(tabIndex: number){
    this._vccs[tabIndex].isVccPaymentGatewayInfoLoaded = true;
  }

  handleIssue(){
    let dialogConfirmationRef = 
      this.dialog.open(ConfirmationDialogIssueVccComponent, {
        data: {
          adxBookingId: this.inputData.id,
          isDeleted: false
        } as ConfirmationDialogVccData
    });
    this.reloadVccInfoOnSuccess(dialogConfirmationRef);
  }

  handleUpdate(tabIndex: number){
    let dialogConfirmationRef = 
      this.dialog.open(ConfirmationDialogUpdateVccComponent, {
        data: {
          adxBookingId: this.inputData.id,
          vccId: this._vccs[tabIndex].id ?? -1,
          isDeleted: false
        } as ConfirmationDialogVccData
    });
    this.reloadVccInfoOnSuccess(dialogConfirmationRef);
  }

  handleDelete(tabIndex: number){
    let dialogConfirmationRef = 
      this.dialog.open(ConfirmationDialogDeleteVccComponent, {
        data: {
          adxBookingId: this.inputData.id,
          vccId: this._vccs[tabIndex].id ?? -1,
          isDeleted: false
        } as ConfirmationDialogVccData
    });
    this.reloadVccInfoOnSuccess(dialogConfirmationRef);
  }

  reloadVccInfoOnSuccess(dialogConfirmationRef: any){ 
    dialogConfirmationRef.afterClosed().subscribe(
      (result: boolean) => {
        if (result != null && result){
          this.getBookingVirtualCreditCardInformation();
        }
      }
    );
  }

  navigateToConnexPayUrl(url: string){
    window.open(url, "_blank");
  }
}