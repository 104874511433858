import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { removeEmptyParams } from 'src/infrastructure/helper/remove-empty-params';
import * as moment from 'moment';
import { BraintreeMatchedVccFilter, BraintreeMatchingVccResponse, SearchBraintreeMatchingReconciliationParams, SearchBraintreeMatchingReconciliationResponse, SerachBraintreeMatchedVccParams } from '../model/braintree-matching-vcc';

@Injectable({
  providedIn: 'root',
})
export class BraintreeMatchingVccService {

  private readonly url = 'dbov2/braintree-data/vcc-matching-search';
  private readonly url1 = 'dbov2/braintree-data/vcc-matching-with-release-date-search';
  private readonly url2 = 'dbov2/braintree-data/vcc-matching-trams-data-search';
  private readonly url3 = 'dbov2/braintree-data/vcc-matching-trams-data-adjust';

  private _dataSource$ = new BehaviorSubject<BraintreeMatchedVccFilter | {}>({});

  constructor(private http: HttpClient) { }

  getBraintreeMatchingFilter(): Observable<BraintreeMatchedVccFilter | {}> {
    return this._dataSource$.asObservable();
  }

  setBraintreeMatchingFilter(value: BraintreeMatchedVccFilter) {
    this._dataSource$.next(value || null);
  }

  getOrganizedFilters(filters?: BraintreeMatchedVccFilter) {
    return {
      releasedDateFrom: filters?.releasedDateFrom ? moment(filters?.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      releasedDateTo: filters?.releasedDateTo ? moment(filters?.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      lastFourCC: filters?.lastFourCC ?? '',
      adxTripReference: filters?.adxTripReference ?? '',
      tramsInvoiceNumber: filters?.tramsInvoiceNumber ?? '',
      vendorName: filters?.vendorName ?? '',
      isMatched: filters?.isMatched ?? '',
    };
  }

  searchMatching({ filters, page, sort }: SerachBraintreeMatchedVccParams): Observable<BraintreeMatchingVccResponse> {
    const params: Record<string, string | number | boolean> = {
      pageSize: page?.pageSize || 25,
      pageNumber: (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',

      ...this.getOrganizedFilters(filters),
    };

    removeEmptyParams(params);

    const isMatchedValue = filters?.isMatched ?? undefined;
    if (isMatchedValue !== undefined) {
      params['isMatched'] = filters!.isMatched as boolean;
    }

    return this.http.get<BraintreeMatchingVccResponse>(`${this.url}`, {
      params: { ...params },
    }).pipe(catchError((error: HttpErrorResponse) => {
      throw new Error(error?.message || 'Server error');
    }));
  }

  searchWithRelaseDateMatching({ filters, page, sort }: SerachBraintreeMatchedVccParams): Observable<BraintreeMatchingVccResponse> {
    const params: Record<string, string | number | boolean> = {
      pageSize: page?.pageSize || 25,
      pageNumber: (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',

      ...this.getOrganizedFilters(filters),
    };

    removeEmptyParams(params);

    const isMatchedValue = filters?.isMatched ?? undefined;
    if (isMatchedValue !== undefined) {
      params['isMatched'] = filters!.isMatched as boolean;
    }

    return this.http.get<BraintreeMatchingVccResponse>(`${this.url1}`, {
      params: { ...params },
    }).pipe(catchError((error: HttpErrorResponse) => {
      throw new Error(error?.message || 'Server error');
    }));
  }

  searchBraintreeMatchingReconciliation({ filters, page, sort }: SearchBraintreeMatchingReconciliationParams): Observable<SearchBraintreeMatchingReconciliationResponse> {
    const params: Record<string, string | number | boolean> = {
      pageSize: page?.pageSize || 25,
      pageNumber: (page?.pageIndex || 0) + 1,
      orderKey: sort?.direction?.toUpperCase() || '',

      ...filters,
    };

    removeEmptyParams(params);

    return this.http.get<SearchBraintreeMatchingReconciliationResponse>(`${this.url2}`, {
      params: { ...params },
    }).pipe(catchError((error: HttpErrorResponse) => {
      throw new Error(error?.message || 'Server error');
    }));
  }

  adjustmentTramsBraintreeReconciliation(pairs: { tramsBookingId: any; amount: number; }[]): Observable<any> {
    const url = `${this.url3}`;
    const body = {
      bookingAmountPairs: pairs.map(pair => ({
        tramsBookingId: pair.tramsBookingId,
        amount: pair.amount
      }))
    };

    return this.http.post<any>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        throw new Error(error?.message || 'Server error');
      })
    );
  }
}
