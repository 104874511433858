<form data-testid="client-payments-edit-modal-form" class="filter" [formGroup]="dynamicFormGroup!" (submit)="updateAmounts()">
<modal-panel
    title="Services"
    (close)="closeModal()"
    data-testid="client-payments-edit-modal-panel"
    class="filter-panel"
    >
    <div class="filter__fields " [ngClass]="{'loading-spinner': !loadedData || !dataSource.length}">
        @if (!loadedData) {
            <mat-spinner data-testid="client-payments-edit-modal-panel-loading-spinner" ></mat-spinner>
        }

        @if(loadedData && dataSource.length) {
            <div class="clients-modal-container mat-elevation-z8" data-testid="client-payments-edit-modal-panel-container">
              <mat-table [dataSource]="dataSource" matSort data-testid="client-payments-edit-modal-panel-table">

                <ng-container matColumnDef="adxInvoiceId">
                  <mat-header-cell data-testid="client-payments-edit-invoice-id-header" class="mat-header-cell" *matHeaderCellDef>Invoice Id</mat-header-cell>
                  <mat-cell data-testid="client-payments-edit-invoice-id-cell" *matCellDef="let payment">{{ payment.adxInvoiceId }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="adxBookingId">
                  <mat-header-cell data-testid="client-payments-edit-adx-booking-id-header" class="mat-header-cell" *matHeaderCellDef>Service Id</mat-header-cell>
                  <mat-cell data-testid="client-payments-edit-adx-booking-id-cell" *matCellDef="let payment">{{ payment.adxBookingId }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="adxBookingTravelTypeName">
                  <mat-header-cell data-testid="client-payments-edit-adx-booking-travel-type-name-header" class="mat-header-cell" *matHeaderCellDef>Travel Type</mat-header-cell>
                  <mat-cell data-testid="client-payments-edit-adx-booking-travel-type-name-cell" *matCellDef="let payment">{{ payment.adxBookingTravelTypeName }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="adxBookingVendorName">
                  <mat-header-cell data-testid="client-payments-edit-adx-booking-vendor-name-header" class="mat-header-cell" *matHeaderCellDef>Vendor Name</mat-header-cell>
                  <mat-cell data-testid="client-payments-edit-adx-booking-vendor-name-cell" *matCellDef="let payment">{{ payment.adxBookingVendorName }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="totalFare">
                  <mat-header-cell data-testid="client-payments-edit-amount-header" class="mat-header-cell" *matHeaderCellDef>Amount</mat-header-cell>
                  <mat-cell data-testid="client-payments-edit-amount-cell" *matCellDef="let payment">
                    <mat-form-field floatLabel="always">
                      <mat-label>Amount</mat-label>
                      <input matInput placeholder="{{payment.totalFare}}" [formControlName]="payment.adxBookingId">
                      @if(dynamicFormGroup!.controls[payment.adxBookingId].value){
                      <button id="payment-form-modal-amount-clear-button"
                              matSuffix mat-icon-button
                              type="button"
                              (click)="dynamicFormGroup!.controls[payment.adxBookingId].reset()">
                        <mat-icon>close</mat-icon>
                      </button>
                      }
                    </mat-form-field>
                  </mat-cell>
                </ng-container>

                <mat-header-row data-testid="client-payments-edit-modal-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row data-testid="client-payments-edit-modal-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>

              </mat-table>

                <mat-paginator data-testid="client-payments-edit-modal-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
                    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
                </mat-paginator>
            </div>
        }
    </div>


    <ng-container modalPanelFooter data-testid="client-payments-edit-modal-panel-footer">
        <div class="modal-footer">
            <button mat-button mat-stroked-button color="primary" type="button" (click)="closeModal()" data-testid="client-payments-edit-modal-panel-close-button">Close</button>
            @if(loadedData && dataSource.length) {
                <button data-testid="client-payments-edit-modal-submit-button" mat-raised-button color="primary" type="submit" [disabled]="isDisabled()">
                    @if(!submitedData){
                        <mat-spinner color="accent" diameter="30"></mat-spinner>
                    } @else {
                        Submit
                    }
                </button>
            }
        </div>
    </ng-container>
</modal-panel>
</form>
