import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {VendorModel} from '../model/vendor-model';
import {CreateVendor} from '../model/create-vendor';
import {UpdateVendor} from '../model/update-vendor';
import {VendorFilterResult} from '../model/vendor-filter-result';
import {SearchVendorResponse} from '../model/search-vendor-response';

@Injectable({
  providedIn: 'root'
})

export class VendorService {
  private resourcePath: string = 'vendor';
  private _vendorFilterSubject: BehaviorSubject<VendorFilterResult> = new BehaviorSubject<VendorFilterResult>({});
  
  constructor(private httpClient: HttpClient) {
  }

  public searchVendors(vendorFilter: any): Observable<any> {
    let httpParams = new HttpParams();
    Object
      .keys(vendorFilter)
      .forEach((key) => {
        if (vendorFilter[key]) {
          httpParams = httpParams.append(key, vendorFilter[key]);
        }
      });

    return this.httpClient
      .get<any>(`${this.resourcePath}/search`, {params: httpParams})
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public getVendorById(id: number): Observable<any> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  public getVendorByKey(httpParams: HttpParams): Observable<any> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/search`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public getVendors(httpParams: HttpParams): Observable<any> {
    return this.httpClient
      .get<any>(this.resourcePath, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }


  public getCountries(): Observable<any> {
    let httpParams = new HttpParams({fromObject: {All: true}});
    return this.httpClient
      .get<any>(`country`, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public createVendor(addVendor: CreateVendor): Observable<any> {
    return this.httpClient
      .post<any>(this.resourcePath, JSON.stringify(addVendor))
      .pipe(catchError(this.errorHandler));
  }

  public updateVendor(updateVendor: UpdateVendor): Observable<any> {
    return this.httpClient
      .put<any>(this.resourcePath, JSON.stringify(updateVendor))
      .pipe(catchError(this.errorHandler));
  }

  getVendorFilter(): Observable<VendorFilterResult> {
    return this._vendorFilterSubject.asObservable();
  }

  setVendorFilter(value: VendorFilterResult): void {
    this._vendorFilterSubject.next(value);
  }

  public getVendorsDboV2(pageNumber: number = 1, pageSize: number = 25, filters: VendorFilterResult = {}): Observable<SearchVendorResponse> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (filters.vendorName) {
      params = params.set('VendorName', filters.vendorName);
    }

    const options = { params };
    return this.httpClient.get<SearchVendorResponse>(`dbov2/vendor/search`, options);
  }
}

export {VendorModel};
