import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError} from 'rxjs';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import * as moment from 'moment';
import {AirMatchingItemModel, AirMatchingReportFilter, AirMatchingResponse, SerachAirMatchingReportParams} from '../model/air-matching-report';

@Injectable({
  providedIn: 'root',
})
export class AirMatchingReportService {
  private readonly url = 'dbov2/fms-invoices-sabre-air-match-data';

  private _dataSource$ = new BehaviorSubject<AirMatchingReportFilter | {}>({});

  constructor(private http: HttpClient) {}

  getAirMatchingFilter (): Observable<AirMatchingReportFilter | {}>{
    return this._dataSource$.asObservable();
  }

  setAirMatchingFilter(value:AirMatchingReportFilter){
    this._dataSource$.next(value || null);
  }

  searchAirMatchingData({filters, page, sort} : SerachAirMatchingReportParams): Observable<AirMatchingResponse>  {

    const params:Record<string, string | number | boolean> = {
      pageSize:  page?.pageSize || 25,
      pageNumber : (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',
      adxReference: filters?.adxReference ?? '',
      sabreReference: filters?.sabreReference ?? '',
      sabreInvoiceIssueDateFrom: filters?.sabreInvoiceIssueDateFrom ? moment(filters?.sabreInvoiceIssueDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      sabreInvoiceIssueDateTo: filters?.sabreInvoiceIssueDateTo ? moment(filters?.sabreInvoiceIssueDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
    };

    removeEmptyParams(params)
    
    return this.http.get<AirMatchingResponse>(`${this.url}`, {
      params: {...params}
    }).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  approveUpdate(item: AirMatchingItemModel) : Observable<any> {
    return this.http.post(`${this.url}`, {invoiceId : [item.fmsSabreInvoiceId]});
  }
}
