<form class="filter" [formGroup]="paymentsReconFilterForm" (submit)="applyFilters()">
    <modal-panel
        data-testid="adx-payment-filter-modal-panel"
        class="adx-payment-filter-modal-panel-cls filter-panel"
        [title]="'Filter'"
        (close)="closeModal();"
        >
            <div data-testid="adx-payment-filter-modal-div-2" class="filter__fields">
                <mat-grid-list class="modal-filter-col-flex" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
                    
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Type</mat-label>
                            <mat-select formControlName="reconType">
                                <mat-option value="I">Client</mat-option>
                                <mat-option value="V">Vendor</mat-option>
                                
                            </mat-select>
                            @if(paymentsReconFilterForm.controls['reconType'].value){
                                <button 
                                    data-testid="pay-method-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('reconType'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Processor</mat-label>
                            <mat-select formControlName="processorIds" multiple>
                                <mat-option [value]="1">Connexpay</mat-option>
                                <mat-option [value]="2">Braintree</mat-option>
                                
                            </mat-select>
                            @if(paymentsReconFilterForm.controls['processorIds'].value){
                                <button 
                                    data-testid="pay-method-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('processorIds'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Adx trip reference</mat-label>
                            <input matInput placeholder="Adx trip reference" value="" formControlName="adxReference">
                            @if(paymentsReconFilterForm.controls['adxReference'].value){
                                <button 
                                    data-testid="invoice-id-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('adxReference')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Last four cc</mat-label>
                            <input matInput placeholder="Last four cc" value="" formControlName="lastFourCC" maxlength="4" (keypress)="checkInput($event)">
                            @if(paymentsReconFilterForm.controls['lastFourCC'].value){
                                <button 
                                    data-testid="invoice-id-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('lastFourCC')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Release date from</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="releaseDateFrom">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(paymentsReconFilterForm.controls['releaseDateFrom'].value){
                                <button 
                                    data-testid="adx-payment-date-from-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('releaseDateFrom')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Release date to</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="releaseDateTo">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(paymentsReconFilterForm.controls['releaseDateTo'].value){
                                <button 
                                    data-testid="adx-payment-date-from-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('releaseDateTo')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>

                </mat-grid-list>
                <mat-grid-list class="modal-filter-col-flex" [cols]="1" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile>
                        <p style="width: 25vw;">Matched</p>
                        <mat-radio-group formControlName="isMatched" aria-label="matching-mode" [color]="'primary'" style="width: 100%;">
                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                            <mat-radio-button [value]="false">No</mat-radio-button>
                        </mat-radio-group>
                        <button
                            data-testid="matching-is-mapped-clear-filter-button"
                            mat-icon-button
                            type="button"
                            (click)="clearFields('isMatched')"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
            <ng-container data-testid="adx-payment-modal-footer-container" modalPanelFooter>
                <div class="modal-footer">
                    <button data-testid="adx-payment-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                        <mat-icon data-testid="adx-payment-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                        Clear All
                    </button>
                    <button data-testid="adx-payment-modal-button" mat-raised-button color="primary" type="submit" [disabled]="paymentsReconFilterForm.invalid">Apply</button>
                </div>
            </ng-container>
    </modal-panel>
</form>
