import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  ClientBalanceFilter,
  ClientBalanceFilterForm,
  ClientBalanceFilterFormGroup,
} from '../../models/client-balance';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatDivider} from '@angular/material/divider';
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
} from '@angular/material/datepicker';
import {MatOption, MatSelect} from '@angular/material/select';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import {PayStatusesResponse} from 'src/app/adx-booking/model/pay-statuses-response';
import {TramsDataSourceModel} from '../../models/trams-data-source';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButton,
    MatIcon,
    MatIconButton,
    MatDialogContent,
    MatDialogActions,
    MatDivider,
    MatFormField,
    MatLabel,
    MatInput,
    MatDatepicker,
    MatDatepickerToggle,
    MatDatepickerInput,
    MatHint,
    MatSuffix,
    MatSelect,
    MatOption,
  ],
  selector: 'app-client-balance-filter',
  templateUrl: './client-balance-filter.component.html',
  styleUrl: './client-balance-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientBalanceFilterComponent implements OnInit {
  private readonly dialogRef = inject<
    MatDialogRef<ClientBalanceFilterComponent, Partial<ClientBalanceFilter>>
  >(MatDialogRef<ClientBalanceFilterComponent>);

  private readonly data = inject<Partial<ClientBalanceFilter>>(MAT_DIALOG_DATA);

  private readonly formBuilder = inject(NonNullableFormBuilder);

  protected form: ClientBalanceFilterForm;

  public datasources: TramsDataSourceModel[] = [];

  public status: PayStatusesResponse[] = [];

  protected get showPaymentDateClearIcon() {
    return !!this.form.controls.paymentDate.value;
  }

  protected get showClientNameClearIcon() {
    return !!this.form.controls.clientName.value;
  }

  protected get showMinBalanceClearIcon() {
    return !!this.form.controls.minOutstandingBalance.value;
  }

  protected get showMaxBalanceClearIcon() {
    return !!this.form.controls.maxOutstandingBalance.value;
  }

  protected get showMinInvoiceClearIcon() {
    return !!this.form.controls.minTotalBilled.value;
  }

  protected get showMaxInvoiceClearIcon() {
    return !!this.form.controls.maxTotalBilled.value;
  }

  protected get showIdClearIcon() {
    return !!this.form.controls.id.value;
  }

  protected get showDataSourceClearIcon() {
    return !!this.form.controls.dataSourceId.value;
  }

  protected get showPayStatusClearIcon() {
    return !!this.form.controls.clientPayStatusId.value;
  }

  constructor() {
    this.form = this.formBuilder.group<ClientBalanceFilterFormGroup>({
      id: [],
      dataSourceId: [],
      clientPayStatusId: [],
      paymentDate: [],
      clientName: [],
      minOutstandingBalance: [],
      maxOutstandingBalance: [],
      minTotalBilled: [],
      maxTotalBilled: [],
    });
  }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form.patchValue(this.data);
  }

  protected preventDecimal(event: KeyboardEvent) {
    if (event.key === '.' || event.key === ',') event.preventDefault();
  }

  protected removeDecimal(
    event: Event,
    control: keyof Pick<
      ClientBalanceFilterFormGroup,
      | 'minOutstandingBalance'
      | 'maxOutstandingBalance'
      | 'minTotalBilled'
      | 'maxTotalBilled'
      | 'id'
    >
  ) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    if (!value || !value.includes('.')) return;
    this.form.controls[control].setValue(parseInt(value.replace('.', '')));
  }

  protected formatClientName() {
    const name = this.form.controls.clientName.value;
    this.form.controls.clientName.setValue(name.replaceAll(' ', ''));
  }

  protected close() {
    this.dialogRef.close();
  }

  protected reset() {
    this.form.reset();
  }

  protected resetFilter(control: keyof ClientBalanceFilterFormGroup) {
    this.form.controls[control].reset();
  }

  protected apply() {
    const { ...filters } = this.form.getRawValue();
    removeEmptyParams(filters);
    this.dialogRef.close(filters);
  }
}
