import {Component, Inject, Optional, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'transaction-history-dialog',
    templateUrl: './transaction-history-dialog.component.html',
})
export class TransactionHistoryDialog implements OnInit {
    title: string;
    vcc: any;

    constructor(
        @Optional() public transactionDialogRef: MatDialogRef<TransactionHistoryDialog>,
        @Inject(MAT_DIALOG_DATA) public data: {title: string, vcc: any },
    ){
        this.title = data.title;
        this.vcc = data.vcc;
    }

    ngOnInit(): void {
        if (!this.data) {
            console.error('Data is missing');
        }
    }

    public closeDialog(): void {
        if (this.transactionDialogRef) {
          this.transactionDialogRef.close(false);
        }
      }
}