import {Component, Inject, Optional, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationDialogCopyAdxBookingComponent} from 'src/app/adx-booking/components/popup/confirmation-dialog/confirmation-dialog-copy-adxbooking.component';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';

@Component({
  selector: 'app-adx-booking-details-modal',
  templateUrl: './adx-booking-details-modal.component.html'
})
export class AdxBookingDetailsModalComponent implements OnInit {
  title: string;
  adxBookingId: any;
  public isLoggedInUserHasManagerRole: boolean = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AdxBookingDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, adxBookingId: any },
    public dialog: MatDialog,
    private azureAuthService: AzureAuthService
  ) {
    this.title = data.title;
    this.adxBookingId = data.adxBookingId;
  }

  ngOnInit(): void {
    this.isLoggedInUserHasManagerRole = this.azureAuthService.checkUserAzureTokenHasManagerRole();
    if (!this.data) {
      console.error('Data is missing');
    }
  }

  public closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close(false);
    }
  }
  
  openCopyConfirmationDialog(adxBookingId : number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogCopyAdxBookingComponent, {
      data: {
        adxBookingId: adxBookingId
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }
}
