import {Component, OnInit} from '@angular/core';
import {AdxPaymentService} from '../../service/adx-payment.service';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {ReconciliationUploadModalComponent} from '../reconciliation-upload-modal/reconciliation-upload-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {PaymentReconciliationFilter, ReconciliationModel} from '../../models/client-reconciliation';
import {ClientPaymentsEditModalComponent} from '../client-payments-edit-modal/client-payments-edit-modal.component';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-client-payments-reconciliation',
  templateUrl: './client-payments-reconciliation.component.html'
})
export class ClientPaymentsReconciliationComponent implements OnInit {
  items: ReconciliationModel[] = [];
  displayedColumns: string[] = [
    'clientNameAlias',
    'reconType',
    'payeeName',
    'lastFour',
    'onFileAmountTotal',
    'fmsPaymentAmountTotal',
    'adxReference',
    'agentName',
    'vendorName',
    'bankNo',
    'bankName',
    'dataSourceName',
    'dataSourceId',
    'actions'

  ];

  reconciliationFilter$?:Subscription;

  filters: PaymentReconciliationFilter = {};
  pagination: Partial<PageEvent & { totalItemCount?: number }> = {};
  sorting: Partial<Sort> = {};

  constructor(
    private adxPaymentService: AdxPaymentService,
    private dialog: MatDialog,
    private exportGridService: ExportGridService,
  ) { }

  ngOnInit(): void {
    this.reconciliationFilter$ = this.adxPaymentService.getPaymentsReconciliationFilter().subscribe((filter: PaymentReconciliationFilter)=>{
      this.filters = filter;
      this.pagination = {
        pageSize: this.pagination.pageSize || 25,
        pageIndex: 0,
        totalItemCount: 0,
      };
      this.getItems();
    })

  }

  getItems(): void {
    this.adxPaymentService.getReconciliation({
      filters: this.filters,
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((data) => {
      this.items = data.items;
      this.pagination = {
        ...this.pagination,
        pageIndex: data.pagination?.pageNumber ? data.pagination.pageNumber - 1 : 0,
        pageSize: data.pagination?.pageSize || this.pagination.pageSize || 25,
        totalItemCount: data.pagination?.totalItemCount || this.items.length,
      };
    });
  }

  handleSortChange(sort: Sort): void {
    this.sorting = sort;

    if (!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = {};
    }

    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  uploadFileDialog(): void {
    this.dialog.open(ReconciliationUploadModalComponent).afterClosed().subscribe((sentFile: boolean) => {
      if (sentFile) {
        this.getItems();
      }
    });
  }

  downloadGridToFile(): void {
    this.exportGridService.getByDataSource(
      'dbov2/payment-reconciliation/recon-search',
      'payments-reconciliation',
      {
        ...this.filters,
        pageSize: this.pagination?.totalItemCount || 100,
      },
      (data) => {
        const record = data as ReconciliationModel;
        return {
          'Name': record?.clientNameAlias,
          'Type': record?.reconType,
          'Payee Name': record?.payeeName,
          'Last Four': record?.lastFour,
          'On File Total': record?.onFileAmountTotal,
          'Fms Total': record?.fmsPaymentAmountTotal,
          'ADX Reference': record?.adxReference,
          'Agent Name': record?.agentName,
          'Vendor Name': record?.vendorName,
          'Bank No': record?.bankNo,
          'Bank Name': record?.bankName,
          'Source Name': record?.dataSourceName,
          'Source Id': record?.dataSourceId,
        };
      }
    );
  }


  openEditModal(element: ReconciliationModel): void {
    this.dialog.open(ClientPaymentsEditModalComponent, { data: element });
  }

  getBackgroundColor(element: ReconciliationModel): string {
    if (element.fmsPaymentAmountTotal == null || element.fmsPaymentAmountTotal === element?.onFileAmountTotal) {
      return 'white';
    }   else {
      return 'rgb(255, 216, 216)';
    }
  }

  getDisplayIcon(element: ReconciliationModel): string {
    if (element.fmsPaymentAmountTotal == null || element.fmsPaymentAmountTotal === element?.onFileAmountTotal) {
      return 'none';
    } else {
      return 'block';
    }
  }
}
