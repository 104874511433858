import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {ChartDataSet, ChartOptions} from 'src/app/shared/components/chart/chart.component';
import {DashboardService} from '../../service/dashboard.service';
import {AhtWeeklyCalculation} from '../../model/aht-weekly-calculation';
import {HttpParams} from '@angular/common/http';
import 'chartjs-adapter-moment';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ChartOptionsService} from 'src/app/shared/components/chart/chart-options.service';
import {FilterResult} from 'src/app/shared/modals/filter-modal/filter-modal.component';
import {DateService} from 'src/app/shared/service/date/date.service';
import {ApplicationConstants} from 'src/app/shared/constants/application.constants';
import {AzureAuthService, UserAzureProfileDetails} from '../../../../infrastructure/services/azure-auth.service';

export enum AhtType {
  Minutes = 'minutes',
  Percentage = 'percentage'
}

@Component({
  selector: 'app-aht-weekly-calculation',
  templateUrl: './aht-weekly-calculation.component.html'
})

@AutoUnsubscribe()
export class AhtWeeklyCalculationComponent implements OnInit {
  @Input() public type: AhtType = AhtType.Minutes;
  @Input() filter!: FilterResult;
  filterDateFrom: Date;
  filterDateTo: Date;
  private appConstants: ApplicationConstants = new ApplicationConstants;
  filterEmail: string;

  get isTypeMinutes() {
    return this.type === AhtType.Minutes;
  }

  userAzureProfileDetails: UserAzureProfileDetails = {};
  ahtWeeklyCalculation: AhtWeeklyCalculation[] = []
  IsLoading: boolean = false;
  public minutesLineChartData!: ChartDataSet | null;
  public percentageLineChartData!: ChartDataSet | null;
  public minutesLineChartOptions: ChartOptions;
  public percentageLineChartOptions: ChartOptions;

  constructor(
    private azureAuthService: AzureAuthService,
    private dashboardService: DashboardService,
    private date: DateService,
    private chartOptions: ChartOptionsService
  ) {
    this.filterDateFrom = this.date.subtractDays(new Date(), 30);
    this.filterDateTo = new Date();
    this.filterEmail = this.appConstants.AllUser;
  }

  ngOnInit(): void {
    const isDirector = this.azureAuthService.checkUserAzureTokenHasDirectorRole();
    if (!isDirector) {
      this.azureAuthService.loggedInUserEmail.subscribe(x => {
        this.filterEmail = x;
      });
    }
    this.handle(this.filterDateFrom.toDateString(), this.filterDateTo.toDateString(), this.filterEmail);
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['filter'] && !changes['filter'].firstChange) {
      this.filterDateFrom = this.filter.from ? this.filter.from : this.filterDateFrom;
      this.filterDateTo = this.filter.to ? this.filter.to : this.filterDateTo;
      this.filterEmail = this.filter.email?.toString() ?? this.filterEmail;
      this.getAhtChart(this.filterDateFrom.toDateString(), this.filterDateTo.toDateString(), this.filterEmail);
    }
  }

  public handle(fromDate: string, toDate: string, email: string) {
    this.IsLoading = true;
    this.getAhtChart(fromDate, toDate, email);
  }

  public getAhtChart(fromDate: string, toDate: string, email: string) {
    this.minutesLineChartData = null;
    this.percentageLineChartData = null;

    if (email == this.appConstants.AllUser) {
      email = '';
    }

    let httpParams = new HttpParams({fromObject: {Email: email ?? '', DateFrom: fromDate, DateTo: toDate}});

    this.dashboardService.searchAhtWeeklyCalculation(httpParams)
      .subscribe((data: any) => {
        this.ahtWeeklyCalculation = data as AhtWeeklyCalculation[];

        const labels = this.ahtWeeklyCalculation.map((d) => `${d.weekNumber}`);
        const dateRanges = this.ahtWeeklyCalculation.map((d) => this.date.getDateRangeForWeek(d.weekNumber));
        const percentageData = this.ahtWeeklyCalculation.map((d) => d.ahtPercentage);
        const minutesData = this.ahtWeeklyCalculation.map((d) => d.ahtMinutes);

        this.createPercentageLineChart(labels, dateRanges, percentageData);
        this.createMinutesLineChart(labels, dateRanges, minutesData);
      });
  }

  createPercentageLineChart(labels: string[], dateRanges: string[], percentageData: number[]) {
    const [dataSet, options] = this.getChartOptions('Percentage', dateRanges, percentageData, labels);
    this.percentageLineChartData = dataSet;
    this.percentageLineChartOptions = options;
  }

  createMinutesLineChart(labels: string[], dateRanges: string[], minutesData: number[]) {
    const [dataSet, options] = this.getChartOptions('Minutes', dateRanges, minutesData, labels);
    this.minutesLineChartData = dataSet;
    this.minutesLineChartOptions = options;
  }

  getChartOptions(yText: string, dateRanges: string[], data: number[], labels: string[]): [ChartDataSet, ChartOptions] {
    let dataSet: ChartDataSet = {
      datasets: [
        {
          data: data,
          label: yText,
          pointBackgroundColor: '#EDBF6B',
          borderColor: '#38ABE0',
          backgroundColor: '#38ABE0',
        },
      ],
      labels: labels,
    };

    let options = this.chartOptions.getChartOptions('line', {
      scales: {
        y: {
          beginAtZero: true
        },
        x: {},
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            title: (context: any) => {
              const index = context[0]?.dataIndex;
              return `Date Range: ${dateRanges[index]}`;
            },
          },
        },
      }
    })

    return [dataSet, options]
  }
}
