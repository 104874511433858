import {Component, Inject, Optional} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GeneralLedgerService} from "../../service/general-ledger.service";
import {GeneralLedgerModel} from "../../model/general-ledger";

@Component({
    selector: 'delete-gl-confirmation',
    templateUrl: './delete-general-ledger-confirmation.component.html'
})
export class DeleteGlConfirmationComnponent {

    isLoading = false;

    constructor(
        @Optional() public confirmationDialogRef: MatDialogRef<DeleteGlConfirmationComnponent>,
        @Inject(MAT_DIALOG_DATA) public glData: GeneralLedgerModel,
        private generalLedgerService: GeneralLedgerService,
        private _snackBar: MatSnackBar,
    ){}

    okClick(){
        if(this.glData.id) {
            this.isLoading = true;
            this.generalLedgerService.deleteGeneralLedger(this.glData.id).subscribe(()=>{
                this._snackBar.open('General ledger deleted successfully', 'Dismiss', {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                });
                this.confirmationDialogRef.close(true);
            });
        } else {
            this.confirmationDialogRef.close();
        }

    }

    onNoClick(){
        this.confirmationDialogRef.close(false);
    }
}