import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AdxAgentBookingService} from '../../service/adx-agent-booking.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-adx-agent-booking-info',
  templateUrl: './adx-agent-booking-info.component.html'
})

@AutoUnsubscribe()
export class AdxAgentBookingInfoComponent {
  @Input() public data: any;
  _adxAgentBooking : any;
  isEdit = false;
  adxAgentBookingForm: FormGroup = this.fb.group({});
  currentAgentId: number = 0;
  autocompleteMode: 'A' | 'I' | 'V' = 'A';
  dataSourceId: number = 0;
  formChanges$?: Subscription;

  constructor(
    private fb: FormBuilder,
    private adxAgentBookingService: AdxAgentBookingService,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit(){
    this.getAdxAgentBookingInfo();
    this.createForm();
  }

  getAdxAgentBookingInfo() {
    if (this.data != null){
      this._adxAgentBooking = this.data;
      this.dataSourceId = this._adxAgentBooking.agent.dataSourceId;
    }
  }

  toggleEdit(): void {
    if (this.isEdit){
      this._adxAgentBooking.agent.adxAgentId = this.currentAgentId;
      this.resetForm();
    }
    else{
      this.currentAgentId = this._adxAgentBooking.agent.adxAgentId;
      this._adxAgentBooking.agent.adxAgentId = null;
      this.clearForm();
    }

    this.isEdit = !this.isEdit;
  }

  createForm(): void {
    this.adxAgentBookingForm = this.fb.group({
      agent: [],
      agentName: [
        this._adxAgentBooking.agent?.agentName,
        Validators.required,
      ],
      firstName: [
        this._adxAgentBooking.agent?.firstName
      ],
      middleName: [
        this._adxAgentBooking.agent?.middleName
      ],
      lastName: [
        this._adxAgentBooking.agent?.lastName
      ],
      interfaceId: [
        this._adxAgentBooking.agent?.interfaceId
      ],
      email: [
        this._adxAgentBooking.agent?.email
      ],
    });

    this.formChanges$ = this.adxAgentBookingForm.controls['agent'].valueChanges.subscribe((value) => {
      if (this.currentAgentId == value.adxAgentId || 
        this._adxAgentBooking.adxBooking.adxAgentBookings
          .filter((agentBoooking:any) => agentBoooking !== null).some((agent: any) => agent.agentId === value.id && !agent.isDeleted)){
        this._snackBar.open('Agent already assigned to this service.', 'Dismiss', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['message-position', 'app-notification-error']
        });

        this.toggleEdit();

        return;
      }

      this.adxAgentBookingForm?.patchValue({
        agentName: value.agentName,
        firstName: value.firstName,
        middleName: value.middleName,
        lastName: value.lastName,
        interfaceId: value.interfaceId,
        email: value.email,
      });
    });
  }

  clearForm(): void {
    this.adxAgentBookingForm?.patchValue({
      agentName: null,
      firstName: null,
      middleName: null,
      lastName: null,
      interfaceId: null,
      email: null,
    });
  }

  resetForm(): void {
    this.adxAgentBookingForm?.patchValue({
      agentName: this._adxAgentBooking.agent?.agentName,
      firstName: this._adxAgentBooking.agent?.firstName,
      middleName: this._adxAgentBooking.agent?.middleName,
      lastName: this._adxAgentBooking.agent?.lastName,
      interfaceId: this._adxAgentBooking.agent?.interfaceId,
      email: this._adxAgentBooking.agent?.email,
    });
  }

  updateAgent(){
    this._adxAgentBooking.agentId = this.adxAgentBookingForm.value.agent.id;
    this._adxAgentBooking.agentBookingCentralDocumentId = this.adxAgentBookingForm.value.agent.tramsCentralDocumentId;
    this._adxAgentBooking.agent = this.adxAgentBookingForm.value.agent;
    this.adxAgentBookingService.updateAdxAgentBooking(this._adxAgentBooking)
      .subscribe({
          next: () => {
            this._snackBar.open('Successfully updated agent booking information!!', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['message-position', 'app-notification-success']
            });
            
            this.currentAgentId = this.adxAgentBookingForm.value.agent.adxAgentId;
            this.isEdit = !this.isEdit;
          },
          error: () => {
            this._snackBar.open('Failed to update agent booking information.', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: ['message-position', 'app-notification-error']
            });
          }
      });
  }
}