<form class="filter" [formGroup]="filterForm" (submit)="applyFilters()">
    <modal-panel
        data-testid="air-matching-report-filter-modal-panel"
        class="air-matching-report-filter-modal-panel-cls filter-panel"
        [title]="'Filter'"
        (close)="closeModal();"
        >
            <div data-testid="air-matching-report-filter-modal-div-2" class="filter__fields">
                <mat-grid-list class="modal-filter-col-flex" [cols]="1" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Adx Reference</mat-label>
                            <input matInput formControlName="adxReference">
                            @if(filterForm.controls['adxReference'].value){
                                <button 
                                    data-testid="air-matching-report-adx-reference-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('adxReference')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Sabre Reference</mat-label>
                            <input matInput formControlName="sabreReference">
                            @if(filterForm.controls['sabreReference'].value){
                                <button 
                                    data-testid="air-matching-report-sabre-reference-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('sabreReference')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Issue date from</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="sabreInvoiceIssueDateFrom">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(filterForm.controls['sabreInvoiceIssueDateFrom'].value){
                                <button 
                                    data-testid="air-matching-report-sabre-invoice-issue-date-from-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('sabreInvoiceIssueDateFrom')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Issue date to</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="sabreInvoiceIssueDateTo">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(filterForm.controls['sabreInvoiceIssueDateTo'].value){
                                <button 
                                    data-testid="air-matching-report-sabre-invoice-issue-date-to-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('sabreInvoiceIssueDateTo')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>
                    
                </mat-grid-list>
            </div>
            <ng-container data-testid="air-matching-report-filter-modal-footer-container" modalPanelFooter>
                <div class="modal-footer">
                    <button data-testid="air-matching-report-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                        <mat-icon data-testid="air-matching-report-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                        Clear All
                    </button>
                    <button data-testid="air-matching-report-filter-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
                </div>
            </ng-container>
    </modal-panel>
    </form>