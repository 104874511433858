import {Component, AfterContentInit, ContentChild, input} from '@angular/core';
import {NgControl} from '@angular/forms';
import {Subscription} from 'rxjs';  
@Component({
    selector: 'editable-form-field',
    templateUrl: './editable-form-field.component.html',
})
export class EditableFormFieldComponent implements AfterContentInit {
  @ContentChild(NgControl) control?: NgControl;
  readonly = input<boolean>(false);
  title = input<string>('');
  testId = input<string>('');
  value: any = '';
  showContent = true;
  valueChangeSubscription$?: Subscription;

  ngAfterContentInit(): void {
    if (this.control) {
      this.value = this.control.value;
      if (this.readonly()) {
        this.valueChangeSubscription$ = this.control.valueChanges?.subscribe(
          (value) => {
            this.value = value;
          }
        );

        this.showContent = false;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription$) {
      this.valueChangeSubscription$.unsubscribe();
    }
  }
}