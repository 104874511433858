import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {MatSort} from '@angular/material/sort';
import {Pagination} from '../../../../infrastructure/pagination/model/pagination';
import {MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';
import {AdxPaymentResponse} from '../../model/adx-payment-response';
import {AdxPaymentService} from '../../service/adx-payment.service';
import {SearchPaginatedAdxPaymentResponse} from '../../model/search-paginated-adx-payment-response';
import {AdxPaymentFilter} from '../../model/adx-payment-filter';
import {AdxPaymentFilterResult} from '../../model/adx-payment-filter-result';
import {MatDialog} from '@angular/material/dialog';
import {AdxPaymentDetailsModalComponent} from '../adx-payment-details-modal/adx-payment-details-modal.component';

@Component({
  selector: 'adx-payment-list',
  templateUrl: './adx-payment-list.component.html',
})

@AutoUnsubscribe()
export class AdxPaymentListComponent implements OnInit, AfterViewInit {
  @Input() public data: any;

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  public dataSource: MatTableDataSource<AdxPaymentResponse> = new MatTableDataSource<AdxPaymentResponse>([]);

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  public displayedColumns: Array<string> = [
    'id',
    'paymentDate',
    'client.profileName',
    'payType.payTypeName',
    'payMethod.paymethodName',
    'amount',
    'ckCcNo',
    'branch.name',
    'bank.name',
    'paymentGroup',
    'remarks',
    'invoiceRef',
  ];

  isLoading: boolean = false;

  public pagination: Pagination = {} as Pagination;
  public adxPaymentFilter: AdxPaymentFilter = {} as AdxPaymentFilter;

  constructor(
    private adxPaymentService: AdxPaymentService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
    private dialog: MatDialog)
    {  }

  public ngOnInit(): void {
    if (this.data == undefined || this.data.pageSize == null) {
      this.data = {
        adxInvoiceId: null,
        pageSize: 25
      }
    }
    this.adxPaymentService.getAdxPaymentFilter().subscribe((filter: AdxPaymentFilterResult) => {
      if (this.paginator !== undefined) {
        this.paginator.pageIndex = 0;
        this.adxPaymentFilter.pageNumber = 1;
      }

      filter.adxInvoiceId = this.data.adxInvoiceId;
      this.adxPaymentFilter.adxInvoiceId = filter.adxInvoiceId;
      this.adxPaymentFilter.pageSize = this.data.pageSize;

      this.searchAdxPayments(new PageEvent(), false);
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public searchAdxPayments(pageEvent: PageEvent, skipPager: boolean): void {
    if (!skipPager) {
      this.setPaginationAfterPageChanged(pageEvent);
    }
    this.adxPaymentService
      .searchPaginatedAdxPayments(this.adxPaymentFilter)
      .subscribe((data: SearchPaginatedAdxPaymentResponse) => {
        if (data) {
          this.pagination = data.pagination;
          this.adxPaymentFilter.pageNumber = data.pagination.pageNumber;
          this.adxPaymentFilter.pageSize = data.pagination.pageSize;
          this.dataSource = new MatTableDataSource<AdxPaymentResponse>(data.items);
          this.isLoading = false;
        }
      });
  }

  public pageChanged(event: PageEvent): void {
    this.searchAdxPayments(event, false);
  }

  public setPaginationAfterPageChanged(pageEvent: PageEvent): void {
    this.adxPaymentFilter.pageSize = this.data.pageSize;

    const isStepForward = pageEvent.pageIndex > pageEvent.previousPageIndex!;

    if (isStepForward) {
      this.adxPaymentFilter.pageNumber += 1;
    } else {
      this.adxPaymentFilter.pageNumber = this.adxPaymentFilter.pageNumber !== undefined &&  this.adxPaymentFilter.pageNumber > 1 ? this.adxPaymentFilter.pageNumber-1 : 1;
    }
  }

  public handleSortChange(sort: any): void {
    this.adxPaymentFilter.propertyName = sort.active;
    this.adxPaymentFilter.orderKey = sort.direction.toUpperCase();

    this.searchAdxPayments(new PageEvent(), false);
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.adxPaymentService
      .searchPaginatedAdxPayments(this.adxPaymentFilter).subscribe((data: SearchPaginatedAdxPaymentResponse) => {
        this.exportGridService.getByDataSource(
          'dbov2/adx-payment/search',
          'adx-payments',
          {
            ... this.adxPaymentFilter,
            pageSize: data?.pagination?.totalItemCount || 100,
          },
          (data)=>{
            const record = data as AdxPaymentResponse;
            return {
              'Id': record?.id,
              'Payment Date': this.datePipe.transform(record?.paymentDate, 'M/d/yyyy'),
              'Client Name': record?.client?.profileName,
              'Pay Type': record?.payType?.payTypeName,
              'Pay Method': record?.payMethod?.paymethodName,
              'Amount': record?.amount,
              'ckCcNo': record?.ckCcNo,
              'Branch': record?.branch?.name,
              'Bank': record?.bank?.name,
              'Payment Group': record?.paymentGroup,
              'Remarks': record?.remarks,
              'Invoice Ref': record?.invoiceRef,
            };
          }
        );
        downloadSubs$?.unsubscribe();
      });
  }

  replayGrid (){
    this.searchAdxPayments(new PageEvent(), false);
  }

  handleIdClick(event: Event, element: any): void {
    event.preventDefault();
    
    if (this.data.adxInvoiceId == null) {
      window.open(`AdxPayments/${element.id}`);
    }
    else {
      this.openDialog(element.id, `Payment information`);
    }
  }

  public openDialog(adxPaymentId: number, title: string): void {
    this.dialog.open(AdxPaymentDetailsModalComponent, {
      width: '55%',
      data: { adxPaymentId: adxPaymentId, title: title }
    });
  }
}
