<modal-panel id="vcc-daily-summary-report-filter-modal-panel" class="vcc-daily-summary-report-filter-modal-panel-cls" [title]="'Filter'" (close)="handleClose()">
  <div id="vcc-daily-summary-report-filter-modal-div-1" class="filter" [formGroup]="form">
    <div id="vcc-daily-summary-report-filter-modal-div-2" class="filter__fields">
      <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
        <mat-grid-tile>
          <mat-form-field style="width: 100%;">
            <mat-label id="vcc-daily-summary-report-filter-report-date-field-title">Report Date</mat-label>
            <input id="vcc-daily-summary-report-filter-report-date-input-field"
                   matInput
                   [matDatepicker]="picker1" readonly
                   [(ngModel)]="config.releasedDate"
                   formControlName="releasedDate"
                   (dateInput)="handleVccDailySummaryReportFilterChange($event, 'releasedDate')">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 id="vcc-daily-summary-report-filter-report-date-datepicker"></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="1" rowHeight="5vw" [gutterSize]="'100px'">
        <mat-grid-tile>
          <mat-form-field style="width: 100%;" id="vcc-daily-summary-report-filter-merchant-id-form-field">
            <mat-label id="vcc-daily-summary-report-filter-merchant-id-label">
              Merchant Name
            </mat-label>
            <mat-select formControlName="merchantGuid"
                        [(value)]="this.selectedMerchantGuid"
                        (selectionChange)="handleVccDailySummaryReportFilterChange($event.value, 'merchantGuid')"
                        id="vcc-daily-summary-report-filter-merchant-id-input">
              <mat-select-trigger>
              </mat-select-trigger>
              <mat-option *ngFor="let merchant of merchantGuids" [value]="merchant.merchantGuid">
                {{ merchant.fullName }}
              </mat-option>
            </mat-select>
            <div id="selected-merchant-name">
              {{ getMerchantName() }}
            </div>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  <ng-container id="vcc-daily-summary-report-filter-modal-footer-container" modalPanelFooter>
    <button id="vcc-daily-summary-report-filter-modal-button" mat-raised-button color="primary" (click)="applyVccDailySummaryReportFilters()">Apply</button>
  </ng-container>
</modal-panel>
