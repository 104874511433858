import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FmsAllComponent} from './home/components/fms-all.component';
import {TemplateLayoutComponent} from './template/components/template-layout/template-layout.component';
import {VendorLayoutComponent} from './vendor/component/layout/vendor-layout/vendor-layout.component';
import {RequestActionLayoutComponent} from './request-action/components/layout/request-action-layout.component';
import {TransactionBookingViewerComponent} from './transaction-booking/components/viewer/transaction-booking-viewer.component';
import {MsalGuard} from '@azure/msal-angular';
import {VendorTemplateLayoutComponent} from './vendor-template/component/layout/vendor-template.layout/vendor-template.layout.component';
import {TransactionBookingLayoutComponent} from './transaction-booking/components/layout/transaction-booking-layout.component';
import {VendorListComponent} from './vendor/component/list/vendor-list.component';
import {TransactionBookingListComponent} from './transaction-booking/components/list/transaction-booking-list.component';
import {FileUploadComponent} from './nav/upload/components/file-upload.component';
import {DashboardComponent} from './dashboard/layout/dashboard/dashboard.component';
import {TransactionsComponent} from './dashboard/layout/transactions/transactions.component';
import {FinanceReportComponent} from './finance-report/layout/finance-report/finance-report.component';
import {CommissionReportComponent} from './finance-report/components/commission-report/commission-report.component';
import {CommissionQueueComponent} from './finance-report/components/commission-queue/commission-queue.component';
import {PaymentQueueComponent} from './finance-report/components/payment-queue/payment-queue.component';
import {TripProfitabilityComponent} from './finance-report/components/trip-profitability/trip-profitability.component';
import {VccDailySummaryReportComponent} from './finance-report/components/vcc-daily-summary-report/vcc-daily-summary-report.component';
import {NoAccessComponent} from './unauthorized-access/component/no-access/no-access.component';
import {HomeLayoutComponent} from './home/components/layout/home-layout.component';
import {UserRoleConstants} from './shared/model/user-role.constants';
import {AuthRoleGuard} from 'src/infrastructure/guards/auth-role.guard';
import {FinanceComponent} from './dashboard/layout/finance/finance.component';
import {TripProfitabilityWithAgencyComponent} from './finance-report/components/trip-profitability-with-agency/trip-profitability-with-agency.component';
import {TraceComponent} from './trace/component/trace.component';
import {InvoicesComponent} from './invoices/layout/invoices/invoices.component';
import {AdxInvoiceLineItemsComponent} from './invoices/component/adx-invoice-line-items/adx-invoice-line-items.component';
import {TramsInvoicesComponent} from './invoices/component/trams-invoices/trams-invoices.component';
import {PaymentsComponent} from './payments/layout/payments/payments.component';
import {AdxVccComponent} from './finance-resource/component/adx-vcc/adx-vcc.component';
import {FmsVccComponent} from './finance-resource/component/fms-vcc/fms-vcc.component';
import {InvoiceDetailComponent} from './invoices/layout/invoice-detail/invoice-detail.component';
import {VccComponent} from './dashboard/layout/vcc/components/vcc.component';
import {VccReportComponent} from './dashboard/layout/vcc/components/vcc-report/vcc-report.component';
import {MatchingVccComponent} from './finance-resource/component/matching-vcc/matching-vcc.component';
import {MatchingVccDetailComponent} from './finance-resource/component/matching-vcc-detail/matching-vcc-detail.component';
import {AlertsGridComponent} from './finance-resource/component/alerts/alerts.component';
import {TripListComponent} from './trip/components/trip-list/trip-list.component';
import {TripDetailsComponent} from './trip/components/trip-details/trip-details.component';
import {AdxInvoiceDetailsComponent} from './adx-invoice/components/adx-invoice-details/adx-invoice-details.component';
import {ClientListComponent} from './client/component/list/client-list.component';
import {AgentListComponent} from './agent/component/list/agent-list.component';
import {TramsInvoiceComponent} from './invoices/component/layout/trams-invoice.component';
import {unsavedChangesGuard} from 'src/infrastructure/guards/unsaved-changes.guard';
import {MatchingVccV2Component} from './finance-resource/component/matching-vcc-v2/matching-vcc-v2.component';
import {HsProcessingComponent} from './trx-team-resource/components/hs-processing/hs-processing.component';
import {AdxBookingDetailsComponent} from './adx-booking/components/adx-booking-details/adx-booking-details.component';
import {PaymentsReceivedGridComponent} from './payments/components/payments-received-grid/payments-received-grid.component';
import {PaymentsMadeGridComponent} from './payments/components/payments-made-grid/payments-made-grid.component';
import {AdxPaymentDetailsComponent} from './adx-payment/components/adx-payment-details/adx-payment-details.component';
import {GeneralLedgerComponent} from './general-ledger/layout/general-ledger.component';
import {ChartOfAccountsComponent} from './general-ledger/components/chart-of-accounts/chart-of-accounts.component';
import {ClientBalanceComponent} from './payments/components/client-balance/client-balance.component';
import {VendorBalanceComponent} from './payments/components/vendor-balance/vendor-balance.component';
import {ArcBspBalanceComponent} from './payments/components/arc-bsp-balance/arc-bsp-balance.component';
import {GLTravelTypeMappingComponent} from './general-ledger/components/travel-type-mapping/travel-type-mapping.component';
import {FmsInvoiceComponent} from './fms-invoice/component/fms-invoice.component';
import {GLClosePeriodComponent} from './general-ledger/components/close-period/close-period.component';
import {ClientPaymentsReconciliationComponent} from './payments/components/client-payments-reconciliation/client-payments-reconciliation.component'
import {TripReferenceDetailsComponent} from './finance-resource/component/trip-reference-details/trip-reference-details.component';
import {TransactionHistoryDetailsComponent} from './finance-resource/component/transaction-history-details/transaction-history-details.component';
import {VendorViewerComponent} from './vendor/component/viewer/vendor-viewer/vendor-viewer.component';
import {BraintreeMatchingVccComponent} from './finance-resource/component/braintree-matching-vcc/braintree-matching-vcc.component';
import {NavExportComponent} from './general-ledger/components/nav-export/nav-export.component';
import {AirMatchingReportComponent} from './finance-resource/component/air-matching-report/air-matching-report.component';
import {paymentStatusResolver} from './payments/resolvers/payment-status.resolver';
import {tramsDataSourceResolver} from './payments/resolvers/trams-data-source.resolver';
import {AgentDetailsComponent} from './agent/component/details/agent-details.component';
import {SplitRateDetailsComponent} from './agent/component/split-rate-details/split-rate-details.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
  },
  {
    path: 'trace',
    component: TraceComponent,
    canActivate: [MsalGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    }
  },
  {
    path: 'Actions',
    component: FmsAllComponent,
    canActivate: [MsalGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    }
  },
  {
    path: 'Clients',
    component: ClientListComponent
  },
  {
    path: 'Agents',
    component: AgentListComponent
  },
  {
    path: 'Dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User,
        UserRoleConstants.Director
      ]
    },
    children: [
      {
        path: 'Transactions',
        component: TransactionsComponent
      },
      {
        path: 'Finance',
        component: FinanceComponent
      },
      {
        path: 'Vcc',
        component: VccComponent
      },
      {
        path: 'VccReport',
        component: VccReportComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Transactions'
      }
    ]
  },
  {
    path: 'FinanceReports',
    component: FinanceReportComponent,
    children: [
      {
        path: 'CommissionReports',
        component: CommissionReportComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'CommissionQueue',
        component: CommissionQueueComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'PaymentQueue',
        component: PaymentQueueComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'TripProfitability',
        component: TripProfitabilityComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'TripProfitabilityWithAgency',
        component: TripProfitabilityWithAgencyComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Director
          ]
        },
      },
      {
        path: 'VccDailySummaryReport',
        component: VccDailySummaryReportComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'CommissionReports'
      }
    ]
  },
  {
    path: 'NoAccess',
    component: NoAccessComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'VendorSearch',
    component: VendorLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Vendors',
    component: VendorListComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Templates',
    component: VendorTemplateLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Rules',
    component: TemplateLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'RequestAction/:id', component: RequestActionLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'RequestAction',
    component: RequestActionLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Services',
    component: TransactionBookingListComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'BookingsSearch',
    component: TransactionBookingLayoutComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'Services/:id',
    component: TransactionBookingViewerComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'FileUpload',
    component: FileUploadComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Invoices',
    component: InvoicesComponent,
    children: [
      {
        path: 'AdxInvoiceLineItems',
        component: AdxInvoiceLineItemsComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'TramsInvoices',
        component: TramsInvoicesComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'AdxInvoices'
      }
    ]
  },
  {
    path: 'Invoices/TramsInvoices/:invoiceId',
    component: TramsInvoiceComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'AdxInvoices/:id',
    component: AdxInvoiceDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Itinerary/:invoiceLineItemId',
    component: InvoiceDetailComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Payments',
    component: PaymentsComponent,
    children: [
      {
        path: 'Received',
        component: PaymentsReceivedGridComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'Made',
        component: PaymentsMadeGridComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'Balances/ClientBalance',
        component: ClientBalanceComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
        resolve: {
          paymentStatuses: paymentStatusResolver,
          dataSources: tramsDataSourceResolver,
        },
      },
      {
        path: 'Balances/VendorBalance',
        component: VendorBalanceComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'Balances/ArcBspBalance',
        component: ArcBspBalanceComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'PaymentsReconciliation',
        component: ClientPaymentsReconciliationComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'Received'
      }
    ]
  },
  {
    path: 'FinanceResources',
    component: PaymentsComponent,
    children: [
      {
        path: 'AdxVcc',
        component: AdxVccComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'AdxVcc/:cardGuid',
        component: AdxVccComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'FmsVcc',
        component: FmsVccComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'MatchingVcc',
        component: MatchingVccComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'ConnexpayVccMatchingReport',
        component: MatchingVccV2Component,
        canActivate: [MsalGuard, AuthRoleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'MatchingVcc/:id',
        component: MatchingVccDetailComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'ConnexpayVccMatchingReport/:id',
        component: MatchingVccDetailComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'BraintreeVccMatchingReport',
        component: BraintreeMatchingVccComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'AirMatchingReport',
        component: AirMatchingReportComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        canDeactivate: [unsavedChangesGuard],
        data: {
          roles: [
            UserRoleConstants.Finance
          ]
        },
      },
      {
        path: 'Alerts',
        component: AlertsGridComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Manager,
            UserRoleConstants.Director
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'AdxVcc'
      }
    ]
  },
  {
    path: 'Trips',
    component: TripListComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    }
  },
  {
    path: 'Trip/:id',
    component: TripDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    }       
  },
  {
    path: 'AdxBookings/:id',
    component: AdxBookingDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'AdxPayments/:id',
    component: AdxPaymentDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'TstResources',
    component: HsProcessingComponent,
    children: [
      {
        path: 'HsProcessing',
        component: HsProcessingComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.User
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'HsProcessing'
      }
    ]
  },
  {
    path: 'GeneralLedger',
    component: GeneralLedgerComponent,
    children: [
      {
        path: 'ChartOfAccounts',
        component: ChartOfAccountsComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance,
            UserRoleConstants.Manager,
          ]
        },
      },
      {
        path: 'TravelTypeMapping',
        component: GLTravelTypeMappingComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance,
            UserRoleConstants.Manager,
          ]
        },
      },
      {
        path: 'NavExport',
        component: NavExportComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Finance,
            UserRoleConstants.Manager,
          ]
        },
      },
      {
        path: 'ClosePeriod',
        component: GLClosePeriodComponent,
        canActivate: [MsalGuard, AuthRoleGuard],
        data: {
          roles: [
            UserRoleConstants.Director
          ]
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'ChartOfAccounts'
      }
    ],
  },
  {
    path: 'fmsinvoice/:id',
    redirectTo: 'FmsInvoice/:id',
    pathMatch: 'full',
  },
  {
    path: 'FmsInvoice/:id',
    component: FmsInvoiceComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.User
      ]
    },
  },
  {
    path: 'TripReference/TravelService/:travelServiceId',
    component: TripReferenceDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'TransactionHistory/:guid',
    component: TransactionHistoryDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Vendor/CentralDocument/:tramsCentralDocumentId',
    component: VendorViewerComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Agents/:agentId',
    component: AgentDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: 'Agents/:agentId/SplitRate',
    component: SplitRateDetailsComponent,
    canActivate: [MsalGuard, AuthRoleGuard],
    data: {
      roles: [
        UserRoleConstants.Finance
      ]
    },
  },
  {
    path: '**',
    redirectTo: '/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
