import {Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter} from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {RedirectRequest} from '@azure/msal-browser';
import {Observable, Subject, filter, map, tap } from 'rxjs';
import {environment} from 'src/environments/environment';
import {TooltipPosition} from '@angular/material/tooltip';
import {FormControl} from '@angular/forms';
import {MatMenuListItem} from '../model/mat-menu-list-item';
import {FilterService} from 'src/app/shared/service/filter/filter.service';
import {ModalService} from 'src/app/shared/service/modal/modal.service';
import {FilterModalComponent, FilterResult} from 'src/app/shared/modals/filter-modal/filter-modal.component';
import {DateService} from 'src/app/shared/service/date/date.service';
import {ApplicationConstants} from 'src/app/shared/constants/application.constants';
import {AutoUnsubscribe} from '../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {AzureAuthService, UserAzureProfileDetails} from '../../../infrastructure/services/azure-auth.service';
import {VccDailySummaryReportFilterModalComponent} from 'src/app/shared/modals/vcc-daily-summary-reports-filter-modal/vcc-daily-summary-reports-filter-modal.component';
import {VccDailySummaryReportFilterResult} from 'src/app/shared/model/vcc-daily-summary-report-filter-result';
import {FinanceReportsFilterModalComponent} from 'src/app/shared/modals/finance-reports-filter-modal/finance-reports-filter-modal.component';
import {FinanceReportFilterResult} from 'src/app/shared/model/finance-report-filter-result';
import {VendorsFilterModalComponent} from 'src/app/vendor/component/vendors-filter-modal/vendors-filter-modal.component';
import {VendorFilterResult} from 'src/app/vendor/model/vendor-filter-result';
import {VendorService} from 'src/app/vendor/service/vendor.service';
import {TransactionBookingsFilterModalComponent} from 'src/app/transaction-booking/components/transaction-bookings-filter-modal/transaction-bookings-filter-modal.component';
import {TransactionBookingService} from 'src/app/transaction-booking/service/transaction-booking.service';
import {TransactionBookingFilterResult} from 'src/app/transaction-booking/model/transaction-booking-filter-result';
import {AdxInvoiceLineItemFilterResult} from 'src/app/invoices/model/adx-invoice-line-item-filter-result';
import {AdxInvoiceLineItemFilterModalComponent} from 'src/app/invoices/component/adx-invoice-line-items-filter-modal/adx-invoice-line-items-filter-modal.component';
import {InvoiceLineItemService} from 'src/app/invoices/service/invoice-line-item.service';
import {TramsInvoiceFilterResult} from '../../invoices/model/trams-invoice-filter-result';
import {TramsInvoiceFilterModalComponent} from '../../invoices/component/trams-invoices-filter-modal/trams-invoices-filter-modal.component';
import {InvoiceService} from '../../invoices/service/invoice.service';
import {AdxVccService} from 'src/app/finance-resource/service/adx-vcc.service';
import {AdxVccFilterComponent} from 'src/app/finance-resource/component/adx-vcc-filter/adx-vcc-filter.component';
import {AdxVccFilter} from 'src/app/finance-resource/model/adx-data';
import {FmsVccService} from '../../finance-resource/service/fms-vcc.service';
import {FmsVccFilterResult} from '../../finance-resource/model/fms-vcc-filter-result';
import {FmsVccFilterModalComponent} from '../../finance-resource/component/fms-vccs-filter-modal/fms-vccs-filter-modal.component';
import {MatchingVccFilterComponent} from 'src/app/finance-resource/component/matching-vcc-filter/matching-vcc-filter.component';
import {MatchingVccService} from 'src/app/finance-resource/service/matching-vcc.service';
import {MatchedVccFilter} from 'src/app/finance-resource/model/match-vcc';
import {ChartsVccService} from '../../dashboard/service/charts-vcc.service';
import {VccChartFilterResult} from '../../dashboard/model/funding-vcc-chart-filter';
import {VccChartFilterModalComponent} from '../../dashboard/layout/vcc/components/vcc-chart-filter-modal/vcc-chart-filter-modal.component';
import {TripFilterModalComponent} from 'src/app/trip/components/trip-filter-modal/trip-filter-modal.component';
import {TripFilterResult} from 'src/app/trip/model/trip-filter-result';
import {TripService} from 'src/app/trip/service/trip.service';
import {ClientFilterModalComponent} from 'src/app/client/component/filter-modal/client-filter-modal.component';
import {ClientFilterResult} from 'src/app/client/component/filter-modal/client-filter-result';
import {ClientService} from 'src/app/client/service/client.service';
import {Client, ClientData} from 'src/app/client/service/client.model';
import {AgentFilterModalComponent} from 'src/app/agent/component/filter-modal/agent-filter-modal.component';
import {AgentFilterResult} from 'src/app/agent/component/filter-modal/agent-filter-result';
import {AgentService} from 'src/app/agent/component/service/agent.service';
import {Agent, AgentData} from 'src/app/agent/component/service/agent.model';
import * as moment from 'moment';
import {HsProcessingFilterComponent} from '../../trx-team-resource/components/hs-processing-filter/hs-processing-filter.component';
import {HsProcessingService} from '../../trx-team-resource/services/hs-processing.service';
import {HsProcessingFilter} from '../../trx-team-resource/models/hs-process.component';
import {AdxPaymentFilterComponent} from 'src/app/payments/components/payment-filter/payment-filter.component';
import {AdxPaymentService} from 'src/app/payments/service/adx-payment.service';
import {AdxPaymentFilter} from 'src/app/payments/models/adx-payment';
import {GeneralLedgerService} from 'src/app/general-ledger/service/general-ledger.service';
import {GlClosePeriodFilterComponent} from 'src/app/general-ledger/components/close-period-filter/close-period-filter.component';
import {ClosePeriodFilter} from 'src/app/general-ledger/model/general-ledger';
import {PaymentReconciliationFilterComponent} from 'src/app/payments/components/payments-reconciliation-filter/payments-reconciliation-filter.component';
import {PaymentReconciliationFilter} from 'src/app/payments/models/client-reconciliation';

@AutoUnsubscribe()
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, OnDestroy {
  public clients: Client[] = [];
  public agents: Agent[] = [];
  public positionOptions: TooltipPosition[] = ['left'];
  // tslint:disable-next-line:typedef
  public position = new FormControl(this.positionOptions[0]);
  location: Location;
  mobile_menu_visible: any = 0;
  menuListItems!: MatMenuListItem[];
  isUserLoggedIn$: Observable<boolean>;
  private readonly _destroy = new Subject<void>();
  selectedMenu!: string;
  chartsPersonEmailFilter!: string | null;
  chartsFilterFromDate?: Date | null;
  isMenuItemsVisibleIfUserHasAnyRole: boolean = false;
  chartsFilterToDate?: Date | string | null;
  @Input() hideMenuToggle!: boolean;
  @Output() menuToggled = new EventEmitter();
  private appConstants: ApplicationConstants = new ApplicationConstants;
  private isInitialFilterModalOpenRequest: boolean = true;
  userAzureProfileDetails$: Observable<UserAzureProfileDetails | null | undefined>;
  public financeReportConfigResult: FinanceReportFilterResult = {};
  public VccDailySummaryReportConfigResult: VccDailySummaryReportFilterResult = {};
  public vendorConfigResult: VendorFilterResult = {};
  public clientConfigResult: ClientFilterResult = {};
  public agentConfigResult: AgentFilterResult = {};
  public transactionBookingConfigResult: TransactionBookingFilterResult = {};
  public hideToggleFilterMenuIcon: boolean = true;
  public adxInvoiceLineItemConfigResult: AdxInvoiceLineItemFilterResult = {};
  public tramsInvoiceConfigResult: TramsInvoiceFilterResult = {};
  public adxConfigResults: AdxVccFilter = {};
  public fmsVccFilterConfigResult: FmsVccFilterResult = {};
  public matchingVccFilterConfigResult: MatchedVccFilter = {};
  public tripConfigResult: TripFilterResult = {};
  public vccChartFilterConfigResult: VccChartFilterResult = {};
  public hsProcessingFilterConfigResult: HsProcessingFilter = {};
  public adxPaymentFilterConfigResult: AdxPaymentFilter = {};
  public PaymentsReconciliationFilterConfigResult: PaymentReconciliationFilter = {};
  public glClosePeriodFilterConfigResult: ClosePeriodFilter = {};

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private azureAuthService: AzureAuthService,
    location: Location,
    private router: Router,
    private modal: ModalService,
    private filterService: FilterService,
    private vendorService: VendorService,
    private clientService: ClientService,
    private agentService: AgentService,
    private transactionBookingService: TransactionBookingService,
    private date: DateService,
    private invoiceLineItemService: InvoiceLineItemService,
    private invoiceService: InvoiceService,
    private adxVccService: AdxVccService,
    private fmsVccService: FmsVccService,
    private matchingVccService: MatchingVccService,
    private chartsVccService: ChartsVccService,
    private tripService: TripService,
    private dialog: MatDialog,
    private hsProcessingService: HsProcessingService,
    private adxPaymentService: AdxPaymentService,
    private generalLedgerService: GeneralLedgerService,
  ) {
    this.chartsFilterFromDate = this.date.subtractDays(new Date(), 30);
    this.chartsFilterToDate = new Date();
    if(this.azureAuthService.checkUserAzureTokenHasAnyRole()){
      this.chartsVccService.getLatestDate().subscribe((latestReleasedDate) => {
        this.vccChartFilterConfigResult.releasedDateFrom = moment(latestReleasedDate).toDate();
      });
    }
    this.vccChartFilterConfigResult.releasedDateTo = this.chartsFilterToDate;
    this.location = location;
    this.menuListItems = [];
    this.userAzureProfileDetails$ = this.azureAuthService.getUserAuth$().pipe(map(ua => ua?.profile));
    this.isUserLoggedIn$ = this.azureAuthService.isUserLoggedIn.pipe(tap(isLoggedIn => this.setMenuListItems(isLoggedIn)));
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const filterablePages = ['Transactions', 'Vcc', 'FinanceReports', 'Vendors', 'Clients', 'Agents', 'Services', 'Invoices/TramsInvoices','Invoices/AdxInvoiceLineItems', 'Payments', 'FinanceResources', 'TstResources', 'Trips', 'GeneralLedger/ClosePeriod'];
      const link = this.router.url;
      this.hideToggleFilterMenuIcon = !filterablePages.some(item => {
        if (item === 'Invoices' && link.startsWith('/Invoices/TramsInvoices')) {
          return link === '/Invoices/TramsInvoices';
        }
        if (link.startsWith('/FinanceResources/Alerts')) {
          return false;
        }
        return link.includes(item);
      });
    });
  }

  ngOnInit() {
    this.router.events.subscribe(() => {
      let $layer: any = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
    localStorage.clear();
  }

  setMenuListItems(isLoggedIn: boolean) {
    if (!isLoggedIn || !this.azureAuthService.checkUserAzureTokenHasAnyRole()) {
      return;
    }
    this.isMenuItemsVisibleIfUserHasAnyRole = true;
    this.menuListItems = [
      {
        menuLinkId: 'vendors',
        menuLinkText: 'Vendors',
        menuIcon: 'face',
        isDisabled: false
      },
      {
        menuLinkId: 'clients',
        menuLinkText: 'Clients',
        menuIcon: 'face_4',
        isDisabled: false
      },
      {
        menuLinkId: 'agents',
        menuLinkText: 'Agents',
        menuIcon: 'face_2',
        isDisabled: false
      },
      {
        menuLinkId: 'services',
        menuLinkText: 'Services',
        menuIcon: 'list',
        isDisabled: false
      },
      {
        menuLinkId: 'actions',
        menuLinkText: 'Actions',
        menuIcon: 'create',
        isDisabled: false
      },
      {
        menuLinkId: 'templates',
        menuLinkText: 'Templates',
        menuIcon: 'map',
        isDisabled: false
      },
      {
        menuLinkId: 'nav-mappings',
        menuLinkText: 'NAV Mappings',
        menuIcon: 'book',
        isDisabled: false
      },
    ];
  }

  azureLogin() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
    } else {
      this.authService.loginRedirect();
    }
  }

  azureLogout() {
    localStorage.clear();
    this.authService.logoutRedirect({ postLogoutRedirectUri: environment.postLogoutUrl });
  }

  clickMenuItem(menuItem: MatMenuListItem) {
    this.selectedMenu = menuItem.menuLinkText;

    if (menuItem.menuLinkText === 'Vendors') {
      this.router.navigate(['/Vendors']);
    }
    if (menuItem.menuLinkText === 'Clients') {
      this.router.navigate(['/Clients']);
    }
    if (menuItem.menuLinkText === 'Agents') {
      this.router.navigate(['/Agents']);
    }
    if (menuItem.menuLinkText === 'Actions') {
      this.router.navigate(['/RequestAction']);
    }
    if (menuItem.menuLinkText === 'Templates') {
      this.router.navigate(['/Templates']);
    }
    if (menuItem.menuLinkText === 'Services') {
      this.router.navigate(['/Services']);
    }
    if (menuItem.menuLinkText === 'NAV Mappings') {
      this.router.navigate(['/FileUpload']);
    }
  }

  openFilter() {
    const link = this.router.url;
    switch (true) {
      case link.includes('Transactions'):
        this.handleTransactionsFilter();
        break;

      case link.includes('/Dashboard/Vcc'):
        this.handleVccChartFilter();
        break;

      case link.includes('/FinanceReports/TripProfitabilityWithAgency'):
        this.handleFinanceReportsFilter();
        break;

      case link.includes('/FinanceReports/TripProfitability'):
        this.handleFinanceReportsFilter();
        break;

      case link.includes('/FinanceReports/VccDailySummaryReport'):
        this.handleVccDailySummaryReportFilter();
        break;

      case link.includes('Vendors'):
        this.handleVendorsFilter();
        break;

      case link.includes('Clients'):
        const dialogRef = this.dialog.open(ClientFilterModalComponent, {
          width: '400px',
          data: { config: this.filterService.getClientFilter() }
        });

        dialogRef.afterClosed().subscribe((result: ClientFilterResult | undefined) => {
          if (result) {
            console.log('Filter applied from navbar:', result);
            this.filterService.setClientFilter(result);
            this.loadClients();
          }
        });
        break;

      case link.includes('Agents'):
        const agentDialogRef = this.dialog.open(AgentFilterModalComponent, {
          width: '400px',
          data: { config: this.filterService.getAgentFilter() }
        });
        agentDialogRef.afterClosed().subscribe((result: AgentFilterResult | undefined) => {
          if (result) {
            this.filterService.setAgentFilter(result);
            this.loadAgents();
          }
        });
        break;

      case link.includes('Services'):
        this.handleBookingsFilter();
        break;

      case link.includes('Invoices/AdxInvoiceLineItems'):
        this.handleAdxInvoiceLineItemsFilter();
        break;
      case link.includes('Invoices/TramsInvoices'):
        this.handleTramsInvoicesFilter();
        break;

      case link.includes('FinanceResources/AdxVcc'):
        this.handleAdxVccFilter();
        break;
      case link.includes('FinanceResources/FmsVcc'):
        this.handleFmsVccFilter();
        break;
      case link.includes('FinanceResources/MatchingVcc'):
        this.handleMatchingVccFilter();
        break;

      case link.includes('TstResources/HsProcessing'):
        this.handleHsProcessingFilter();
        break;

      case link.includes('Trips'):
        this.handleTripFilter();
        break;
      case link.includes('Payments/Received'):
        this.handleAdxPaymentReceivedFilter();
        break;
      case link.includes('Payments/Made'):
        this.handleAdxPaymentMadeFilter();
        break;
      case link.includes('Payments/PaymentsReconciliation'):
        this.handlePaymentsReconcilitionFilter();
        break;
      case link.includes('GeneralLedger/ClosePeriod'):
        this.handleGlClosePeriodFilter();
        break;
    }
  }

  applyClientFilter(filter: ClientFilterResult): void {
    this.filterService.setClientFilter(filter);
  }

  loadClients(): void {
    const filters = this.filterService.getClientFilter();
    this.clientService.getClients(1, 25, filters).subscribe({
      next: (data: ClientData) => {
        this.clients = data.items;
      },
      error: (error: any) => {
        console.error('Error loading clients from navbar', error);
      }
    });
  }

  applyAgentFilter(filter: AgentFilterResult): void {
    this.filterService.setAgentFilter(filter);
  }

  loadAgents(): void {
    const filters = this.filterService.getAgentFilter();
    this.agentService.getAgents(1, 25, filters).subscribe({
      next: (data: AgentData) => {
        this.agents = data.items;
      },
      error: (error: any) => {
        console.error('Error loading agents from navbar', error);
      }
    });
  }

  private openGenericFilterModal(component: any, config: any, callback: (res: any) => void) {
    const filterRef = this.modal.open(component);
    const filterInstance = filterRef.componentInstance;
    filterInstance.config = config;
    filterRef.afterClosed().subscribe(callback);
  }

  private handleTransactionsFilter() {
    if (this.isInitialFilterModalOpenRequest && this.azureAuthService.checkUserAzureTokenHasDirectorRole()) {
      this.isInitialFilterModalOpenRequest = false;
      this.chartsPersonEmailFilter = this.appConstants.AllUser;
    }
    this.openGenericFilterModal(FilterModalComponent, {
      from: this.chartsFilterFromDate,
      to: this.chartsFilterToDate,
      email: this.chartsPersonEmailFilter
    }, (res: FilterResult | null) => {
      if (res) {
        this.chartsFilterFromDate = res.from;
        this.chartsFilterToDate = res.to;
        this.chartsPersonEmailFilter = res.email?.toString() ?? this.chartsPersonEmailFilter;
        this.filterService.setFilter(res);
      }
    });
  }

  private handleVccChartFilter() {
    this.openGenericFilterModal(VccChartFilterModalComponent, this.vccChartFilterConfigResult, (res: VccChartFilterResult | null) => {
      if (res) {
        this.vccChartFilterConfigResult = res;
        this.chartsVccService.setVccChartFilter(res);
      }
    });
  }

  private handleVccDailySummaryReportFilter() {
    this.openGenericFilterModal(VccDailySummaryReportFilterModalComponent, this.VccDailySummaryReportConfigResult, (res: VccDailySummaryReportFilterResult | null) => {
      if (res) {
        this.VccDailySummaryReportConfigResult = res;
        this.filterService.setVccDailySummaryReportFilter(res);
      }
    });
  }

  private handleFinanceReportsFilter() {
    this.openGenericFilterModal(FinanceReportsFilterModalComponent, this.financeReportConfigResult, (res: FinanceReportFilterResult | null) => {
      if (res) {
        this.financeReportConfigResult = res;
        this.filterService.setFinanceReportFilter(res);
      }
    });
  }

  private handleVendorsFilter() {
    this.openGenericFilterModal(VendorsFilterModalComponent, this.vendorConfigResult, (res: VendorFilterResult | null) => {
      if (res) {
        this.vendorConfigResult = res;
        this.vendorService.setVendorFilter(res);
      }
    });
  }

  private handleBookingsFilter() {
    this.openGenericFilterModal(TransactionBookingsFilterModalComponent, this.transactionBookingConfigResult, (res: TransactionBookingFilterResult | null) => {
      if (res) {
        this.transactionBookingConfigResult = res;
        this.transactionBookingService.setBookingFilter(res);
      }
    });
  }

  private handleAdxInvoiceLineItemsFilter() {
    this.openGenericFilterModal(AdxInvoiceLineItemFilterModalComponent, this.adxInvoiceLineItemConfigResult, (res: AdxInvoiceLineItemFilterResult | null) => {
      if (res) {
        this.adxInvoiceLineItemConfigResult = res;
        this.invoiceLineItemService.setAdxInvoiceLineItemsFilter(res);
      }
    });
  }

  private handleTramsInvoicesFilter() {
    this.openGenericFilterModal(TramsInvoiceFilterModalComponent, this.tramsInvoiceConfigResult, (res: TramsInvoiceFilterResult | null) => {
      if (res) {
        this.tramsInvoiceConfigResult = res;
        this.invoiceService.setTramsInvoicesFilter(res);
      }
    });
  }

  private handleAdxVccFilter() {
    this.openGenericFilterModal(AdxVccFilterComponent, this.adxConfigResults, (res: AdxVccFilter | null) => {
      if (res) {
        this.adxConfigResults = res;
        this.adxVccService.setAdxFilter(res);
      }
    });
  }

  private handleFmsVccFilter() {
    this.openGenericFilterModal(FmsVccFilterModalComponent, this.fmsVccFilterConfigResult, (res: FmsVccFilterResult | null) => {
      if (res) {
        this.fmsVccFilterConfigResult = res;
        this.fmsVccService.setFmsVccFilter(res);
      }
    });
  }

  private handleMatchingVccFilter() {
    this.openGenericFilterModal(MatchingVccFilterComponent, this.matchingVccFilterConfigResult, (res: MatchedVccFilter | null) => {
      if (res) {
        this.matchingVccFilterConfigResult = res;
        this.matchingVccService.setMatchingFilter(res);
      }
    });
  }

  private handleHsProcessingFilter() {
    this.openGenericFilterModal(HsProcessingFilterComponent, this.hsProcessingFilterConfigResult, (res: HsProcessingFilter | null) => {
      if (res) {
        this.hsProcessingFilterConfigResult = res;
        this.hsProcessingService.setHsProcessingFilter(res);
      }
    });
  }

  private handleTripFilter() {
    this.openGenericFilterModal(TripFilterModalComponent, this.tripConfigResult, (res: TripFilterResult | null) => {
      if (res) {
        this.tripConfigResult = res;
        this.tripService.setTripFilter(res);
      }
    });
  }

  private handleAdxPaymentReceivedFilter() {
    this.openGenericFilterModal(AdxPaymentFilterComponent, this.adxPaymentFilterConfigResult, (res: AdxPaymentFilter | null) => {
      if (res) {
        this.adxPaymentFilterConfigResult = res;
        this.adxPaymentService.setPaymentReceivedFilter(res);
      }
    });
  }

  private handleAdxPaymentMadeFilter() {
    this.openGenericFilterModal(AdxPaymentFilterComponent, this.adxPaymentFilterConfigResult, (res: AdxPaymentFilter | null) => {
      if (res) {
        this.adxPaymentFilterConfigResult = res;
        this.adxPaymentService.setPaymentMadeFilter(res);
      }
    });
  }

  private handlePaymentsReconcilitionFilter() {
    this.openGenericFilterModal(PaymentReconciliationFilterComponent, this.PaymentsReconciliationFilterConfigResult, (res: PaymentReconciliationFilter | null) => {
      if (res) {
        this.PaymentsReconciliationFilterConfigResult = res;
        this.adxPaymentService.setPaymentsReconciliationFilter(res);
      }
    });
  }

  private handleGlClosePeriodFilter() {
    this.openGenericFilterModal(GlClosePeriodFilterComponent, this.glClosePeriodFilterConfigResult, (res: ClosePeriodFilter | null) => {
      if (res) {
        this.glClosePeriodFilterConfigResult = res;
        this.generalLedgerService.setGLClosePeriodFilter(res);
      }
    });
  }
}
