import {Component, Input, Optional} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

export enum VendorViewerType {
  Vendor,
  VendorPayment,
  CancellationRefund
}

@Component({
  selector: 'app-vendor-viewer-modal',
  templateUrl: './vendor-viewer-modal.component.html'
})
export class VendorViewerModalComponent {

  @Input()
  public vendorData!: any;

  @Input()
  public viewerType: VendorViewerType = VendorViewerType.Vendor;

  @Input()
  public title!: string;

  public vendorViewerType = VendorViewerType;

  constructor(@Optional() public dialogRef: MatDialogRef<VendorViewerModalComponent>) {
  }

  public handleClose(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
