import {
  Component,
  DestroyRef,
  effect,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {ClientBalanceFilterService} from '../../service/client-balance-filter.service';
import {MatDialog} from '@angular/material/dialog';
import {ClientBalanceFilterComponent} from '../client-balance-filter/client-balance-filter.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ClientBalanceDataResponse,
  ClientBalanceFilter,
  ClientBalanceFilterParams,
  ClientBalanceResponse,
} from '../../models/client-balance';
import {
  DEFAULT_CLIENT_PAY_STATUS,
  DEFAULT_SORT_PROPERTY_NAME,
  transformKeyToUpperCase,
} from '../../constants/client-balance-filter';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, MatSortHeader, Sort} from '@angular/material/sort';
import {firstValueFrom} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import * as moment from 'moment';
import {PayStatusesResponse} from 'src/app/adx-booking/model/pay-statuses-response';
import {TramsDataSourceModel} from '../../models/trams-data-source';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Component({
  imports: [
    MatIcon,
    MatButton,
    MatIconButton,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatCell,
    MatCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatPaginator,
    MatSort,
    MatSortHeader,
  ],
  standalone: true,
  selector: 'app-client-balance',
  templateUrl: './client-balance.component.html',
  styleUrls: ['./client-balance.component.scss'],
})
export class ClientBalanceComponent implements OnInit {
  @ViewChild(MatSort) private readonly sort!: MatSort;

  private readonly destroyRef = inject(DestroyRef);

  private readonly dialog = inject(MatDialog);

  private readonly activatedRoute = inject(ActivatedRoute);

  private readonly exportGridService = inject(ExportGridService);

  private readonly clientBalanceFilterService = inject(ClientBalanceFilterService);

  private readonly http = inject(HttpClient);

  private paymentStatus: PayStatusesResponse[] = [];

  private dataSources: TramsDataSourceModel[] = [];

  private filters: Partial<ClientBalanceFilter> &
    Pick<
      ClientBalanceFilterParams,
      'PageNumber' | 'PageSize' | 'PropertyName' | 'OrderKey'
    > = {
      PageNumber: 1,
      PageSize: 25,
      PropertyName: DEFAULT_SORT_PROPERTY_NAME,
      OrderKey: 'desc',
      clientPayStatusId: DEFAULT_CLIENT_PAY_STATUS,
    };

  protected displayedColumns: string[] = [
    'clientName',
    'id',
    'email',
    'phoneNumber',
    'totalBilled',
    'outstandingBalance',
    'dataSourceName',
  ];

  protected datasource = new MatTableDataSource<ClientBalanceDataResponse>([]);

  protected pagination?: ClientBalanceResponse['pagination'];

  constructor() {
    effect(() => {
      const triggerModal = this.clientBalanceFilterService.triggerModal();
      if (!triggerModal) return;
      this.openFilters();
    });
  }

  public ngOnInit(): void {
    this.initData();
    this.list();
  }

  private async initData() {
    await this.getPaymentStatuses();
    await this.getDatasources();
  }

  private async getPaymentStatuses(): Promise<void> {
    const data = await firstValueFrom(this.activatedRoute.data);
    this.paymentStatus = [...data['paymentStatuses']];
  }

  private async getDatasources(): Promise<void> {
    const data = await firstValueFrom(this.activatedRoute.data);
    this.dataSources = [...data['dataSources']];
  }

  private list() {
    const { PageNumber, PageSize, PropertyName, OrderKey, ...rest } = this.filters;
    transformKeyToUpperCase(rest);
    var filters: ClientBalanceFilterParams = {
      ...rest,
      PageNumber,
      PageSize,
      PropertyName,
      OrderKey,
    };

    if (filters.PaymentDate)
      filters.PaymentDate = moment(filters.PaymentDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    this.clientBalanceFilterService
      .listClientBalances(filters)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.datasource.data = response.items;
          this.pagination = response.pagination;
        },
      });
  }

  private openFilters() {
    const dialog = this.dialog.open<
      ClientBalanceFilterComponent,
      Partial<ClientBalanceFilter>,
      ClientBalanceFilter
    >(ClientBalanceFilterComponent, {
      data: { ...this.filters },
      width: '36vw',
      maxHeight: '75vh',
    });

    dialog
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result) => {
          this.clientBalanceFilterService.triggerModal.set(false);
          if (!result) return;
          const { PageNumber, PageSize, PropertyName, OrderKey } = this.filters;
          this.filters = {
            ...result,
            PageNumber,
            PageSize,
            PropertyName,
            OrderKey,
          };
          this.list();
        },
      });
    dialog.componentInstance.status = [...this.paymentStatus];
    dialog.componentInstance.datasources = [...this.dataSources];
  }

  protected onPageChanged({ pageIndex }: PageEvent) {
    this.filters.PageNumber = pageIndex + 1;
    this.list();
  }

  protected onSortChanged({ active, direction }: Sort) {
    if (!active) return;
    this.filters.PropertyName = direction === '' ? DEFAULT_SORT_PROPERTY_NAME : active;
    this.filters.OrderKey = direction === 'asc' ? 'asc' : 'desc';
    this.list();
  }

  protected refresh() {
    this.list();
  }

  protected downloadGridToFile() {
    const { ...filters } = this.filters;
    transformKeyToUpperCase(filters);

    this.exportGridService.getByDataSource(
      'dbov2/client-balances',
      'client-balances',
      {
        ...filters,
        PageSize: this.pagination?.totalItemCount || 100,
        PropertyName: this.sort?.active || 'clientName',
        OrderKey: 'desc',
      },
      (data) => {
        const result = data as ClientBalanceDataResponse;
        return {
          'Client Name': result.clientName,
          'Client ID': result.id,
          'Client Email': result.email,
          Phone: result.phoneNumber,
          'Invoice Total': result.totalBilled,
          'Balance Due': result.outstandingBalance,
          'Data Source Name': result.dataSourceName,
        };
      }
    );
  }

  protected downloadGridToPdf(): void {
    if (!this.datasource.data || this.datasource.data.length === 0) {
      console.error('No records available to export to PDF. Please adjust your filters and try again.');
      return;
    }

    // Prepare the parameters similar to what's used in downloadGridToFile
    const { ...filters } = this.filters;
    transformKeyToUpperCase(filters);

    const params = {
      ...filters,
      exportToPdf: true,
      format: 'pdf',
      formatCurrency: 'true',
      hideCurrencySymbol: 'true',
      currencyCode: 'USD',
      currencySymbol: '$',
      currencyFormat: '0.00',
      PageSize: this.pagination?.totalItemCount || 100,
      PropertyName: this.sort?.active || 'clientName',
      OrderKey: 'desc',
    };

    // Call the API to get PDF content
    this.http.get<{ pdfBase64: string }>('dbov2/client-balances', { params })
      .subscribe({
        next: (response: { pdfBase64: string }) => {
          if (response && response.pdfBase64) {
            this.savePdfFromBase64(response.pdfBase64, 'client-balance.pdf');
          } else {
            console.error('Invalid PDF response:', response);
          }
        },
        error: (error: HttpErrorResponse) => {
          console.error('Error downloading PDF:', error);
        }
      });
  }

  /**
   * Converts a Base64 string to a Blob and triggers download
   * @param base64String The Base64 encoded PDF content
   * @param fileName The name to use for the downloaded file
   */
  private savePdfFromBase64(base64String: string, fileName: string): void {
    try {
      console.log('Starting PDF conversion process');
      // Remove data URI prefix if present
      const base64Content = base64String.replace(/^data:application\/pdf;base64,/, "");
      console.log('Base64 content prepared, length:', base64Content.length);

      // Convert Base64 to Blob
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      console.log('Created PDF blob, size:', blob.size);

      // Create download link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName || 'document.pdf';
      console.log('Download link created, triggering click');

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        console.log('Download cleanup completed');
      }, 100);
    } catch (error) {
      console.error('Error processing PDF:', error);
    }
  }

}
