import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {catchError, map, Observable} from 'rxjs';
import {ProfileFilters, ProfileModel} from '../../model/profiles';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

    constructor(private http:HttpClient){}

    searchProfiles(filter?: ProfileFilters): Observable<ProfileModel[]>{
        let params = new HttpParams()

        if (filter?.dataSourceId) {
            params = params.set('DataSourceId', filter.dataSourceId);
        }

        if (filter?.profileName) {
            params = params.set('ProfileName', filter.profileName);
        }

        if (filter?.profileType) {
            params = params.set('ProfileType', filter.profileType);
        }

        return this.http.get<ProfileModel[]>('dbov2/profiles/search', {params})
        .pipe(
            map((profiles)=>{
                return profiles.map((profile)=>({id: profile.profileId, ...profile}));
            }),
            catchError((error:HttpErrorResponse)=>{
                throw new Error(error?.message || 'Server error');
            }
        ));
    }
}

