import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {TripFilterResult} from '../../model/trip-filter-result';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';
import {TripExternalStatusTypeService} from '../../service/trip-external-status-type.service';
import {TripExternalStatusType} from '../../model/trip-external-status-type';
import {TripService} from '../../service/trip.service';

@AutoUnsubscribe()
@Component({
  selector: 'trip-filter-modal',
  templateUrl: './trip-filter-modal.component.html'
})
export class TripFilterModalComponent implements OnInit {
  @Input() config!: TripFilterResult;
  tripExternalStatusTypes: TripExternalStatusType[] = [];
  breakPoint$?:Subscription;
  isSmall: boolean = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<TripFilterModalComponent>,
    breakpointObserver: BreakpointObserver,
    private tripExternalStatusTypeService: TripExternalStatusTypeService,
    private tripService: TripService,
  ) {
      this.breakPoint$ = breakpointObserver.observe([
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall
      ]).subscribe(result => {
          this.isSmall = result.matches;
      });
  }

  form: FormGroup = new FormGroup({
    withInvoice: new FormControl(null),
    tripName: new FormControl(null),
    externalNumber: new FormControl(null, [Validators.pattern(/^[a-zA-Z0-9]*$/)]),
    externalReference: new FormControl('', Validators.pattern(/^\S*$/)),
    externalStatusTypeId: new FormControl(null),
    tripStartDateFrom: new FormControl(null),
    tripStartDateTo: new FormControl(null),
    tripEndDateFrom: new FormControl(null),
    tripEndDateTo: new FormControl(null),
    endDate: new FormControl(null),
    isCancelled: new FormControl(null),
    isArchived: new FormControl(null)
  });

  ngOnInit(): void {
    this.tripService.getTripFilter().subscribe((filter)=>{
      this.config = filter;
      this.form.patchValue(this.config || {});
      this.fillQuoteStatusCodes();
    });
  }

  applyTripFilters() {
    this.config = this.form.value;
    this.config.tripName = this.config.tripName?.trim();

    if (this.config.externalNumber) {
      (this.config as any).externalNumber = this.config.externalNumber.toString().trim();
    }

    this.config.externalReference = this.config.externalReference?.trim();
    this.dialogRef.close(this.config);
  }

  handleTripFiltersFormCloseEvent() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  fillQuoteStatusCodes() {
    this.tripExternalStatusTypeService.getAllTripExternalStatusTypes().subscribe((data) => {
      this.tripExternalStatusTypes = data;
    });
  }

  public clearTripFilters(...args:string[]): void {
    if(!args.length) {
      this.form.reset();
      return;
    }
    args.forEach((field)=>{
        this.form.controls[field].reset()
    })
  }
}
