import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TransactionHistory} from '../model/transaction-history';

@Injectable({
  providedIn: 'root'
})
export class TransactionHistoryService {
    constructor(private http:HttpClient) {}

    getHistory(guid:string): Observable<TransactionHistory> {
        return this.http
            .get<TransactionHistory>(`dbov2/connex-pay-purchases?CardGuid=${guid}`)
            .pipe(catchError((error: HttpErrorResponse)=>{
                throw new Error(error?.message || 'Server error');
            }));
    }
}