<form data-testid="adx-booking-udid-info-form" class="template-form adx-booking-info-form">  
    <div data-testid="adx-booking-udid-info-main-div" class="filter__fields">
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-booking-udid-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
                    @for (adxBookingUdid of _adxBookingUdids; track adxBookingUdid) {
                        <mat-grid-tile data-testid="adx-booking-udid-info-value-tile">
                            <mat-form-field data-testid="adx-booking-udid-info-value-field">
                                <mat-label data-testid="adx-booking-udid-info-value-field-title">Number: {{adxBookingUdid.udidNo}} </mat-label>
                                <input data-testid="adx-booking-udid-info-value-input-field" matInput value="{{adxBookingUdid.udidValue}}">
                            </mat-form-field>
                        </mat-grid-tile>
                    }
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>