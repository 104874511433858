import {Component, EventEmitter, Input, Optional, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {VccPaymentGateway} from 'src/app/virtual-credit-card/model/vcc-payment-gateway';
import {PaymentGatewayService} from 'src/app/virtual-credit-card/service/payment-gateway-service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {TransactionBookingFilter} from 'src/app/transaction-booking/model/transaction-booking-filter';

@Component({
  selector: 'app-vcc-payment-gateway-viewer',
  templateUrl: './vcc-payment-gateway-viewer.component.html'
})

@AutoUnsubscribe()
export class VccPaymentGatewayViewerComponent {
  @Input() public title!: string;
  private _data: any;

  @Input()
  set data(value: any) {
    this._data = value;
    this.getVccInformation();
  }

  get data(): any {
    return this._data;
  }
  @Input() public isLoaderVisible: boolean = true;
  _vcc: any;
  isLoading: boolean = true;

  @Output() innerLoadState = new EventEmitter<boolean>(true);

  bookingFilter: TransactionBookingFilter = {} as TransactionBookingFilter;

  constructor(
    private buildr: FormBuilder,
    @Optional() public dialogRef: MatDialogRef<VccPaymentGatewayViewerComponent>,
    private paymentGatewayService: PaymentGatewayService) {

  }

  getVccInformation() {
      const vccId = this.data.vccDataSource == "Issued in FMS VCC" ? this.data.id: null;
      const appId = this.data.vccDataSource === "Issued in ADX VCC"? "ADX"
                    : (this.data.vccDataSource === "Issued in FMS VCC" || this.data.purchasedCardGuid !== undefined)
                      ? "FMS" : "ADX";
      const guidId = this.data.providerUniqueId || this.data.purchasedCardGuid;;

      this.paymentGatewayService.getDbov2VccPaymentGatewayInfoById(vccId, guidId, appId)
        .subscribe((data: VccPaymentGateway) => {
          if (data) {
            this._vcc = data;
            this.isLoading = false;
            this.innerLoadState.emit(false);
          }
      });
  }

  handleVccPaymentGatewayViewerCloseBtnClick() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  vccPaymentGatewayViewerForm = this.buildr.group({
    action: this.buildr.control(''),
    purchaseLogUniqueID: this.buildr.control(''),
    cardGuid: this.buildr.control(''),
  })
}
