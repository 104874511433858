<div data-testid="delete-ttm-dialog-confirmpopup" class="confirmpopup">
    <div data-testid="delete-ttm-dialog-dialog-title" mat-dialog-title>
      <label data-testid="delete-ttm-dialog-title-label" class="title">Delete Travel Type Mapping Confirmation</label>
    </div>
    <hr data-testid="delete-ttm-dialog-first-hr">
    <div data-testid="delete-ttm-dialog-dialog-content" mat-dialog-content>
      <p data-testid="delete-ttm-dialog-content-p1">Are you sure you want to delete this travel type mapping?</p>
      <p data-testid="delete-ttm-dialog-content-p2">{{glTravelTypeModel.generalLedger.glName}} - {{glTravelTypeModel.travelType.travelTypeName}}</p>
    </div>
    <hr data-testid="delete-ttm-dialog-second-hr">
    <div data-testid="delete-ttm-dialog-dialog-actions" mat-dialog-actions style="float: right;">
      <button
        data-testid="delete-ttm-dialog-ok-button"
        type="submit" mat-raised-button
        (click)="okClick()"
        color="primary"
        cdkFocusInitial matTooltip="Delete Travel Type Mapping"
        aria-label="Delete Travel Type Mapping"
        [disabled]="isLoading"
      >
        @if(isLoading){
          <mat-spinner
            data-testid="delete-ttm-dialog-spinner"
            color="accent"
            diameter="30">
          </mat-spinner>
        } @else {
          <span data-testid="delete-ttm-dialog-ok-span">Ok</span>
        }
      </button>
      <button
        data-testid="delete-ttm-dialog-no-button"
        mat-raised-button
        (click)="onNoClick()"
        color="warn"
        [disabled]="isLoading">
          No
      </button>
    </div>
  </div>
  