import {AfterViewChecked, Component, Input} from '@angular/core';
import {TransactionHistoryService} from '../../service/transaction-history.service';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {Transaction} from '../../model/transaction-history';

@AutoUnsubscribe()
@Component({
    selector: 'transaction-history-list',
    templateUrl: './transaction-history-list.component.html',
})
export class TransactionHistoryList implements AfterViewChecked{

    @Input() guid?: string;
    @Input() data: any;

    noResults = false;

    isLoading = false;

    transactions$?: Subscription;

    transactions?: Transaction[];

    constructor(
        private transactionHistoryService: TransactionHistoryService,
    ){}

    ngAfterViewChecked(): void {
        if(this.isLoading){
            document.getElementById('transaction-history-container')?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    loadHistory() :void {
        this.isLoading = true;
        if(this.guid){
            this.transactions$ = this.transactionHistoryService.getHistory(this.guid)
            .subscribe({
                next: (data)=>{
                    this.isLoading = false;
                    this.transactions = data.transactions;
                },
                error: ()=>{
                    this.isLoading = false;
                    this.noResults = true;
                    this.transactions = [];
                }
            });
        } else {
            this.isLoading = false;
            this.noResults = true;
            this.transactions = [];
        }

    }
}