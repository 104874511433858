import {Component, Input, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ActivatedRoute} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AdxBookingService} from '../../service/adx-booking.service';
import {AdxBookingResponse} from '../../model/adx-booking-response';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogCopyAdxBookingComponent} from 'src/app/adx-booking/components/popup/confirmation-dialog/confirmation-dialog-copy-adxbooking.component';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';
import {NewAdxBookingDialogComponent} from '../popup/new-adx-booking-dialog/new-adx-booking-dialog.component';

@Component({
  selector: 'app-adx-booking-details',
  templateUrl: './adx-booking-details.component.html'
})

@AutoUnsubscribe()
export class AdxBookingDetailsComponent implements OnInit {
  @Input() public data: any;
  bookingData: any;
  adxBookingId: any;
  public isLoggedInUserHasManagerRole: boolean = false;
  public selectedTab: string = "General info";

  constructor(private adxBookingService: AdxBookingService,
    private route: ActivatedRoute,
    private azureAuthService: AzureAuthService,
    public dialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.isLoggedInUserHasManagerRole = this.azureAuthService.checkUserAzureTokenHasManagerRole();

    if (this.data == null){
      this.route.paramMap.subscribe(params => {
        this.adxBookingId = params.get('id');
      });
    }
    else{
      this.adxBookingId = this.data;
    }
    
    this.getAdxBooking();
  }

  getAdxBooking() {
    this.adxBookingService
      .getAdxBookingById(this.adxBookingId)
      .subscribe((data: AdxBookingResponse) => {
          this.bookingData = data;
          this.bookingData.isModal = this.data != null;
      });
    }
  
  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTab = event.tab.textLabel;
  }
  
  openCopyConfirmationDialog(adxBookingId : number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogCopyAdxBookingComponent, {
      data: {
        adxBookingId: adxBookingId
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openNewAdxBookingConfirmationDialog(adxBookingId, result);
      }
    });
  }

  openNewAdxBookingConfirmationDialog(currentAdxBookingId : number, newAdxBookingId: number): void {
    this.dialog.open(NewAdxBookingDialogComponent, {
      data: {
        currentAdxBookingId: currentAdxBookingId,
        newAdxBookingId: newAdxBookingId
      },
    });
  }

  submitedDataHandler(completed: boolean) {
    if(completed) {
      this.getAdxBooking();
    }
  }
}