<form data-testid="adx-invoice-info-form" class="template-form adx-invoice-info-form">  
    <div data-testid="adx-invoice-info-main-div" class="filter__fields">
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-invoice-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile data-testid="adx-invoice-info-profile-name-grid-tile">
                        <read-only-form-field title="Client Name" testId="adx-invoice-info-profile-name" value="{{_adxInvoice.client?.profileName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-record-locator-grid-tile">
                        <read-only-form-field title="Invoice Record Locator" testId="adx-invoice-info-record-locator" value="{{_adxInvoice.recordLocator}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-invoice-type-code-grid-tile">
                        <read-only-form-field title="Invoice Type" testId="adx-invoice-info-invoice-type-code" value="{{_adxInvoice.invoiceTypeCode}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-issue-date-grid-tile">
                        <read-only-form-field title="Issue Date" testId="adx-invoice-info-issue-date" value="{{_adxInvoice.issueDate | date:'M/d/yyyy'}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-branch-name-grid-tile">
                        <read-only-form-field title="Branch" testId="adx-invoice-info-branch-name" value="{{_adxInvoice.branch?.name}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-invoice-info-invoice-group-grid-tile">
                        <read-only-form-field title="Invoice Group" testId="adx-invoice-info-invoice-group" value="{{_adxInvoice.invoiceGroup}}" />
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>