import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import { Subscription } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ExportGridService } from 'src/app/shared/service/files/export-grid.service';
import { DatePipe } from '@angular/common';
import { BraintreeMatchingVccService } from '../../service/braintree-matching-vcc.service';
import { BraintreeMatchedVccFilter, BraintreeMatchingVccModel } from '../../model/braintree-matching-vcc';
import { MaskedCardPipe } from 'src/infrastructure/pipes/masked-card.pipe';
import { MatDialog } from '@angular/material/dialog';
import { BraintreeMatchingVccModalComponent } from '../braintree-matching-vcc-modal/braintree-matching-vcc-edit-modal.component';

@AutoUnsubscribe()
@Component({
  selector: 'braintree-matching-vcc',
  templateUrl: './braintree-matching-vcc.component.html',
})
export class BraintreeMatchingVccComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  braintreeMatchingFilter$?: Subscription;

  filters: BraintreeMatchedVccFilter = {};
  displayedColumns: string[] = [];
  items: any[] = [];
  itemsWithReleaseDate: any[] = [];
  pagination = { pageSize: 25, pageIndex: 0, totalItemCount: 0 };
  sorting: Sort = { active: 'clientNameAlias', direction: 'asc' };

  allowNavigate = true;
  isFetching = false; // Add a flag to prevent multiple calls

  constructor(
    private braintreeVccService: BraintreeMatchingVccService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
    private maskedNumberPipe: MaskedCardPipe,
    private dialog: MatDialog
  ) { }

  canDeactivate() {
    return this.allowNavigate;
  }

  ngOnInit(): void {
    this.braintreeVccService.getBraintreeMatchingFilter().subscribe({
      next: (filter: BraintreeMatchedVccFilter) => {
        this.filters = filter;
        this.initializePaginationAndSorting();
        this.updateDisplayedColumns();
        this.checkAndFetchItems();
      },
      error: (err: any) => {
        console.error('Error fetching Braintree matching filter', err);
      }
    });
  }

  ngAfterViewInit(): void {
    this.sort.active = 'clientNameAlias';
    this.sort.direction = 'asc';
  }

  initializePaginationAndSorting(): void {
    this.pagination = {
      pageSize: this.pagination.pageSize || 25,
      pageIndex: 0,
      totalItemCount: 0,
    };
    this.sorting = { active: 'clientNameAlias', direction: 'asc' };
  }

  updateDisplayedColumns(): void {
    if (this.filters['releasedDateFrom'] || this.filters['releasedDateTo']) {
      this.displayedColumns = [
        'clientNameAlias',
        'releasedDate',
        'payeeName',
        'lastFour',
        'amount',
        'orderNumber',
        'agentName',
        'tramsPaymentDetailAmount',
        'bankName',
        'branchName',
        'dataSourceName',
        'actions'
      ];
    } else {
      this.displayedColumns = [
        'clientNameAlias',
        'payeeName',
        'lastFour',
        'amount',
        'orderNumber',
        'agentName',
        'tramsPaymentDetailAmount',
        'bankName',
        'branchName',
        'dataSourceName',
        'actions'
      ];
    }
  }

  checkAndFetchItems(): void {
    if (this.displayedColumns.includes('releasedDate')) {
      this.getWithRelaseDateItems();
    } else {
      this.getItems();
    }
  }

  handleSortChange(sort: Sort): void {
    this.sorting = sort;

    if (!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = { active: '', direction: '' };
    }
    this.checkAndFetchItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };
    this.checkAndFetchItems();
  }

  getItems() {
    if (this.isFetching) return;
    this.isFetching = true;
    console.log(this.filters);
    this.braintreeVccService.searchMatching({
      filters: this.filters,
      page: this.pagination,
      sort: this.sorting,
    }).subscribe({
      next: (data: { items: any[]; pagination: { pageNumber: number; totalItemCount: any; }; }) => {
        this.items = data?.items;
        this.pagination = {
          ... this.pagination,
          pageIndex: data.pagination.pageNumber - 1,
          totalItemCount: data.pagination.totalItemCount,
        };
        this.isFetching = false;
      },
      error: () => {
        this.isFetching = false;
      }
    });
  }

  getWithRelaseDateItems() {
    if (this.isFetching) return;
    this.isFetching = true;
    console.log(this.filters);
    this.braintreeVccService.searchWithRelaseDateMatching({
      filters: this.filters,
      page: this.pagination,
      sort: this.sorting,
    }).subscribe({
      next: (data: { items: any[]; pagination: { pageNumber: number; totalItemCount: any; }; }) => {
        this.itemsWithReleaseDate = data?.items;
        console.log(data?.items);
        this.pagination = {
          ... this.pagination,
          pageIndex: data.pagination.pageNumber - 1,
          totalItemCount: data.pagination.totalItemCount,
        };
        this.isFetching = false;
      },
      error: () => {
        this.isFetching = false;
      }
    });
  }

  downloadGridToFile(): void {
    this.braintreeVccService.searchMatching({
      filters: this.filters,
      page: { pageSize: this.pagination.pageSize },
      sort: this.sorting,
    }).subscribe({
      next: (data: any) => {
        this.exportGridService.downloadDataSource(
          data,
          'braintree-matching-vcc-report',
          (item) => {
            const record = item as BraintreeMatchingVccModel;
            return {
              'Client Name': record?.clientNameAlias,
              'Payee Name': record?.payeeName,
              'Last Four': this.maskedNumberPipe.transform(record?.lastFour),
              'Amount On File': record?.amount,
              'Adx Reference': record?.orderNumber,
              'Agent Name': record?.agentName,
              'Trams Total': record?.tramsPaymentDetailAmount,
              'Bank Name': record?.bankName,
              'Branch Name': record?.branchName,
              'Source Name': record?.dataSourceName
            }
          });
      },
      error: (err: any) => {
        console.error('Error downloading grid to file', err);
      }
    });
  }

  downloadGridWithReleaseDateToFile(): void {
    this.braintreeVccService.searchWithRelaseDateMatching({
      filters: this.filters,
      page: { pageSize: this.pagination.pageSize },
      sort: this.sorting,
    }).subscribe({
      next: (data: any) => {
        this.exportGridService.downloadDataSource(
          data,
          'braintree-matching-vcc-with-relase-date-report',
          (item) => {
            const record = item as BraintreeMatchingVccModel;
            return {
              'Released Date': this.datePipe.transform(record?.releasedDate, 'M/d/yyyy'),
              'Client Name': record?.clientNameAlias,
              'Payee Name': record?.payeeName,
              'Last Four': this.maskedNumberPipe.transform(record?.lastFour),
              'Amount On File': record?.amount,
              'Adx Reference': record?.orderNumber,
              'Agent Name': record?.agentName,
              'Trams Total': record?.tramsPaymentDetailAmount,
              'Bank Name': record?.bankName,
              'Branch Name': record?.branchName,
              'Source Name': record?.dataSourceName
            }
          });
      },
      error: (err: any) => {
        console.error('Error downloading grid with release date to file', err);
      }
    });
  }

  getBackgroundColor(element: BraintreeMatchingVccModel): string {
    if (element.tramsPaymentDetailAmount == null || element.tramsPaymentDetailAmount === element?.amount) {
      return '#FFFFFF';
    } else {
      return '#FAD4D5';
    }
  }

  openEditModal(element: BraintreeMatchingVccModel): void {
    this.dialog.open(BraintreeMatchingVccModalComponent, { data: element });
  }

  getDisplayIcon(element: BraintreeMatchingVccModel): string {
    if (element.tramsPaymentDetailAmount == null || element.tramsPaymentDetailAmount === element?.amount) {
      return 'none';
    } else {
      return 'block';
    }
  }
}
