import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

interface UdidData {
  bookingNo: string;
  bookingUdids: Array<{ udidNo: string; udidValue: string }>;
}

@AutoUnsubscribe()
@Component({
  selector: 'app-booking-udid-info',
  templateUrl: './booking-udid-info.component.html'
})
export class BookingUdidInfoComponent implements OnInit {
  bookingNo: string = '';
  bookingUdids: Array<{ udidNo: string; udidValue: string }> = [];
  hasData: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<BookingUdidInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UdidData
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.bookingNo = this.data.bookingNo || '';
      this.bookingUdids = Array.isArray(this.data.bookingUdids) ? this.data.bookingUdids : [];
      this.hasData = this.bookingUdids.length > 0;
    }
  }
}
