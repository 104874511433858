import {AfterViewInit, Directive, Input, inject, OnInit} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Directive({
    selector: 'mat-option[selectAll]',
})
export class SelectAllDirective implements OnInit, AfterViewInit {
    @Input({ required: true }) allValues: unknown[] = [];
  
    
    private _matSelect = inject(MatSelect);
    private _matOption = inject(MatOption);

    matSelectRef$?: Subscription;
    optionSubs$?:Subscription;
    selectSubs$?:Subscription;

    ngOnInit(): void {
        this.matSelectRef$ = this._matSelect.ngControl.control?.valueChanges.subscribe((value)=>{
            this.validateSelectAll(value, value.length ? false: true);
        });
    }

    ngAfterViewInit(): void {
      const parentFormControl = this._matSelect.ngControl.control;
        
        this.optionSubs$ = this._matOption.onSelectionChange.subscribe((optionEvent) => {
            if (optionEvent.isUserInput) {
                if (optionEvent.source.selected) {
                    parentFormControl?.setValue(this.allValues);
                    this._matOption.select(false);
                } else {
                    parentFormControl?.setValue([]);
                    this._matOption.deselect(false);
                }
            }
        });
  
        this.selectSubs$ = this._matSelect.optionSelectionChanges.subscribe((selectEvent) => {
            if (selectEvent.isUserInput && selectEvent.source.value !== this._matOption.value) {
                if (!selectEvent.source.selected) {
                    this._matOption.deselect(false);
                } else {
                    if (parentFormControl?.value.length === this.allValues.length) {
                        this._matOption.select(false);
                    }
                }
            }
        });

        this.validateSelectAll(this._matSelect.ngControl.control?.value);
    }

    validateSelectAll(value: unknown[], cleanForm?:boolean): void {
        if(value[0]  === this._matOption.value){
            value.shift();
        }

        if (value?.length === this.allValues.length) {
            this._matOption.select(false);
        }

        if(cleanForm){
            this._matOption.deselect(true);
        }
    }
}
  