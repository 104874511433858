@if (readonly()) {
  <mat-form-field appearance='outline' [attr.data-testid]="testId() ? testId()+'-readonly-field' : '' ">
    <mat-label [attr.data-testid]="testId() ? testId()+'-readonly-field-title' : '' ">{{title()}}</mat-label>
      <input 
        matInput
        [value]=" isDate() ? (displayPropFunc()(innerValue) | date:'M/d/yyyy') : displayPropFunc()(innerValue)"
        readonly
        [attr.data-testid]="testId() ? testId()+'-readonly-input-field' : '' ">
    
    @if(buttonIcon()){
      <button matSuffix mat-icon-button matTooltip="{{buttonIcon()?.tooltip}}" [attr.aria-label]="buttonIcon()?.tooltip || ''" (click)="handleIconButton();" type="button">
        <mat-icon>{{buttonIcon()?.icon}}</mat-icon>  
      </button>
    }
    @else {
      <mat-icon matSuffix>edit_off</mat-icon>
    }
  </mat-form-field>
} 
@if (showContent || !readonly()) {
  <ng-content></ng-content>
}