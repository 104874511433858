import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, catchError, throwError} from 'rxjs';
import {AdxBookingUdidModel} from '../models/adx-booking-udid-model';
import {AddAdxBookingUdidRequest} from '../models/add-adx-booking-udid-request';

@Injectable({
  providedIn: 'root'
})

export class AdxBookingUdidService {
  private resourcePath: string = 'dbov2/adx-booking-udid';
  
  constructor(private httpClient: HttpClient) {
  }

  getAdxBookingUdidsByBookingId(bookingId: number): Observable<AdxBookingUdidModel[]> {
      return this.httpClient
        .get<AdxBookingUdidModel[]>(`${this.resourcePath}/booking/${bookingId}`)
        .pipe(catchError(this.errorHandler));
  }

  createAdxBookingUdid(adxBookingUdid: AddAdxBookingUdidRequest): Observable<any> {
    return this.httpClient
      .post<any>(this.resourcePath, JSON.stringify(adxBookingUdid))
      .pipe(catchError(this.errorHandler));
  }

  updateAdxBookingUdid(data: AdxBookingUdidModel): Observable<AdxBookingUdidModel> {
    return this.httpClient.put<AdxBookingUdidModel>(`${this.resourcePath}`, data)
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message || 'Server Error'));
  }
}
