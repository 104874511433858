<div class="grid-title-event">
  <h2 data-testid="booking-trip-list-title">Services</h2>

  <div class="events-container">
      @if(dataSource.filteredData.length){
          <button  data-testid="booking-trip-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
              <mat-icon fontIcon="replay"></mat-icon>
          </button>
          <button color="primary" data-testid="download-booking-trip-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
              <mat-icon>download</mat-icon>
          </button>
      }
  </div>
</div>
<div class="bookings-container mat-elevation-z8" *ngIf="!this.isLoading">
  <mat-table
    id="booking-table"
    [dataSource]="dataSource"
    (matSortChange)="handleSortChange($event)"
    matSort>

    <ng-container matColumnDef="tripReference">
      <mat-header-cell id="booking-trip-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Trip Reference</mat-header-cell>
      <mat-cell id="booking-trip-reference-cell" *matCellDef="let element">{{element.tripReference}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Agent.FirstName">
      <mat-header-cell  id="booking-agent-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Agent Name</mat-header-cell>
      <mat-cell id="booking-agent-name-cell" *matCellDef="let element">{{element.agentName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bookingName">
      <mat-header-cell id="booking-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
      <mat-cell id="booking-name-cell"  *matCellDef="let element">{{element.bookingName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bookingStartDate">
      <mat-header-cell id="booking-start-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
      <mat-cell  id="booking-start-date-cell" *matCellDef="let element">{{element.bookingStartDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bookingEndDate">
      <mat-header-cell id="booking-end-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
      <mat-cell id="booking-end-date-cell" *matCellDef="let element">{{element.bookingEndDate  | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
      <mat-header-cell id="booking-date-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
      <mat-cell  id="booking-date-created-cell"*matCellDef="let element">{{element.dateCreated  | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ServiceStatus.Name">
      <mat-header-cell id="booking-service-status-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell id="booking-service-status-name-cell" *matCellDef="let element">{{element.serviceStatusName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="BookingStatus.BookingStatusName">
      <mat-header-cell id="booking-status-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> FMS Status </mat-header-cell>
      <mat-cell id="booking-status-name-header" *matCellDef="let element">{{element.bookingStatusName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vendorPaymentTC">
      <mat-header-cell id="booking-vendor-payment-tc-header" class="mat-header-cell" *matHeaderCellDef>Service Payment TC</mat-header-cell>
      <mat-cell id="booking-vendor-payment-tc-cell" *matCellDef="let row" (click)="$event.stopPropagation()">
        <button id="booking-vendor-payment-button" mat-icon-button color="primary" aria-label="Vendor Payment" *ngIf="checkVendorPaymentBookingTemplate(row.bookingTemplates)"
          (click)="openDialog(row, bookingViewerType.VendorPayment)">
          <mat-icon>checkmark</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cancellationRefundTC">
      <mat-header-cell id="booking-cancellation-refund-tc-header" class="mat-header-cell" *matHeaderCellDef>Service Cancellation Refund TC</mat-header-cell>
      <mat-cell id="booking-cancellation-refund-tc-cell" *matCellDef="let row" (click)="$event.stopPropagation()">
        <button id="booking-cancellation-refund-tc-button" mat-icon-button color="primary" aria-label="Cancellation Refund" *ngIf="checkCancellationRefundBookingTemplate(row.bookingTemplates)"
          (click)="openDialog(row, bookingViewerType.CancellationRefund)">
          <mat-icon>checkmark</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Vendor.VendorName">
      <mat-header-cell id="vendor-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Vendor Name</mat-header-cell>
      <mat-cell id="vendor-name-header" *matCellDef="let element">{{element.vendor?.vendorName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vendorId">
      <mat-header-cell id="vendor-details-header" class="mat-header-cell" *matHeaderCellDef>Vendor Details</mat-header-cell>
      <mat-cell id="vendor-details-cell" *matCellDef="let row" (keypress)="$event.stopPropagation()" (click)="$event.stopPropagation()">
        <button id="vendor-details-button" mat-icon-button color="primary" aria-label="Vendor Details" *ngIf="checkVendorInformation(row.vendorId)"
          (click)="openDialog(row, bookingViewerType.VendorInformation)">
          <mat-icon>storefront</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="helpSpotActions" *ngIf="this.isLoggedInUserHasManagerRole">
      <mat-header-cell id="booking-hs-tickets-header" class="mat-header-cell" *matHeaderCellDef>HelpSpot Action</mat-header-cell>
      <mat-cell id="booking-hs-tickets-cell" *matCellDef="let row" (click)="$event.stopPropagation()">
        <button *ngIf="row.tasks.length == 0" id="booking-hs-tickets-button-create" mat-raised-button (click)="upsertHsTicket(row, 'create')">Create</button>
        <button *ngIf="row.tasks.length > 0" id="booking-hs-tickets-button-update" mat-raised-button (click)="upsertHsTicket(row, 'update')">Update</button>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true">
    </mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns;"
      (click)="openDialog(row, bookingViewerType.Booking)">
    </mat-row>
  </mat-table>
  <mat-paginator
    id="booking-paginator"
    [length]="pagination.totalItemCount"
    [hidePageSize]="true"
    [pageSize]="pagination.pageSize"
    (page)="pageChanged($event)">
  </mat-paginator>
</div>
