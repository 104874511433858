import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError, throwError} from 'rxjs';
import {SearchPaginatedAdxBookingResponse} from '../model/search-paginated-adx-booking-response';
import {AdxBookingFilterResult} from '../model/adx-booking-filter-result';
import {AdxBookingResponse} from '../model/adx-booking-response';
import {AdxBookingCopyResponse} from '../model/adx-booking-copy-response';
import {AdxBookingUpdateRequest} from '../model/adx-booking-update';
import {BaseHistoryModel} from 'src/app/adx-booking/model/base-history-model';
import {PayStatusesResponse} from '../model/pay-statuses-response';

@Injectable({
  providedIn: 'root'
})

export class AdxBookingService {
  private resourcePath: string = 'dbov2/adx-booking';
  private _adxBookingFilterSubject: BehaviorSubject<AdxBookingFilterResult> = new BehaviorSubject<AdxBookingFilterResult>({});
  
  constructor(private httpClient: HttpClient) {
  }

  public getAdxBookingById(id: number): Observable<AdxBookingResponse> {
    return this.httpClient
      .get<AdxBookingResponse>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  searchPaginatedAdxBookings(adxBookingFilter: any): Observable<SearchPaginatedAdxBookingResponse> {
    let httpParams = new HttpParams();
    Object
      .keys(adxBookingFilter)
      .forEach((key) => {
        if (adxBookingFilter[key]) {
          httpParams = httpParams.append(key, adxBookingFilter[key]);
        }
      });
      return this.httpClient
        .get<SearchPaginatedAdxBookingResponse>(`${this.resourcePath}`, {params: httpParams})
        .pipe(catchError(this.errorHandler));
  }

  copyAdxBooking(adxBookingId: number): Observable<AdxBookingCopyResponse> {
    return this.httpClient
      .post<AdxBookingCopyResponse>(`${this.resourcePath}/copy`, { adxBookingId })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => new Error(error.message || 'Server Error'));
  }

  getAdxBookingFilter(): Observable<AdxBookingFilterResult> {
    return this._adxBookingFilterSubject.asObservable();
  }

  setAdxBookingFilter(value: AdxBookingFilterResult): void {
    this._adxBookingFilterSubject.next(value);
  }

  updateAdxBooking(data:AdxBookingUpdateRequest): Observable<AdxBookingUpdateRequest> {
    return this.httpClient.put<AdxBookingUpdateRequest>(`${this.resourcePath}`, data)
    .pipe(catchError(this.errorHandler));
  }

  getBookingHistoryById(id: number): Observable<[BaseHistoryModel[]]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('AdxBookingId', id);

    return this.httpClient
      .get<[BaseHistoryModel[]]>(`${this.resourcePath}/history`, { params: httpParams })
      .pipe(catchError(this.errorHandler));
  }

  public getAdxBookingByTravelServiceId(travelServiceId: number): Observable<AdxBookingResponse> {
    return this.httpClient
      .get<AdxBookingResponse>(`${this.resourcePath}/travel-service/${travelServiceId}`)
  }

  deleteAdxBookingById(adxBookingId:number): Observable<void>{
    return this.httpClient.delete<void>(`dbov2/adx-booking`, { params: {adxBookingId} })
    .pipe(catchError((error:HttpErrorResponse)=>{
      throw new Error(error.message || 'Server error')
    }));
  }

  getPayStatuses(params?: Record<string, boolean>): Observable<PayStatusesResponse[]>{
    return this.httpClient.get<PayStatusesResponse[]>('dbov2/pay-statuses', {
        params: {...params}
    }).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }
}
