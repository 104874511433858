import {Component, Input} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'app-adx-agent-booking-info',
  templateUrl: './adx-agent-booking-info.component.html'
})

@AutoUnsubscribe()
export class AdxAgentBookingInfoComponent {
  @Input() public data: any;
  _adxAgentBooking : any;

  constructor() {
  }

  ngOnInit(){
    this.getAdxAgentBookingInfo();
  }

  getAdxAgentBookingInfo() {
    if (this.data != null){
      this._adxAgentBooking = this.data;
    }
  }
}