<div class="grid-title-event">
  <h2 data-testid="agent-list-title">Agents</h2>

  <div class="events-container">
    <ng-container *ngIf="dataSource.filteredData.length">
      <button data-testid="agent-list-refresh-button" aria-label="Refresh grid" mat-icon-button (click)="replayGrid()">
        <mat-icon fontIcon="replay"></mat-icon>
      </button>
      <button color="primary" data-testid="download-agent-list-button" aria-label="Download Agent List" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
        <mat-icon>download</mat-icon>
      </button>
    </ng-container>
  </div>
</div>

<div class="agents-container mat-elevation-z8" *ngIf="!this.isLoading" id="agent-list-agents-container">
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="handleSortChange($event)" id="agent-list-mat-table">
    <ng-container matColumnDef="agentName" id="agent-list-agent-name-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="agent-list-agent-name-header-cell">Agent Name</mat-header-cell>
      <mat-cell *matCellDef="let agent" id="agent-list-agent-name-cell" class="mat-cell">
        <a href="#" (click)="getRecord(agent); $event.preventDefault();">{{ agent.agentName }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="agentCode" id="agent-list-agent-code-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="agent-list-agent-code-header-cell">Agent Code</mat-header-cell>
      <mat-cell *matCellDef="let agent" id="agent-list-agent-code-cell">{{ agent.interfaceId }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="email" id="agent-list-email-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="agent-list-email-header-cell">Email</mat-header-cell>
      <mat-cell *matCellDef="let agent" id="agent-list-email-cell">{{ agent.email }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dataSourceName" id="agent-list-data-source-name-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="agent-list-data-source-name-header-cell">Data Source Name</mat-header-cell>
      <mat-cell *matCellDef="let agent" id="agent-list-data-source-name-cell">{{ agent.tramsDataSource.displayName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="tramsCentralDocumentId" id="agent-list-trams-central-document-id-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="agent-list-trams-central-document-id-header-cell">Trams Central Document Id</mat-header-cell>
      <mat-cell *matCellDef="let agent" id="agent-list-trams-central-document-id-cell">{{ agent.tramsCentralDocumentId }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="splitRate" id="agent-list-split-rate-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="agent-list-split-rate-header-cell">Split Rate</mat-header-cell>
      <mat-cell *matCellDef="let agent" id="agent-list-split-rate-cell" class="mat-cell">
        <a href="#" (click)="openSplitRateDialog(agent); $event.preventDefault();">{{ agent.splitRate ? agent.splitRate+'%': '' }}</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" data-testid="agent-list-header-row"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" id="agent-list-mat-row"></mat-row>
  </mat-table>

  <mat-paginator [length]="pagination.totalItemCount"
                 [hidePageSize]="true"
                 [pageSize]="pagination.pageSize"
                 [pageIndex]="pagination.pageIndex"
                 (page)="handlePageEvent($event)"
                 id="client-list-mat-paginator">
  </mat-paginator>
</div>
