<modal-panel id="agent-filter-modal-panel" class="agent-filter-modal-panel-cls" [title]="'Filter'" (close)="handleAgentFiltersFormCloseEvent()">
  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Agent Name</mat-label>
        <input matInput formControlName="agentName">
        <button *ngIf="form.controls['agentName'].value" matSuffix mat-icon-button (click)="clearAgentFilters('agentName')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Agent Code</mat-label>
        <input matInput formControlName="agentCode">
        <button *ngIf="form.controls['agentCode'].value" matSuffix mat-icon-button (click)="clearAgentFilters('agentCode')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end" style="display: flex; justify-content: space-between;">
    <button mat-stroked-button color="primary" (click)="clearAgentFilters('all')" style="margin-right: auto;">
      <mat-icon>delete_outline</mat-icon>
      Clear All
    </button>
    <button mat-raised-button color="primary" (click)="applyAgentFilters()">Apply</button>
  </mat-dialog-actions>
</modal-panel>
