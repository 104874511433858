import {Component, Input, OnInit, Optional} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ActivatedRoute} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AdxBookingService} from '../../service/adx-booking.service';
import {AdxBookingResponse} from '../../model/adx-booking-response';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PayStatusesResponse} from '../../model/pay-statuses-response';
import {AdxBookingDetailsModalComponent} from '../adx-booking-details-modal/adx-booking-details-modal.component';

@Component({
  selector: 'app-adx-booking-details',
  templateUrl: './adx-booking-details.component.html'
})

@AutoUnsubscribe()
export class AdxBookingDetailsComponent implements OnInit {
  @Input() public data: any;
  bookingData: any;
  adxBookingId: any;
  public selectedTab: string = "General info";
  
  payStatusesList?: PayStatusesResponse[];
  constructor(
    private adxBookingService: AdxBookingService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    @Optional() public dialogParentRef: MatDialogRef<AdxBookingDetailsModalComponent>) {
  }

  public ngOnInit(): void {
    if (this.data == null){
      this.route.paramMap.subscribe(params => {
        this.adxBookingId = params.get('id');
      });
    }
    else{
      this.adxBookingId = this.data;
    }
    
    this.getAdxBooking();

    this.adxBookingService.getPayStatuses({isDeleted: false})
    .subscribe((data: PayStatusesResponse[]) => {
      if (data) {
        this.payStatusesList = data;
      }
    });
  }

  getAdxBooking() {
    this.adxBookingService
      .getAdxBookingById(this.adxBookingId)
      .subscribe((data: AdxBookingResponse) => {
          this.bookingData = data;
          this.bookingData.isModal = this.data != null;
      });
    }
  
  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTab = event.tab.textLabel;
  }

  submitedDataHandler(completed: boolean) {
    if(completed) {
      this.getAdxBooking();
    }
  }

  confirmCopyServiceHandler(completed: boolean) {
    this.dialogParentRef.close(completed);
  }
}
