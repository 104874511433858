import {inject} from '@angular/core';
import type {ResolveFn} from '@angular/router';
import {Observable} from 'rxjs';
import {PayStatusesResponse} from 'src/app/adx-booking/model/pay-statuses-response';
import {AdxBookingService} from 'src/app/adx-booking/service/adx-booking.service';

export const paymentStatusResolver: ResolveFn<
  Observable<PayStatusesResponse[]>
> = (_route, _state) => {
  const adxBookingService = inject(AdxBookingService);
  return adxBookingService.getPayStatuses();
};
