import {Component, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html'
})
export class PromptComponent {
  userInput: string = '';
  @Output() textChange = new EventEmitter<string>();

  constructor() {
  }

  sendMessage() {
    if (this.userInput.trim() !== '') {
      this.textChange.emit(this.userInput.trim());
      this.userInput = '';
    }
  }
}
