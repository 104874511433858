import {Component, effect, Inject, input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {map, Observable, Subscription} from 'rxjs';
import {BankModel, BranchModel} from 'src/app/shared/model/bank';
import {CurrencyModel, PayMethodModel, PayTypeResponse, ProfileTypeModel, SubmitToModel} from 'src/app/shared/model/pay';
import {PayService} from 'src/app/shared/service/pay/pay.service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {autocompleteAsyncValidator, autocompleteFormFilter, replayHttpQuery} from 'src/infrastructure/helper/autocomplete-form-filter';
import {AdxPaymentService} from '../../service/adx-payment.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@AutoUnsubscribe()
@Component({
  selector: 'payment-form',
  templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent implements OnInit{
  stepperInformation = input<{dataSourceId?: number, adxInvoiceId?:number}>();

  paymentForm!: FormGroup;

  payTypeList$?: Observable<PayTypeResponse>;

  filteredBank$?: Observable<BankModel[]>;
  bankList$!: Observable<BankModel[]>;
  displayWithBank = (bank: BankModel) => {
    return bank?.name || '';
  };

  filteredBranch$?: Observable<BranchModel[]>;
  branchList$!: Observable<BranchModel[]>;
  displayWithBranch = (branch: BranchModel) => {
    return branch?.name || '';
  };

  filteredPayMethod$?: Observable<PayMethodModel[]>;
  payMethodList$!: Observable<PayMethodModel[]>;
  displayWithPayMethod = (payMethod: PayMethodModel) => {
    return payMethod?.paymethodName || '';
  };

  filteredProfileType$?: Observable<ProfileTypeModel[]>;
  profileType$!: Observable<ProfileTypeModel[]>;
  displayWithProfileType = (profileType: ProfileTypeModel) => {
    return profileType?.profileTypeName || '';
  };

  filteredSubmitTo$?: Observable<SubmitToModel[]>;
  submitToList$!: Observable<SubmitToModel[]>;
  displayWithSubmitTo = (submitTo: SubmitToModel) => {
    return submitTo?.submitToLink || '';
  };

  filteredCurrency$?: Observable<CurrencyModel[]>;
  currencyList$!: Observable<CurrencyModel[]>;
  displayWithCurrencyList = (currency: CurrencyModel) => {
    return currency?.currencyName
      ? `${currency?.currencyName}${currency?.symbol ? ' (' + currency?.symbol + ')' : ''}`
      : '';
  };

  autocompleteMode: 'A' | 'I' | 'V' = 'A';
  paymethodChanges$?: Subscription;
  private readonly _currentYear = new Date().getFullYear();
  readonly maxDate = new Date(this._currentYear + 50, 0, 1);
  isLoadingSpinner: boolean = false;

  // Add new property to store client name
  private clientName: string = '';

  constructor(
    @Optional() public paymentFormDialogRef: MatDialogRef<PaymentFormComponent>,
    private fb: FormBuilder,
    private payService: PayService,
    private adxPaymentService: AdxPaymentService,
    private _snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public serviceData?: { adxBookingId?:number, recordLocator?:string, adxInvoiceId?:number, dataSourceId?:number },
  ){
    this.paymentForm = this.fb.group({
      amount: [null, Validators.required],
      payType: [null, Validators.required],
      bank: [null, Validators.required],
      branch: [null, Validators.required],
      payMethod: [null, Validators.required],
      profileType: [null, Validators.required],
      submitTo: [null, Validators.required],
      paymentDate: [null, Validators.required],
      invoiceReference: [null, Validators.required],
      adxBookingId: [null],
      remarks: [null],
      number: [null, Validators.required],
      name: [null, Validators.required],
      currency: [null, Validators.required],
    });

      this.paymethodChanges$ = this.paymentForm.get('payMethod')?.valueChanges.subscribe(payMethod => {
          this.updateNumberValidation(payMethod);
      });

      effect(() => {
          if(this.isStepperForm()) {
              this.setAutocompleteComponents();
              this.patchDisableFields();
              this.setAutocompleteValidator();
          }
      });

  }

  ngOnInit(): void {
    this.payTypeList$ = this.payService.getPayType({ all: true });

    this.bankList$ = replayHttpQuery(this.payService.getBanks({ all: true }));

    this.branchList$ = replayHttpQuery(this.payService.getBranches({ all: true }));

    this.payMethodList$ = replayHttpQuery(this.payService.getPayMethod({ all: true }));

    this.profileType$ = replayHttpQuery(this.payService.getProfileType({ all: true })).pipe(
      map((response: ProfileTypeModel[]) => {
        return response.filter(
          (item) => ['A', 'I', 'V', 'C'].indexOf(item.profileTypeCode) >= 0
        );
      }
      ));

    this.submitToList$ = replayHttpQuery(this.payService.getSubmitTo({ all: true }));

    this.currencyList$ = replayHttpQuery(this.payService.getCurrency({ all: true })),


    this.setAutocompleteComponents();
    this.patchDisableFields();
    this.setAutocompleteValidator();
  }

  setAutocompleteMode(profileType: ProfileTypeModel): void {
    if (profileType.profileTypeCode === 'I') {
      this.autocompleteMode = 'I';
      return;
    }

    if (profileType.profileTypeCode === 'V') {
      this.autocompleteMode = 'V';
      return;
    }

    this.autocompleteMode = 'A';
  }
  
  isStepperForm() : boolean {
    return !!(this.stepperInformation()?.adxInvoiceId && this.stepperInformation()?.dataSourceId);
  }

  createPayment(): void {
    if (!this.validateNumberField()) {
      return;
    }

    this.isLoadingSpinner = true;
    const formValues = this.paymentForm.getRawValue();

    // First fetch invoice details
    this.adxPaymentService.getInvoiceDetails(formValues.invoiceReference).subscribe({
      next: (response) => {
        if (response.infoBlock?.client) {
          // Set client name from invoice details
          const { firstName, middleName, lastName } = response.infoBlock.client;
          this.clientName = [firstName, middleName, lastName]
            .filter(name => name)
            .join(' ');

          // Fallback to client.name if no structured name is available
          if (!this.clientName) {
            this.clientName = response.infoBlock.client.name || '';
          }

          // Handle remarks
          let remarks = formValues.remarks || '';

          // For Vendor type payments, append vendor name to remarks
          if (formValues.profileType.profileTypeCode === 'V') {
            // Get vendor name from the name field's vendorName property
            const vendorName = formValues.name?.profileName || formValues.name?.vendorName;
            if (vendorName) {
              remarks = remarks ? `${remarks} ${vendorName}` : vendorName;
            }
          }

          // Create payment with client name as payee
          this.adxPaymentService.createAdxPayment({
            amount: formValues.amount,
            payTypeId: formValues.payType,
            bankId: formValues.bank.id,
            branchId: formValues.branch.id,
            payMethodId: formValues.payMethod.id,
            profileTypeCode: formValues.profileType.profileTypeCode,
            submitToCode: formValues.submitTo.submitToLinkCode,
            paymentDate: moment(formValues.paymentDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            adxInvoiceId: formValues.invoiceReference,
            remarks: remarks,
            ckCcNo: this.formatCC(formValues.number),
            payeeName: this.clientName, // Always use client name from invoice
            currencyCode: formValues.currency.code,
            currencyId: formValues.currency.id,
            adxBookingId: formValues.adxBookingId,
          }).subscribe({
            next: (_) => {
              this.isLoadingSpinner = false;
              this._snackBar.open('Payment created successfully', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
              this.paymentFormDialogRef.close(true);
            },
            error: () => {
              this.isLoadingSpinner = false;
              this.paymentFormDialogRef.close(true);
            }
          });
        }
      },
      error: (error) => {
        this.isLoadingSpinner = false;
        console.error('Error fetching invoice details:', error);
        this._snackBar.open('Error fetching invoice details', 'Dismiss', {
          duration: 3000,
        });
      }
    });
  }

  closePaymentForm(): void {
    if (this.paymentFormDialogRef) {
      this.paymentFormDialogRef.close();
    }
  }

  clearFields(...args: string[]): void {
    if (args.length) {
      args.forEach((field) => {
        this.paymentForm.controls[field].reset();
      });
    } else {
      this.paymentForm.reset();
    }
    this.patchDisableFields();
    this.setAutocompleteComponents();
  }

    checkInput(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }

    checkInputAmount(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            if (charCode!== 45 && charCode !== 46 ) {
                event.preventDefault();
            }
        }
    }

    profileTypeSelected(): void {
        this.paymentForm.controls['name'].reset();
    }

    patchDisableFields(): void {
        const link = this.router.url;
        const payTypeControl = this.paymentForm.controls['payType'];

        if(link.includes('Payments/Received')){
            payTypeControl.setValue(1);
            payTypeControl.disable();
        }

        if(link.includes('Payments/Made')){
            payTypeControl.setValue(2);
            payTypeControl.disable();
        }

        if(this.serviceData?.adxBookingId){
            this.paymentForm.controls['adxBookingId'].setValue(this.serviceData?.adxBookingId);
            this.paymentForm.controls['adxBookingId'].disable();
        }

        if(this.serviceData?.adxInvoiceId){
            this.paymentForm.controls['invoiceReference'].setValue(this.serviceData?.adxInvoiceId);
            this.paymentForm.controls['invoiceReference'].disable();
        }

        if(this.isStepperForm()){
            this.paymentForm.controls['invoiceReference'].setValue(this.stepperInformation()?.adxInvoiceId);
            this.paymentForm.controls['invoiceReference'].disable();
        }
    }

    setAutocompleteComponents(): void {
        this.filteredBank$ = autocompleteFormFilter(
            this.paymentForm.controls['bank'],
            'name',
            this.bankList$.pipe(map((bankList)=>{
                if(this.isStepperForm()){
                  return bankList.filter((bank)=> bank.dataSourceId === this.stepperInformation()!.dataSourceId);
                }

                if(this.serviceData?.dataSourceId){
                  return bankList.filter((bank)=> bank.dataSourceId === this.serviceData?.dataSourceId);
                }

                return bankList;
            }))
        );

        this.filteredBranch$ = autocompleteFormFilter(
            this.paymentForm.controls['branch'],
            'name',
            this.branchList$.pipe(map((branchList)=>{
                if(this.isStepperForm()){
                  return branchList.filter((branch)=> branch.dataSourceId === this.stepperInformation()!.dataSourceId);
                }

                if(this.serviceData?.dataSourceId){
                  return branchList.filter((branch)=> branch.dataSourceId === this.serviceData?.dataSourceId);
                }

                return branchList;
            }))
        );

        this.filteredPayMethod$ = autocompleteFormFilter(this.paymentForm.controls['payMethod'], 'paymethodName', this.payMethodList$);
        
        this.filteredProfileType$ = autocompleteFormFilter(this.paymentForm.controls['profileType'], 'profileTypeName', this.profileType$);
        
        this.filteredSubmitTo$ = autocompleteFormFilter(this.paymentForm.controls['submitTo'], 'submitToLink', this.submitToList$);
        
        this.filteredCurrency$ = autocompleteFormFilter(this.paymentForm.controls['currency'], 'currencyName', this.currencyList$);
    }

    setAutocompleteValidator(): void {
        this.paymentForm.controls['bank'].setAsyncValidators(autocompleteAsyncValidator(this.bankList$));

        this.paymentForm.controls['branch'].setAsyncValidators(autocompleteAsyncValidator(this.branchList$));

        this.paymentForm.controls['payMethod'].setAsyncValidators(autocompleteAsyncValidator(this.payMethodList$));

        this.paymentForm.controls['profileType'].setAsyncValidators(autocompleteAsyncValidator(this.profileType$));

        this.paymentForm.controls['submitTo'].setAsyncValidators(autocompleteAsyncValidator(this.submitToList$));

        this.paymentForm.controls['currency'].setAsyncValidators(autocompleteAsyncValidator(this.currencyList$));
    }

    private updateNumberValidation(paymethodName: string): void {
      const numberControl = this.paymentForm.get('number');
      if (paymethodName === 'CC' || paymethodName === 'CC Merchant') {
        numberControl?.setValidators([Validators.required, this.ccMerchantValidator]);
      } else {
        numberControl?.clearValidators();
        numberControl?.setValidators(Validators.required);
      }
      numberControl?.updateValueAndValidity();
    }

  private ccMerchantValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    const isValidLength = value.length <= 16;
    const lastFourDigits = value.slice(-4);
    const isLastFourDigitsNumeric = /^\d{4}$/.test(lastFourDigits);

    if (isValidLength && isLastFourDigitsNumeric) {
      return null;
    }

    return { ccMerchantInvalid: true };
  }

  private validateNumberField(): boolean {
    const payMethod = this.paymentForm.get('payMethod')?.value.paymethodName;
    const numberControl = this.paymentForm.get('number');

    if (payMethod === 'CC' || payMethod === 'CC Merchant') {
      const validationErrors = this.ccMerchantValidator(numberControl!);
      if (validationErrors) {
        numberControl?.setErrors(validationErrors);
        return false;
      }
    }

    return true;
  }

  formatCC(cc: string): string {
    const payMethod = this.paymentForm.get('payMethod')?.value.paymethodName;
    if(payMethod === 'CC' || payMethod === 'CC Merchant'){
      const ccElements = cc.split("");
      while(ccElements.length<4){
        ccElements.unshift('0')
      }
      return `XXXXXXXXXXX${ccElements.join("")}`
    }
    return cc;
  }
}
