<div>
    <h2 data-testid="adx-booking-details-title" class="page-details-title-style">Service {{this.bookingData.id}}</h2>
</div>

<mat-tab-group dynamicHeight disablePagination="true" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="General info">
        <app-adx-booking-info [data]="bookingData" (submitedData)="submitedDataHandler($event)"></app-adx-booking-info>
    </mat-tab>
    @if (bookingData.adxAgentBookings && bookingData.adxAgentBookings.length > 0){
        <mat-tab label="Agents">
            <br>
            @for (adxAgentBooking of bookingData.adxAgentBookings; track adxAgentBooking?.id) {
                <app-adx-agent-booking-info [data]="adxAgentBooking"></app-adx-agent-booking-info>
            }
        </mat-tab>
    }
    @if(bookingData.adxBookingUdids && bookingData.adxBookingUdids.length > 0){
        <mat-tab label="Udids">
            <app-adx-booking-udid-info [data]="bookingData.adxBookingUdids"></app-adx-booking-udid-info>
        </mat-tab>
    }
    @if(bookingData.adxBookingSegments && bookingData.adxBookingSegments.length > 0) {
        <mat-tab label="Segments">
            @for (adxBookingSegment of bookingData.adxBookingSegments; track adxBookingSegment?.adxBookingId) {
                <app-adx-booking-segment-info [data]="adxBookingSegment"></app-adx-booking-segment-info>
            }
        </mat-tab>
    }
</mat-tab-group>

<button *ngIf="isLoggedInUserHasManagerRole && !this.data" data-testid="adx-booking-detail-copy-button" mat-raised-button color="primary" 
    (click)="openCopyConfirmationDialog(adxBookingId)" style="float: right;">Copy service</button>