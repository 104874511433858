import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {MatSort} from '@angular/material/sort';
import {Pagination} from '../../../../infrastructure/pagination/model/pagination';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';
import {AdxBookingResponse} from '../../model/adx-booking-response';
import {AdxBookingService} from '../../service/adx-booking.service';
import {SearchPaginatedAdxBookingResponse} from '../../model/search-paginated-adx-booking-response';
import {AdxBookingFilter} from '../../model/adx-booking-filter';
import {AdxBookingFilterResult} from '../../model/adx-booking-filter-result';
import {AdxBookingDetailsModalComponent} from '../adx-booking-details-modal/adx-booking-details-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';
import {VccViewerComponent, VccViewerType} from 'src/app/virtual-credit-card/component/viewer/vcc-viewer.component';
import {VccViewerData} from 'src/app/virtual-credit-card/model/vcc-viewer-data';
import {MatSnackBar} from '@angular/material/snack-bar';		
import {HistoryComponent} from 'src/app/history/components/history/history.component';
import {ModalService} from 'src/app/shared/service/modal/modal.service';
import {ConfirmationDialogDeleteAdxBookingComponent} from '../confirmation-dialog-delete-adx-booking/confirmation-dialog-delete-adx-booking.component';
import {PaymentFormComponent} from 'src/app/payments/components/payment-form/payment-form.component';

@Component({
  selector: 'adx-booking-list',
  templateUrl: './adx-booking-list.component.html',
})

@AutoUnsubscribe()
export class AdxBookingListComponent implements OnInit, AfterViewInit {
  @Input() public data: any;

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  public dataSource: MatTableDataSource<AdxBookingResponse> = new MatTableDataSource<AdxBookingResponse>([]);
  
  public displayedColumns: Array<string> = [
    'id',
    'recordLocator',
    'travelType',
    'reservationNo',	
    'confirmationNumber',
    'totalFare',
    'departDate',
    'returnDate',
    'action'
  ];

  isLoading: boolean = false;

  public pagination: Pagination = {} as Pagination;
  public adxBookingFilter: AdxBookingFilter = {} as AdxBookingFilter;
  loggedInUserHasFinanceAndManagerRole: boolean = false;
  
  constructor(
    private adxBookingService: AdxBookingService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private azureAuthService: AzureAuthService,
    private _snackBar: MatSnackBar,
    private modal: ModalService)
    {
      this.loggedInUserHasFinanceAndManagerRole = this.azureAuthService.checkUserAzureTokenHasManagerRole() && this.azureAuthService.checkUserAzureTokenHasFinanceRole();;
    }

  public ngOnInit(): void {
    if (this.data == undefined || this.data.pageSize == null) {
      this.data = {
        adxInvoiceId: null,
        pageSize: 25
      }
    }
    this.adxBookingService.getAdxBookingFilter().subscribe((filter: AdxBookingFilterResult) => {
      if (this.paginator !== undefined) {
        this.paginator.pageIndex = 0;
        this.adxBookingFilter.pageNumber = 1;
      }

      filter.adxInvoiceId = this.data.adxInvoiceId;
      this.adxBookingFilter.adxInvoiceId = filter.adxInvoiceId;
      this.adxBookingFilter.pageSize = this.data.pageSize;

      this.searchAdxBookings(new PageEvent(), false);
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public searchAdxBookings(pageEvent: PageEvent, skipPager: boolean): void {
    if (!skipPager) {
      this.setPaginationAfterPageChanged(pageEvent);
    }
    this.adxBookingService
      .searchPaginatedAdxBookings(this.adxBookingFilter)
      .subscribe((data: SearchPaginatedAdxBookingResponse) => {
        if (data) {
          this.pagination = data.pagination;
          this.adxBookingFilter.pageNumber = data.pagination.pageNumber;
          this.adxBookingFilter.pageSize = data.pagination.pageSize;
          this.dataSource = new MatTableDataSource<AdxBookingResponse>(data.items);
          this.isLoading = false;
        }
      });
  }

  public pageChanged(event: PageEvent): void {
    this.searchAdxBookings(event, false);
  }

  public setPaginationAfterPageChanged(pageEvent: PageEvent): void {
    this.adxBookingFilter.pageSize = this.data.pageSize;

    const isStepForward = pageEvent.pageIndex > pageEvent.previousPageIndex!;

    if (isStepForward) {
      this.adxBookingFilter.pageNumber += 1;
    } else {
      this.adxBookingFilter.pageNumber = this.adxBookingFilter.pageNumber !== undefined &&  this.adxBookingFilter.pageNumber > 1 ? this.adxBookingFilter.pageNumber-1 : 1;
    }
  }

  public handleSortChange(sort: any): void {
    this.adxBookingFilter.propertyName = sort.active;
    this.adxBookingFilter.orderKey = sort.direction.toUpperCase();

    this.searchAdxBookings(new PageEvent(), false);
  }

  downloadGridToFile(): void {
    const downloadSubs$ = this.adxBookingService
      .searchPaginatedAdxBookings(this.adxBookingFilter).subscribe((data: SearchPaginatedAdxBookingResponse) => {
        this.exportGridService.getByDataSource(
          'dbov2/adx-booking',
          'adx-bookings',
          {
            ... this.adxBookingFilter,
            pageSize: data?.pagination?.totalItemCount || 100,
          },
          (data)=>{
            const record = data as AdxBookingResponse;
            return {
              'Id': record?.id,
              'Record Locator': record?.recordLocator,
              'Reservation Number': record?.reservationNo,
              'Confirmation Number': record?.confirmationNumber,
              'Total Fare': record?.totalFare,
              'Depart Date': this.datePipe.transform(record?.departDate, 'M/d/yyyy'),
              'Return Date': this.datePipe.transform(record?.returnDate, 'M/d/yyyy'),
            };
          }
        );
        downloadSubs$?.unsubscribe();
      });
  }

  replayGrid (){
    this.searchAdxBookings(new PageEvent(), false);
  }

  handleIdClick(event: Event, element: any): void {
    event.preventDefault();
    
    if (this.data.adxInvoiceId == null) {
      window.open(`AdxBookings/${element.id}`);
    }
    else {
      this.openDialog(element.id, `Service information`);
    }
  }

  public openDialog(adxBookingId: number, title: string): void {
    const dialogRef = this.dialog.open(AdxBookingDetailsModalComponent, {
      width: '55%',
      data: { adxBookingId: adxBookingId, title: title }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.replayGrid();
      }
    });
  }

  manageServiceVirtualCreditCardClick(adxBookingId : number){
    let dialogConfirmationRef = 
            this.dialog.open(VccViewerComponent, {
              width: '60%',
              data: {
                id: adxBookingId,
                type: this.loggedInUserHasFinanceAndManagerRole ? VccViewerType.Update : VccViewerType.Info,
              } as VccViewerData
          })
          dialogConfirmationRef.afterClosed().subscribe(
            (result: boolean) => {
              if (result != null && result){
                console.log(result);
              }
            }
          );
  }

  handleHistoryClick(adxBookingId : number){
    this.adxBookingService.getBookingHistoryById(adxBookingId)
      .subscribe((data: any) => {
        if(data.length == 0){
          this._snackBar.open('No Service history found for Id: ' + adxBookingId, 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['message-position', 'app-notification-success']
          });
        }
        else
        {
          let dialogConfirmationRef = 
            this.dialog.open(HistoryComponent, {
              width: '60%',
              data: {
                mode: 'Service',
                historyData: data,
                id: adxBookingId,
              }
          })
          dialogConfirmationRef.afterClosed().subscribe(
            (result: boolean) => {
              if (result != null && result){
                console.log(result);
              }
            }
          );
        }
      });
  }

  handleDeleteService(adxBooking : AdxBookingResponse){
    if(adxBooking){
        const modalRef = this.modal.open(ConfirmationDialogDeleteAdxBookingComponent, {
            data: { adxBookingId:adxBooking.id, recordLocator: adxBooking.recordLocator },
        });
        modalRef.afterClosed().subscribe((deleted)=>{
            if(deleted){
                this.replayGrid();
            }
        });
    }
  }

  handleServicePayments(adxBooking : AdxBookingResponse){
    if(adxBooking){
        this.dialog.open(PaymentFormComponent, {
            data: { adxBookingId:adxBooking.id, recordLocator: adxBooking.recordLocator },
        });
    }
  }  
}
