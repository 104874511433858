<section class="client-balance-filter__header">
  <h2>Filter</h2>

  <button mat-icon-button (click)="close()">
    <mat-icon fontIcon="close"></mat-icon>
  </button>
</section>

<mat-divider />

<mat-dialog-content class="client-balance-filter__content">
  <form class="client-balance-filter__form" [formGroup]="form">
    <mat-form-field>
      <mat-label>Payment date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="paymentDate" />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #picker />
      @if (showPaymentDateClearIcon) {
      <button matSuffix mat-icon-button (click)="resetFilter('paymentDate')">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      } @else {
      <mat-datepicker-toggle matIconSuffix [for]="picker" />
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Client Name</mat-label>
      <input
        matInput
        formControlName="clientName"
        (focusout)="formatClientName()"
      />
      @if (showClientNameClearIcon) {
      <button matSuffix mat-icon-button (click)="resetFilter('clientName')">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Balance due min</mat-label>
      <input
        matInput
        type="number"
        min="0"
        (keydown)="preventDecimal($event)"
        (input)="removeDecimal($event, 'minOutstandingBalance')"
        formControlName="minOutstandingBalance"
      />
      @if (showMinBalanceClearIcon) {
      <button
        matSuffix
        mat-icon-button
        (click)="resetFilter('minOutstandingBalance')"
      >
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Balance due max</mat-label>
      <input
        matInput
        type="number"
        min="0"
        (keydown)="preventDecimal($event)"
        (input)="removeDecimal($event, 'maxOutstandingBalance')"
        formControlName="maxOutstandingBalance"
      />
      @if (showMaxBalanceClearIcon) {
      <button
        matSuffix
        mat-icon-button
        (click)="resetFilter('maxOutstandingBalance')"
      >
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Invoice total min</mat-label>
      <input
        matInput
        type="number"
        min="0"
        (keydown)="preventDecimal($event)"
        (input)="removeDecimal($event, 'minTotalBilled')"
        formControlName="minTotalBilled"
      />
      @if (showMinInvoiceClearIcon) {
      <button matSuffix mat-icon-button (click)="resetFilter('minTotalBilled')">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Invoice total max</mat-label>
      <input
        matInput
        type="number"
        min="0"
        (keydown)="preventDecimal($event)"
        (input)="removeDecimal($event, 'maxTotalBilled')"
        formControlName="maxTotalBilled"
      />
      @if (showMaxInvoiceClearIcon) {
      <button matSuffix mat-icon-button (click)="resetFilter('maxTotalBilled')">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>FMS Client ID</mat-label>
      <input
        matInput
        type="number"
        min="0"
        (keydown)="preventDecimal($event)"
        (input)="removeDecimal($event, 'id')"
        formControlName="id"
      />
      @if (showIdClearIcon) {
      <button matSuffix mat-icon-button (click)="resetFilter('id')">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date Source Name</mat-label>
      <mat-select formControlName="dataSourceId">
        @for (item of datasources; track $index) {
        <mat-option [value]="item.id">
          {{ item.sourceName }}
        </mat-option>
        }
      </mat-select>
      @if (showDataSourceClearIcon) {
      <button matSuffix mat-icon-button (click)="resetFilter('dataSourceId')">
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Client Pay Status</mat-label>
      <mat-select formControlName="clientPayStatusId">
        @for (item of status; track $index) {
        <mat-option [value]="item.id">
          {{ item.payStatusName }}
        </mat-option>
        }
      </mat-select>
      @if (showPayStatusClearIcon) {
      <button
        matSuffix
        mat-icon-button
        (click)="resetFilter('clientPayStatusId')"
      >
        <mat-icon fontIcon="close"></mat-icon>
      </button>
      }
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-divider />

<mat-dialog-actions class="client-balance-filter__footer">
  <button mat-stroked-button color="primary" (click)="reset()">
    Clear All
    <mat-icon>delete_outline</mat-icon>
  </button>
  <button mat-raised-button color="primary" (click)="apply()">Apply</button>
</mat-dialog-actions>
