import {Component, Optional, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {AdxInvoiceService} from 'src/app/adx-invoice/service/adx-invoice.service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
    selector: 'payment-stepper-form',
    templateUrl: './payment-stepper-form.component.html',
    styleUrl: './payment-stepper-form.component.scss',
})
export class PaymentStepperFormComponent {
    invoiceForm!: FormGroup;
    isLoading = false;

    invoiceData:{dataSourceId?: number, adxInvoiceId?:number} = {};

    @ViewChild('stepper') private stepperRef!: MatStepper;

    constructor(
        @Optional() public dialogRef: MatDialogRef<PaymentStepperFormComponent>,
        private fb: FormBuilder,
        private adxInvoiceService: AdxInvoiceService,
    ){
        this.invoiceForm = this.fb.group({
            invoiceInput: ['', Validators.required],
        });
    }

    closeModal(): void{
        this.dialogRef.close();
    }

    validateInvoice(): void {
        this.isLoading = true;
        this.adxInvoiceService.getAdxInvoiceById(this.invoiceForm.get('invoiceInput')?.value).subscribe(
            {
                next: (data=>{
                    if(data?.id && data?.dataSourceId){
                        this.invoiceData = {
                            dataSourceId: data.dataSourceId,
                            adxInvoiceId: data.id,
                        };
                        this.isLoading = false;
                        setTimeout(() => {
                            this.stepperRef.next();
                        });

                    } else {
                        this.isLoading = false;
                        this.invoiceForm.get('invoiceInput')?.setErrors({incorrectInvoice: true});
                    }

                }),
                error: ()=>{
                    this.isLoading = false;
                    this.invoiceForm.get('invoiceInput')?.setErrors({incorrectInvoice: true});
                }
            }
        )
    }

    clearInvoice(): void {
        this.invoiceData = {};
    }

    preventFormValidation(event:any, stepper: MatStepper): void {
        stepper.selected!.interacted = false;
        if (event.selectedIndex === 0 && !this.invoiceData.adxInvoiceId && !this.invoiceData.dataSourceId) {
            event.preventDefault();
        }
    }

    checkInput(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }
}