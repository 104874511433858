import {Component, OnInit} from '@angular/core';
import {ChartDataSet, ChartOptions} from '../../../../../shared/components/chart/chart.component';
import {ChartsVccService} from '../../../../service/charts-vcc.service';
import {VccChartFilter} from '../../../../model/funding-vcc-chart-filter';
import {AggregatedChartModel, ChartResponse} from '../../../../model/vcc-chart-model';
import * as moment from 'moment';
import {TooltipItem} from 'chart.js';
import {MatchedVccFilter} from '../../../../../finance-resource/model/match-vcc';
import {MatchingVccService} from '../../../../../finance-resource/service/matching-vcc.service';
import {Router} from '@angular/router';
import {MerchantsService} from 'src/infrastructure/merchants/service/merchants.service';
import {Merchant} from 'src/app/dashboard/model/merchant-guid';

@Component({
    selector: 'aggregated-vcc-chart',
    templateUrl: './aggregated-vcc-chart.component.html'
})

export class AggregatedVccChartComponent implements OnInit {
    
  public shortLabels: string[] = [];
  merchantGuids: Merchant[] = [];
  public vccChartFilter: VccChartFilter = {} as VccChartFilter;
  public dataAggregated!: ChartDataSet;
  originalValues: number[] = [];
  limitPercentage: number = 0;
  baseLimit: number = 0.04;
  adjustedValue: number = 0;
  adjustedLimit: number = 0.3;
  filteredData : AggregatedChartModel[] = [];
  public chargedData: AggregatedChartModel[] = [];
  public matchingVccFilterConfigResult: MatchedVccFilter = {};
  public aggregatedVccChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
          callbacks: {
              label: (tooltipItem: TooltipItem<'bar'>) => {
                const index = tooltipItem.dataIndex;
                const dataItem = this.filteredData[index];
                if (dataItem) {
                  const originalValue = this.originalValues[index];
                  return [
                    `Charged: ${originalValue}`,
                    `Sales: ${dataItem.amountOfSales}`,
                    `Refunds: ${dataItem.amountOfRefunds}`,
                    `Net Value: ${dataItem.amountNetValue}`,
                  ];
                }
                return '';
              }
          }
      },
      legend: {
        display: true,
        align: 'center',
        onClick: (e) => {
          const mouseEvent = e.native as MouseEvent;
          mouseEvent.stopPropagation();
        }
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        font: {
          weight: 'bold'
        },
        formatter: (value: number, context: any) => {
          if (value !== 0) {
            const countOfPurchase = this.filteredData[context.dataIndex].countOfPurchase;
            return `${countOfPurchase}`;
          }
          return '';
        }
      }
    },
    scales: {
      y: {
          beginAtZero: true
      }
    }
  }

  constructor(private chartsVccService: ChartsVccService,
              private matchingVccService: MatchingVccService,
              private merchantsService: MerchantsService,
              private router: Router,
  ) { }
  
  ngOnInit() {
    const today = moment();
    this.chartsVccService.getLatestDate().subscribe((latestReleasedDate) => {
      this.vccChartFilter.propertyName = 'releasedDateFrom';
      this.vccChartFilter.orderKey = 'DESC';
      this.vccChartFilter.releasedDateFrom = latestReleasedDate;
      this.vccChartFilter.releasedDateTo = today.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

      this.chartsVccService.getVccChartFilter().subscribe((filter) => {
        this.vccChartFilter.merchantGuid = filter.merchantGuid;
        this.vccChartFilter.releasedDateFrom = filter.releasedDateFrom ? moment(filter.releasedDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : this.vccChartFilter.releasedDateFrom;
        this.vccChartFilter.releasedDateTo = filter.releasedDateTo ? moment(filter.releasedDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : this.vccChartFilter.releasedDateTo;
       
        this.merchantsService.merchants$.subscribe((data)=>{
          this.merchantGuids = data;
          this.getAggregatedData();
        });
      });
    });
    
  }

  get dateFilterText() {
    return this.chartsVccService.getDateSubTitle();
  }

  getAggregatedData() {
    this.chartsVccService.searchAggregatedVcc(this.vccChartFilter).subscribe((aggregatedResponse: AggregatedChartModel[]) => {
      this.filteredData = aggregatedResponse.filter(
        (item) => item.amountOfPurchase !== 0 || 
        item.amountOfRefunds !== 0 ||
        item.amountOfSales !== 0  ||
        item.amountNetValue !== 0
      );
      this.buildAgregetedDataSet(this.filteredData);
    });
  }

  buildAgregetedDataSet(aggregatedData : AggregatedChartModel[]) {
    this.shortLabels = aggregatedData.map(item => {
      const merchant = this.merchantGuids.find(m => m.merchantGuid === item.merchantGuid);
      return merchant ? merchant.shortName : '';
    });

    this.originalValues = aggregatedData.map(item => parseFloat(item.amountOfPurchase.toFixed(2)));
    
    const validAmounts = aggregatedData
        .map(item => Math.abs(item.amountOfPurchase))
        .filter(value => value !== undefined && value !== null && !isNaN(value));

    const maxAmountOfPurchase = validAmounts.length > 0 ? Math.max(...validAmounts) : 0;
    this.limitPercentage = maxAmountOfPurchase * this.baseLimit;

    const adjustedAmountOfPurchase = aggregatedData.map((item: AggregatedChartModel) => {
      this.adjustedValue = Math.abs(parseFloat(item.amountOfPurchase.toFixed(2)));
      const isNegative = this.adjustedValue < 0;

      if (this.adjustedValue === 0) {
        this.adjustedValue = this.limitPercentage;
      } else if (this.adjustedValue <= this.limitPercentage) {
        this.adjustedValue += (this.limitPercentage - this.adjustedValue) * this.adjustedLimit;
        if (isNegative) {
          this.adjustedValue = Math.abs(this.adjustedValue);
        }
      }
      return this.adjustedValue;
    });
    
    this.dataAggregated = {
      labels: this.shortLabels,
      datasets: [{
        data: adjustedAmountOfPurchase,
        label: 'Total VCCs Charged',
        backgroundColor: '#00593F',
      }]
    }
  }

  navigateToReport(event: ChartResponse) {
    const selectedMerchant = this.merchantGuids.find(merchant => merchant.shortName === event.label);
    this.matchingVccFilterConfigResult.merchantGuid = selectedMerchant?.merchantGuid;
    this.matchingVccFilterConfigResult.releasedDateFrom = this.vccChartFilter.releasedDateFrom ? moment(this.vccChartFilter.releasedDateFrom).toDate() : null;
    this.matchingVccFilterConfigResult.releasedDateTo = this.vccChartFilter.releasedDateTo ? moment(this.vccChartFilter.releasedDateTo).toDate() : null;

    this.matchingVccService.setMatchingFilter(this.matchingVccFilterConfigResult);
    this.router.navigate(['/FinanceResources/MatchingVcc']);
  }
}