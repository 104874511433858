<form [formGroup]="tripInfoForm" class="template-form" *ngIf="_trip != undefined && _trip != null">
  <div class="grid-title-event" style="justify-content: flex-end;">
      <div class="events-container">
          <button color="primary" id="trip-info-edit-button" aria-label="Edit" mat-stroked-button (click)="toggleTripEdit()">{{ isEditable ? 'Update' : 'Edit' }}</button>
      </div>
</div>
  <div data-testid="trip-info-modal-div" class="filter__fields">
    <mat-card class="mat-elevation-z8">
      <mat-card-content>
        <mat-grid-list data-testid="trip-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="85">
          <mat-grid-tile data-testid="trip-name-grid-tile">
            <mat-form-field data-testid="trip-name-field">
              <mat-label data-testid="trip-name-field-title">Trip name: </mat-label>
              <input [readonly]="!isEditable" data-testid="trip-name-input-field" matInput [(ngModel)]="_trip.tripName" formControlName="tripName">
              <mat-error *ngIf="tripInfoForm.controls['tripName'].hasError('required')">
                Trip name is required
              </mat-error>
              <mat-error *ngIf="tripInfoForm.controls['tripName'].hasError('tripNameTooLong')">
                Trip name must be 500 characters or less
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile data-testid="trip-description-grid-tile">
            <mat-form-field data-testid="trip-description-field">
              <mat-label data-testid="trip-description-field-title">Trip description: </mat-label>
              <input [readonly]="!isEditable" data-testid="trip-description-input-field" matInput [(ngModel)]="_trip.tripDescription" formControlName="tripDescription">
              <mat-error *ngIf="tripInfoForm.controls['tripDescription'].hasError('lengthTooLong')">
                Trip description must be 2600 characters or less
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile data-testid="trip-external-number-grid-tile">
            <mat-form-field data-testid="trip-external-number-field">
              <mat-label data-testid="trip-external-number-field-title">External number: </mat-label>
              <input readonly [disabled]="true" data-testid="trip-external-number-input-field" matInput value="{{_trip.externalNumber}}">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile data-testid="trip-external-reference-grid-tile">
            <mat-form-field data-testid="trip-external-reference-field">
              <mat-label data-testid="trip-external-reference-field-title">External reference: </mat-label>
              <input readonly [disabled]="true" data-testid="trip-external-reference-input-field" matInput value="{{_trip.externalReference}}">
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field data-testid="trip-start-date-field">
              <mat-label data-testid="trip-start-date-field-title">Trip start date: </mat-label>
              <input
              data-testid="trip-start-date-input-field"
                [readonly]="!isEditable"
                matInput
                [matDatepicker]="picker1"
                [(ngModel)]="_trip.tripStartDate"
                formControlName="tripStartDate"
                [min]="currentDate">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle [disabled]="true" matIconSuffix [for]="picker1"></mat-datepicker-toggle> 
              <mat-datepicker #picker1 id="trip-start-date-datepicker"></mat-datepicker>
              <mat-error *ngIf="tripInfoForm.controls['tripStartDate'].hasError('required')">
                Trip start date is required
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field data-testid="trip-end-date-field">
              <mat-label data-testid="trip-end-date-field-title">Trip end date: </mat-label>
              <input
                data-testid="trip-end-date-input-field"
                matInput  
                [readonly]="!isEditable"
                [matDatepicker]="picker2"
                [(ngModel)]="_trip.tripEndDate"
                formControlName="tripEndDate"
                [min]="currentDate">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle [disabled]="true" matIconSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2 id="trip-start-date-datepicker"></mat-datepicker>
              <mat-error *ngIf="tripInfoForm.controls['tripEndDate'].hasError('required')">
                Trip end date is required
              </mat-error>
              <mat-error *ngIf="tripInfoForm.controls['tripEndDate'].hasError('endDateInvalid')">
                Trip end date must be after the start date
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card> 
  </div>
</form>