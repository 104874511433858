import {Component, ContentChild, Directive, EventEmitter, Input, Output} from '@angular/core';

@Directive({
  selector: '[modalPanelFooter]',
})
export class ModalPanelFooterDirective {
}

@Directive({
  selector: '[modalPanelHeader]',
})
export class ModalPanelHeaderDirective {
}

@Component({
  selector: 'modal-panel',
  templateUrl: './modal-panel.component.html',
  styleUrls: ['./modal-panel.component.scss'],
  host: {
    'class': 'modal-panel'
  }
})
export class ModalPanelComponent {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Input() title: string = '';
  @Input() showConfirmDialog: boolean = false;
  @ContentChild(ModalPanelFooterDirective)
  panelFooter!: ModalPanelFooterDirective;
  @ContentChild(ModalPanelHeaderDirective)
  panelHeader!: ModalPanelHeaderDirective;

  handleClose() {
    this.close.emit();
  }
}
