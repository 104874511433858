<form data-testid="client-payments-edit-modal-form" class="filter" [formGroup]="dynamicFormGroup!" (submit)="updateAmounts()">
  <modal-panel title="Services"
               (close)="closeModal()"
               data-testid="client-payments-edit-modal-panel"
               class="filter-panel">
    <div class="filter__fields" [ngClass]="{'loading-spinner': !loadedData || !dataSource.length}">
      <ng-container *ngIf="!loadedData; else loadedContent">
        <mat-spinner data-testid="client-payments-edit-modal-panel-loading-spinner"></mat-spinner>
      </ng-container>

      <ng-template #loadedContent>
        <div class="clients-modal-container mat-elevation-z8" data-testid="client-payments-edit-modal-panel-container">
          <mat-table [dataSource]="dataSource" matSort data-testid="client-payments-edit-modal-panel-table" class="responsive-table">

            <ng-container matColumnDef="adxInvoiceId">
              <mat-header-cell data-testid="client-payments-edit-invoice-id-header" class="mat-header-cell" *matHeaderCellDef>Invoice Id</mat-header-cell>
              <mat-cell data-testid="client-payments-edit-invoice-id-cell" *matCellDef="let payment">{{ payment.adxInvoiceId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="adxBookingId">
              <mat-header-cell data-testid="client-payments-edit-adx-booking-id-header" class="mat-header-cell" *matHeaderCellDef>Service Id</mat-header-cell>
              <mat-cell data-testid="client-payments-edit-adx-booking-id-cell" *matCellDef="let payment">{{ payment.adxBookingId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="adxBookingTravelTypeName">
              <mat-header-cell data-testid="client-payments-edit-adx-booking-travel-type-name-header" class="mat-header-cell" *matHeaderCellDef>Travel Type</mat-header-cell>
              <mat-cell data-testid="client-payments-edit-adx-booking-travel-type-name-cell" *matCellDef="let payment">{{ payment.adxBookingTravelTypeName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="adxBookingVendorName">
              <mat-header-cell data-testid="client-payments-edit-adx-booking-vendor-name-header" class="mat-header-cell" *matHeaderCellDef>Vendor Name</mat-header-cell>
              <mat-cell data-testid="client-payments-edit-adx-booking-vendor-name-cell" *matCellDef="let payment">{{ payment.adxBookingVendorName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalFare">
              <mat-header-cell data-testid="client-payments-edit-amount-header" class="mat-header-cell" *matHeaderCellDef>Amount</mat-header-cell>
              <mat-cell data-testid="client-payments-edit-amount-cell" *matCellDef="let payment" class="responsive-cell">
                <mat-form-field floatLabel="always" class="responsive-form-field">
                  <mat-label></mat-label>
                  <input matInput placeholder="{{payment.totalFare}}" [formControlName]="formControlNames[payment.adxBookingId].totalFare">
                </mat-form-field>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="vendorPaymentMadeAmt">
              <mat-header-cell data-testid="vendor-payment-made-edit-amount-header" class="mat-header-cell" *matHeaderCellDef>Vendor Made Payment</mat-header-cell>
              <mat-cell data-testid="vendor-payment-made-edit-amount-cell" *matCellDef="let payment" class="responsive-cell">
                <mat-form-field floatLabel="always" class="responsive-form-field">
                  <mat-label></mat-label>
                  <input matInput placeholder="{{payment.vendorPaymentMadeAmt}}" [formControlName]="formControlNames[payment.adxBookingId].vendorPaymentMadeAmt">
                </mat-form-field>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="clientPaymentRecievedAmt">
              <mat-header-cell data-testid="client-payment-recieved-edit-amount-header" class="mat-header-cell" *matHeaderCellDef>Client Recieved Payment</mat-header-cell>
              <mat-cell data-testid="vendor-payment-made-edit-amount-cell" *matCellDef="let payment" class="responsive-cell">
                <mat-form-field floatLabel="always" class="responsive-form-field">
                  <mat-label></mat-label>
                  <input matInput placeholder="{{payment.clientPaymentRecievedAmt}}" [formControlName]="formControlNames[payment.adxBookingId].clientPaymentRecievedAmt">
                </mat-form-field>
              </mat-cell>
            </ng-container>

            <mat-header-row data-testid="client-payments-edit-modal-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row data-testid="client-payments-edit-modal-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </mat-table>

          <mat-paginator data-testid="client-payments-edit-modal-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
                         [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
          </mat-paginator>
        </div>
      </ng-template>
    </div>

    <ng-container modalPanelFooter data-testid="client-payments-edit-modal-panel-footer">
      <div class="modal-footer">
        <button mat-button mat-stroked-button color="primary" type="button" (click)="closeModal()" data-testid="client-payments-edit-modal-panel-close-button">Close</button>
        <ng-container *ngIf="loadedData && dataSource.length">
          <button data-testid="client-payments-edit-modal-submit-button" mat-raised-button color="primary" type="submit" [disabled]="isDisabled()">
            <ng-container *ngIf="!submitedData; else submitText">
              <mat-spinner color="accent" diameter="30"></mat-spinner>
            </ng-container>
            <ng-template #submitText>Submit</ng-template>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </modal-panel>
</form>
