<div id="delete-alert-dialog-confirmpopup" class="confirmpopup">
    <div id="-delete-alert-dialog-dialog-title" mat-dialog-title>
      <label id="delete-alert-dialog-title-label" class="title">Delete Alert:</label>
    </div>
    <hr id="delete-alert-dialog-first-hr">
    <div id="delete-alert-dialog-dialog-content" mat-dialog-content>
      <p id="delete-alert-dialog-content-p">Are you sure you want to delete this alert?</p>
      <p id="delete-alert-dialog-content-p2">{{alertData.alertName}}</p>
    </div>
    <hr id="delete-alert-dialog-second-hr">
    <div id="delete-alert-dialog-dialog-actions" mat-dialog-actions style="float: right;">
      <button
        id="delete-alert-dialog-ok-button"
        type="submit" mat-raised-button
        (click)="okClick()"
        color="primary"
        cdkFocusInitial matTooltip="Delete alert"
        aria-label="Delete Alert">
          <span id="delete-alert-dialog-ok-span">Ok</span>
      </button>
      <button
        id="delete-alert-dialog-no-button"
        mat-raised-button
        (click)="onNoClick()"
        color="warn">
        No
      </button>
    </div>
  </div>
  