import {Component, OnInit, Optional} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {map, Observable} from "rxjs";
import {BankModel, BranchModel} from "src/app/shared/model/bank";
import {CurrencyModel, PayMethodModel, PayTypeResponse, ProfileTypeModel, SubmitToModel} from "src/app/shared/model/pay";
import {PayService} from "src/app/shared/service/pay/pay.service";
import {autocompleteFormFilter, replayHttpQuery} from "src/infrastructure/helper/autocomplete-form-filter";

@Component({
    selector: 'payment-form',
    templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent implements OnInit{
    paymentForm!: FormGroup;

    payTypeList$?:Observable<PayTypeResponse>;

    filteredBank$?: Observable<BankModel[]>;
    bankList$!:Observable<BankModel[]>;
    displayWithBank = (bank:BankModel)=> {
        return bank?.name || '';
    };

    filteredBranch$?: Observable<BranchModel[]>;
    branchList$!:Observable<BranchModel[]>;
    displayWithBranch = (branch:BranchModel)=> {
        return branch?.name || '';
    };

    filteredPayMethod$?: Observable<PayMethodModel[]>;
    payMethodList$!:Observable<PayMethodModel[]>;
    displayWithPayMethod = (payMethod:PayMethodModel)=> {
        return payMethod?.paymethodName || '';
    };

    filteredProfileType$?: Observable<ProfileTypeModel[]>;
    profileType$!:Observable<ProfileTypeModel[]>;
    displayWithProfileType = (profileType:ProfileTypeModel)=> {
        return profileType?.profileTypeName || '';
    };

    filteredSubmitTo$?:Observable<SubmitToModel[]>;
    submitToList$!:Observable<SubmitToModel[]>;
    displayWithSubmitTo = (submitTo:SubmitToModel)=> {
        return submitTo?.submitToLink || '';
    };

    filteredCurrency$?:Observable<CurrencyModel[]>;
    currencyList$!:Observable<CurrencyModel[]>;
    displayWithCurrencyList = (currency:CurrencyModel)=> {
        return currency?.currencyName 
            ? `${currency?.currencyName}${currency?.symbol ? ' ('+currency?.symbol+')' : ''}`
            : '';
    };

    autocompleteMode: 'A' | 'I' = 'A';

    constructor(
        @Optional() public paymentFormDialogRef: MatDialogRef<PaymentFormComponent>,
        private fb: FormBuilder,
        private payService: PayService,
        private router: Router,
    ){
        this.paymentForm = this.fb.group({ 
            amount: [null, Validators.required],
            payType: [null, Validators.required],
            bank: [null, Validators.required],
            branch: [null, Validators.required],
            payMethod: [null, Validators.required],
            profileType: [null, Validators.required],
            submitTo: [null, Validators.required],
            paymentDate: [null, Validators.required],
            invoiceReference: [null, Validators.required],
            remarks: [null, Validators.required],
            number: [null, Validators.required],
            name: [null, Validators.required],
            currency: [null, Validators.required],
        });
    }

    ngOnInit(): void {
        this.payTypeList$ = this.payService.getPayType({all: true});

        this.bankList$ = replayHttpQuery(this.payService.getBanks({all: true}));

        this.branchList$ = replayHttpQuery(this.payService.getBranches({all: true}));

        this.payMethodList$ = replayHttpQuery(this.payService.getPayMethod({all: true}));

        this.profileType$ = replayHttpQuery(this.payService.getProfileType({all: true})).pipe(
            map((response: ProfileTypeModel[])=>{
                return response.filter(
                    (item)=> ['A','I',].indexOf(item.profileTypeCode) >= 0
                );
            }
        ));

        this.submitToList$ = replayHttpQuery(this.payService.getSubmitTo({all: true}));

        this.currencyList$ = replayHttpQuery(this.payService.getCurrency({all: true})),


        this.setAutocompleteComponents();
        this.setPayType();
    }

    setAutocompleteMode(profileType: ProfileTypeModel): void {
        if(profileType.profileTypeCode === 'I') {
            this.autocompleteMode = 'I';
            return;
        }

        this.autocompleteMode = 'A';
    }

    createPayment(): void {
        console.log(this.paymentForm.getRawValue());
        this.closePaymentForm();
    }

    closePaymentForm(): void {
        if (this.paymentFormDialogRef) {
            this.paymentFormDialogRef.close();
        }
    }

    clearFields(...args:string[]): void {
        if(args.length) {
            args.forEach((field)=>{
                this.paymentForm.controls[field].reset();
            });
        } else {
            this.paymentForm.reset();
        }
        this.setPayType();
        this.setAutocompleteComponents();
    }

    checkInput(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }

    checkInputAmount(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            if (charCode !== 46 ) {
                event.preventDefault();
            }
        }
    }

    setPayType(): void {
        const link = this.router.url;
        const payTypeControl = this.paymentForm.controls['payType'];

        if(link.includes('Payments/Received')){
            payTypeControl.setValue(1);
            payTypeControl.disable();
        }

        if(link.includes('Payments/Made')){
            payTypeControl.setValue(2);
            payTypeControl.disable();
        }
    }

    setAutocompleteComponents(): void {
        this.filteredBank$ = autocompleteFormFilter(this.paymentForm.controls['bank'], 'name', this.bankList$);

        this.filteredBranch$ = autocompleteFormFilter(this.paymentForm.controls['branch'],'name', this.branchList$);

        this.filteredPayMethod$ = autocompleteFormFilter(this.paymentForm.controls['payMethod'], 'paymethodName', this.payMethodList$);
        
        this.filteredProfileType$ = autocompleteFormFilter(this.paymentForm.controls['profileType'], 'profileTypeName', this.profileType$);
        
        this.filteredSubmitTo$ = autocompleteFormFilter(this.paymentForm.controls['submitTo'], 'submitToLink', this.submitToList$);
        
        this.filteredCurrency$ = autocompleteFormFilter(this.paymentForm.controls['currency'], 'currencyName', this.currencyList$);
    }
}