
<ng-container>
    @if (!(loading$ | async)) {
    <form data-testid="payment-tab-detail-form" class="template-form payment-tab-detail-form">  
      <div data-testid="payment-tab-detail-main-container" class="container">
        <mat-tab-group dynamicHeight (selectedTabChange)="tabChanged($event)">
            @for (payment of payments; track payment) {
            <mat-tab label="Payment {{payment.id}}">
                <mat-card class="mat-elevation-z8">
                    <mat-card-content>
                        @if(payment.ckCcNo !== undefined && payment.ckCcNo !== null){
                            <read-only-form-field title="Check No" testId="payment-tab-detail-ckCcNo" value="{{payment.ckCcNo}}" />
                        }
                        @if(payment.amount !== undefined && payment.amount !== null){
                            <read-only-form-field title="Amount" testId="payment-tab-detail-amount" value="{{payment.amount}}" />
                        }
                        @if(payment.remarks !== undefined && payment.remarks !== null){
                            <read-only-form-field title="Remarks" testId="payment-tab-detail-remarks" value="{{payment.remarks}}" />
                        }
                        @if(payment.paymentDate !== undefined && payment.paymentDate !== null){
                            <read-only-form-field title="Payment Date" testId="payment-tab-detail-payment-date" value="{{payment.paymentDate | date :'MM/dd/yyyy'}}" />
                        }
                        @if(payment.paymentMethod !== undefined && payment.paymentMethod !== null){
                            <read-only-form-field title="Payment Method" testId="payment-tab-detail-method" value="{{payment.paymentMethod}}" />
                        }
                    </mat-card-content>
                </mat-card>
            </mat-tab>
            }
        </mat-tab-group>
      </div>
    </form>
    }
</ng-container>
