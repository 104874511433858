<ng-container data-testid="split-rate-info-container">
  <div data-testid="split-rate-info-content" >
    <mat-table data-testid="split-rate-info-table" [dataSource]="splitRateList!" (matSortChange)="handleSortChange($event)" matSort>
      <ng-container matColumnDef="rate">
        <mat-header-cell data-testid="split-rate-info-rate-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
          Split Rate
        </mat-header-cell>
        <mat-cell data-testid="split-rate-info-rate-cell" class="mat-cell" *matCellDef="let element">
          <span>{{element?.rate}}/{{100-element.rate}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="validFrom">
          <mat-header-cell data-testid="split-rate-info-valid-from-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
              Valid From
          </mat-header-cell>
          <mat-cell data-testid="split-rate-info-valid-from-cell" class="mat-cell" *matCellDef="let element">
            <span>{{element?.validFrom | date: 'M/d/yyyy'}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validTo">
          <mat-header-cell data-testid="split-rate-valid-to-rate-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
              Valid To
          </mat-header-cell>
          <mat-cell data-testid="split-rate-info-valid-to-cell" class="mat-cell" *matCellDef="let element">
            <span>{{element?.validTo | date: 'M/d/yyyy'}}</span>
          </mat-cell>
        </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />
      <mat-row *matRowDef="let row; columns: displayedColumns; let i = index" />
    </mat-table>
    <mat-paginator data-testid="payments-made-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
        [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
    </mat-paginator>
  </div>
