import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {Observable, Subscription, take} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {VendorBalanceFilter} from '../../models/vendor-balances';
import {AdxBookingService} from 'src/app/adx-booking/service/adx-booking.service';
import {PayStatusesResponse} from 'src/app/adx-booking/model/pay-statuses-response';
import {VendorBalanceService} from '../../service/vendor-balance.service';
import {TramsDataSourceService} from '../../service/trams-data-source.service';
import {TramsDataSourceModel} from '../../models/trams-data-source';

@AutoUnsubscribe()
@Component({
    selector: 'vendor-balance-filter',
    templateUrl: './vendor-balance-filter.component.html',
})
export class VendorBalanceFilterComponent implements OnInit {
    @Input() config!:VendorBalanceFilter;

    vendorBalanceFilterForm!:FormGroup;

    payStatusList$?: Observable<PayStatusesResponse[]>
    tramsDataSourceList$?: Observable<TramsDataSourceModel[]>
    breakPoint$?:Subscription;
    isSmall: boolean = false;

    constructor(
        @Optional() public dialogRef: MatDialogRef<VendorBalanceFilterComponent>,
        private formBuilder:FormBuilder,
        private adxBookingService: AdxBookingService,
        private vendorBalanceService: VendorBalanceService,
        private tramsDataSourceService: TramsDataSourceService,
        breakpointObserver: BreakpointObserver,
    ){
        this.vendorBalanceFilterForm = this.formBuilder.group({
            paymentDate: null,
            vendorName: null,
            minOutstandingBalance: null,
            maxOutstandingBalance: null,
            minTotalBilled: null,
            maxTotalBilled: null,
            dataSourceId: null,
            vendorPayStatusId: null,
        });

        this.breakPoint$ = breakpointObserver.observe([
            Breakpoints.Medium,
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmall = result.matches;
        });
        
    }

    ngOnInit(): void {
        this.vendorBalanceFilterForm.patchValue(this.config || {});

        this.vendorBalanceService.getVendorBalanceFilter()
        .pipe(take(1))
        .subscribe((filter)=>{
            this.vendorBalanceFilterForm.patchValue({...this.config, ... filter});
        });

        this.payStatusList$ = this.adxBookingService.getPayStatuses();
        this.tramsDataSourceList$ = this.tramsDataSourceService.getTramsDataSourceList();
    }

    applyFilters(): void {
        this.config = this.vendorBalanceFilterForm.value;
        this.config.vendorName = this.config.vendorName?.trim();
        this.config.minOutstandingBalance = this.config.minOutstandingBalance?.trim();
        this.config.maxOutstandingBalance = this.config.maxOutstandingBalance?.trim();
        this.config.minTotalBilled = this.config.minTotalBilled?.trim();
        this.config.maxTotalBilled = this.config.maxTotalBilled?.trim();
        this.dialogRef.close(this.config);
    }

    handleBookingFiltersFormCloseEvent(): void {
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }

    clearFields(...args:string[]): void {
        if(!args.length) {
            this.vendorBalanceFilterForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.vendorBalanceFilterForm.controls[field].reset()
        })
    }

    checkNumberInput(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            if (charCode!== 45 && charCode !== 46 ) {
                event.preventDefault();
            }
        }
    }
}
