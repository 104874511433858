<div id="business-errors-dialog-confirmpopup" class="confirmpopup">
    <div id="-business-errors-dialog-dialog-title" mat-dialog-title>
      <label id="business-errors-dialog-title-label" class="title">Business Errors:</label>
    </div>
    <hr id="business-errors-dialog-first-hr">
    <div id="business-errors-dialog-content" mat-dialog-content>
        <ul id="business-errors-dialog-error-list">
            @for (error of errors; track error) {
                <li>{{error}}</li>
            }
        </ul>
    </div>
    <hr id="business-errors-dialog-second-hr">
    <div id="business-errors-dialog-dialog-actions" mat-dialog-actions style="float: right;">
      <button
        id="business-errors-dialog-close-button"
        type="submit" mat-raised-button
        (click)="okClick()"
        color="primary"
        cdkFocusInitial
        aria-label="close dialog">
          <span id="delete-alert-dialog-close-span">Close</span>
      </button>
    </div>
  </div>
  