import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {map, Observable, of, shareReplay, startWith, switchMap} from "rxjs";

export const autocompleteFormFilter = (formControl: AbstractControl, nameProp:string, listObservable: Observable<any[]>): Observable<any> => {
    return formControl.valueChanges.pipe(
        startWith(''),
        switchMap((model:string | Record<string, any>) => {
            return listObservable.pipe(
                map((list) => {
                    return list?.filter((element) =>{                        
                            if(typeof model === 'string'){
                                return element[nameProp]?.toLowerCase().includes(model?.toLowerCase())
                            } else {
                                return element === model;
                            }
                        }
                    );
                }),
            );
        })
    );
}

export const replayHttpQuery = (getObservable: Observable<any>): Observable<any>=>{
    return getObservable.pipe(
        shareReplay(),
        map((res)=>{
            return res.items;
        },
    ));
}

export function autocompleteAsyncValidator(optionsObservable: Observable<any[]>): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        if (!control.value) {
          return of(null);
        }
    
        return optionsObservable.pipe(
          switchMap(options => {
            if(!options){
                of({invalidOption: { value: 'no options' }})
            }
            const isValidOption = options.includes(control.value);
            return isValidOption ? of(null) : of({ invalidOption: { value: control.value } });
          })
        );
      };
  }