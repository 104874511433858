<form class="filter" [formGroup]="braintreeMatchingFilterForm" (submit)="applyBaintreeMatchingFilters()">
    <modal-panel
        data-testid="braintree-matching-vcc-filter-modal-panel"
        class="braintree-matching-vcc-filter-modal-panel-cls filter-panel"
        title="Filter"
        (close)="closeForm();"
        >
            <div data-testid="braintree-matching-vcc-filter-modal-div-2" class="filter__fields">
                <mat-grid-list class="modal-filter-col-flex" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Release date from</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="releasedDateFrom">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(braintreeMatchingFilterForm.controls['releasedDateFrom'].value){
                                <button 
                                    data-testid="braintree-matching-vcc-release-date-from-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearMatchingFields('releasedDateFrom')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Release date to</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="releasedDateTo">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker #picker2></mat-datepicker>
                            @if(braintreeMatchingFilterForm.controls['releasedDateTo'].value){
                                <button 
                                    data-testid="braintree-matching-vcc-release-date-to-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearMatchingFields('releasedDateTo')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Last four cc</mat-label>
                            <input matInput placeholder="Last four cc" value="" formControlName="lastFourCC" maxlength="4" (keypress)="checkInput($event)">
                            @if(braintreeMatchingFilterForm.controls['lastFourCC'].value){
                                <button 
                                    data-testid="braintree-matching-vcc-last-four-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearMatchingFields('lastFourCC')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Adx Trip reference</mat-label>
                            <input matInput placeholder="Adx Trip Reference" value="" formControlName="adxTripReference">
                            @if(braintreeMatchingFilterForm.controls['adxTripReference'].value){
                                <button 
                                    data-testid="braintree-matching-vcc-adx-trip-reference-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearMatchingFields('adxTripReference')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="1" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile>
                        <p style="width: 25vw;">Matched VCC's</p>
                        <mat-radio-group formControlName="isMatched" aria-label="is-matched" [color]="'primary'" style="width: 100%;">
                            <mat-radio-button [value]="true">Yes</mat-radio-button>
                            <mat-radio-button [value]="false">No</mat-radio-button>
                        </mat-radio-group>
                        <button 
                            data-testid="braintree-matching-vcc-is-matched-clear-filter-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearMatchingFields('isMatched')">
                                <mat-icon>close</mat-icon>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
            <ng-container data-testid="braintree-matching-vcc-modal-footer-container" modalPanelFooter>
                <div class="modal-footer">
                    <button data-testid="braintree-matching-vcc-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearMatchingFields()">
                        <mat-icon data-testid="braintree-matching-vcc-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                        Clear All
                    </button>
                    <button data-testid="braintree-matching-vcc-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
                </div>
            </ng-container>
    </modal-panel>
    </form>
