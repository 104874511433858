import {AfterViewChecked, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {IConversationMessage} from '../../model/conversation-message';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html'
})
export class ConversationComponent implements AfterViewChecked {
  @ViewChild('conversationContainer', {static: true}) conversationContainer!: ElementRef<HTMLDivElement>;

  @Input() messages: IConversationMessage[] = [];

  ngAfterViewChecked(): void {
    const calculatedTop = this.conversationContainer.nativeElement.scrollHeight
      - this.conversationContainer.nativeElement.clientHeight;
    if (calculatedTop != this.conversationContainer.nativeElement.scrollTop)
      this.conversationContainer.nativeElement.scrollTop = calculatedTop;
  }
}
