<form data-testid="braintree-matching-vcc-edit-modal-form" class="filter" [formGroup]="dynamicFormGroup!" (submit)="updateAmounts()">
  <modal-panel title="Services"
               (close)="closeModal()"
               data-testid="braintree-matching-vcc-edit-modal-panel"
               class="filter-panel">
    <div class="filter__fields" [ngClass]="{'loading-spinner': !loadedData || !dataSource.length}">
      <ng-container *ngIf="!loadedData; else loadedContent">
        <mat-spinner data-testid="braintree-matching-vcc-edit-modal-panel-loading-spinner"></mat-spinner>
      </ng-container>

      <ng-template #loadedContent>
        <div class="braintree-matching-vcc-modal-container mat-elevation-z8" data-testid="braintree-matching-vcc-edit-modal-panel-container">
          <mat-table [dataSource]="dataSource" matSort data-testid="braintree-matching-vcc-edit-modal-panel-table" class="responsive-table">

            <ng-container matColumnDef="tramsInvoiceNumber">
              <mat-header-cell data-testid="braintree-matching-vcc-edit-invoice-number-header" class="mat-header-cell" *matHeaderCellDef>Trams Invoice Number</mat-header-cell>
              <mat-cell data-testid="braintree-matching-vcc-edit-invoice-number-cell" *matCellDef="let payment">{{ payment.tramsInvoiceNumber }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="tramsBookingNo">
              <mat-header-cell data-testid="braintree-matching-vcc-edit-adx-booking-no-header" class="mat-header-cell" *matHeaderCellDef>Booking No</mat-header-cell>
              <mat-cell data-testid="braintree-matching-vcc-edit-adx-booking-no-cell" *matCellDef="let payment">{{ payment.tramsBookingNo }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="tramsBookingTravelTypeName">
              <mat-header-cell data-testid="braintree-matching-vcc-edit-trams-booking-travel-type-name-header" class="mat-header-cell" *matHeaderCellDef>Travel Type</mat-header-cell>
              <mat-cell data-testid="braintree-matching-vcc-edit-trams-booking-travel-type-name-cell" *matCellDef="let payment">{{ payment.tramsBookingTravelTypeName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="tramsBookingVendorName">
              <mat-header-cell data-testid="braintree-matching-vcc-edit-trams-booking-vendor-name-header" class="mat-header-cell" *matHeaderCellDef>Vendor Name</mat-header-cell>
              <mat-cell data-testid="braintree-matching-vcc-edit-trams-booking-vendor-name-cell" *matCellDef="let payment">{{ payment.tramsBookingVendorName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="totalFare">
              <mat-header-cell data-testid="braintree-matching-vcc-edit-amount-header" class="mat-header-cell" *matHeaderCellDef>Amount</mat-header-cell>
              <mat-cell data-testid="braintree-matching-vcc-edit-amount-cell" *matCellDef="let payment" class="responsive-cell">
                <mat-form-field floatLabel="always" class="responsive-form-field">
                  <mat-label></mat-label>
                  <input matInput placeholder="{{payment.totalFare}}" [formControlName]="formControlNames[payment.tramsBookingId].totalFare">
                </mat-form-field>
              </mat-cell>
            </ng-container>

            <mat-header-row data-testid="braintree-matching-vcc-edit-modal-header-row" *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row data-testid="braintree-matching-vcc-edit-modal-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </mat-table>

          <mat-paginator data-testid="braintree-matching-vcc-edit-modal-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
                         [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
          </mat-paginator>
        </div>
      </ng-template>
    </div>

    <ng-container modalPanelFooter data-testid="braintree-matching-vcc-edit-modal-panel-footer">
      <div class="modal-footer">
        <button mat-button mat-stroked-button color="primary" type="button" (click)="closeModal()" data-testid="braintree-matching-vcc-edit-modal-panel-close-button">Close</button>
        <ng-container *ngIf="loadedData && dataSource.length">
          <button data-testid="braintree-matching-vcc-edit-modal-submit-button" mat-raised-button color="primary" type="submit" [disabled]="isDisabled()">
            <ng-container *ngIf="!submitedData; else submitText">
              <mat-spinner color="accent" diameter="30"></mat-spinner>
            </ng-container>
            <ng-template #submitText>Submit</ng-template>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </modal-panel>
</form>
