import {Component} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ModalService} from "src/app/shared/service/modal/modal.service";
import {NavExportSendModalComponent} from "../nav-export-send-modal/nav-export-send-modal.component";

@Component({
  selector: 'app-nav-export',
  templateUrl: './nav-export.component.html',
})
export class NavExportComponent {

    navForm!:FormGroup;

    constructor(
        private formBuilder:FormBuilder,
        private modalService: ModalService,
    ){
        this.navForm = this.formBuilder.group({
            startDate: null,
            endDate: null,
        });
    }

    clearFormFields(...args:string[]){
        args.forEach((field)=>{
            this.navForm.controls[field].reset()
        })
    }

    submitForm(){
        this.modalService.open(NavExportSendModalComponent);
    }
}