import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Component, Input, OnInit, Optional} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {AdxPaymentService} from "../../service/adx-payment.service";
import * as moment from "moment";
import {PaymentReconciliationFilter} from "../../models/client-reconciliation";

@Component({
    selector: 'payments-reconciliation-filter',
    templateUrl: './payments-reconciliation-filter.component.html',
})
export class PaymentReconciliationFilterComponent implements OnInit{
    @Input() config!: PaymentReconciliationFilter;

    paymentsReconFilterForm!: FormGroup;

    breakPoint$?:Subscription;
    isSmall: boolean = false;

    constructor(
        @Optional() public paymentsReconDialogRef: MatDialogRef<PaymentReconciliationFilterComponent>,
        breakpointObserver: BreakpointObserver,
        private formBuilder:FormBuilder,
        private adxPaymentsService: AdxPaymentService,
    ){
        this.paymentsReconFilterForm = this.formBuilder.group({
            reconType: null,
            processorIds: [null, Validators.required],
            adxReference: null,
            lastFourCC: null,
            isMatched: null,
            releaseDateFrom: null,
            releaseDateTo: null,
        });

        this.breakPoint$ = breakpointObserver.observe([
            Breakpoints.Medium,
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmall = result.matches;
        });
    }

    ngOnInit(): void {
        this.adxPaymentsService.getPaymentsReconciliationFilter().subscribe((filter)=>{
            this.config = filter;
            this.paymentsReconFilterForm.patchValue(this.config || {});
        });
    }

    applyFilters(): void {
        this.config = this.paymentsReconFilterForm.value;
        this.config.adxReference = this.config.adxReference?.trim();
        this.config.lastFourCC = this.config.lastFourCC;
        this.config.releaseDateFrom = this.config.releaseDateFrom? moment(this.config.releaseDateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
        this.config.releaseDateTo = this.config.releaseDateTo? moment(this.config.releaseDateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
        this.paymentsReconDialogRef.close(this.config);
    }

    closeModal(): void {
        if (this.paymentsReconDialogRef) {
            this.paymentsReconDialogRef.close();
        }
    }

    clearFields(...args:string[]): void {
        if(!args.length) {
            this.paymentsReconFilterForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.paymentsReconFilterForm.controls[field].reset()
        })
    }

    checkInput(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }

}