import {Component, Input} from '@angular/core';

@Component({
  selector: 'widget-info',
  templateUrl: './widget-info.component.html'
})
export class WidgetInfoComponent {

  @Input() title!: string;
  @Input() text!: string;
  @Input() textSub!: string;
  @Input() icon!: string;
  @Input() color: 'success' | 'danger' | 'info' | 'caution' | 'primary-300' = 'success';

  get cssClass() {
    let colorStyle = `widget-${this.color}`;
    return [colorStyle];
  }

}
