
<div class="justify-center">
    <mat-card class="mat-elevation-z8 modal-dialog-pane">
        <mat-card-content>
            <h2 data-testid="nav-export-title">[MOCK] Nav Export</h2>
            <form class="filter" [formGroup]="navForm" (submit)="submitForm()">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="picker1" formControlName="startDate">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    @if(navForm.controls['startDate'].value){
                        <button 
                            data-testid="nav-export-start-date-clear-filter-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFormFields('startDate')">
                                <mat-icon>close</mat-icon>
                        </button>
                    } @else {
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    }
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
    
                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="endDate">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    @if(navForm.controls['endDate'].value){
                        <button 
                            data-testid="nav-export-end-date-clear-filter-button"
                            matSuffix mat-icon-button
                            type="button"
                            (click)="clearFormFields('endDate')">
                                <mat-icon>close</mat-icon>
                        </button>
                    } @else {
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    }
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
    
                <div class="justify-center">
                    <div class="items-space-around w-100">
                        <button data-testid="" mat-stroked-button color="primary" type="button">Show Report</button>
                        <button data-testid="" mat-raised-button color="primary" type="submit">Send To Nav</button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
