import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AirMatchingReportService} from '../../service/air-matching-report.service';
import {take} from 'rxjs';
import {AirMatchingReportFilter} from '../../model/air-matching-report';

@AutoUnsubscribe()
@Component({
    selector: 'air-matching-report-filter',
    templateUrl: './air-matching-report-filter.component.html',
})
export class AirMatchingReportFilterComponent implements OnInit {
    @Input() config!:AirMatchingReportFilter;

    filterForm!:FormGroup;

    constructor(
        @Optional() public dialogRef: MatDialogRef<AirMatchingReportFilterComponent>,
        private formBuilder:FormBuilder,
        private airMatchingReportService: AirMatchingReportService,
    ){
        this.filterForm = this.formBuilder.group({
            adxReference: null,
            sabreReference: null,
            sabreInvoiceIssueDateFrom: null,
            sabreInvoiceIssueDateTo: null,
        });
        
     }

    ngOnInit(): void {
        this.airMatchingReportService.getAirMatchingFilter()
        .pipe(take(1))
        .subscribe((filter: AirMatchingReportFilter) => {
            this.config = filter;
            this.filterForm.patchValue(this.config || {});
        });
    }

    applyFilters(){
        this.config = this.filterForm.value;
        this.config.adxReference = this.config.adxReference?.trim();
        this.config.sabreReference = this.config.sabreReference?.trim();
        this.closeModal(this.config)
    }

    closeModal(config?: AirMatchingReportFilter) {
        this.dialogRef.close(config);
    }

    clearFields(...args:string[]){
        if(!args.length) {
            this.filterForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.filterForm.controls[field].reset()
        })
    }
}
