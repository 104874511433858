<div datatest-id="update-air-matching-report-dialog-confirmpopup" class="confirmpopup">
    <div datatest-id="update-air-matching-report-dialog-title" mat-dialog-title>
      <label datatest-id="update-air-matching-report-dialog-title-label" class="title">Approve Update Air Matching Report: Confirmation</label>
    </div>
    <hr datatest-id="update-air-matching-report-dialog-first-hr">
    <div datatest-id="update-air-matching-report-dialog-content" mat-dialog-content>
      <p datatest-id="update-air-matching-report-dialog-content-p1">Are you sure you want to create in TRAMS invoices with negative amount of selected record?</p>
    </div>
    <hr datatest-id="update-air-matching-report-dialog-second-hr">
    <div datatest-id="update-air-matching-report-dialog-dialog-actions" mat-dialog-actions style="float: right;">
      <button
        datatest-id="update-air-matching-report-dialog-ok-button"
        type="submit" mat-raised-button
        (click)="okClick()"
        color="primary"
        cdkFocusInitial matTooltip="Continue update process"
        aria-label="Continue update process"
        [disabled]="isLoading"
      >
        @if(isLoading){
          <mat-spinner
            datatest-id="update-air-matching-report-dialog-spinner"
            color="accent"
            diameter="30"
            >
          </mat-spinner>
        } @else {
          <span datatest-id="update-air-matching-report-dialog-ok-span">Ok</span>
        }
      </button>
      <button
        datatest-id="updte-air-matching-report-dialog-no-button"
        mat-raised-button
        (click)="onNoClick()"
        color="warn"
        [disabled]="isLoading">
          No
      </button>
    </div>
  </div>
  