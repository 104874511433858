import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {ConfirmationDialogVccData} from 'src/app/virtual-credit-card/model/confirmation-dialog-vcc-data';
import {PaymentGatewayService} from 'src/app/virtual-credit-card/service/payment-gateway-service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-confirmation-dialog-update-vcc',
  templateUrl: './confirmation-dialog-update-vcc.component.html'
})
export class ConfirmationDialogUpdateVccComponent implements OnInit {
  isLoadingSpinner: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogUpdateVccComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogVccData,
    private paymentGatewayService: PaymentGatewayService,
    private _snackBar: MatSnackBar,
    public loader: LoadingService
  ) {
  }
  ngOnInit(): void {
    this.loader.hide();
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  okClick(): void {
    this.isLoadingSpinner = true;
    this.paymentGatewayService.updateDbov2PaymentGatewayVccService(this.data)
    .subscribe({
      next: (_) => {
        this.isLoadingSpinner = false;
        this.dialogRef.close(true);
        this._snackBar.open(`Successfully virtual credit card is updated for the service ID:'${this.data.adxBookingId}'.\n
                            With the amount limit of '${_.amountLimit}'.`, 'Dismiss', {
          duration: 0,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['message-position', 'app-notification-success']
        });
      },
      error: () => {
        this.isLoadingSpinner = false;
        this.dialogRef.close(false);
      }
    });
  }
}
