import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'maskedCard',
  standalone: true
})
export class MaskedCardPipe implements PipeTransform {

  transform(card?: string, replace: string = '*'): string {
    return card ? `${replace}${card.slice(card.length - 4, card.length)}` : '';
  }

}
