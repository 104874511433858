import {Card} from './card';
import {ResponseCard} from './response-card';

export class RequestCard implements Card {
  prompt: string = '';
  status: 'waiting' | 'done' | 'error' = 'waiting';
  responseCard: ResponseCard | null = null;

  get kind(): 'request' | 'response' {
    return 'request';
  }

  get content(): string {
    return this.prompt;
  }
}
