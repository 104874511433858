import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {AppConfigurationClient, GetConfigurationSettingResponse} from '@azure/app-configuration';
import {from, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AzureAppConfigurationService {

  appConfigurationClient: AppConfigurationClient | undefined;

  constructor() {
    this.appConfigurationClient = new AppConfigurationClient(environment.appConfigConnectionString);
  }

  public getConfigurationSetting(key: string): Observable<GetConfigurationSettingResponse | undefined> {
    return from(
      Promise.resolve(
        this.appConfigurationClient?.getConfigurationSetting({key: `.appconfig.featureflag/${key}`})
      )
    );
  }

  public async getByKey(key: string): Promise<KeyValuePair<string | undefined, string | undefined> | void | undefined> {
    if (!key) {
      return undefined;
    }

    return this.appConfigurationClient
      ?.getConfigurationSetting({key: key})
      .then(value => this.ToKeyValuePair(value));
  }

  public ToKeyValuePair(response: GetConfigurationSettingResponse): KeyValuePair<string | undefined, string | undefined> {
    return {
      key: response.key,
      value: response.value
    }
  }

  public async InitializeAsync(): Promise<void> {
    const promises = [
      this.getByKey('clientId'),
      this.getByKey('authority'),
      this.getByKey('cacheLocation'),
      this.getByKey('graphApiUrl'),
      this.getByKey('fmsApiScope'),
      this.getByKey('graphApiScope'),
      this.getByKey('instrumentationKey'),
      this.getByKey('healthCheckInterval'),
      this.getByKey('openAiApiKey'),
      this.getByKey('openAiEndpoint'),
      this.getByKey('openAiDeploymentName')
    ];
    if (environment.environmentName !== 'Local') {
      promises.push(this.getByKey('apiUrl'));
      promises.push(this.getByKey('dboV2ApiUrl'));
      promises.push(this.getByKey('environmentName'));
      promises.push(this.getByKey('postLogoutUrl'));
      promises.push(this.getByKey('redirectUri'));
      promises.push(this.getByKey('openAiApiKey'));
      promises.push(this.getByKey('openAiEndpoint'));
      promises.push(this.getByKey('openAiDeploymentName'));
    }

    await Promise.all(promises).then(result => {
      for (let environmentKey in environment) {
        for (let resultElement of result) {
          if (resultElement?.key === environmentKey) {
            // @ts-ignore
            environment[environmentKey] = resultElement.value;
          }
        }
      }
    });
  }
}

interface KeyValuePair<TKey, TValue> {
  key: TKey;
  value: TValue;
}
