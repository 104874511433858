import {Injectable} from '@angular/core';
import {IConversationMessage} from '../model/conversation-message';
import {OpenAiService} from '../service/open-ai.service';
import {RequestCard} from '../model/request-card';
import {ResponseCard} from "../model/response-card";

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  public messages: IConversationMessage[] = [];

  constructor(
    private openAiService: OpenAiService) {
  }

  public submitPrompt(userInput: string): Promise<ResponseCard | undefined> {
    const reqCard = new RequestCard;
    reqCard.prompt = userInput;
    reqCard.status = 'waiting';

    return this.openAiService.createChatCompletion([reqCard]);
  }
}
