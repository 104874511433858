import {Component, OnInit} from '@angular/core';
import {Location, PopStateEvent} from '@angular/common';
import {Router, NavigationEnd, NavigationStart} from '@angular/router';
import * as $ from 'jquery';
import {Subject, takeUntil} from 'rxjs';
import {AutoUnsubscribe} from '../infrastructure/decorators/auto-unsubscribe.decorator';
import {LoadingService} from './shared/service/loading.service';
import {SideNavMenu, SideNavMenuItem} from './shared/model/nav.model';
import {BreakpointService, Breakpoints} from './shared/service/breakpoint/breakpoint.service';
import {AzureAuthService} from '../infrastructure/services/azure-auth.service';
import {UserRoleConstants} from './shared/model/user-role.constants';
import {OpenAiService} from "./open-ai/service/open-ai.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
@AutoUnsubscribe()
export class AppComponent implements OnInit {
  private lastPoppedUrl?: string;
  private stack: number[] = [];
  title = 'admin-panel';
  loading$: any;
  private destroy$ = new Subject<void>();

  menuItems: SideNavMenu = [];

  //Do not remove health check service from constructor
  constructor(
    public location: Location,
    private router: Router,
    public loader: LoadingService,
    private azureAuthService: AzureAuthService,
    private bp: BreakpointService,
    public openAiService: OpenAiService) {
    this.azureAuthService.registerAuth(this.destroy$);
    this.azureAuthService.isUserLoggedIn.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isLoggedIn) => {
      this.setDashboardActionsVisibility(isLoggedIn);
    })
  }

  public ngOnInit(): void {
    this.loading$ = this.loader.loading$;

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.stack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.stack.pop()!);
        } else
          window.scrollTo(0, 0);
      }
    });

    const window_width = $(window).width();
    let $sidebar = $('.sidebar');
    let $sidebar_responsive = $('body > .navbar-collapse');
    let $sidebar_img_container = $sidebar.find('.sidebar-background');


    if (window_width! > 767) {
      if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
        $('.fixed-plugin .dropdown').addClass('open');
      }

    }

    $('.fixed-plugin a').click(function (event) {
      if ($(this).hasClass('switch-trigger')) {
        if (event.stopPropagation) {
          event.stopPropagation();
        } else if (window.event) {
          window.event.cancelBubble = true;
        }
      }
    });

    $('.fixed-plugin .badge').click(function () {
      $(this).siblings().removeClass('active');
      $(this).addClass('active');

      let new_color = $(this).data('color');

      if ($sidebar.length !== 0) {
        $sidebar.attr('data-color', new_color);
      }

      if ($sidebar_responsive.length != 0) {
        $sidebar_responsive.attr('data-color', new_color);
      }
    });

    $('.fixed-plugin .img-holder').click(function () {
      let $full_page_background = $('.full-page-background');

      $(this).parent('li').siblings().removeClass('active');
      $(this).parent('li').addClass('active');


      let new_image = $(this).find('img').attr('src');

      if ($sidebar_img_container.length != 0) {
        $sidebar_img_container.fadeOut('fast', function () {
          $sidebar_img_container.css('background-image', 'url("' + new_image + '")');
          $sidebar_img_container.fadeIn('fast');
        });
      }

      if ($full_page_background.length != 0) {

        $full_page_background.fadeOut('fast', function () {
          $full_page_background.css('background-image', 'url("' + new_image + '")');
          $full_page_background.fadeIn('fast');
        });
      }

      if ($sidebar_responsive.length != 0) {
        $sidebar_responsive.css('background-image', 'url("' + new_image + '")');
      }
    });
  }

  setDashboardActionsVisibility(isCompleted: boolean) {
    if(!isCompleted) {
        return;
    }

    this.handleAzureTokenWithoutRoles();

    const roles: Record<string, boolean> = {
      [UserRoleConstants.User]: this.azureAuthService.checkUserAzureTokenHasUserRole(),
      [UserRoleConstants.Manager]: this.azureAuthService.checkUserAzureTokenHasManagerRole(),
      [UserRoleConstants.Finance]: this.azureAuthService.checkUserAzureTokenHasFinanceRole(),
      [UserRoleConstants.Director]: this.azureAuthService.checkUserAzureTokenHasDirectorRole()
    }

    this.addHomePageNavLink();

    this.addDashboardNavLink(roles);

    this.addFinanceReportsNavLink(roles);

    this.addHelpSpotReportLink(roles);

    this.addInvoicesLink(roles);

    this.addPaymentsLink(roles);

    this.addFinanceResourcesLink(roles);

    this.addTstResourcesLink(roles);

    this.addTripsLink();

    this.addGeneralLedgerLink(roles);
  }

  private handleAzureTokenWithoutRoles() {
    if(!this.azureAuthService.checkUserAzureTokenHasAnyRole()) {
        this.router.navigate(['NoAccess']);
    }
  }

  private addHomePageNavLink() {
    let homePageNavLink: SideNavMenuItem = { path: '/Home/Home', title: 'Home', icon: 'home', id: 'home', idTag: 'home', isExpanded: this.router.url.includes('/Home') };
    this.menuItems.push(homePageNavLink);
  }

  private addDashboardNavLink(roles: Record<string, boolean>) {
    let dashboardNavLinks: SideNavMenuItem = { path: '/Dashboard', title: 'Dashboard', icon: 'dashboard', idTag:'dashboard', subMenu: [], isExpanded: this.router.url.includes('/Dashboard') };

    if(roles[UserRoleConstants.User] || roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]  || roles[UserRoleConstants.Director]) {
      dashboardNavLinks.subMenu?.push({ path: '/Dashboard/Transactions', title: 'Transactions', icon: 'account_balance', id: 'transactions', idTag: 'transactions', isExpanded: this.router.url.includes('/Dashboard/Transactions') });
    }
    if(roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]  || roles[UserRoleConstants.Director]) {
      dashboardNavLinks.subMenu?.push({ path: '/Dashboard/Vcc', title: 'VCC', icon: 'chart', id: 'vcc', idTag: 'vcc', isExpanded: this.router.url.includes('/Dashboard/Vcc') });
    }
    if (roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]){
      dashboardNavLinks.subMenu?.push({ path: '/Dashboard/Finance', title: 'Finance', icon: 'chart', id: 'finance', idTag: 'finance', isExpanded: this.router.url.includes('/Dashboard/Finance') });
    }

    if(dashboardNavLinks.subMenu && dashboardNavLinks.subMenu.length > 0) {
      this.menuItems.push(dashboardNavLinks);
    }
  }

  private addFinanceReportsNavLink(roles: Record<string, boolean>) {
    let financeNavLinks: SideNavMenuItem = { path: '/FinanceReports', title: 'Finance Reports', icon: 'account_balance', idTag: 'finanace-reports', subMenu: [], isExpanded: this.router.url.includes('/FinanceReports') };

    if(roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]) {
      financeNavLinks.subMenu = [
        /*  { path: '/FinanceReports/CommissionReports', title: 'Commission Reports', icon: '', id: 'commissionReports', idTag: 'commission-reports', isExpanded: this.router.url.includes('/FinanceReports/CommissionReports') },*/
       /*   { path: '/FinanceReports/CommissionQueue', title: 'Commission Queue', icon: '', id: 'commissionQueue', idTag: 'commission-queue', isExpanded: this.router.url.includes('/FinanceReports/CommissionQueue') },*/
         /* { path: '/FinanceReports/PaymentQueue', title: 'Payment Queue', icon: '', id: 'paymentQueue', idTag: 'payment-queue', isExpanded: this.router.url.includes('/FinanceReports/PaymentQueue') },*/
          { path: '/FinanceReports/VccDailySummaryReport', title: 'VCC Daily Summary Report', icon: '', id: 'VccDailySummaryReport', idTag: 'vcc-daily-summary-report', isExpanded: this.router.url.includes('/FinanceReports/VccDailySummaryReport') },
          { path: '/FinanceReports/TripProfitability', title: 'Trip Profitability', icon: '', id: 'tripProfitability', idTag: 'trip-profitability', isExpanded: this.router.url.includes('/FinanceReports/TripProfitability') }
        ];
    }
    if(roles[UserRoleConstants.Director]) {
      financeNavLinks.subMenu?.push({ path: '/FinanceReports/TripProfitabilityWithAgency', title: 'Trip Profitability With Agency', icon: '', id: 'tripProfitabilityWithAgency', idTag:'trip-profitability-with-agency'});
    }

    if(financeNavLinks.subMenu && financeNavLinks.subMenu.length > 0) {
        this.menuItems.push(financeNavLinks);
    }
  }

  private addHelpSpotReportLink(roles: Record<string, boolean>) {
    if(roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]  || roles[UserRoleConstants.Director]) {
      this.addMenuItem({
        icon: 'assessment',
        id: 'helpspot_reports',
        path: 'https://navigatrgroup.sharepoint.com/sites/maverick/hq/SitePages/Helpspot%20Transactions.aspx?xsdata=MDV8MDF8fDRiMDRmNTFjMTY1NDQxNDhkNzk2MDhkYjg5NTk2ODlkfDUxY2I1ZGY0ZGYwNTRlNWJiMjBjMTdkNTc2YWI4NWUzfDB8MHw2MzgyNTQ3ODkzMDMzMjY2MTR8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPbU00T0RnMlpqZzFMV0l6WkRrdE5HTm1OUzFpWkdVMkxUZGxaakEwTlRaaVlqYzVaRjlsTmpjek5EY3pOaTA0T1RVMUxUUmlORFl0WWpnMk5TMHlNMll5WTJObU9HWTNOakpBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVFk0T1RnNE1qRXlPVEExTnc9PXwyNTEwYmY0ZDE5M2I0NjJjZDc5NjA4ZGI4OTU5Njg5ZHwxMDUxZmE5MGIwMzA0ZWI2ODE5MzUyODkyYzMzNjFjYw%3d%3d&sdata=dzkvc29jSHh5UmhTVE9kWEMrVjhZNVk0M2pNelZCc3V6U0NqWFd5QUJtdz0%3d&ovuser=51cb5df4-df05-4e5b-b20c-17d576ab85e3%2cjeff.kwan%40navigatr.com&OR=Teams-HL&CT=1690818558756&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA3MDMwNzMzMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3d%3d&SafelinksUrl=https%3a%2f%2fnavigatrgroup.sharepoint.com%2fsites%2fmaverick%2fhq%2fSitePages%2fHelpspot%2520Transactions.aspx',
        title: 'Helpspot Reports',
        isExternal: true,
        idTag:'helpspot-reports'
      });
    }
  }

  private addInvoicesLink(roles: Record<string, boolean>) {
    let invoicesLinks: SideNavMenuItem = { path: '/Invoices', title: 'Invoices', icon: 'description', idTag: 'invoices', subMenu: [], isExpanded: this.router.url.includes('/Invoices') };

    if (roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]){
      invoicesLinks.subMenu = [
        { path: '/Invoices/AdxInvoiceLineItems', title: 'Pre-Trams invoice data sent by ADX', icon: '', id: 'adxInvoices', idTag: 'adx-invoices', isExpanded: this.router.url.includes('/Invoices/AdxInvoiceLineItems') },
        { path: '/Invoices/TramsInvoices', title: 'TRAMS Invoices', icon: '', id: 'tramsInvoices', idTag: 'trams-invoices', isExpanded: this.router.url.includes('/Invoices/TramsInvoices') }
      ];
    }

    if(invoicesLinks.subMenu && invoicesLinks.subMenu.length > 0) {
      this.menuItems.push(invoicesLinks);
    }
  }

  private addPaymentsLink(roles: Record<string, boolean>) {
    let paymentsLinks: SideNavMenuItem = { path: '/Payments', title: 'Payments', icon: 'paid', idTag: 'payments', subMenu: [], isExpanded: this.router.url.includes('/Payments') };

    if (roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]){
      paymentsLinks.subMenu = [
        { path: '/Payments/Received', title: 'Received', icon: '', id: 'paymentreceived', idTag: 'payment-received', isExpanded: this.router.url.includes('/Payments/Received') },
        { path: '/Payments/Made', title: 'Made', icon: '', id: 'paymentmade', idTag: 'payment-made', isExpanded: this.router.url.includes('/Payments/Made') },
        { path: '/Payments/Balances', title: 'Balances', icon: 'account_balance_wallet', id: 'paymentbalances', idTag: 'payment-balances', isExpanded: this.router.url.includes('/Payments/Balances'),
          subMenu: [
            { path: '/Payments/Balances/ClientBalance', title: 'Client Balance', icon: '', id: 'paymentbalancesclientbalance', idTag: 'payment-balances-client-balance', isExpanded: false },
            { path: '/Payments/Balances/VendorBalance', title: 'Vendor Balance', icon: '', id: 'paymentbalancesvendorbalance', idTag: 'payment-balances-vendor-balance', isExpanded: false },
            { path: '/Payments/Balances/ArcBspBalance', title: 'ARC BSP Balance', icon: '', id: 'paymentbalancesarcbspbalance', idTag: 'payment-balances-arc-bsp-balance', isExpanded: false }
          ]
         }
      ];
    }

    if(paymentsLinks.subMenu && paymentsLinks.subMenu.length > 0) {
      this.menuItems.push(paymentsLinks);
    }
  }

  private addFinanceResourcesLink(roles: Record<string, boolean>) {
    let financeResourcesLinks: SideNavMenuItem = { path: '/FinanceResources', title: 'Finance Resources', icon: 'support', idTag: 'financeresources', subMenu: [], isExpanded: this.router.url.includes('/FinanceResources') };

    if (roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]){
      financeResourcesLinks.subMenu = [
        { path: '/FinanceResources/AdxVcc', title: 'Issued VCC', icon: '', id: 'adxVcc', idTag: 'adx-vcc', isExpanded: this.router.url.includes('/FinanceResources/AdxVcc') },
        { path: '/FinanceResources/FmsVcc', title: 'Issued in FMS VCC', icon: '', id: 'fmsVcc', idTag: 'fms-vcc', isExpanded: this.router.url.includes('/FinanceResources/FmsVcc') },
        { path: '/FinanceResources/MatchingVcc', title: 'Matching VCC', icon: '', id: 'matchingVcc', idTag: 'matching-vcc', isExpanded: this.router.url.includes('/FinanceResources/MatchingVcc') },
        { path: '/FinanceResources/MatchingVccV2', title: 'Matching VCC V2', icon: '', id: 'matchingVccV2', idTag: 'matching-vcc-v2', isExpanded: this.router.url.includes('/FinanceResources/MatchingVccV2') },
        { path: '/FinanceResources/Alerts', title: 'Finance Alerts', icon: '', id: 'alerts', idTag: 'alerts', isExpanded: this.router.url.includes('/FinanceResources/Alerts') },
      ];
    }

    if(financeResourcesLinks.subMenu && financeResourcesLinks.subMenu.length > 0) {
      this.menuItems.push(financeResourcesLinks);
    }
  }

  private addTstResourcesLink(roles: Record<string, boolean>) {
    let tstResourcesLinks: SideNavMenuItem = { path: '/TstResources', title: 'TST Resources', icon: 'groups', idTag: 'tstresources', subMenu: [], isExpanded: this.router.url.includes('/TstResources') };
    
    if (roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]){
      tstResourcesLinks.subMenu = [
        { path: '/TstResources/HsProcessing', title: 'Services HS Processing', icon: '', id: 'hsProcessing', idTag: 'hs-processing', isExpanded: this.router.url.includes('/TstResources/HsProcessing') },
      ];
    }

    if(tstResourcesLinks.subMenu && tstResourcesLinks.subMenu.length > 0) {
      this.menuItems.push(tstResourcesLinks);
    }
  }

  private addTripsLink(){
    this.addMenuItem({ icon: 'luggage', id: 'trips', path: '/Trips', title: 'Trips', idTag:'trips' });
  }

  private addGeneralLedgerLink(roles: Record<string, boolean>){
    const generalLedgerLinks: SideNavMenuItem = { icon: 'menu_book', id: 'generalLedger', path: '/GeneralLedger', title: 'General Ledger', idTag:'general-ledger', subMenu: [], isExpanded: this.router.url.includes('/GeneralLedger')};

    if (roles[UserRoleConstants.Manager]  || roles[UserRoleConstants.Finance]){
      generalLedgerLinks.subMenu = [
        { path: '/GeneralLedger/ChartOfAccounts', title: 'Chart Of Accounts', icon: '', id: 'chartOfAccounts', idTag: 'chart-of-accounts', isExpanded: this.router.url.includes('/GeneralLedger/ChartOfAccounts') },
        { path: '/GeneralLedger/TravelTypeMapping', title: 'Travel Type Mapping', icon: '', id: 'travelTypeMapping', idTag: 'travel-type-mapping', isExpanded: this.router.url.includes('/GeneralLedger/TravelTypeMapping') },
      ]
    }

    if(generalLedgerLinks.subMenu && generalLedgerLinks.subMenu.length > 0) {
      this.menuItems.push(generalLedgerLinks);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  addMenuItem(item: SideNavMenuItem) {
    const exists = this.menuItems.some(mi => mi.id === item.id);

    if (!exists) {
      this.menuItems.push(item);
    }
  }

  get isLgScreen$() {
    return this.bp.isActive([Breakpoints.LG]);
  }
}
