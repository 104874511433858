import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {PaymentProvider} from '../model/adx-data';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdxVccPaymentProviderService {
    constructor(private http:HttpClient) {}

    getProviders(): Observable<PaymentProvider[]> {
        return this.http
            .get<PaymentProvider[]>('dbov2/payment-providers')
            .pipe(catchError((error: HttpErrorResponse)=>{
                throw new Error(error?.message || 'Server error');
            }));
    }
}