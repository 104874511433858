import {Component, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ActivatedRoute} from '@angular/router';
  
@Component({
  selector: 'trip-details',
  templateUrl: './trip-details.component.html',
})

@AutoUnsubscribe()
export class TripDetailsComponent implements OnInit {
  data: any;

  constructor(private route: ActivatedRoute)
    {  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.data = {
        tripId: params.get('id'),
        pageSize: 25
      }
    });
  }
}