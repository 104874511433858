@if(!isLoading){
  @if (title !== undefined && title !== null) {
    <mat-dialog-content class="flex-space-between">
      <mat-label id="vcc-payment-gateway-label-title" class="modal-title-bold">{{this.title}}</mat-label>
      <mat-dialog-actions align="end">
        <button id="vcc-payment-gateway-cancel-button" mat-icon-button color="primary" aria-label="Close Modal"
          (click)="handleVccPaymentGatewayViewerCloseBtnClick()">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-dialog-actions>
    </mat-dialog-content>
  }

  <form [formGroup]="vccPaymentGatewayViewerForm" id="vcc-payment-gateway-viewer-form" class="template-form">
    <div id="vcc-payment-gateway-main-container" class="container">
        @if (_vcc.status !== undefined && _vcc.status !== null) {
          <read-only-form-field title="Status" testId="vcc-payment-gateway-status" value="{{_vcc.status}}" />
        }
        @if (_vcc.providerId !== undefined && _vcc.providerId !== null) {
          <read-only-form-field title="Provider ID" testId="vcc-payment-gateway-provider-id" value="{{_vcc.providerId}}" />
        }
        @if (_vcc.purchaseLogUniqueID !== undefined && _vcc.purchaseLogUniqueID !== null) {
          <read-only-form-field title="Card Guid" testId="vcc-payment-gateway-purchase-log-unique-id" value="{{_vcc.purchaseLogUniqueID}}" />
        }
        @if (_vcc.creditCard.cardNumber !== undefined && _vcc.creditCard.cardNumber !== null) {
          <read-only-form-field title="Card Number" testId="vcc-payment-gateway-card-number" value="{{_vcc.creditCard.cardNumber}}" />
        }
        @if (_vcc.creditCard.cardHolderName !== undefined && _vcc.creditCard.cardHolderName !== null) {
          <read-only-form-field title="Card Holder Name" testId="vcc-payment-gateway-card-holder-name" value="{{_vcc.creditCard.cardHolderName}}" />
        }
        @if (_vcc.creditCard.creditCardTypes !== undefined && _vcc.creditCard.creditCardTypes !== null) {
          <read-only-form-field title="Type" testId="vcc-payment-gateway-types" value="{{_vcc.creditCard.creditCardTypes}}" />
        }
        @if (_vcc.creditCard.amountLimit !== undefined && _vcc.creditCard.amountLimit !== null) {
          <read-only-form-field title="Amount of charges allowed" testId="vcc-payment-gateway-amount-limit" value="{{_vcc.creditCard.amountLimit}}" />
        }
        @if (_vcc.creditCard.issuedAmount !== undefined && _vcc.creditCard.issuedAmount !== null) {
          <read-only-form-field title="Issued Amount" testId="vcc-payment-gateway-issued-amount" value="{{_vcc.creditCard.issuedAmount}}" />
        }
        @if (_vcc.creditCard.currencyCode !== undefined && _vcc.creditCard.currencyCode !== null) {
          <read-only-form-field title="Currency Code" testId="vcc-payment-gateway-currency-code" value="{{_vcc.creditCard.currencyCode}}" />
        }
        @if (_vcc.creditCard.availableBalance !== undefined && _vcc.creditCard.availableBalance !== null) {
          <read-only-form-field title="Available Balance" testId="vcc-payment-gateway-available-balance" value="{{_vcc.creditCard.availableBalance}}" />
        }
        @if (_vcc.customerId !== undefined && _vcc.customerId !== null) {
          <read-only-form-field title="Customer ID" testId="vcc-payment-gateway-customer-id" value="{{_vcc.customerId}}" />
        }
        @if (_vcc.usageLimit !== undefined && _vcc.usageLimit !== null) {
          <read-only-form-field title="Usage Limit" testId="vcc-payment-gateway-usage-limit" value="{{_vcc.usageLimit}}" />
        }
        @if (_vcc.expirationDate !== undefined && _vcc.expirationDate !== null) {
          <read-only-form-field title="Expiration Date" testId="vcc-payment-gateway-expiration-date" value="{{_vcc.expirationDate | date: 'M/d/yyyy'}}" />
        }
        @if (_vcc.terminateDate !== undefined && _vcc.terminateDate !== null) {
          <read-only-form-field title="Termination Date" testId="vcc-payment-gateway-termination-date" value="{{_vcc.terminateDate | date: 'M/d/yyyy'}}" />
        }
        @if (_vcc.activeFromDate !== undefined && _vcc.activeFromDate !== null) {
          <read-only-form-field title="Active From Date" testId="vcc-payment-gateway-active-from-date" value="{{_vcc.activeFromDate | date: 'M/d/yyyy'}}" />
        }
        @if (_vcc.activeToDate !== undefined && _vcc.activeToDate !== null) {
          <read-only-form-field title="Active To Date" testId="vcc-payment-gateway-active-to-date" value="{{_vcc.activeToDate | date: 'M/d/yyyy'}}" />
        }
    </div>
  </form>
} @else {
  <div class="loading-spinner">
    @if(isLoaderVisible) {
      <mat-spinner></mat-spinner>
    }
  </div>
}

