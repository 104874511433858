import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdxInvoiceDetailResponse} from 'src/app/adx-invoice/model/adx-invoice-detail-response';
import {AdxInvoiceService} from 'src/app/adx-invoice/service/adx-invoice.service';

@Component({
  selector: 'app-client-modal',
  templateUrl: './client-modal.component.html'
})
export class ClientModalComponent implements OnInit {

  dataSource: AdxInvoiceDetailResponse[] = [];

  loadedData = false;
  
  displayedColumns: string[] = [
    'recordLocator',
    'issueDate',
    'usdTotal',
    'cadTotal',
  ];

  constructor(
    @Optional() public clientDialogRef: MatDialogRef<ClientModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private adxInvoiceService: AdxInvoiceService,
  ){}

  ngOnInit(): void {
    this.getItems();
  }

  closeModal(): void {
    this.clientDialogRef.close();
  }

  getItems(): void {
    this.adxInvoiceService.searchByQueryAdxInvoices({
      clientId: this.data,
      invoiceTypeCode: 'V',
    }).subscribe((data)=>{
      this.dataSource = data;
      this.loadedData = true;
    });
  }

  getUsdSum(invoice:AdxInvoiceDetailResponse): number {
    let usd = 0;
    invoice.adxBookings.forEach((bookings)=>{
      if (bookings.currency.code === 'USD') {
        usd += bookings.totalFare;
      }
    });

    return usd;
  }

  getCadSum(invoice:AdxInvoiceDetailResponse): number {
    let cad = 0;
    invoice.adxBookings.forEach((bookings)=>{
      if (bookings.currency.code === 'CAD') {
        cad += bookings.totalFare;
      }
    });
    
    return cad;
  }
}
