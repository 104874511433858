<ng-container *ngTemplateOutlet="adxTable;context:{dataSource: dataSource$ | async}">
</ng-container>

<ng-template #adxTable let-dataSource="dataSource">
    <div class="grid-title-event">
        <h2 style="text-align:center" id="adx-vcc-list-title">Issued VCC's</h2>

        <div class="events-container">
            @if(dataSource?.items?.length){
                <refresh-list [filtersAdx]="adxFilter" [page]="adxPage" (keepFilters)="handleKeepFilters($event)"></refresh-list>
                <button color="primary" id="download-adx-vcc-button" aria-label="Download Adx Vcc File" mat-stroked-button (click)="downloadGridToFile()" style="justify-content:flex-end">
                    <mat-icon>download</mat-icon>
                </button>
            }
        </div>
    </div>
@defer (when dataSource){
    <mat-table data-testid="adx-vcc-table" [dataSource]="dataSource?.items" class="compact-mat-table" (matSortChange)="handleSortChange($event)" matSort>
        <ng-container matColumnDef="id">
            <mat-header-cell id="fms-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                FMS Id
            </mat-header-cell>
            <mat-cell id="fms-id-cell" *matCellDef="let element">
                {{element?.id}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tripReference">
            <mat-header-cell data-testid="adx-tripReference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                 Trip Reference
            </mat-header-cell>
            <mat-cell id="adx-tripReference-cell" *matCellDef="let element">
                <ng-container *ngIf="element?.isTripDetailsExists === 1; else noLink">
                    <a id="adx-tripReference-id-link" href="TripReference/TravelService/{{element?.travelServiceId}}" style="text-decoration: underline; color: #00241A;"
                       (click)="$event.preventDefault(); onTripReferenceClick(element)">
                        <div id="adx-tripReference-id-div">
                            {{ element?.tripReference }}
                        </div>
                    </a>
                </ng-container>
                <ng-template #noLink>
                    <div id="adx-tripReference-id-div">
                        {{ element?.tripReference }}
                    </div>
                </ng-template>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="merchantName">
            <mat-header-cell id="merchantName-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                Merchant Name
            </mat-header-cell>
            <mat-cell id="merchantName-cell" *matCellDef="let element">
                {{element?.merchantName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="vendorName">
            <mat-header-cell id="vendor-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                Vendor Name
            </mat-header-cell>
            <mat-cell id="vendor-name-cell" class="mat-cell" *matCellDef="let element">
                <span class="compact-mat-cell-text" matTooltip={{element?.vendorName}}  [matTooltipPosition]="'above'">
                    <a id="adx-vcc-vendor-name-cell-link" href="Vendor/CentralDocument/{{element?.tramsCentralDocumentId}}"
                    (click)="$event.preventDefault();
                        $event.stopPropagation();
                        onVendorNameClick(element)">
                        {{element?.vendorName}}
                    </a>
                </span>

            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cardStatus">
            <mat-header-cell id="cardStatus-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                Status
            </mat-header-cell>
            <mat-cell id="cardStatus-cell" *matCellDef="let element">
                {{element?.cardStatus}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="maskedCreditCardNumber">
            <mat-header-cell id="masked-credit-card-number-header" class="mat-header-cell" *matHeaderCellDef
                mat-sort-header>
                Masked Credit Card Number
            </mat-header-cell>
            <mat-cell id="masked-credit-card-number-cell" *matCellDef="let element">
              <a  id="masked-credit-card-number-id-link" href="TransactionHistory/{{element?.providerUniqueId}}" style="text-decoration: underline; color: #00241A;"
                (click)="$event.preventDefault(); onMaskedCreditCardNumberClick(element)">
                <div id="masked-credit-card-number-div">
                  {{element?.maskedCreditCardNumber | maskedCard}}
                </div>
              </a>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
            <mat-header-cell id="date-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                Posting Date
            </mat-header-cell>
            <mat-cell id="date-created-cell" *matCellDef="let element">
                {{element?.dateCreated | date:'M/d/yyyy'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expirationDate">
            <mat-header-cell id="expiration-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                Expiration Date
            </mat-header-cell>
            <mat-cell id="expiration-date-cell" *matCellDef="let element">
                {{element?.expirationDate | date:'M/d/yyyy'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
            <mat-header-cell id="amount-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                Issued Amount
            </mat-header-cell>
            <mat-cell id="amount-cell" *matCellDef="let element">
                {{element?.amount}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="availableBalance">
            <mat-header-cell id="availableBalance-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                Balance Amount
            </mat-header-cell>
            <mat-cell id="availableBalance-cell" *matCellDef="let element">
                {{element?.availableBalance}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amountCurrencyId">
            <mat-header-cell id="amount-currency-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
                Currency
            </mat-header-cell>
            <mat-cell id="amount-currency-cell" *matCellDef="let element">
                {{element?.amountCurrency}}
            </mat-cell>
        </ng-container>
        @if(showPay){
            <ng-container matColumnDef="Pay" id="fms-vcc-list-central-pay-column">
                <mat-header-cell class="mat-header-cell" *matHeaderCellDef id="fms-vcc-list-pay-header-cell">Pay</mat-header-cell>
                <mat-cell *matCellDef="let row" id="fms-vcc-list-pay-cell">
                    <button mat-raised-button *ngIf="row.paymentProviderId === 19" (click)="$event.stopPropagation(); openAmountModal(row)">Pay</button>
                </mat-cell>
            </ng-container>
        }
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />
          <mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index">
        </mat-row>
    </mat-table>
    <mat-paginator id="booking-paginator" [length]="dataSource?.pagination?.totalItemCount" [hidePageSize]="true"
        [pageSize]="dataSource?.pagination?.pageSize" [pageIndex]="dataSource?.pagination?.pageNumber - 1" (page)="handlePageChanged($event)">
    </mat-paginator>
}
</ng-template>
