<div>
    <h2 data-testid="adx-booking-details-title" class="page-details-title-style">Service {{this.bookingData.id}}</h2>
</div>

<mat-tab-group dynamicHeight (selectedTabChange)="tabChanged($event)">
  <mat-tab label="General info">
      <app-adx-booking-info [data]="bookingData" [payStatusesOptions]="payStatusesList" 
        (submitedData)="submitedDataHandler($event)" (confirmCopyService)="confirmCopyServiceHandler($event)"></app-adx-booking-info>
  </mat-tab>
    @if (bookingData.adxAgentBookings && bookingData.adxAgentBookings.length > 0){
        <mat-tab label="Agents">
            <br>
            @for (adxAgentBooking of bookingData.adxAgentBookings; track adxAgentBooking?.id) {
                <app-adx-agent-booking-info [data]="adxAgentBooking"></app-adx-agent-booking-info>
            }
        </mat-tab>
    }
    <mat-tab label="Udids">
        <app-adx-booking-udid-info [data]="bookingData"></app-adx-booking-udid-info>
    </mat-tab>
    @if(bookingData.adxBookingSegments && bookingData.adxBookingSegments.length > 0) {
        <mat-tab label="Segments">
            @for (adxBookingSegment of bookingData.adxBookingSegments; track adxBookingSegment?.adxBookingId) {
                <app-adx-booking-segment-info [data]="adxBookingSegment"></app-adx-booking-segment-info>
            }
        </mat-tab>
    }
</mat-tab-group>
