import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AirMatchingReportService} from '../../service/air-matching-report.service';
import {AirMatchingItemModel} from '../../model/air-matching-report';

@Component({
    selector: 'confirmation-dialog-air-matching',
    templateUrl: './confirmation-dialog-air-matching.component.html'
})
export class ConfirmationDialogAirMatchingComnponent {
    isLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: { itemToSave: AirMatchingItemModel },
        @Optional() public dialogRef: MatDialogRef<ConfirmationDialogAirMatchingComnponent>,
        private _snackBar: MatSnackBar,
        private airMatchingReportService: AirMatchingReportService,
    ){}

    okClick(){
        this.isLoading = true;
        if (this.dialogData && this.dialogData.itemToSave) {

            this.airMatchingReportService.approveUpdate(this.dialogData?.itemToSave).subscribe(()=>{
                this._snackBar.open(
                    'Request sent successfully', 'Dismiss', {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                });

                this.dialogRef.close(true);
            });
        } else {
            this.dialogRef.close();
        }
    }

    onNoClick(){
        this.dialogRef.close();
    }
}