
<ng-container>
  @if(!(loading$ | async)){
    <form id="trams-invoice-detail-form" class="template-form trams-invoice-detail-form">  
      <div id="trams-invoice-detail-main-container" class="container">
        <mat-tab-group dynamicHeight (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Invoice">
                <mat-card class="mat-elevation-z8">
                  <mat-card-content>
                    @if (invoiceData.clientName) {
                      <read-only-form-field title="Client Name" testId="client-name" value="{{invoiceData.clientName}}" />
                    }
                    @if (invoiceData.clientCentralDocumentId) {
                      <read-only-form-field title="Client Id" testId="client-id" value="{{invoiceData.clientCentralDocumentId}}" />
                    }
                    @if (invoiceData.recordLocator) {
                      <read-only-form-field title="Invoice Record Locator" testId="record-locator" value="{{invoiceData.recordLocator}}" />
                    }
                    @if (invoiceData.invoiceTypeCodeName) {
                      <read-only-form-field title="Invoice Type" testId="invoice-type-code" value="{{invoiceData.invoiceTypeCodeName}}" />
                    }
                    @if (invoiceData.tramsInvoiceNumber) {
                      <read-only-form-field title="Invoice Number" testId="invoice-no" value="{{invoiceData.tramsInvoiceNumber}}" />
                    }
                    @if (invoiceData.invoiceGroup) {
                      <read-only-form-field title="Invoice Group" testId="invoice-group" value="{{invoiceData.invoiceGroup}}" />
                    }
                    @if (invoiceData.branchName) {
                      <read-only-form-field title="Branch" testId="branch-name" value="{{invoiceData.branchName}}" />
                    }
                    @if (invoiceData.issueDate) {
                      <read-only-form-field title="Issue Date" testId="issue-date" value="{{formatDate(invoiceData?.issueDate)}}" />
                    }
                    @if (invoiceData.dataSourceId) {
                      <read-only-form-field title="Data Source Id" testId="data-source-id" value="{{invoiceData.dataSourceId}}" />
                    }
                    @if (invoiceData.dataSourceName) {
                      <read-only-form-field title="Data Source Name" testId="data-source-name" value="{{invoiceData.dataSourceName}}" />
                    }
                  </mat-card-content>
                </mat-card>
            </mat-tab>
            @for (booking of invoiceData.bookings; track booking) {
            <mat-tab [label]="booking.tramsBookingNo">
              <br />
              @if (invoiceData.clientCentralDocumentId) {
                <read-only-form-field title="Main Passenger Name" testId="main-passenger-name" value="{{booking.mainPassengerName}}" />
              }
              @if (booking.vendorCentralDocumentId) {
                <read-only-form-field title="Vendor Id" testId="vendor-id" value="{{booking.vendorCentralDocumentId}}" />
              }
              @if (booking.vendorName) {
                <read-only-form-field title="Vendor Name" testId="vendor-name" value="{{booking.vendorName}}" />
              }
              @if (booking.tramsBookingNo) {
                <read-only-form-field title="Trams Booking No" testId="trams-booking-no" value="{{booking.tramsBookingNo}}" />
              }
              @if (booking.departDate) {
                <read-only-form-field title="Depart Date" testId="depart-date" value="{{formatDate(booking.departDate)}}" />
              }
              @if (booking.returnDate) {
                <read-only-form-field title="Arrival Date" testId="arrival-date" value="{{formatDate(booking.returnDate)}}" />
              }
              @if (booking.totalFare) {
                <read-only-form-field title="Booking Total" testId="total-fare" value="{{booking.totalFare}}" />
              }
              @if (areTaxesDefined(booking)) {
                <read-only-form-field title="Booking Taxes" testId="booking-taxes" [value]="getTotalTaxes(booking)" />
              }
              @if (booking.commAmt !== undefined && booking.commAmt !== null) {
                <read-only-form-field title="Commission Amount" testId="comm-amt" value="{{booking.commAmt}}" />
              }
              @if (booking.clientPaidByCc) {
                <read-only-form-field title="CC Number" testId="cc-no" value="{{booking.clientPaidByCc}}" />
              }
              @if (booking.confirmationNumber) {
                <read-only-form-field title="Confirmation Number" testId="confirmation-number" value="{{booking.confirmationNumber}}" />
              }
              @if (booking.currencyName) {
                <read-only-form-field title="Currency" testId="currency" value="{{booking.currencyName}}" />
              }
              @if (booking.recordLocator) {
                <read-only-form-field title="Booking Record Locator" testId="booking-record-locator" value="{{booking.recordLocator}}" />
              }
              @if (booking.submitToCodeName) {
                <read-only-form-field title="Submit To Code" testId="submit-to-code-name" value="{{booking.submitToCodeName}}" />
              }
              @if (booking.gstAmt) {
                <read-only-form-field title="GST Amount" testId="gst-amt" value="{{booking.gstAmt}}" />
              }
              @if (booking.qstAmt) {
                <read-only-form-field title="QST Amount" testId="qst-amt" value="{{booking.qstAmt}}" />
              }
              @if (booking.tax1Amt) {
                <read-only-form-field title="Tax1 Amount" testId="tax1-amt" value="{{booking.tax1Amt}}" />
              }
              @if (booking.tax2Amt) {
                <read-only-form-field title="Tax2 Amount" testId="tax2-amt" value="{{booking.tax2Amt}}" />
              }
              @if (booking.tax3Amt) {
                <read-only-form-field title="Tax3 Amount" testId="tax3-amt" value="{{booking.tax3Amt}}" />
              }
              @if (booking.tax4Amt) {
                <read-only-form-field title="Tax4 Amount" testId="tax4-amt" value="{{booking.tax4Amt}}" />
              }
              @if (booking.clientGstAmt) {
                <read-only-form-field title="Client GST Amount" testId="client-gst-amt" value="{{booking.clientGstAmt}}" />
              }
              @if (booking.travelTypeName) {
                <read-only-form-field title="Travel Type" testId="travel-type" value="{{booking.travelTypeName}}" />
              }
              @if (booking.bookingUdids?.length >= 1) {
                <read-only-form-field title="List of UDIDs" testId="booking-udids" [value]="getUdidValues(booking.bookingUdids)" (keypress)="showMoreAboutBookingUdidsInfo()" (click)="showMoreAboutBookingUdidsInfo()" />
              }
              @if (booking.agentBookings?.length >= 1) {
                <read-only-form-field title="List of agents" testId="agent-ids" [value]="getAgentValues(booking.agentBookings)" (keypress)="showMoreAboutAgentsInfo()" (click)="showMoreAboutAgentsInfo()" />
              }
              @if (booking.bookingSegments?.length >= 1) {
                <read-only-form-field title="List of segments" testId="segments-ids" [value]="getSegmentValues(booking.bookingSegments)" (keypress)="showMoreAboutSegmentsInfo()" (click)="showMoreAboutSegmentsInfo()" />
              }
              @if (booking.remitAmount) {
                <read-only-form-field title="Remit Amount" testId="remit-amt" value="{{booking.remitAmount}}" />
              }
            </mat-tab>
            }
        </mat-tab-group>
      </div>
    </form>
  }
</ng-container>
