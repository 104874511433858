import {Component, Inject, Optional} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {merge} from "rxjs";
import {AutoUnsubscribe} from "src/infrastructure/decorators/auto-unsubscribe.decorator";
import {FmsVccPayService} from "../../service/fms-vcc-pay.service";


@AutoUnsubscribe()
@Component({
    selector: 'app-fms-vcc-pay',
    templateUrl: './fms-vcc-pay.component.html',
})
export class FmsVccPayComponent {
    fmsPayForm!:FormGroup;
    errorMessage='';
    disabledSubmit = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        @Optional() public fmsPayDialogRef: MatDialogRef<FmsVccPayComponent>,
        private fmsVccPayService: FmsVccPayService,
        private formBuilder: FormBuilder,
        private _snackBar: MatSnackBar,
    ){
        let amt = this.dialogData.mode === 'AdxVcc' ? this.dialogData.amount : this.dialogData.virtualCreditCardCurrentAmt;
        this.fmsPayForm = this.formBuilder.group({
            amount: [null, [Validators.required, Validators.pattern(/^\d{1,10}(\.\d{1,10})?\d{0,10}$/),Validators.max(amt), Validators.min(0.1)]],
        });


        merge(this.fmsPayForm.statusChanges, this.fmsPayForm.valueChanges)
            .subscribe(() => this.updateErrorMessage());

    }

    handleFmsPayFiltersFormCloseEvent(){
        this.fmsPayDialogRef.close();
    }

    clearAmountFilter(){
        this.fmsPayForm.reset();
    }

    updateErrorMessage() {
        if (this.fmsPayForm.controls['amount'].hasError('required')) {
          this.errorMessage = 'You must enter a value';
          this.disabledSubmit = true;
          return;
        }

        if (this.fmsPayForm.controls['amount'].hasError('pattern')) {
          this.errorMessage = 'Value must be int or decimal';
          this.disabledSubmit = true;
          return;
        }
        
        let amt = this.dialogData.mode === 'AdxVcc' ? this.dialogData.amount : this.dialogData.virtualCreditCardCurrentAmt;
        if (this.fmsPayForm.controls['amount'].hasError('max')) {
            this.errorMessage = `Value must be equal or less than ${amt}`;
            this.disabledSubmit = true;
            return;
        }

        if (this.fmsPayForm.controls['amount'].hasError('min')) {
            this.errorMessage = `Value must be greater than 0`;
            this.disabledSubmit = true;
            return;
        }
        
        this.errorMessage = '';
        this.disabledSubmit = false;
    }

    submitAmount(){
        if(this.fmsPayForm.valid) {
            this.disabledSubmit = true;
            if(this.dialogData.mode === 'AdxVcc'){
                this.fmsVccPayService.sendAdxPay({
                    id: this.dialogData.id,
                    purchasedCardGuid: this.dialogData.purchasedCardGuid,
                    amount: Number(this.fmsPayForm.controls['amount'].value),
                }).subscribe(()=>{
                    this._snackBar.open(
                        `Amount applied: ${this.fmsPayForm.controls['amount'].value}`,
                        'Dismiss',
                        {
                            panelClass: ['message-position', 'app-notification-success']
                        }
                    )
                    this.fmsPayDialogRef.close({amount: this.fmsPayForm.controls['amount'].value});
                })
            }else if(this.dialogData.mode === 'FmsVcc'){
                this.fmsVccPayService.sendFmsPay({
                    id: this.dialogData.id,
                    purchasedCardGuid: this.dialogData.purchasedCardGuid,
                    amount: Number(this.fmsPayForm.controls['amount'].value),
                }).subscribe(()=>{
                    this._snackBar.open(
                        `Amount applied: ${this.fmsPayForm.controls['amount'].value}`,
                        'Dismiss',
                        {
                            panelClass: ['message-position', 'app-notification-success']
                        }
                    )
                    this.fmsPayDialogRef.close({amount: this.fmsPayForm.controls['amount'].value});
                })
            }
        } else {
            this.errorMessage = 'Card expired or terminated';
        }
    }
}
