@if(!isLoading){
  @if (title !== undefined && title !== null) {
    <h2 data-testid="vcc-payment-gateway-label-title" class="page-details-title-style">{{this.title}}</h2>
  }

  <form [formGroup]="vccPaymentGatewayViewerForm" id="vcc-payment-gateway-viewer-form" class="template-form">
    <div id="vcc-payment-gateway-main-container" class="container">
        @if (_vcc.status !== undefined && _vcc.status !== null) {
          <read-only-form-field title="Status" testId="vcc-payment-gateway-status" value="{{_vcc.status}}" />
        }
        @if (_vcc.providerId !== undefined && _vcc.providerId !== null) {
          <read-only-form-field title="Provider ID" testId="vcc-payment-gateway-provider-id" value="{{_vcc.providerId}}" />
        }
        @if (_vcc.purchaseLogUniqueID !== undefined && _vcc.purchaseLogUniqueID !== null) {
          <read-only-form-field title="Card Guid" testId="vcc-payment-gateway-purchase-log-unique-id" value="{{_vcc.purchaseLogUniqueID}}" />
        }
        @if (_vcc.creditCard.cardHolderName !== undefined && _vcc.creditCard.cardHolderName !== null) {
          <read-only-form-field title="Card Holder Name" testId="vcc-payment-gateway-card-holder-name" value="{{_vcc.creditCard.cardHolderName}}" />
        }
        @if (_vcc.creditCard.creditCardTypes !== undefined && _vcc.creditCard.creditCardTypes !== null) {
          <read-only-form-field title="Type" testId="vcc-payment-gateway-types" value="{{_vcc.creditCard.creditCardTypes}}" />
        }
        @if (_vcc.creditCard.amountLimit !== undefined && _vcc.creditCard.amountLimit !== null) {
          <read-only-form-field title="Amount of charges allowed" testId="vcc-payment-gateway-amount-limit" value="{{_vcc.creditCard.amountLimit}}" />
        }
        @if (_vcc.creditCard.issuedAmount !== undefined && _vcc.creditCard.issuedAmount !== null) {
          <read-only-form-field title="Issued Amount" testId="vcc-payment-gateway-issued-amount" value="{{_vcc.creditCard.issuedAmount}}" />
        }
        @if (_vcc.creditCard.currencyCode !== undefined && _vcc.creditCard.currencyCode !== null) {
          <read-only-form-field title="Currency Code" testId="vcc-payment-gateway-currency-code" value="{{_vcc.creditCard.currencyCode}}" />
        }
        @if (_vcc.creditCard.availableBalance !== undefined && _vcc.creditCard.availableBalance !== null) {
          <read-only-form-field title="Available Balance" testId="vcc-payment-gateway-available-balance" value="{{_vcc.creditCard.availableBalance}}" />
        }
        @if (_vcc.customerId !== undefined && _vcc.customerId !== null) {
          <read-only-form-field title="Customer ID" testId="vcc-payment-gateway-customer-id" value="{{_vcc.customerId}}" />
        }
        @if (_vcc.usageLimit !== undefined && _vcc.usageLimit !== null) {
          <read-only-form-field title="Usage Limit" testId="vcc-payment-gateway-usage-limit" value="{{_vcc.usageLimit}}" />
        }
        @if (_vcc.expirationDate !== undefined && _vcc.expirationDate !== null) {
          <read-only-form-field title="Expiration Date" testId="vcc-payment-gateway-expiration-date" value="{{_vcc.expirationDate | date: 'M/d/yyyy'}}" />
        }
    </div>
  </form>
} @else {
  <div class="loading-spinner">
    @if(isLoaderVisible && !data.isPage) {
      <mat-spinner></mat-spinner>
    }
  </div>
}

