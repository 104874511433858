import {Component, OnInit, Input, ViewChild, ViewContainerRef} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {TransactionBookingService} from '../../../transaction-booking/service/transaction-booking.service';
import {Action} from '../../model/action';
import {ActivatedRoute} from '@angular/router';
import {AddeditVendorComponent} from 'src/app/vendor/component/addedit/addedit-vendor/addedit-vendor.component';
import {
  VendorTemplateAddEditComponent
} from 'src/app/vendor-template/component/addedit/vendor-template-addedit/vendor-template-addedit.component';
import {TemplateLayoutComponent} from 'src/app/template/components/template-layout/template-layout.component';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {
  BookingTemplateAddEditComponent
} from 'src/app/booking-template/component/addedit/booking-template-addedit/booking-template-addedit.component';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-request-action-layout',
  templateUrl: './request-action-layout.component.html'
})
export class RequestActionLayoutComponent implements OnInit {
  @Input() public data: any;
  transactionBookingId: string | null = null;
  actionSelected: any = 0;
  actionSelectControl = new FormControl<Action | null>(null, Validators.required);
  @ViewChild('container', {read: ViewContainerRef})
  container!: ViewContainerRef;
  actionsList: Action[] = [];
  components: any = [];
  _booking: any;
  loading$: any;
  
  constructor(
    private buildr: FormBuilder,
    private transactionBookingService: TransactionBookingService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public loader: LoadingService) {
  }

  ngOnInit() {
    this.loading$ = this.loader.loading$;
    this.transactionBookingId = this.route.snapshot.paramMap.get('id');
    this.getBookingInformation();
  }

  getBookingInformation() {
    if (this.transactionBookingId !== undefined && this.transactionBookingId !== null) {
      this.transactionBookingService.getBookingById(Number(this.transactionBookingId)).subscribe(item => {
        if (item) {
          this.processBooking(item);
        }
      });
    } else {
      this.processDefaultActions();
    }
  }
  
  processBooking(booking: any) {
    this._booking = booking;
  
    if (this._booking.vendorId !== undefined && this._booking.vendorId !== null) {
      this.processVendorActions();
    } else {
      this.actionsList.push({ id: '1', name: 'Add vendor information' });
    }
  
    this.actionsList.push({ id: '5', name: 'Add/Edit service template (Vendor Payment)' });
    this.actionsList.push({ id: '6', name: 'Add/Edit service template (Cancellation Refund)' });
  }
  
  processVendorActions() {
    this.actionsList.push({ id: '1', name: 'Edit vendor information' });
    this.actionsList.push({ id: '3', name: 'Add/Edit vendor template (Vendor Payment)' });
    this.actionsList.push({ id: '4', name: 'Add/Edit vendor template (Cancellation Refund)' });
  }
  
  processDefaultActions() {
    this.actionsList.push({ id: '1', name: 'Add vendor information' });
    this.actionsList.push({ id: '2', name: 'Add terms and conditions template' });
  }

  onSelectAction(actionId: number) {
    this.actionSelected = actionId;
  }

  requestActionForm = this.buildr.group({
    action: this.buildr.control(''),
    templateName: this.buildr.control(''),
    templateNotes: this.buildr.control(''),
  })

  reset(): void {
    this.container.detach();
    let i = this.components.length;
    while (i--) {
      this.components.splice(i, 1);
    }
  }

  submitAction(): void {
    if (this.actionSelectControl.invalid) {
      return;
    }
  
    this.reset();
    this.loading$ = this.loader.show();
  
    switch (this.actionSelected) {
      case "1":
        this.handleAddEditVendor();
        break;
      case "2":
        this.handleAddTemplate();
        break;
      case "3":
        this.handleVendorTemplate(1);
        break;
      case "4":
        this.handleVendorTemplate(2);
        break;
      case "5":
        this.handleBookingTemplate(1);
        break;
      case "6":
        this.handleBookingTemplate(2);
        break;
    }
  }
  
  handleAddEditVendor(): void {
    const vendorData = { vendorId: this._booking?.vendorId ?? -1 };
    const addEditVendorRef = this.container.createComponent(AddeditVendorComponent);
    addEditVendorRef.instance.data = vendorData;
    this.components.push(addEditVendorRef);
  }
  
  handleAddTemplate(): void {
    const templateRef = this.container.createComponent(TemplateLayoutComponent);
    this.components.push(templateRef);
  }
  
  handleVendorTemplate(termsConditionsTypeId: number): void {
    const vendorTemplateData = { vendorId: this._booking?.vendorId ?? -1, vendorName: this._booking?.vendor?.vendorName ?? '', termsConditionsTypeId };
    const vendorTemplateRef = this.container.createComponent(VendorTemplateAddEditComponent);
    vendorTemplateRef.instance.data = vendorTemplateData;
    this.components.push(vendorTemplateRef);
  }
  
  handleBookingTemplate(termsConditionsTypeId: number): void {
    const data = { transactionBookingId: this._booking?.transactionBookingId ?? -1, bookingName: this._booking?.bookingName ?? '', termsConditionsTypeId };
    const bookingTemplateRef = this.container.createComponent(BookingTemplateAddEditComponent);
    bookingTemplateRef.instance.data = data;
    this.components.push(bookingTemplateRef);
  }
}