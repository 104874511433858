<div class="agent-info-content" id="agent-info-content">

  <read-only-form-field title="Agent Name" testId="agent-info-agent-name" [value]="_agent.agentName || ''" />
  
  <read-only-form-field title="Agent Code" testId="agent-info-agent-code" [value]="_agent.interfaceId || ''" />
  
  <read-only-form-field title="Email" testId="agent-info-email" [value]="_agent.email || ''" />
  
  <read-only-form-field title="Data Source Name" testId="agent-info-data-source-name" [value]="_agent.tramsDataSource?.displayName || ''" />

  <read-only-form-field title="Trams Central Document Id" testId="agent-info-trams-document-id" [value]="_agent.tramsCentralDocumentId || ''" />

  <read-only-form-field title="Address" testId="agent-info-address" [value]="formatAddress(_agent.address)" />
  
  <read-only-form-field title="Phone" testId="agent-info-phone" [value]="_agent.phone || ''" />

</div>