import {Component,OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Agent} from 'src/app/agent/component/service/agent.model';
import {AgentService} from '../service/agent.service';

@Component({
  selector: 'agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: []
})
export class AgentDetailsComponent implements OnInit {
  @Input() public data: any;
  agentData: any;
  agentId: any;

  constructor(
      private agentService: AgentService,
      private route: ActivatedRoute){}

  public ngOnInit(): void {
    if (this.data == null) {
      this.route.paramMap.subscribe(params => {
        this.agentId = params.get('agentId');
      });

      this.getAgentInfo();
    }
    else{
      this.agentData = this.data;
    }
  }

  getAgentInfo() {
    this.agentService
      .getAgentById(this.agentId)
      .subscribe((data: Agent) => {
        this.agentData = data;
    });
  }
}
