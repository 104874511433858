export const DEFAULT_CLIENT_PAY_STATUS = 3;

export const DEFAULT_SORT_PROPERTY_NAME = 'clientName';

export const transformKeyToUpperCase = (filters: Record<string, unknown>) => {
    Object.keys(filters).forEach((key) => {
    const filterKey = key.charAt(0).toUpperCase() + key.slice(1);
    filters[filterKey] = filters[key];
    delete filters[key];
  });
};
