<form data-testid="adx-agent-booking-info-form" class="template-form adx-booking-info-form">  
    <div data-testid="adx-agent-booking-info-main-div" class="filter__fields">
        <h2 data-testid="adx-agent-booking-info-title" class="page-details-title-style">Agent {{_adxAgentBooking.agent?.adxAgentId}}</h2>
        <mat-card class="mat-elevation-z8">
            <mat-card-content>
                <mat-grid-list data-testid="adx-agent-booking-info-grid-list" [cols]="2" gutterSize="10px" rowHeight="90">
                    <mat-grid-tile data-testid="adx-agent-booking-info-name-grid-tile">
                        <read-only-form-field title="Name" testId="adx-agent-booking-info-name" value="{{_adxAgentBooking.agent?.agentName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-first-name-grid-tile">
                        <read-only-form-field title="First Name" testId="adx-agent-booking-info-first-name" value="{{_adxAgentBooking.agent?.firstName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-middle-name-grid-tile">
                        <read-only-form-field title="Middle Name" testId="adx-agent-booking-info-middle-name" value="{{_adxAgentBooking.agent?.middleName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-last-name-grid-tile">
                        <read-only-form-field title="Last Name" testId="adx-agent-booking-info-last-name" value="{{_adxAgentBooking.agent?.lastName}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-interface-grid-tile">
                        <read-only-form-field title="Interface" testId="adx-agent-booking-info-interface" value="{{_adxAgentBooking.agent?.interfaceId}}" />
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="adx-agent-booking-info-email-grid-tile">
                        <read-only-form-field title="Email" testId="adx-agent-booking-info-email" value="{{_adxAgentBooking.agent?.email}}" />
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-card-content>
        </mat-card>
    </div>
</form>