<div class="grid-title-event">
  <h2 data-testid="baintree-matching-vcc-list-title">Braintree VCC Matching Report</h2>
  <div *ngIf="items.length || itemsWithReleaseDate.length" class="events-container">
    <button data-testid="baintree-matching-vcc-refresh-button" aria-label="Refresh grid" mat-icon-button (click)="filters.releasedDateFrom || filters.releasedDateTo ? getWithRelaseDateItems() : getItems()">
      <mat-icon fontIcon="replay"></mat-icon>
    </button>
    <button color="primary" class="btn-download" data-testid="download-baintree-matching-vcc-button" aria-label="Download Braintree Matching Vcc File" mat-stroked-button (click)="filters.releasedDateFrom || filters.releasedDateTo ? downloadGridWithReleaseDateToFile() : downloadGridToFile()">
      <mat-icon>download</mat-icon>
    </button>
  </div>
</div>
<mat-table data-testid="baintree-matching-vcc-table" class="compact-mat-table" [dataSource]="filters.releasedDateFrom || filters.releasedDateTo ? itemsWithReleaseDate : items" (matSortChange)="handleSortChange($event)" matSort>

  <ng-container matColumnDef="clientNameAlias">
    <mat-header-cell data-testid="baintree-client-name-alias-header" class="mat-header-cell" style="min-width: 10%;" *matHeaderCellDef mat-sort-header>
      Client Name Alias
    </mat-header-cell>
    <mat-cell data-testid="baintree-client-name-alias-cell" *matCellDef="let element" class="mat-cell" [ngStyle]="{'background-color': getBackgroundColor(element)}" style="min-width: 10%;">
      <span>{{element?.clientNameAlias}}</span>
    </mat-cell>
  </ng-container>

  <ng-container *ngIf="filters.releasedDateFrom || filters.releasedDateTo" matColumnDef="releasedDate">
    <mat-header-cell data-testid="baintree-released-date-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Released Date
    </mat-header-cell>
    <mat-cell data-testid="baintree-released-date-name-cell" *matCellDef="let element" class="mat-cell" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.releasedDate | date:'M/d/yyyy'}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="payeeName">
    <mat-header-cell data-testid="baintree-payee-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Payee Name
    </mat-header-cell>
    <mat-cell data-testid="baintree-payee-name-cell" *matCellDef="let element" class="mat-cell" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.payeeName}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastFour">
    <mat-header-cell data-testid="baintree-last-four-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Last Four
    </mat-header-cell>
    <mat-cell data-testid="baintree-last-four-cell" *matCellDef="let element" class="mat-cell" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.lastFour }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell data-testid="baintree-amount-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Amount
    </mat-header-cell>
    <mat-cell data-testid="baintree-amount-cell" *matCellDef="let element" class="mat-cell" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.amount}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="orderNumber">
    <mat-header-cell data-testid="baintree-order-number-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Order Number
    </mat-header-cell>
    <mat-cell data-testid="baintree-order-number-cell" *matCellDef="let element" class="mat-cell" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.orderNumber}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="agentName">
    <mat-header-cell data-testid="baintree-agent-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Agent Name
    </mat-header-cell>
    <mat-cell data-testid="baintree-agent-name-cell" *matCellDef="let element" class="mat-cell" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.agentName}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="tramsPaymentDetailAmount">
    <mat-header-cell data-testid="baintree-trams-total-fare-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>
      Trams Total
    </mat-header-cell>
    <mat-cell data-testid="baintree-trams-total-fare-cell" *matCellDef="let element" class="mat-cell" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.tramsPaymentDetailAmount}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="bankName" class="hideOnMin">
    <mat-header-cell data-testid="baintree-trams-bank-name-header" class="mat-header-cell hideOnMin" *matHeaderCellDef mat-sort-header>
      Bank Name
    </mat-header-cell>
    <mat-cell data-testid="baintree-trams-bank-name-cell" *matCellDef="let element" class="mat-cell hideOnMin" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.bankName}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="branchName" class="hideOnMin">
    <mat-header-cell data-testid="baintree-trams-branch-name-header" class="mat-header-cell hideOnMin" *matHeaderCellDef mat-sort-header>
      Branch Name
    </mat-header-cell>
    <mat-cell data-testid="baintree-trams-invoice-record-locator-cell" *matCellDef="let element" class="mat-cell hideOnMin" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.branchName}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="dataSourceName" class="hideOnMin">
    <mat-header-cell data-testid="baintree-trams-source-name-header" class="mat-header-cell hideOnMin" *matHeaderCellDef mat-sort-header>
      Source Name
    </mat-header-cell>
    <mat-cell data-testid="baintree-trams-source-name-cell" *matCellDef="let element" class="mat-cell hideOnMin" [ngStyle]="{'background-color': getBackgroundColor(element)}">
      <span>{{element?.dataSourceName}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions" class="hideOnMin">
    <mat-header-cell data-testid="baintree-action-button-header" class="mat-header-cell hideOnMin" *matHeaderCellDef mat-sort-header>
      Actions
    </mat-header-cell>
    <mat-cell data-testid="baintree-action-button-cell" *matCellDef="let element" class="mat-cell hideOnMin">
      <div class="flexible-content">
        <button mat-icon-button aria-label="edit braintree payemnt" (click)="openEditModal(element)" [ngStyle]="{'display': getDisplayIcon(element)}">
          <mat-icon fontIcon="edit" />
        </button>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></mat-row>
</mat-table>
<mat-paginator data-testid="baintree-matching-vcc-paginator"
               [length]="pagination.totalItemCount"
               [pageSizeOptions]="[25, 50, 75, 100]"
               [pageSize]="pagination.pageSize"
               [pageIndex]="pagination.pageIndex"
               (page)="handlePageChanged($event)"></mat-paginator>
