import {Component, Input} from '@angular/core';
import {DatePipe} from '@angular/common';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AdxPaymentByIdResponse} from '../../model/adx-payment-by-id-response';

@Component({
  selector: 'app-adx-payment-info',
  templateUrl: './adx-payment-info.component.html'
})

@AutoUnsubscribe()
export class AdxPaymentInfoComponent {
  @Input() public data: any;
  _adxPayment!: AdxPaymentByIdResponse;
  infoInvoiceField = {
    icon: 'open_in_new',
    tooltip: 'Navigate to invoice'
  }

constructor(private datePipe: DatePipe
) {
}

  ngOnInit(){
    this.getAdxPaymentInfo();
  }

  getAdxPaymentInfo() {
    if (this.data != null){
      this._adxPayment = this.data;
    }
  } 

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM/dd/yyyy') || '';
  }

  hasRedirectInvoice(): boolean {
    return this._adxPayment.adxInvoiceId! > 0 && !this._adxPayment.isModal;
  }

  redirectToInvoice(): void {
    if(this.hasRedirectInvoice()) {
      location.href = `AdxInvoices/${this._adxPayment.adxInvoiceId}`;
    }
  }

  hasRedirectBooking(): boolean {
    return this._adxPayment.adxBookingId! > 0 && !this._adxPayment.isModal;
  }

  redirectToBooking(): void {
    if(this.hasRedirectBooking()) {
      location.href = `AdxBookings/${this._adxPayment.adxBookingId}`;
    }
  }
}
