import {Component, Input} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'app-adx-booking-udid-info',
  templateUrl: './adx-booking-udid-info.component.html'
})

@AutoUnsubscribe()
export class AdxBookingUdidInfoComponent {
  @Input() public data: any;
  _adxBookingUdids : any;

  constructor() {
  }

  ngOnInit(){
    this.getAdxBookingUdidInfo();
  }

  getAdxBookingUdidInfo() {
    if (this.data != null){
      this._adxBookingUdids = this.data;
    }
  }
}