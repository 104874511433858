import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReconciliationModel} from '../../models/client-reconciliation';
import {AdxPaymentService} from '../../service/adx-payment.service';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {MaskedCardPipe} from 'src/infrastructure/pipes/masked-card.pipe';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {MatSnackBar} from '@angular/material/snack-bar';

@AutoUnsubscribe()
@Component({
    selector: 'client-payments-edit-modal',
    templateUrl: './client-payments-edit-modal.component.html'
})
export class ClientPaymentsEditModalComponent implements OnInit{

    displayedColumns: string[] = [
        'adxInvoiceId',
        'adxBookingId',
        'adxBookingTravelTypeName',
        'adxBookingVendorName',
        'totalFare',
    ];

    loadedData = false;
    submitedData = true;
    dataSource: any [] = [];

    formChanges$?: Subscription;
    dynamicFormGroup?: FormGroup;
    initialValues: Record<string,string> = {};
    hasChanges = false;

    amountPattern = /^-?\d{1,10}(\.\d{1,4})?$/;

    pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
    sorting: Partial<Sort> = {};

    constructor(
        @Optional() public dialogRef: MatDialogRef<ClientPaymentsEditModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReconciliationModel,
        private adxPaymentService: AdxPaymentService,
        private maskedCardPipe: MaskedCardPipe,
        private snackBar: MatSnackBar,
    ){}

    ngOnInit(): void {
        if(!this.data){
            this.dialogRef.close();
        }

        this.pagination = {
            pageSize: this.pagination.pageSize || 25,
            pageIndex: 0,
            totalItemCount: 0,
        };

        this.getItems();
    }

    buildForm(): void {
        const formControllers = this.getFormControlers();
        this.dynamicFormGroup = new FormGroup(formControllers);

        this.dataSource.forEach((service)=>{
            this.initialValues[`${service.adxBookingId}`] = `${service.totalFare}`;
        });

        this.formChanges$?.unsubscribe();

        this.formChanges$ = this.dynamicFormGroup.valueChanges.subscribe((formValues: Record<string,string>)=>{
            this.hasChanges = false;
            const entries = Object.entries(formValues);
            for(const value of entries) {
                if(value[1]!== '' && value[1] !== null) {
                    this.hasChanges = true;
                }
            }
        });
    }

    getFormControlers(): Record<string, FormControl> {
        const formList: Record<string, FormControl> = {}
        this.dataSource.forEach((service)=>{
            formList[service.adxBookingId] = new FormControl('', Validators.pattern(this.amountPattern));
        });

        return formList;
    }

    handleSortChange(sort: Sort): void { 
        this.sorting = sort;
        
        if(!this.sorting?.active || !this.sorting?.direction) {
            this.sorting = {};
        }
          
        this.getItems();
    }
            
    handlePageChanged(page: PageEvent): void {
        this.pagination = {
            ...this.pagination,
            ...page,
        };
        
        this.getItems();
    }

    getItems(): void {
        this.loadedData = false;
        this.adxPaymentService.searchAdxReconciliation({
            filters: {
                ccNoLastFourDigits:  this.maskedCardPipe.transform(this.data.lastFour, ''),
                tripExternalReference: this.data.adxReference,
                vendorName: this.data.vendorName
            },
            page: this.pagination,
            sort: this.sorting,
        }).subscribe((data) => {
            console.log(data);
            this.dataSource = [];
            data?.items.forEach((item: any) => {
              this.dataSource.push(item);
            });
            console.log(this.dataSource);
            this.pagination = {
            ...this.pagination,
            pageIndex: data.pagination?.pageNumber ? data.pagination.pageNumber - 1 : 0,
            pageSize: data.pagination?.pageSize || this.pagination.pageSize || 25,
              totalItemCount: data.pagination?.totalItemCount || this.dataSource.length,
            };
            this.loadedData = true;
            this.buildForm();
        });
    }

    isDisabled(): boolean {
        return !this.dynamicFormGroup || this.dynamicFormGroup.pristine || !this.hasChanges || this.dynamicFormGroup.invalid || !this.submitedData; 
    }

    updateAmounts(): void {
        this.submitedData = false;
        const entries = Object.entries(this.dynamicFormGroup?.value);
        const updates$: Observable<any>[] = []

        entries.forEach((formUpdates)=>{
            if(formUpdates[1] !== '' && formUpdates[1] !== null && !isNaN(Number(formUpdates[1]))) {
                updates$.push(
                  this.adxPaymentService.adjustmentAdxPaymentReconciliation(
                        {
                            adxBookingId: formUpdates[0] as unknown as number,
                            amount: Number(formUpdates[1]) - Number(this.initialValues[formUpdates[0]]),
                            payType: 2,
                            payMethod: 3,
                            profileType: 'V',
                            adxTripId: this.dataSource[0].tripId,
                            bankId: this.data.fmsBankId,
                            branchId: this.data.fmsBranchId,
                            ckCcNo: this.data.originalCkCcNo,
                            payeeName: this.data.fmsPayeeName
                        }
                    )
                )
            }
        });

        this.snackBar.open('Service adjusted successfully', 'Dismiss', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
        });

        forkJoin(updates$).subscribe({
            next: ()=>{
                this.getItems();
                this.submitedData = true;
            },
            error: ()=>{
                this.submitedData = true;
            }
        });
    }

    closeModal(): void {
        this.dialogRef.close();
    }
}
