<h2 mat-dialog-title>Assigned segments for {{bookingNo}}:</h2>
<hr>
<mat-dialog-content>
    <ul>
        <mat-list>
            @for (segment of segments; track segment) {
                <li class="li-style-initial">
                     @if (segment.flightNumber) {
                        <mat-list-item>Flight Number: {{segment.flightNumber}}</mat-list-item>
                    }
                    @if (segment.departDateTime) {
                        <mat-list-item>Depart Date Time: {{segment.departDateTime}}</mat-list-item>
                    }
                    @if (segment.arrivalDateTime) {
                        <mat-list-item>Arrival Date Time: {{segment.arrivalDateTime}}</mat-list-item>
                    }
                    @if (segment.fareBasesCode) {
                        <mat-list-item>Fare Bases Code: {{segment.fareBasesCode}}</mat-list-item>
                    }
                    @if (segment.fare) {
                        <mat-list-item>Fare: {{segment.fare}}</mat-list-item>
                    }
                    @if (segment.departureCity) {
                        <mat-list-item>Departure City: {{segment.departureCity}}</mat-list-item>
                    }
                    @if (segment.arrivalCity) {
                        <mat-list-item>Arrival City: {{segment.arrivalCity}}</mat-list-item>
                    }
                    @if (segment.airlineCode) {
                        <mat-list-item>Airline Code: {{segment.airlineCode}}</mat-list-item>
                    }
                    @if (segment.classOfService) {
                        <mat-list-item>Class of Service: {{segment.classOfService}}</mat-list-item>
                    }
                </li>
            }
        </mat-list>
    </ul>
</mat-dialog-content>
<hr>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>